import { loop, Cmd } from 'redux-loop';
import { PositionActions } from './index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';

export const createReducer = ({ assetName, actions }) => {
    const initialState = {
        position: null,
        loading: false,
        error: null,
    };

    const reducer = (state: any = initialState, action) => {
        switch (action.type) {
            case `${assetName}_FETCH_INIT`:
                return loop(
                    { ...state, loading: true },
                    Cmd.run(actions.fetch, {
                        successActionCreator: actions.fetchSuccess,
                        failActionCreator: actions.fetchError,
                        args: [action.accountNumber],
                    }),
                );
            case `${assetName}_FETCH_SUCCESS`:
                return {
                    ...state,
                    position: action.position,
                    error: false,
                    loading: false,
                };
            case `${assetName}_FETCH_ERROR`:
                return { ...state, error: action.error, loading: false };
            case PositionActions.POSITIONS_FETCH:
                return loop(
                    { ...state },
                    Cmd.action({
                        type: `${assetName}_FETCH_INIT`,
                        accountNumber: action.accountNumber,
                    }),
                );
            case AuthActions.AUTH_LOGOUT:
                return { ...initialState };
            default:
                return state;
        }
    };

    return reducer;
};
