import { Cmd, loop } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchBanners, fetchBannersSuccess, fetchBannersError } from './index.actions';
import { BannersState } from './index.types';

export const initialState: BannersState = {
    banners: [],
    wasBannerRead: undefined,
    loading: false,
    error: undefined,
};

export const bannersReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.BANNERS_FETCH_INIT:
            return loop(
                { ...state, loading: true, error: undefined },
                Cmd.run(fetchBanners, {
                    successActionCreator: fetchBannersSuccess,
                    failActionCreator: fetchBannersError,
                }),
            );

        case Actions.BANNERS_FETCH_SUCCESS:
            return { ...state, banners: action.banners, loading: false, error: undefined };

        case Actions.BANNERS_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.BANNERS_SET:
            return { ...state, banners: action.banners };

        case Actions.BANNERS_SET_READ_STATUS:
            return { ...state, wasBannerRead: action.readStatus };

        default:
            return state;
    }
};
