export const Actions = {
    FETCH_SUITABILITY_PROFILE_INIT: 'FETCH_SUITABILITY_PROFILE_INIT',
    FETCH_SUITABILITY_PROFILE_SUCCESS: 'FETCH_SUITABILITY_PROFILE_SUCCESS',
    FETCH_SUITABILITY_PROFILE_ERROR: 'FETCH_SUITABILITY_PROFILE_ERROR',
    FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_INIT:
        'FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_INIT',
    FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_SUCCESS:
        'FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_SUCCESS',
    EMPTY_SUITABILITY_PROFILE: 'EMPTY_SUITABILITY_PROFILE',
    FILL_SUITABILITY_PROFILE_MODAL: 'FILL_SUITABILITY_PROFILE_MODAL',
    SET_FILL_SUITABILITY_PROFILE_MODAL: 'SET_FILL_SUITABILITY_PROFILE_MODAL',
    SET_SUITABILITY_PROFILE: 'SET_SUITABILITY_PROFILE',
    SET_LOADING_POST_SUITABILITY: 'SET_LOADING_POST_SUITABILITY',
};

export interface Profile {
    Id?: number;
    ClientId?: number;
    Version?: number;
    UserProfile?: UserProfileEnum;
    UserProfileName?: string;
    Validity?: Date;
    UserIdInsert?: number;
    UserIdUpdate?: number;
    InsertDate?: Date;
    UpdateDate?: Date;
    Observation?: string;
    LastAnsweredVersion?: number;
    LastAnsweredUserProfile?: LastAnsweredUserProfileEnum;
    LastAnsweredUserProfileName?: string;
    Questions?: Array<Question>;
}

export enum UserProfileEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

export enum LastAnsweredUserProfileEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

export interface Question {
    Id?: number;
    QuestionNumber?: string;
    QuestionId?: number;
    ParentQuestionId?: number;
    Description?: string;
    Type?: QuestionTypeEnum;
    Alternatives?: Array<Alternative>;
    Answers?: Array<Answer>;
    Questions?: Array<Question>;
}

export enum QuestionTypeEnum {
    NUMBER_0 = 0, // only one alternative
    NUMBER_1 = 1, // multiple alternatives
    NUMBER_2 = 2, // parent
}

export interface Alternative {
    Id?: number;
    Description?: string;
    Value?: number;
}

export interface Answer {
    Id?: number;
}

export interface RegisterProfile {
    UserId?: number;
    ClientId?: number;
    Questions?: Array<ProfileQuestion>;
    CommandName?: string;
    IpAddress?: string;
}

export interface UpdateProfile {
    Id?: number;
    UserId?: number;
    ClientId?: number;
    Questions?: Array<ProfileQuestion>;
    CommandName?: string;
    IpAddress?: string;
}

export interface RegisterProfileClient {
    Id?: number;
    UserIdAdm?: number;
    Observation?: string;
    ClientId?: number;
    Questions?: Array<ProfileQuestion>;
    CommandName?: string;
    IpAddress?: string;
}

export interface ProfileQuestion {
    Id?: number;
    Answers?: Array<number>;
}

export interface RegisterProfileResult {
    Id?: number;
}

export interface UpdateProfileClient {
    Id?: number;
    UserIdAdm?: number;
    Observation?: string;
    ClientId?: number;
    Questions?: Array<ProfileQuestion>;
    CommandName?: string;
    IpAddress?: string;
}

export interface ProfileByAccount {
    Id?: number;
    Version?: number;
    UserProfile?: UserProfileEnum;
    UserProfileName?: string;
    AccountNumber?: number;
    ClientId?: number;
    ClientName?: string;
    InsertDate?: Date;
    UpdateDate?: Date;
}
