import * as React from 'react';

import { InputText, InputDate } from '@terrainvest/react-components';

import { Field, SelectField } from '../../Form/Field';

import { CommonPrefixes } from '../../../../Redux/Common/index.constants';

import { StyledRow, StyledCol, StyledSelect } from '../../Details/index.styles';
import { StyledHelperImage } from '../index.styles';

import { fieldLengthLimits } from '../../index.settings';

import { validateRequiredField, validateDate, validateFields } from '../../index.validators';

import CNHNumber from '../assets/nCNH.png';
import CNHSafetyCode from '../assets/cdSegCNH.png';
import CNHValidateDate from '../assets/dtExp.png';

const CNHForm = ({ showOptionalField }) => (
    <StyledRow>
        <StyledCol md={6} sm={12}>
            <Field name="DocumentNumber" validate={value => validateFields('cnhNumber', value)}>
                {({ inputProps }) => (
                    <InputText
                        {...inputProps}
                        id="cnh-number"
                        label="Número da CNH"
                        fullWidth
                        helperContent={<StyledHelperImage src={CNHNumber} />}
                        maxLength={fieldLengthLimits.cnhNumber}
                    />
                )}
            </Field>
        </StyledCol>
        {showOptionalField && (
            <StyledCol md={6} sm={12}>
                <Field name="DocumentIssuerDate" validate={validateDate}>
                    {({ inputProps }) => (
                        <InputDate
                            {...inputProps}
                            id="rg-validate-date"
                            label="Data de expedição"
                            fullWidth
                            helperContent={<StyledHelperImage src={CNHValidateDate} />}
                        />
                    )}
                </Field>
            </StyledCol>
        )}
        <StyledCol md={6} sm={12}>
            <SelectField
                name="DocumentIssuerName"
                commonPrefix={CommonPrefixes.DocumentIssuerAgencies}
                validate={validateRequiredField}
            >
                {({ field, form: { errors }, onChange, options, defaultValue }) => (
                    <StyledSelect
                        name={field.name}
                        label="Emissor"
                        fullWidth
                        suggest
                        suggestLimit={8}
                        error={!!errors[field.name]}
                        message={errors[field.name] || ''}
                        defaultValue={defaultValue}
                        options={options}
                        onChange={onChange}
                    />
                )}
            </SelectField>
        </StyledCol>
        {showOptionalField && (
            <StyledCol md={6} sm={12}>
                <SelectField
                    name="DocumentIssuerState"
                    commonPrefix={CommonPrefixes.States}
                    validate={validateRequiredField}
                >
                    {({ field, form: { errors }, onChange, options, defaultValue }) => (
                        <StyledSelect
                            name={field.name}
                            label="UF"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!errors[field.name]}
                            message={errors[field.name] || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectField>
            </StyledCol>
        )}
        {showOptionalField && (
            <StyledCol sm={12} md={12} xl={12}>
                <Field
                    name="DocumentSecondaryNumber"
                    validate={value => validateFields('safetyCode', value)}
                >
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            id="cnh-security-code"
                            label="Código de segurança"
                            fullWidth
                            helperContent={<StyledHelperImage src={CNHSafetyCode} />}
                            maxLength={fieldLengthLimits.safetyCode}
                        />
                    )}
                </Field>
            </StyledCol>
        )}
    </StyledRow>
);

export default CNHForm;
