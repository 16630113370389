import { Actions } from './index.constants';
import { normalizeFundsHistory } from './index.normalizer';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { parseDateForAPI } from '../../Utils/Parsers';

export const fetchPositionFundsHistoryInit = (accountNumber, startDate, endDate) => ({
    type: Actions.POSITION_FUNDS_HISTORY_FETCH_INIT,
    accountNumber,
    startDate: parseDateForAPI(startDate),
    endDate: parseDateForAPI(endDate),
});

export const fetchPositionFundsHistoryAssets = (accountNumber, startDate, endDate) => {
    return fetch(
        ENDPOINTS.GET_FUND_HISTORY.replace(':accountNumber', accountNumber)
            .replace(':startDate', startDate)
            .replace(':endDate', endDate),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeFundsHistory);
};

export const fetchPositionFundsHistorySuccess = history => ({
    type: Actions.POSITION_FUNDS_HISTORY_FETCH_SUCCESS,
    history,
});

export const fetchPositionFundsHistoryError = error => ({
    type: Actions.POSITION_FUNDS_HISTORY_FETCH_ERROR,
    error: error.message,
});
