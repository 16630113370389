import * as React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton, formatCurrency } from '@terrainvest/react-components';

import { RootState } from '../../store';
import { feedbackNoFinancialData } from '../Common/TextualInfo';
import * as S from './index.styles';

export const FinancialOverview = ({ onlyValues = false }) => {
    const { balance, loading, error, visible } = useSelector(
        (state: RootState) => state.balanceState,
    );
    const [d1Balance, setD1BalanceState] = React.useState(0);
    const [d2Balance, setD2BalanceState] = React.useState(0);

    const replaceString = '--';

    const isInvestmentsNegative = visible && balance && balance.Investments < 0;
    const isAssetsBalanceNegative = visible && balance && balance.Total < 0;
    const isBalanceAvailableNegative = visible && balance && balance.Available < 0;
    const isProjectedBalanceNegative = visible && balance && balance.ProjectedBalance < 0;
    const isD1BalanceNegative = visible && balance && d1Balance && d1Balance < 0;
    const isD2BalanceNegative = visible && balance && d2Balance && d2Balance < 0;

    React.useEffect(() => {
        if (!balance) {
            return;
        }

        const d1Cred = balance.Credits.filter(e => e.Day === 1).pop();
        const d1Debit = balance.Debits.filter(e => e.Day === 1).pop();

        const d2Cred = balance.Credits.filter(e => e.Day === 2).pop();
        const d2Debit = balance.Debits.filter(e => e.Day === 2).pop();

        const d1BalanceTotal = d1Cred.Value - d1Debit.Value;
        const d2BalanceTotal = d2Cred.Value - d2Debit.Value;

        setD1BalanceState(d1BalanceTotal);
        setD2BalanceState(d2BalanceTotal);
    }, [balance]);

    const renderTitle = rawValue => {
        const loadingFeedback = (!rawValue && rawValue !== 0) || loading;

        const renderLoadingFeedback = { title: <Skeleton height={23} width={100} /> };
        const renderDataFeedback = {
            title:
                visible && (rawValue || rawValue === 0) ? formatCurrency(rawValue) : replaceString,
        };

        return loadingFeedback ? renderLoadingFeedback : renderDataFeedback;
    };

    const renderContent = () => {
        const isLoadingOrHasData = loading || (!loading && !error && balance);
        const hasError = !loading && error;

        if (isLoadingOrHasData) {
            return displayDynamicContent();
        }

        if (hasError) {
            return <S.StyledNoDataFeedback>{feedbackNoFinancialData}</S.StyledNoDataFeedback>;
        }

        return null;
    };

    const displayDynamicContent = () => {
        return (
            <S.StyledInfo>
                <S.StyledHighlightedBalanceText
                    small
                    subTitle="Meus Investimentos"
                    isNegative={isInvestmentsNegative}
                    {...renderTitle(balance && balance.Investments)}
                />
                <S.StyledHighlightedBalanceText
                    small
                    subTitle="Saldo Disp."
                    isNegative={isBalanceAvailableNegative}
                    {...renderTitle(balance && balance.Available)}
                />
                <S.StyledHighlightedBalanceText
                    small
                    subTitle="Patrimônio"
                    isNegative={isAssetsBalanceNegative}
                    {...renderTitle(balance && balance.Total)}
                />
                <S.StyledHighlightedBalanceText
                    small
                    subTitle="D+1"
                    isNegative={isD1BalanceNegative}
                    {...renderTitle(balance && d1Balance)}
                />
                <S.StyledHighlightedBalanceText
                    small
                    subTitle="D+2"
                    isNegative={isD2BalanceNegative}
                    {...renderTitle(balance && d2Balance)}
                />
                {onlyValues && (
                    <S.StyledHighlightedBalanceText
                        small
                        subTitle="Saldo Projetado"
                        isNegative={isProjectedBalanceNegative}
                        {...renderTitle(balance && balance.ProjectedBalance)}
                    />
                )}
            </S.StyledInfo>
        );
    };

    if (onlyValues) return <S.StyledWrapperHorizontal>{renderContent()}</S.StyledWrapperHorizontal>;

    return (
        <S.StyledWrapper>
            {renderContent()}
            {balance && <S.StyledHiddenBalanceSwitch iconType="far" />}
        </S.StyledWrapper>
    );
};

export default FinancialOverview;
