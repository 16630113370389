import * as React from 'react';
import * as PropTypes from 'prop-types';

import { Popover, useId } from '@terrainvest/react-components';

import { StyledIcon, StyledHelperContainer } from './index.styles';

const HelpButton = ({ content, padLeft, padRight, size, messageWidth, icon }) => {
    const [open, setOpen] = React.useState(false);
    const [id] = useId('help-button-');

    return (
        <StyledHelperContainer>
            <StyledIcon
                id={id}
                icon={icon}
                onClick={() => setOpen(prev => !prev)}
                padLeft={padLeft}
                padRight={padRight}
                size={size}
            />
            <Popover
                bindTo={id}
                open={open}
                onClose={() => setOpen(false)}
                width={messageWidth}
                enableMobile
                darkMode
            >
                {content}
            </Popover>
        </StyledHelperContainer>
    );
};

HelpButton.defaultProps = {
    padLeft: false,
    padRight: false,
    size: '20px',
    messageWidth: '',
    icon: ['fas', 'circle-question'],
};

HelpButton.propTypes = {
    padLeft: PropTypes.bool,
    padRight: PropTypes.bool,
    content: PropTypes.node.isRequired,
    size: PropTypes.string,
    messageWidth: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string),
};

export default HelpButton;
