import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizeBrokerage } from './index.normalize';
import { responseToFile } from '../../../Utils/Parsers';

export const fetchBrokerageNoteInit = () => ({
    type: Actions.FETCH_BROKERAGENOTE_INIT,
});

export const fetchBrokerageNote = () => {
    return fetch(ENDPOINTS.GET_BROKERAGENOTE_MARKET, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeBrokerage);
};

export const fetchBrokerageNoteSuccess = markets => ({
    type: Actions.FETCH_BROKERAGENOTE_SUCCESS,
    markets,
});

export const fetchBrokerageNoteError = error => ({
    type: Actions.FETCH_BROKERAGENOTE_ERROR,
    error: error.message,
});

export const fetchBrokerageNoteReset = () => ({
    type: Actions.FETCH_BROKERAGENOTE_RESET,
});

export const mountBrokerageReportUrl = data =>
    ENDPOINTS.GET_BROKERAGENOTE_SUBMIT.replace(':accountNumber', data.account)
        .replace(':marketType', data.marketType)
        .replace(':startDate', data.startDate)
        .replace(':endDate', data.endDate);

export const fetchBrokerageReport = async data => {
    const url = mountBrokerageReportUrl(data);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = 'relatorio_nota_corretagem.pdf';
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
};

export const fetchBrokerageNoteFile = async id => {
    const url = ENDPOINTS.GET_BROKERAGE_NOTE_FILE.replace(':id', id);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = 'relatorio_nota_corretagem.pdf';
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
};

export const postBrokerageNoteSubmit = payload => {
    return fetch(ENDPOINTS.POST_BROKERAGE_NOTE_SUBMIT, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => {
        if (response.status && response.status !== 200) {
            throw new Error(response.status.toString());
        }
        return response;
    });
};

export const fetchBrokerageNotesResults = () => {
    return fetch(ENDPOINTS.GET_BROKERAGE_NOTE_RESULTS, {
        method: 'GET',
    }).then(response => response.json());
};

export const mountBrokerageFAXReportUrl = data =>
    ENDPOINTS.GET_BROKERAGENOTE_FAX_SUBMIT.replace(':accountNumber', data.account)
        .replace(':date', data.date)
        .replace(':marketType', 'BOV');

export const fetchBrokerageFAXReport = async data => {
    const url = mountBrokerageFAXReportUrl(data);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = 'relatorio_nota_fax.pdf';
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
};
