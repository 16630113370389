import styled from 'styled-components';
import { StyledRowNoPadd, StyledColNoPadd } from '../../../Common/Grid';

export const StyledRow = styled(StyledRowNoPadd)`
    margin: 8px -16px 0 -16px;
`;

export const StyledCol = styled(StyledColNoPadd)`
    margin-bottom: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
`;

export const StyledHeaderAddedItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

export const StyledRemoveItem = styled.div`
    cursor: pointer;
    margin-left: auto;
`;
