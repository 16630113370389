import { normalizeString } from '../../Utils/Parsers/stringParsers';

export const normalizeAdvisorPhoneNumber = incomingObj => {
    const normalizedObj = { ...incomingObj };

    const trimmedDDDPhone = normalizeString(normalizedObj.DddCommercialPhone);
    const trimmedPhone = normalizeString(normalizedObj.CommercialPhone);

    const split = trimmedPhone.split('');
    split.splice(trimmedPhone.length - 4, 0, '-');

    const hyphonated = split.join('').trim();

    const fullPhone = `(${trimmedDDDPhone}) ${hyphonated}`;
    normalizedObj.normalized = { phone: fullPhone };

    return normalizedObj;
};

export const normalizeAdvisor = advisor => {
    return normalizeAdvisorPhoneNumber(advisor);
};
