import moment from 'moment';

export const Actions = {
    SUBSCRIPTION_FETCH_INIT: 'SUBSCRIPTION_FETCH_INIT',
    SUBSCRIPTION_FETCH_SUCCESS: 'SUBSCRIPTION_FETCH_SUCCESS',
    SUBSCRIPTION_FETCH_ERROR: 'SUBSCRIPTION_FETCH_ERROR',
    SUBSCRIPTION_CHANGE_TAB_INIT: 'SUBSCRIPTION_CHANGE_TAB_INIT',
    SUBSCRIPTION_CHANGE_TAB_SUCCESS: 'SUBSCRIPTION_CHANGE_TAB_SUCCESS',
    SUBSCRIPTION_CHANGE_TAB_ERROR: 'SUBSCRIPTION_CHANGE_TAB_ERROR',
    SUBSCRIPTION_HISTORY_FETCH_INIT: 'SUBSCRIPTION_HISTORY_FETCH_INIT',
    SUBSCRIPTION_HISTORY_FETCH_SUCCESS: 'SUBSCRIPTION_HISTORY_FETCH_SUCCESS',
    SUBSCRIPTION_HISTORY_FETCH_ERROR: 'SUBSCRIPTION_HISTORY_FETCH_ERROR',
};

const replace = (value: number) => {
    return String(value).replace('.', ',');
};

export const format = ({ type, value }) => {
    if (type === 'coin' && value !== null) {
        return `R$ ${replace(value)}`;
    }
    if (type === 'percentage' && value !== null) {
        return `${replace(value)} %`;
    }
    if (type === 'date' && value !== null) {
        const date = moment(value).format('DD/MM/YYYY');
        return date;
    }
    if (type === 'text' && value !== null) {
        return value;
    }
    return '--';
};
