import { media } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

export const CardsWrapper = styled.div`
    display: flex;
    column-gap: 1.5%;
    margin-top: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    ${media.max.tablet`
        max-width: 450px;
        flex-direction: column-reverse;
        gap: ${({ theme }) => theme.global.spaces.xRay};
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    `}
`;

export const CardsWrapperNoReversed = styled(CardsWrapper)`
    ${media.max.tablet`
        flex-direction: column;
    `}
`;

const getTypeGlobalColor = (type: string, theme) => {
    switch (type) {
        case 'success':
            return theme.global.colorAliases.success;

        case 'info':
            return theme.global.colorAliases.info;

        case 'warn':
            return theme.global.colorAliases.warn;

        case 'error':
            return theme.global.colorAliases.error;

        case 'errordark':
            return '#be123c';

        case 'warndark':
            return '#f97316';

        default:
            return 'white';
    }
};

const getColoredStyles = (type: string, theme) => {
    return css`
        box-shadow: ${theme.components.card.shadow},
            inset 0 0 0 1px ${getTypeGlobalColor(type, theme)};
    `;
};

export const Card = styled.div<{ colored?: string }>`
    position: relative;
    width: 100%;
    min-height: 481px;
    border-radius: 5px;
    background: white;
    box-shadow: ${({ theme }) => theme.components.card.shadow};
    box-sizing: border-box;
    ${({ colored, theme }) => colored && getColoredStyles(colored, theme)};
    ${media.max.tablet`
        min-height: 100px;
        background: transparent;
        box-shadow: none;
        border: none;
    `}
`;
