import * as React from 'react';
import ViewRouter from './ViewRouter';
import ErrorBoundary from '../ErrorBoundary';

const View = () => (
    <ErrorBoundary>
        <ViewRouter />
    </ErrorBoundary>
);

export default View;
