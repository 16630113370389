import { fetchNoAuthorization } from '../../Utils/Api/fetchAWS';
import { responseToFile } from '../../Utils/Parsers';
import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import {
    normalizePlatform,
    normalizePlatformsAndTypes,
    normalizePortfolioPlatforms,
} from './index.normalizers';

export const fetchClientPlatformsInit = selectedAccountId => ({
    type: Actions.CLIENT_PLATFORMS_FETCH_INIT,
    accountId: selectedAccountId,
});

export const fetchClientPlatforms = accountId => {
    return Promise.all([
        fetch(ENDPOINTS.GET_CLIENT_PLATFORMS.replace(':accountId', accountId), {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=utf-8',
            },
        }),
        fetch(ENDPOINTS.GET_PLATFORMS, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=utf-8',
            },
        }),
    ])
        .then(responses => {
            if (responses[0].ok && responses[1].ok) {
                return Promise.all([responses[0].json(), responses[1].json()]);
            }
            return Promise.reject();
        })
        .then(normalizePlatformsAndTypes);
};

export const fetchPlatformById = platformId => {
    return fetch(ENDPOINTS.GET_PLATFORM.replace(':id', platformId), {
        method: 'GET',
        headers: {
            'content-type': 'application/json; charset=utf-8',
        },
    })
        .then(response => response.json())
        .then(normalizePlatform);
};

export const fetchClientPlatformsSuccess = data => ({
    type: Actions.CLIENT_PLATFORMS_FETCH_SUCCESS,
    clientPlatforms: data[0] || [],
    platforms: data[1] || [],
});

export const fetchClientPlatformsError = () => ({
    type: Actions.CLIENT_PLATFORMS_FETCH_ERROR,
});

export const acquirePlatform = payload => {
    return fetch(ENDPOINTS.POST_ACQUIRE_PLATFORM, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const cancelPlatform = payload => {
    return fetch(ENDPOINTS.DELETE_PLATFORM, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const changeMetaTraderPassword = ({
    accountId,
    platformId,
    newPassword,
    electronicCheck,
}) => {
    return fetch(ENDPOINTS.POST_CHANGE_META_TRADER_PASSWORD, {
        method: 'POST',
        body: JSON.stringify({
            Account: accountId,
            PlatformId: platformId,
            NewPassword: newPassword,
            ElectronicCheck: electronicCheck,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const acquireTradeMachine = eletronicCheck => {
    return fetch(ENDPOINTS.POST_ACQUIRE_TRADEMACHINE, {
        method: 'POST',
        body: JSON.stringify({ ElectronicCheck: eletronicCheck }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => response.json());
};

export const cancelTradeMachine = eletronicCheck => {
    return fetch(ENDPOINTS.POST_ACQUIRE_TRADEMACHINE, {
        method: 'POST',
        body: JSON.stringify({ ElectronicCheck: eletronicCheck }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => response.json());
};

export const fetchClientAcquiredPlatforms = accountId =>
    fetch(ENDPOINTS.GET_CLIENT_PLATFORMS.replace(':accountId', accountId), {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => {
        response.json();
    });

export const fetchPortfolioPlatforms = () => {
    const url = ENDPOINTS.GET_NEW_PLATFORMS;

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizePortfolioPlatforms);
};

export const getTermFile = async (url, filename, useBaseUrl) => {
    const response = await fetchNoAuthorization(url, {
        method: 'GET',
        useBaseUrl,
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

export const getTermFileWithAuthorization = async (url, filename, useBaseUrl = true) => {
    const response = await fetch(url, {
        method: 'GET',
        useBaseUrl,
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};
