import { fetch, ENDPOINTS } from '../../Utils/Api';
import { Actions } from './index.constants';
import { normalizer } from './index.normalize';

export const fetchRetailGuaranteeInit = account => ({
    type: Actions.RETAIL_GUARANTEE_FETCH_INIT,
    account,
});

export const fetchRetailGuarantee = account => {
    const url = ENDPOINTS.GET_RETAIL_GARANTEE.replace(':account', account);

    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizer);
};

export const fetchRetailGuaranteeSuccess = retail => ({
    type: Actions.RETAIL_GUARANTEE_FETCH_SUCCESS,
    retail,
});

export const fetchRetailGuaranteeError = () => ({
    type: Actions.RETAIL_GUARANTEE_FETCH_ERROR,
});

export const setStatus = status => ({
    type: Actions.SET_RETAIL_GUARANTEE_STATUS,
    status,
});

export const postAdhere = params =>
    fetch(ENDPOINTS.POST_RETAIL_GARANTEE_ADHERE, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const postCancel = params =>
    fetch(ENDPOINTS.POST_RETAIL_GARANTEE_CANCEL, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const postClientAgreement = (AgreementId, ClientId, EletronicCheck) => {
    return fetch(ENDPOINTS.POST_RETAIL_GARANTEE_FILE, {
        method: 'POST',
        body: JSON.stringify({
            AgreementType: AgreementId,
            ClientId,
            EletronicCheck,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
