import { formatCurrency } from '@terrainvest/react-components';

const NORMALIZE_DISPLAY_FIELDS = [
    {
        key: 'ValorAceitoGarantia',
        label: 'Valor',
        normalizer: value => formatCurrency(value),
    },
];

export const normalizeCollateralAsset = incomingAsset => {
    const normalizedObj = { ...incomingAsset, normalized: {} };

    NORMALIZE_DISPLAY_FIELDS.forEach(field => {
        const normalizedField = {
            label: field.label,
            rawValue: normalizedObj[field.key],
            value: field.normalizer(normalizedObj[field.key]),
        };

        normalizedObj.normalized = { ...normalizedObj.normalized, [field.key]: normalizedField };
    });

    return normalizedObj;
};

export default normalizeCollateralAsset;
