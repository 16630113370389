import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchRecommendations,
    fetchRecommendationsSuccess,
    fetchRecommendationsError,
} from './index.actions';

export const initialState = {
    table: null,
    loading: false,
    error: null,
};

export const recommendationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_RECOMMENDATIONS_INIT:
            return loop(
                { ...state, loading: true, error: null },
                Cmd.run(fetchRecommendations, {
                    successActionCreator: fetchRecommendationsSuccess,
                    failActionCreator: fetchRecommendationsError,
                }),
            );

        case Actions.FETCH_RECOMMENDATIONS_SUCCESS:
            return { ...state, table: [...action.table], loading: false, error: false };

        case Actions.FETCH_RECOMMENDATIONS_ERROR:
            return { ...state, error: null, loading: false };

        case Actions.FETCH_RECOMMENDATIONS_RESET:
            return { ...state, error: null, table: null, loading: false };

        default:
            return state;
    }
};
