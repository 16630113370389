export enum PLATFORM_TYPES {
    DEFAULT = 1,
    TERRA = 2,
    BUY_IN_APP = 3,
    META_TRADER = 4,
}

export const clientHasAcquiredPlatform = (
    clientPlatforms: Array<any>,
    targetPlatform: Array<any>,
) => {
    if (!clientPlatforms || !clientPlatforms.length || !targetPlatform) {
        return false;
    }

    return clientPlatforms.some(clientPlatform => {
        return clientPlatform.Id === targetPlatform.Id;
    });
};

export type PlatformImage = {
    fileUrl: string;
    fileName: string;
    id: number;
    orderNumber: number;
    platformId: number;
};

export type PlatformRlpType = {
    id: number;
    active: boolean;
    title: string;
    description: string;
    urlText: string;
    urlLink: string;
    rlpMandatory: string;
    cardType: number;
};

export type PlatformProps = {
    id: number;
    name: string;
    summary: string;
    description: string;
    version: string;
    paymentType: string;
    price: number;
    paymentPeriod: string;
    type: number;
    active: number;
    agreementFileName: string;
    agreementFileUrl: string;
    revokeAgreementFileName: string;
    revokeAgreementFileUrl: string;
    imageUrl: string;
    image: string;
    extention: string;
    suitabilityProfileId: number;
    suitabilityProfileDescription: string;
    rlpMessageType: number;
    groupId: number;
    registerDate: string;
    registerUserId: number;
    modificationDate: string;
    modificationUserId: number;
    rlpType: PlatformRlpType;
    images: PlatformImage[];
};
