import { Actions } from './index.constants';
import { fetch, fetchFile, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers, normalizeOffer, normalizeAssetDetails } from './index.normalize';
import { responseToFile, blobToFile } from '../../Utils/Parsers';

export const fetchInvestmentFundsAssetsInit = () => ({
    type: Actions.FETCH_FUNDS_INIT,
});

export const fetchFundsAssets = () => {
    return fetch(ENDPOINTS.GET_INVESTMENT_FUNDS, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchFundsSuccess = offers => ({
    type: Actions.FETCH_FUNDS_SUCCESS,
    offers,
});

export const fetchFundsError = error => ({
    type: Actions.FETCH_FUNDS_ERROR,
    error: error.message,
});

export const fetchFundsAsset = assetId => {
    const url = ENDPOINTS.GET_INVESTMENT_FUND.replace(':id', assetId);
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeOffer);
};

export const fetchFundAssetDetails = (accountNumber, assetId) => {
    const url = ENDPOINTS.GET_FUND_DETAILS.replace(':accountNumber', accountNumber).replace(
        ':assetId',
        assetId,
    );
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeAssetDetails);
};

export const fetchFundAsset = assetId => {
    const url = ENDPOINTS.GET_INVESTMENT_FUND.replace(':id', assetId);
    return fetch(url, { method: 'GET' }).then(response => response.json());
};

export async function fetchAssetFile(id, filename) {
    const url = ENDPOINTS.GET_ASSET_FILE.replace(':id', id);
    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
}

export async function fetchAdhesionFile(fundAssetId, account, filename) {
    const url = ENDPOINTS.GET_ADHESION_FILE.replace(':fundAssetId', fundAssetId.toString()).replace(
        ':accountNumber',
        account,
    );

    const firstResponse = await fetch(url, { method: 'GET' });
    const firstResponseJson = await firstResponse.json();
    const responseWithContent = await fetch(firstResponseJson.UrlAgreementFile, {
        method: 'GET',
        useBaseUrl: false,
    });

    const blob: Blob = new Blob([await responseWithContent.blob()], { type: 'text/html' });
    if (responseWithContent.status && responseWithContent.status === 200) {
        return blobToFile(blob, filename);
    }

    throw new Error(responseWithContent.status.toString());
}

export const getTermFile = async (url, filename) => {
    const response = await fetch(url, {
        method: 'GET',
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

export const fetchRiskScienceTerm = ({ ClientId: clientId, AccountNumber: account }) => {
    const url = ENDPOINTS.RISK_SCIENCE_TERM.replace(':clientId', clientId).replace(
        ':account',
        account,
    );
    return fetchFile(url, {
        method: 'GET',
    });
};

export const postVirtualBuyOrder = payload => {
    return fetch(ENDPOINTS.POST_VIRTUAL_BUY_ORDER, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const postRedeemRequest = payload => {
    return fetch(ENDPOINTS.POST_REDEEM_REQUEST, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
