import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizeHistory } from './index.normalize';

export const fetchWithdrawHistoryInit = data => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_INIT,
    data,
});

export const fetchWithdrawHistory = async data => {
    let url = ENDPOINTS.GET_WITHDRAW_HISTORY;

    Object.keys(data).forEach(key => {
        url = url.replace(`:${key}`, data[key]);
    });

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
    const history = await response.json();
    return normalizeHistory(history);
};

export const fetchWithdrawHistorySuccess = history => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_SUCCESS,
    history,
});

export const fetchWithdrawHistoryError = error => ({
    type: Actions.WITHDRAW_HISTORY_FETCH_ERROR,
    error: error.message,
});

export const selectTransferToCancel = transferId => ({
    type: Actions.WITHDRAW_HISTORY_SET_TRANSFER_ID_TO_CANCEL,
    transferId,
});

export const resetTransferToCancel = () => ({
    type: Actions.WITHDRAW_HISTORY_RESET_TRANSFER_ID_TO_CANCEL,
});

export const cancelTransferRequest = payload => {
    return fetch(ENDPOINTS.POST_CANCEL_WITHDRAW, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

/* Pagination */
export const goToNextPage = () => ({
    type: Actions.WITHDRAW_GO_TO_NEXT_PAGE,
});

export const goToPreviousPage = () => ({
    type: Actions.WITHDRAW_GO_TO_PREVIOUS_PAGE,
});

/* Filter */
export const setCommonFilter = value => ({
    type: Actions.WITHDRAW_SET_FILTER,
    filterType: 'commonFilter',
    value,
});

export const setFilterByStatus = value => ({
    type: Actions.WITHDRAW_SET_FILTER,
    filterType: 'statusFilter',
    value,
});
