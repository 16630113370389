import * as React from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useRouteMatch, Redirect } from 'react-router-dom';

import { Loading } from '@terrainvest/react-components';

import { setAuth, logout } from '../../Redux/Auth';
import { setTerraApp } from '../../Redux/Mobile';
import { selectAccountNumber, fetchClientData } from '../../Redux/Client';

const AppLogin = () => {
    const [loading, setLoading] = React.useState(true);
    const [authenticated, setAuthenticated] = React.useState(false);

    const dispatch = useDispatch();
    const match = useRouteMatch<{ accessToken?: string; account?: string }>();

    const { accessToken, account } = match.params;

    React.useEffect(() => {
        if (accessToken && account) {
            setLoading(true);
            setAuthenticated(false);

            try {
                const jwtData = jwtDecode(accessToken);
                dispatch(
                    setAuth({
                        access_token: accessToken,
                        ...jwtData,
                    }),
                );

                fetchClientData(jwtData.ClientId)
                    .then(client => {
                        if (client.Accounts.includes(account)) {
                            dispatch(setTerraApp(true));
                            dispatch(selectAccountNumber(account));
                            setAuthenticated(true);
                            setLoading(false);
                        } else {
                            processTokenError();
                        }
                    })
                    .catch(() => processTokenError());
            } catch {
                processTokenError();
            }
        }
    }, [accessToken, account]);

    const processTokenError = () => {
        setAuthenticated(false);
        setLoading(false);
        dispatch(logout());
    };

    const getRedirect = () => (authenticated ? <Redirect to="/app" /> : <Redirect to="/login" />);

    return <>{loading ? <Loading visible={loading} /> : getRedirect()}</>;
};

export default AppLogin;
