import * as React from 'react';

import { PanelSlideProps } from './index.types';
import * as S from './index.styles';

const PanelSlide = ({ children, width, open, setClose, ...otherProps }: PanelSlideProps) => {
    const getWidth = () => {
        if (open) return `${width}px`;
        return '0px';
    };

    return (
        <S.StyledPanelSlideContainer open={open} {...otherProps}>
            <S.StyledOverflow onClick={setClose} open={open} width={getWidth()} {...otherProps}>
                <S.CloseIcon icon={['far', 'circle-xmark']} {...otherProps} />
            </S.StyledOverflow>
            <S.StyledContainer width={getWidth()}>{children}</S.StyledContainer>
        </S.StyledPanelSlideContainer>
    );
};

export default PanelSlide;
