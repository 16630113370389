import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchClientPlatforms,
    fetchClientPlatformsSuccess,
    fetchClientPlatformsError,
} from './index.actions';

export const initialState = {
    clientPlatforms: null,
    platforms: null,
    platformsOnAccountId: null,
    loading: false,
    error: null,
};

export const platformsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CLIENT_PLATFORMS_FETCH_INIT:
            return loop(
                { ...state, platformsOnAccountId: action.accountId, loading: true },
                Cmd.run(fetchClientPlatforms, {
                    successActionCreator: fetchClientPlatformsSuccess,
                    failActionCreator: fetchClientPlatformsError,
                    args: [action.accountId],
                }),
            );
        case Actions.CLIENT_PLATFORMS_FETCH_SUCCESS:
            return {
                ...state,
                clientPlatforms: action.clientPlatforms,
                platforms: action.platforms,
                loading: false,
                error: false,
            };
        case Actions.CLIENT_PLATFORMS_FETCH_ERROR:
            return { ...state, error: true, loading: false };
        default:
            return state;
    }
};
