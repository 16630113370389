import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';
import { profileIdToColor } from '../../../UserProfileForm/Suitability/index.styles';

export const StyledHeaderProfileNameItem = styled.div`
    color: #42555d;
    display: flex;
    justify-content: flex-end;

    ${Text.Styles.StyledText} {
        > b {
            ${({ profileId }) => profileIdToColor(profileId)};
        }
    }
`;
