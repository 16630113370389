import * as React from 'react';
import { useSelector } from 'react-redux';
import { HighlightedText } from '@terrainvest/react-components';

import User from '../../UserProfileForm';
import { FORM_SECTIONS } from '../../UserProfileForm/Form';

import { StyledContainerNoPadd, StyledRowNoPadd } from '../../Common/Grid';
import { StyledCol } from '../../../Template/StandardTemplate/index.styles';
import { disclaimerTexts } from '../../Common/TextualInfo';

import { needsSuitabilityUpdate, isCadastralUpdate } from '../../../Utils/General';
import Separator from '../../../Utils/General/index.separator';
import { useNotifyAppLoad } from '../../../Utils/Hooks';

import {
    StyledOpenFlatCard,
    StyledContentWrapper,
    StyledGlobalNotification,
    StyledDisclaimer,
} from '../index.styles';
import { isExpiredDate } from '../../../Utils/Parsers';

export const ExpiredUserProfile = () => {
    const { client, loading: loadingClient } = useSelector(state => state.clientState);
    const { profile: suitabilityProfile } = useSelector(state => state.suitabilityProfileState);

    const userDataExpired = isExpiredDate(client.DueDate);
    const suitabilityExpired = needsSuitabilityUpdate(suitabilityProfile);
    const cadastralUpdate = isCadastralUpdate(client);

    useNotifyAppLoad();

    if (loadingClient) {
        return null;
    }

    return (
        <StyledContainerNoPadd>
            <StyledRowNoPadd>
                <StyledCol>
                    <Separator />
                    <StyledOpenFlatCard>
                        <StyledContentWrapper>
                            <HighlightedText
                                asideColor="error"
                                title="ATUALIZE SEUS DADOS"
                                subTitle="Cadastro expirado"
                                large
                            />

                            <StyledDisclaimer>
                                {disclaimerTexts.dataExpiration.reviewAndAcceptToContinue}
                            </StyledDisclaimer>
                        </StyledContentWrapper>
                    </StyledOpenFlatCard>
                    <User
                        userHasExpiredData={userDataExpired || cadastralUpdate}
                        suitabilityExpiredData={suitabilityExpired}
                        focusedAnchor={
                            !userDataExpired && suitabilityExpired && FORM_SECTIONS.SUITABILITY
                        }
                        onClose={() => {}}
                        onSubmit={() => {}}
                        redirectOnSuccess={false}
                    />
                </StyledCol>
            </StyledRowNoPadd>
            <StyledGlobalNotification />
        </StyledContainerNoPadd>
    );
};

export default ExpiredUserProfile;
