/* eslint-disable max-len */
export const ENDPOINTS = {
    SIGNUP_CONTINUE: 'https://novo-cadastro.terrainvestimentos.com.br/continuar-cadastro',
    PENDING_DOCUMENTS_UPLOAD:
        'https://novo-cadastro.terrainvestimentos.com.br/documentos-pendentes/:authCode',
    STRUCTURED_PRODUCTS_INFO:
        'https://www.terrainvestimentos.com.br/investimentos/produtos-estruturados',
    TERRA_EVOLUTION: 'https://app.terrainvestimentos.com.br/',
    TERRA_SITE: 'https://www.terrainvestimentos.com.br/',

    GET_CLIENT: '/terra.register/api/client/:clientId',
    POST_CLIENT: '/terra.register/api/register',
    PUT_CLIENT_NO_DATA_CHANGED: '/terra.register/api/client/due-date/approve',
    GET_ACCOUNT: '/terra.register/api/account',
    GET_BANKS: '/terra.register/api/bank/:clientId',
    GET_BALANCE_REPORT: '/terra.finance/api/balance/account/:accountNumber',
    GET_ACCOUNTS: '/terra.finance/api/balance/user/:userId',
    POST_AUTH: '/terra.authentication/oauth/token',
    POST_CUSTOMER_AUTH: '/terra.authentication/api/customer/account-number/:accountNumber',
    PUT_NEW_PASSWORD: '/terra.authentication/api/password/request-new',
    PUT_VALIDATE_TOKEN: '/terra.authentication/api/token/validate',
    PUT_RESET_PASSWORD: '/terra.authentication/api/password/reset',
    GET_COMMON: '/terra.register/api/common',

    POST_CREATE_TOKEN: '/terra.core.api.authorization.token/api/authorize/new-code',
    GET_VALIDATE_TOKEN: '/terra.core.api.authorization.token/api/authorize/valid-code/:code',

    // BANK ACCOUNT
    POST_NEW_BANK_ACCOUNT: '/terra.client/api/bank-account',
    DELETE_REMOVE_BANK_ACCOUNT: '/terra.client/api/bank-account',

    // Compliance agreement
    POST_COMPLIANCE_CLIENT_AGREEMENT: '/terra.compliance.agreement/api/client-agreement/type',
    GET_CLIENT_AGREEMENT: '/terra.compliance.agreement/api/client-agreement/client/:clientId',
    GET_CLIENT_AGREEMENT_BY_TYPE:
        '/terra.compliance.agreement/api/client-agreement/client/:clientId/type/:type',
    GET_AGREEMENT_FILE: '/terra.compliance.agreement/api/file/type/:agreementId',
    GET_TYPE_AGREEMENT: '/terra.compliance.agreement/api/type-agreement',
    GET_AGREEMENTS_PDF_BY_CLIENT_ID:
        '/terra.compliance.agreement/api/registration-form/client/:clientId/signature/:signatureOption/pdf',
    GET_AGREEMENTS_PDF:
        '/terra.compliance.agreement/api/file/client-agreement?agreementId=:agreementId&clientid=:clientId',

    // Platforms
    GET_PLATFORMS: '/terra.platform/api/platform/actives/partner',
    GET_PLATFORM: '/terra.platform/api/platform/:id',
    GET_NEW_PLATFORMS: '/terra.research/api/platform/actives',
    GET_CLIENT_PLATFORMS: '/terra.platform/api/customer/:accountId',
    POST_ACQUIRE_PLATFORM: '/terra.platform/api/platform/hire',
    POST_ACQUIRE_TRADEMACHINE: '/terra.platform/api/trademachine/redirect',
    DELETE_PLATFORM: '/terra.platform/api/platform/revoke',

    GET_SUITABILITY_PROFILE: '/terra.compliance.suitability/api/profile/:clientId',
    POST_SUITABILITY_PROFILE: '/terra.compliance.suitability/api/profile',
    PUT_SUITABILITY_PROFILE: '/terra.compliance.suitability/api/profile',
    GET_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER:
        '/terra.compliance.suitability/api/profile/account/:accountNumber',
    POST_DOCUMENT: '/terra.register/api/document',

    POST_CHANGE_PASSWORD: '/terra.authentication/api/password/change',
    PUT_CHANGE_ELECTRONIC_CHECK: '/terra.authentication/api/electronic-check/change',
    POST_FORGOT_ELECTRONIC_CHECK: '/terra.authentication/api/electronic-check/reset',

    POST_CHANGE_META_TRADER_PASSWORD: '/terra.platform/api/meta-trader/change-password',

    // Positions
    GET_STOCKS_POSITION: '/terra.finance/api/position/bovespa/:accountNumber',
    GET_FIXED_INCOME_POSITION: '/terra.fixedincome/api/position/client/:accountNumber',
    GET_FIXED_INCOME_HISTORY:
        '/terra.fixedincome/api/order/historical/:accountNumber/:startDate/:endDate',
    GET_FUTURES_POSITION: '/terra.finance/api/position/bmf/:accountNumber',
    GET_TREASURY_DIRECT_POSITION: '/terra.treasurydirect/api/position/:accountNumber',
    GET_FUNDS_POSITION: '/terra.investmentfund/api/position/:accountNumber',
    GET_FUND_DETAILS: '/terra.investmentfund/api/position/:accountNumber/fund/:assetId',
    GET_FUND_POSITION: '/terra.investmentfund/api/position/:idPosition/account/:accountNumber',
    GET_FUND_HISTORY:
        '/terra.investmentfund/api/virtual-operation/start-date/:startDate/end-date/:endDate/account/:accountNumber',

    GET_ADDRESS: '/terra.register.searchaddress/api/address',

    // Investments (investment offers)
    // Fixed Income
    GET_INVESTMENT_OFFERS: '/terra.fixedincome/api/offer/available/partner',
    GET_INVESTMENT_OFFER: '/terra.fixedincome/api/offer/:id',
    POST_BUY_ORDER: '/terra.fixedincome/api/order/buy',

    // Funds
    GET_INVESTMENT_FUNDS: '/terra.investmentfund/api/asset/partner',
    GET_INVESTMENT_FUND: '/terra.investmentfund/api/asset/:id',
    GET_ASSET_FILE: '/terra.investmentfund/api/asset-file/:id',
    POST_VIRTUAL_BUY_ORDER: '/terra.investmentfund/api/virtual-operation/apply',
    POST_REDEEM_REQUEST: '/terra.investmentfund/api/virtual-operation/rescue',
    GET_ADHESION_FILE: '/terra.investmentfund/api/asset-file/:fundAssetId/:accountNumber',
    GET_TERM_FILE:
        '/terra.investmentfund/api/asset-file/agreement-term/preview/fund/:fundId/client/:clientId/account/:clientAccount',
    RISK_SCIENCE_TERM:
        '/terra.investmentfund/api/risk-science-term/preview/client/:clientId/account/:account',

    // Stocks
    GET_INVESTMENT_BTC: '/terra.finance/api/stocks/btc/:accountNumber',
    GET_INVESTMENT_TERM: '/terra.finance/api/stocks/term/:accountNumber',
    GET_INVESTMENT_PRIVATE_PENSION: '/terra.privatepension/api/position/client',
    GET_INVESTMENT_PROVENTS_DIVIDENDS: '/terra.finance/api/stocks/earnings/:accountNumber',
    GET_INVESTMENT_MARGIN_GUARANTEES: '/terra.collateral/api/collateral/:accountNumber',
    GET_INVESTMENT_MARGIN_GUARANTEES_INTRADAY:
        '/terra.collateral/api/collateral/intraday-movement/:accountNumber',

    // Treasury
    GET_TD_INVESTMENT_OFFERS: '/terra.treasurydirect/api/offer/available/partner',
    GET_TD_BY_ID: '/terra.treasurydirect/api/offer/available/:id',
    GET_AVAILABLE_DIRECT_TREASURY_ASSET:
        '/terra.treasurydirect/api/offer/available/type/:assetName/maturity/:dueDate',
    GET_DIRECT_TREASURY_ASSET: '/terra.treasurydirect/api/offer/type/:assetName/maturity/:dueDate',
    POST_TD_BUY_ORDER: '/terra.treasurydirect/api/operation/buy',
    POST_REDEEM_DIRECT_TREASURY_REQUEST: '/terra.treasurydirect/api/operation/sell',

    // Extracts and Reports
    POST_EXTRACTS_EMAIL: '/terra.finance/api/finance/report/email',
    POST_EMAIL_FUTURE_RELEASES: '/terra.finance/api/future-release/report/email',

    GET_AVAILABLE_REPORTS: '/terra.research/api/report-type',
    GET_EXTRACTS_DATE: '/terra.finance/api/finance/report/extract/dates/:accountNumber',
    GET_EXTRACTS_DATE_PDF: '/terra.finance/api/finance/report/extract/:accountNumber/month/:month',
    GET_EXTRACTS_PERIOD_PDF:
        '/terra.finance/api/finance/report/extract/:accountNumber/start-date/:initial/end-date/:final',
    GET_BROKERAGENOTE_MARKET: '/terra.finance/api/brokerage/report/market-type',
    GET_BROKERAGENOTE_DATES: '/terra.finance/api/brokerage/report/dates/:accountNumber/:market',
    GET_BROKERAGENOTE_YEARS: '/terra.finance/api/brokerage/report/year/:market',
    GET_BROKERAGENOTE_MONTHS: '/terra.finance/api/brokerage/report/month/:market/:year',
    GET_BROKERAGENOTE_SUBMIT:
        '/terra.finance/api/brokerage/report/note/:accountNumber/market-type/:marketType/start-date/:startDate/end-date/:endDate',
    POST_BROKERAGE_NOTE_SUBMIT: '/terra.finance/api/brokerage/report/note/request',
    GET_BROKERAGE_NOTE_RESULTS: '/terra.finance/api/brokerage/report/note/results',
    GET_BROKERAGE_NOTE_FILE: '/terra.finance/api/brokerage/report/note/results/file/:id',
    GET_BROKERAGENOTE_FAX_SUBMIT:
        '/terra.finance/api/brokerage/report/note-fax/account/:accountNumber/market-type/:marketType/date/:date',
    GET_OPENMARKET_DATES: '/terra.research/api/dates/open-market',
    GET_FUTUREMARKET_DATES: '/terra.research/api/dates/future-market',
    GET_ANALYZE_WEEKLY_DATES: '/terra.research/api/dates/weekly-report',
    GET_ANALYZE_MONTHLY_DATES: '/terra.research/api/dates/monthly-report',
    GET_MARKET_DATES_SUBMIT: '/terra.research/api/report/analysis/:id',
    GET_MARKET_STATUS: '/terra.fixedincome/api/order/is-open',
    GET_CUSTODY_DATES: '/terra.finance/api/custody/report/dates/account/:accountNumber',
    GET_CUSTODY_SUBMIT: '/terra.finance/api/custody/report/:accountNumber/date/:id',
    GET_OPPORTUNITIES: '/terra.research/api/opportunities/partner',
    GET_RECOMMENDATION: '/terra.research/api/recommendation',
    GET_RECOMMENDATION_BY_ID: '/terra.research/api/recommendation/:id',
    GET_RECOMMENDATION_ACTIVE: '/terra.research/api/recommendation/actives/partner',
    GET_RECOMMENDATION_ASSETS: '/terra.research/api/recommendation/:id/assets',
    GET_RECOMMENDATION_CHARTS: '/terra.research/api/recommendation/:id/charts',
    GET_RECOMMENDATION_MINIMAL_APPLICATION:
        '/terra.research/api/recommendation/minimal-application',
    POST_RECOMMENDATION_SIGN: '/terra.research/api/recommendation-investment',
    GET_RECOMMENDATION_TABLE: '/terra.research/api/research',
    GET_RECOMMENDATION_HISTORY: '/terra.research/api/research/:id/history',
    GET_RECOMMENDATION_REPORT: '/terra.research/api/report/recommendation/:id',
    GET_FINANCIAL_INCOME_REPORT: '/terra.finance/api/income-tax/:account/year/:fiscalYear/pdf',
    GET_FINANCIAL_INCOME_DATES: '/terra.finance/api/income-tax/dates',
    GET_DIVIDENDPORTFOLIO_DATES: '/terra.research/api/dates/dividend-portfolio',
    GET_SMALLCAPSWALLET_DATES: '/terra.research/api/dates/small-caps-wallet',
    GET_PORTFOLIOFIIS_DATES: '/terra.research/api/dates/portfolio-fiis',
    GET_ACTIONSGUIDE_DATES: '/terra.research/api/dates/actions-guide',
    GET_INVESTMENTFUNDSPORTFOLIO_DATES: '/terra.research/api/dates/investment-funds-portfolio',
    GET_LETTERTOINVESTOR_DATES: '/terra.research/api/dates/letter-to-investor',

    GET_CHECKING_ACCOUNT_EXTRACT:
        '/terra.finance/api/future-release/extract/:accountNumber/start-date/:startDate/end-date/:endDate/order-ascending/:orderAsc',

    GET_FUTURE_RELEASES_EXTRACT: '/terra.finance/api/future-release/days/account/:accountNumber',
    GET_FUTURE_RELEASES_PDF: '/terra.finance/api/future-release/report/:accountNumber',
    GET_PATRIMONY_INFO: '/terra.finance/api/finance/patrimony/account/:accountNumber',

    // Profitability
    GET_EQUITY_FLUTUATION:
        '/terra.finance.portfoliomanager/api/portfolio-manager/portfolio-variation/date/:date/account/:account',
    GET_EVOLUTION_VARIATION:
        '/terra.finance.portfoliomanager/api/portfolio-manager/profitability/account/:accountNumber',
    GET_EVOLUTION_CHART_PERIOD: '/terra.finance.portfoliomanager/api/portfolio-manager/period',
    GET_EVOLUTION_CHART_DATA_PERCENT:
        // eslint-disable-next-line max-len
        '/terra.finance.portfoliomanager/api/portfolio-manager/portfolio-chart-percentage/period/:periodId/interval/:intervalType/account/:accountNumber',
    GET_EVOLUTION_CHART_DATA_VALUE:
        // eslint-disable-next-line max-len
        '/terra.finance.portfoliomanager/api/portfolio-manager/portfolio-chart-financial/period/:periodId/account/:accountNumber',

    // Public Offers
    GET_PUBLIC_OFFERS: '/terra.publicoffering/api/offering/actives/partner',
    GET_PUBLIC_OFFER: '/terra.publicoffering/api/offering/:id',
    POST_BOOK_PUBLIC_OFFER: '/terra.publicoffering/api/reserve',
    PUBLIC_OFFERS_RISK_SCIENCE_TERM:
        '/terra.publicoffering/api/risk-science-term/preview/client/:clientId/account/:account',
    GET_OFFER_HISTORY: '/terra.publicoffering/api/reserve/account/:account',
    POST_CANCEL_PUBLIC_OFFER: '/terra.publicoffering/api/reserve/cancel ',

    // Withdraw
    POST_WITHDRAW: '/terra.finance/api/withdraw',
    GET_WITHDRAW_HISTORY:
        '/terra.finance/api/withdraw/:startDate/:endDate/:accountNumber/:withdrawStatusId',
    POST_CANCEL_WITHDRAW: '/terra.finance/api/withdraw/cancel',
    GET_WITHDRAW_VALUES: '/terra.finance/api/withdraw/getwithdrawalbalance/:accountNumber',
    GET_IS_VALID_WITHDRAW_TIME: '/terra.finance/api/withdraw/is-valid-withdraw-time',

    // Homebroker
    GET_HOMEBROKER_TOKEN: '/terra.authentication/api/user/tokenhb/:id',
    POST_HOMEBROKER_REDIRECT:
        'https://trade3.terrainvestimentos.com.br/hbnet2/STIntegracaoPortalHBTerra/IntegracaoPortalHB.aspx',

    // Banners
    GET_BANNERS: '/terra.banner/api/banner-content/actives',

    // RLP
    GET_RLP_STATUS: '/terra.register/api/register/rlp/:clientId/verify',
    PATCH_RLP_STATUS: '/terra.register/api/register/rlp',
    GET_RLP_ITEMS: '/terra.backoffice/api/rlp-item',
    GET_RLP_DATA: '/terra.backoffice/api/data-rlp-portal/period/:referenceMonth',
    GET_RLP_TIME_OMS: '/terra.backoffice/api/rlp-time-oms/period/:referenceMonth',

    // Custody Transfer
    GET_POSITION_TRANSFER_REASON: '/terra.backoffice/api/position-transfer-reason',
    GET_BROKER: '/terra.backoffice/api/broker',
    POST_POSITION_TRANSFER: '/terra.backoffice/api/position-transfer',
    GET_CUSTODY_TRANSFER_HISTORIC: '/terra.backoffice/api/position-transfer/customer/:clientId',
    GET_POSITION_TRANSFER_STATUS: '/terra.backoffice/api/position-transfer-status',
    POST_POSITION_TRANSFER_PREVIEW_PDF: '/terra.backoffice/api/position-transfer-file/preview',

    // Retail Garantee
    GET_RETAIL_GARANTEE: '/terra.collateral/api/bail-insurance/account/:account',
    GET_RETAIL_CLIENT_STATUS: '/terra.collateral/api/bail-insurance-client/account/:account',
    POST_RETAIL_GARANTEE_ADHERE: '/terra.collateral/api/bail-insurance/adhere',
    POST_RETAIL_GARANTEE_CANCEL: '/terra.collateral/api/bail-insurance/cancel',
    POST_RETAIL_GARANTEE_FILE: '/terra.compliance.agreement/api/client-agreement/type',

    // Partner Functionality
    GET_PARTNER_NEW_FUNCTIONALITIES:
        '/terra.whitelabel.partner/api/partner-functionality/available',
    GET_PARTNER_FUNCTIONALITY:
        '/terra.whitelabel.partner/api/partner-functionality/partner/available/menu',
    GET_PARTNER_BUTTONS:
        '/terra.whitelabel.partner/api/partner-functionality/partner/available/buttons',
    GET_PARTNER_HIGHLIGHTS:
        '/terra.whitelabel.partner/api/partner-functionality/partner/available/highlights',

    // Colors Mode
    GET_COLOR_MODE_SELECTED: '/terra.client/api/configuration/style',
    POST_NEW_COLOR_MODE_SELECTED: '/terra.client/api/configuration/style/light/:isLight',

    GET_URL_EDUCATIONAL: '/terra.b2c.api.educational/api/educational/single-sign-on',

    // Partner
    GET_PARTNER: '/terra.whitelabel.partner/api/partner/url',

    // Advisors
    GET_ADVISOR: '/terra.register.aai/api/advisor/:advisorId/basic',

    GET_UPLOAD_TOKEN: '/terra.authentication.oauth/api/customer/get-token-upload-documents/:userId',

    // Structured Products
    GET_STRUCTURED_PRODUCTS_STATUS:
        '/terra.structuredproducts/api/customer-status/client/:clientId',
    POST_STRUCTURED_PRODUCTS: '/terra.structuredproducts/api/customer-status/',
    GET_STRUCTURED_PRODUCTS:
        '/terra.structuredproducts/api/structured-product-type/actives/partner',
    GET_STRUCTURED_PRODUCTS_DETAILS:
        '/terra.structuredproducts/api/structured-product-type/:productId',
    GET_STRUCTURED_PRODUCTS_TYPE:
        '/terra.structuredproducts/api/structured-product/structured-product-type/:productId',
    POST_STRUCTURED_PRODUCTS_APPLY:
        '/terra.structuredproducts/api/structured-product-operation/solicitation',
    GET_STRUCTURED_PRODUCTS_REQUESTS:
        '/terra.structuredproducts/api/structured-product-operation/account/:account',

    // Paid Custody
    GET_PAID_CUSTODY_STATUS: '/terra.custodyrent/api/custody-terms/account/:account',
    SET_PAID_CUSTODY_STATUS: '/terra.custodyrent/api/custody-terms/',

    // Private Pension
    GET_PRIVATE_PENSION: '/terra.privatepension/api/asset/actives/partner',
    GET_PENSION_DETAILS: '/terra.privatepension/api/asset/:id',
    GET_RELATIVES: '/terra.privatepension/api/relation/actives',
    GET_PRIVATE_PENSION_BANKS: '/terra.privatepension/api/bank/accept-automatic-debit',
    GET_PORTABILITY_BANKS: '/terra.privatepension/api/bank/actives',
    GET_PORTABILITY_INSURANCES: '/terra.privatepension/api/insurance-company/actives',
    GET_PAYMENT_METHODS: '/terra.privatepension/api/payment-method/actives',
    POST_HIRE_PRIVATE_PENSION: '/terra.privatepension/api/hiring',
    POST_TRANSFER_PRIVATE_PENSION: '/terra.privatepension/api/portability',
    GET_PRIVATE_PENSION_HISTORY: '/terra.privatepension/api/operation/account/:account',
    POST_PRIVATE_PENSION_RESCUE: '/terra.privatepension/api/reclaim',

    // Subscription
    GET_SUBSCRIPTION_RESERVES: '/terra.subscription/api/subscription/reserves',
    GET_MY_SUBSCRIPTION: '/terra.subscription/api/subscription/subscriptions',
    POST_SUBSCRIPTION_GRAPHQL: '/terra.b2c.api.orchestrator/api/graphql',

    POST_SEND_SUBSCRIPTION_GRAPHQL: '/terra.b2c.api.orchestrator/api/subscription',

    // Videos
    GET_VIDEO_FEED: '/terra.socialmedia/api/video-channel',

    GET_TERMS_OF_ADESION:
        '/terra.privatepension/api/reports/preview/agreement-term/client/:clientId/asset/:assetId',

    GET_HIRING: '/terra.insurance/api/hiring',
    GET_LIFE_INSURANCE: '/terra.insurance/api/insurance-type/actives/partner',
    GET_LIFE_INSURANCE_ID: '/terra.insurance/api/insurance-type/:id',
    GET_LIFE_INSURANCE_REQUESTS: '/terra.insurance/api/hiring/client/:client',
};
