import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchCustodySubmit,
    fetchCustodySubmitSuccess,
    fetchCustodySubmitError,
} from './index.actions';

export const initialState = {
    loading: false,
    error: null,
    fileUrl: null,
};

export const custodySubmitReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_CUSTODY_SUBMIT_INIT:
            return state.loading === true
                ? state
                : loop(
                      { ...state, loading: true, error: null },
                      Cmd.run(fetchCustodySubmit, {
                          successActionCreator: fetchCustodySubmitSuccess,
                          failActionCreator: fetchCustodySubmitError,
                          args: [action.data],
                      }),
                  );

        case Actions.FETCH_CUSTODY_SUBMIT_SUCCESS:
            return {
                ...state,
                fileUrl: action.fileUrl,
                loading: false,
                error: false,
            };

        case Actions.FETCH_CUSTODY_SUBMIT_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_CUSTODY_SUBMIT_RESET:
            return { ...state, error: null, loading: false };

        default:
            return state;
    }
};
