import styled from 'styled-components';
import { StyledButton } from '../../LoginSide/index.styles';

export const StyledWrapper = styled.div`
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    margin: 0 auto;
    text-align: center;
`;

export const StyledImage = styled.img``;

export const StyledPrimaryButton = styled(StyledButton)`
    margin-top: ${({ theme }) => theme.global.spaces.xRay};
    opacity: 0.8;
`;

export const StyledSecondaryButton = styled(StyledButton)`
    background-color: unset;
    :hover,
    :active,
    :focus {
        background-color: unset;
    }
`;
