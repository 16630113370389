export const Actions = {
    CLIENT_PLATFORMS_FETCH_INIT: 'CLIENT_PLATFORMS_FETCH_INIT',
    CLIENT_PLATFORMS_FETCH_SUCCESS: 'CLIENT_PLATFORMS_FETCH_SUCCESS',
    CLIENT_PLATFORMS_FETCH_ERROR: 'CLIENT_PLATFORMS_FETCH_ERROR',
};

export enum PLATFORM_TYPES {
    TERRA_EVOLUTION = 2,
}

export const TERRA_PLATFORM_ID = 1;
