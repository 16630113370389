import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Button, Text } from '@terrainvest/react-components';

import { TokenControlType } from './index.types';

const media1050 = '@media(max-width: 1050px)';
const media400 = '@media(max-width: 400px)';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const getTokenColorByType = (type: TokenControlType) => {
    switch (type) {
        case 'complete':
            return '#16A34A';

        case 'error':
            return '#BE123C';

        default:
            return '#A1A1AA';
    }
};

const StyledTokenInput = css`
    ${({ theme }) => css`
        padding: 0 ${theme.global.spaces.lowFrequencyXRay} !important;
        font-weight: ${theme.global.fontWeights.bold} !important;
        font-size: ${theme.global.fontSizes.microwave} !important;
    `};
    width: calc(100% / 6) !important;
    height: 68px !important;
    text-align: center;
    outline: none;
    ${media1050} {
        padding: 0 ${({ theme }) => theme.global.spaces.extremehighFrequencyGammaRay} !important;
    }
    ${media.max.tablet`
        padding: 0 ${({ theme }) => theme.global.spaces.lowFrequencyXRay} !important;
    `}
    ${media400} {
        height: 60px !important;
        padding: 0 ${({ theme }) => theme.global.spaces.highFrequencyGammaRay} !important;
    }
    border: 2px solid ${({ typeToken }) => getTokenColorByType(typeToken)} !important;
`;

const StyledCodeInput = css`
    display: flex !important;
`;

export const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTokenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 366px;
    justify-content: center;
    input {
        ${StyledTokenInput};
    }
    > div {
        ${StyledCodeInput};
    }
`;

export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > button {
        font-weight: ${({ theme }) => theme.global.fontWeights.medium};
        border-radius: ${({ theme }) => theme.global.spaces.extremehighFrequencyGammaRay};
    }
    ${media.max.tablet`
        width: 60%;
        flex-direction: column-reverse;
        row-gap: ${({ theme }) => theme.global.spaces.lowFrequencyUltraviolet};
    `}
    ${media.max.sm`
        width: 70%;
    `}
`;

export const HelpText = styled(Text)`
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
    color: ${({ theme }) => theme.global.colors.doveGrayLight};
    ${media.max.tablet`
        display: none;
    `}
`;

export const PrimaryButton = styled(Button)`
    width: 100%;
`;
