import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Skeleton } from '@terrainvest/react-components';

export const StyledSkeleton = styled(Skeleton)`
    ${props => `
        margin: ${props.theme.components.sideNav.item.padding};
    `}

    ${media.max.tablet`
        display: none;
    `}
`;
