import * as React from 'react';
import * as PropTypes from 'prop-types';

import { useId } from '@terrainvest/react-components';

import {
    StyledContainer,
    StyledContent,
    StyledInfoText,
    StyledMainLogo,
    StyledMainText,
    StyledSideLogo,
    StyledDescription,
} from './index.styles';

const NoDataFound = ({ mainLogo, sideLogo, mainText, description, mobileWidth }) => {
    const [id] = useId('noda-data-found-');

    return (
        <StyledContainer id={id} mobileWidth={mobileWidth}>
            {mainLogo && <StyledMainLogo src={mainLogo} alt={`main-logo-${id}`} />}
            <StyledContent>
                <StyledSideLogo src={sideLogo} alt={`side-logo-${id}`} />
                <StyledInfoText>
                    <StyledMainText large>{mainText}</StyledMainText>
                    <StyledDescription>{description}</StyledDescription>
                </StyledInfoText>
            </StyledContent>
        </StyledContainer>
    );
};

NoDataFound.propTypes = {
    mainLogo: PropTypes.node,
    sideLogo: PropTypes.node.isRequired,
    mainText: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    mobileWidth: PropTypes.string,
};

NoDataFound.defaultProps = {
    mainLogo: null,
    mobileWidth: null,
};

export default NoDataFound;
