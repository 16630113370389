import { fetch, ENDPOINTS } from '../../Utils/Api';
import { responseToFile } from '../../Utils/Parsers';
import { Actions } from './index.constants';
import { normalizeAvailableReports } from './index.normalize';

export const fetchAvailableReportsInit = () => ({
    type: Actions.FETCH_AVAILABLE_REPORTS_INIT,
});

export const fetchAvailableReports = () => {
    return fetch(ENDPOINTS.GET_AVAILABLE_REPORTS, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
        .then(response => response.json())
        .then(normalizeAvailableReports);
};

export const fetchAvailableReportsSuccess = reports => ({
    type: Actions.FETCH_AVAILABLE_REPORTS_SUCCESS,
    reports,
});

export const fetchAvailableReportsError = () => ({
    type: Actions.FETCH_AVAILABLE_REPORTS_ERROR,
});

export async function fetchPrintReport(data, filenameSuffix) {
    const response = await fetch(ENDPOINTS.GET_MARKET_DATES_SUBMIT.replace(':id', data.id), {
        method: 'GET',
    });

    const filename = `relatorio_${filenameSuffix}.pdf`;
    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    return Promise.reject(response.status.toString());
}
