import * as React from 'react';
import { InputText, InputTel, InputDate, InputCnpj, Select } from '@terrainvest/react-components';

import {
    validateFields,
    validateRequiredField,
    validateDate,
    validateEmail,
    validateLength,
    validatePhone,
    validateRequiredCnpj,
} from '../../index.validators';

import { StyledRow, StyledCol } from '../../Common/GridStyles';

import { CommonPrefixes } from '../../../../Redux/Common/index.constants';
import { FORM_SECTIONS } from '../../Form';
import { fieldLengthLimits } from '../../index.settings';

import { Field, SelectField } from '../../Form/Field';
import FormCard from '../../Common/FormCard';
import Flags from '../Flags';
import { SelectConstitutionFormat } from '../index.styles';
import Institutional from './Institutional';

export const DetailsCompanyForm = () => {
    return (
        <FormCard title={FORM_SECTIONS.DETAILS.COMPANY_NAME}>
            <StyledRow>
                <StyledCol md={12} sm={12}>
                    <Field name="Name" validate={value => validateFields('company', value)}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Razão Social"
                                fullWidth
                                maxLength={fieldLengthLimits.company}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="CpfCnpj" validate={validateRequiredCnpj}>
                        {({ inputProps }) => (
                            <InputCnpj {...inputProps} label="CNPJ" fullWidth disabled />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <SelectField
                        name="Activity"
                        commonPrefix={CommonPrefixes.BusinessActivities}
                        validate={validateRequiredField}
                    >
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <Select
                                name={field.name}
                                label="Atividade"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                onChange={onChange}
                                options={options}
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="BirthDate" validate={value => validateDate(value)}>
                        {({ inputProps }) => (
                            <InputDate
                                {...inputProps}
                                label="Data da Fundação"
                                fullWidth
                                disabled
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="Email" validate={validateEmail}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="E-mail"
                                fullWidth
                                maxLength={fieldLengthLimits.email}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="Skype" validate={value => validateLength('skype', value)}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Skype"
                                fullWidth
                                maxLength={fieldLengthLimits.skype}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="MobilePhoneNumber" validate={validatePhone}>
                        {({ inputProps }) => <InputTel {...inputProps} label="Celular" fullWidth />}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <SelectField name="AdvisorId" commonPrefix={CommonPrefixes.Advisors}>
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <Select
                                name={field.name}
                                label="Assessor"
                                fullWidth
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={onChange}
                                disabled
                            />
                        )}
                    </SelectField>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <SelectField
                        name="ConstitutionFormat"
                        commonPrefix={CommonPrefixes.ConstitutionForm}
                        validate={validateRequiredField}
                    >
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <SelectConstitutionFormat
                                name={field.name}
                                defaultValue={defaultValue}
                                label="Forma da Constituição"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!meta.error}
                                message={meta.error || ''}
                                onChange={onChange}
                                options={options}
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <Institutional />
                <Flags isCompany />
            </StyledRow>
        </FormCard>
    );
};

export default DetailsCompanyForm;
