import { TAB_NAME } from '../../View/Subscription/index.constants';
import { Actions } from './index.constants';

export const initialState = {
    subscriptionTab: TAB_NAME.TAB1,
};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SUBSCRIPTION_CHANGE_TAB_INIT:
            return {
                ...state,
                subscriptionTab: action.subscriptionTab,
                loading: false,
                error: false,
            };

        case Actions.SUBSCRIPTION_CHANGE_TAB_SUCCESS:
            return {
                ...state,
                subscriptionTab: action.subscriptionTab,
                loading: false,
                error: false,
            };

        case Actions.SUBSCRIPTION_CHANGE_TAB_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
