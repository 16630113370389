import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { postNotification, postNotificationFromError } from '../../../Redux/Notification';
import { fetchHomebrokerToken } from '../../../Redux/Homebroker/index.actions';
import { ENDPOINTS } from '../../../Utils/Api';

import RLPOptin from '../RLPOptin';
import { StyledTitle } from '../SideNav/SideNavSection/index.styles';
import { SectionProps } from '../SideNav/index.types';

enum homeBrokerStatus {
    'WaitingSync' = 0,
    'Synced' = 1,
}

export const Homebroker = ({ sectionInfo }: { sectionInfo: SectionProps }) => {
    const dispatch = useDispatch();

    const { selectedAccount } = useSelector((state: RootState) => state.clientState);
    const { isRlpActive } = useSelector((state: RootState) => state.rlpState);

    const [loading, setLoading] = React.useState(false);
    const [modalOptions, setModalOptions] = React.useState({
        visible: false,
        firstEntry: true,
    });

    const handleOpenHomebroker = form => {
        const windowRef = window.open('', '', 'Homebroker');
        form.submit();
        document.body.removeChild(form);
        windowRef.close();
    };

    const appendToForm = (form, ...elements) => {
        elements.forEach(e => form.appendChild(e));
    };

    const appendToDocument = element => {
        document.body.appendChild(element);
    };

    const appendElementsToParent = (parent, ...elements) => {
        appendToForm(parent, ...elements);
        appendToDocument(parent);
    };

    const makeTokenInput = token => {
        const tokenInput = document.createElement('input');
        tokenInput.setAttribute('type', 'hidden');
        tokenInput.setAttribute('name', 'token');
        tokenInput.setAttribute('value', token);

        return tokenInput;
    };

    const makeFormElement = () => {
        const form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', ENDPOINTS.POST_HOMEBROKER_REDIRECT);
        form.setAttribute('target', 'Homebroker');
        form.setAttribute('id', 'hbForm');
        return form;
    };

    const handleHomebrokerInit = (response: {
        Status: homeBrokerStatus;
        Message: string;
        Token: string;
    }) => {
        if (response.Status === homeBrokerStatus.WaitingSync) {
            dispatch(postNotification(response.Message, { type: 'warn' }));
            return;
        }

        const form = makeFormElement();
        const tokenInput = makeTokenInput(response.Token);

        appendElementsToParent(form, tokenInput);
        handleOpenHomebroker(form);
    };

    const handleGetTokenError = err => {
        dispatch(postNotificationFromError(err, {}, err.message));
    };

    const randomOpenRule = max => {
        const aux = new Uint32Array(1);
        crypto.getRandomValues(aux);
        return aux[0] % max === 0;
    };

    const shouldDisplayRlpOptin = () => {
        return (
            !isRlpActive && isRlpActive != null && (modalOptions.firstEntry || randomOpenRule(3))
        );
    };

    const getToken = async () => {
        return fetchHomebrokerToken(selectedAccount);
    };

    const handleHomebroker = async () => {
        setLoading(true);
        try {
            const response = await getToken();
            handleHomebrokerInit(response);
        } catch (err) {
            handleGetTokenError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        const rlpOptinWillOpen = shouldDisplayRlpOptin();
        setModalOptions({ ...modalOptions, visible: rlpOptinWillOpen, firstEntry: false });
        if (!rlpOptinWillOpen) {
            handleHomebroker();
        }
    };

    const handleCloseModal = () => {
        setModalOptions({ ...modalOptions, visible: false });
        handleHomebroker();
    };

    return (
        <>
            {modalOptions.visible && (
                <RLPOptin onclose={handleCloseModal} visible={modalOptions.visible} />
            )}
            <StyledTitle
                id="home-broker-button"
                auth="homebroker"
                onClick={handleClick}
                loading={loading}
            >
                {sectionInfo.title}
            </StyledTitle>
        </>
    );
};
