import * as React from 'react';
import {
    FormGroup,
    H2,
    Icon,
    InputCpfCnpj,
    InputTel,
    InputText,
    InputDate,
    Radio,
    Text,
} from '@terrainvest/react-components';
import { useFormikContext } from 'formik';

import { parseDateForDisplay } from '../../../../Utils/Parsers';
import Separator from '../../../../Utils/General/index.separator';
import { REGISTRATION_STATUS } from '../../../../Utils/General/index.registration-status';

import { disclaimerTexts, StyledDisclaimerText } from '../../../Common/TextualInfo';

import { fieldLengthLimits } from '../../index.settings';
import AddressInput from '../../AdressInput';
import { Field } from '../../Form/Field';
import {
    StyledCol,
    StyledHeaderAddedItemWrapper,
    StyledRemoveItem,
    StyledRow,
} from '../../Common/GridStyles';
import {
    isValidEmail,
    isValidPhone,
    validateCpfCnpjValue,
    validateLength,
    validateRequiredField,
    dateLessThanToday,
    validateFile,
} from '../../index.validators';

import * as S from './index.styles';

export const AssetManagerInput = ({ index, onRemove, isCompany }) => {
    const preffix = `AssetManagers[${index}]`;

    const { setFieldValue, values } = useFormikContext<any>();

    const hasValidityDate = values.AssetManagers[index].ValidityIndeterminate === false;
    const [showValidityDate, setShowValidityDate] = React.useState<boolean>(hasValidityDate);

    const getNameAndRemoveAttribute = name => {
        if (name) {
            const element = document.getElementsByName(name);
            return element[0]?.removeAttribute('hidden');
        }

        return name;
    };

    const handleRemove = () => {
        onRemove(index);
    };

    const handleValidityIndeterminateChangedEnabled = () => {
        setFieldValue(`${preffix}.ValidityIndeterminate` as never, true);
        setShowValidityDate(false);
    };

    const handleValidityIndeterminateChangedDisabled = () => {
        setFieldValue(`${preffix}.ValidityIndeterminate` as never, false);
        setShowValidityDate(true);
    };

    const onChange = (name, type, form) => file => {
        form.setFieldValue('RegistrationStatusId', REGISTRATION_STATUS.IN_ANALYSIS);

        form.setFieldValue(name, {
            file,
            clientId: values.ClientId,
            type,
        });
    };

    const onRemoveFile = name => {
        setFieldValue(name, {});
    };

    return (
        <>
            <StyledHeaderAddedItemWrapper>
                <H2>{`Administrador de Carteira ${index + 1}`}</H2>
                <StyledRemoveItem onClick={handleRemove}>
                    <Text weight="medium">
                        Remover administrador de carteira <Icon icon="xmark" size="sm" padLeft />
                    </Text>
                </StyledRemoveItem>
            </StyledHeaderAddedItemWrapper>
            <Separator />
            <StyledRow>
                <StyledCol md={6} sm={12}>
                    <Field name={`${preffix}.AssetManagerName`} validate={validateRequiredField}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name={`${preffix}.AssetManagerCpfCnpj`} validate={validateCpfCnpjValue}>
                        {({ inputProps }) => (
                            <InputCpfCnpj {...inputProps} label="CPF/CNPJ" fullWidth />
                        )}
                    </Field>
                </StyledCol>

                <AddressInput preffix={preffix} />

                <StyledCol md={4} sm={12}>
                    <Field
                        name={`${preffix}.Skype`}
                        validate={value => validateLength('skype', value)}
                    >
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Skype"
                                fullWidth
                                maxLength={fieldLengthLimits.skype}
                            />
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}.MobilePhone`} validate={isValidPhone}>
                        {({ inputProps }) => <InputTel {...inputProps} label="Celular" fullWidth />}
                    </Field>
                </StyledCol>

                <StyledCol md={4} sm={12}>
                    <Field name={`${preffix}.Email`} validate={isValidEmail}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="E-mail"
                                fullWidth
                                maxLength={fieldLengthLimits.email}
                            />
                        )}
                    </Field>
                </StyledCol>

                {!isCompany && (
                    <StyledCol col={12}>
                        <StyledDisclaimerText small>
                            {disclaimerTexts.procurator.authorization}
                        </StyledDisclaimerText>
                    </StyledCol>
                )}

                <StyledCol md={12} sm={12}>
                    <Field name={`${preffix}.ValidityIndeterminate`}>
                        {({ field }) => (
                            <FormGroup
                                name={field.name}
                                border
                                vertical={false}
                                fullWidth
                                onChange={evt => {
                                    field.onChange(evt);
                                }}
                            >
                                <Text>Validade</Text>
                                <Radio
                                    label="Indeterminada"
                                    defaultChecked={field.value === true}
                                    // eslint-disable-next-line react/jsx-boolean-value
                                    defaultValue={true}
                                    onChange={handleValidityIndeterminateChangedEnabled}
                                />
                                <Radio
                                    label="Até"
                                    defaultChecked={field.value === false}
                                    defaultValue={false}
                                    onChange={handleValidityIndeterminateChangedDisabled}
                                />
                            </FormGroup>
                        )}
                    </Field>
                </StyledCol>

                {showValidityDate && (
                    <StyledCol md={6} sm={12}>
                        <Field name={`${preffix}.ValidityDate`} validate={dateLessThanToday}>
                            {({ inputProps, field }) => (
                                <InputDate
                                    {...inputProps}
                                    defaultValue={parseDateForDisplay(field.value)}
                                    laterDate
                                    label="Validade"
                                    fullWidth
                                    calendarProps={{
                                        maxYear: new Date().getFullYear() + 30,
                                        minYear: new Date().getFullYear(),
                                    }}
                                />
                            )}
                        </Field>
                    </StyledCol>
                )}
            </StyledRow>

            <Field name={`files.AssetManagerContract${index}`} validate={validateFile}>
                {({ field, meta, form }) => {
                    return (
                        <>
                            <S.StyledFileSection
                                name={getNameAndRemoveAttribute(field.name)}
                                error={meta.error}
                            >
                                <H2 weight="medium">
                                    Contrato de Administração de Carteira {index + 1}
                                </H2>
                                <S.CustomInputFileUpload
                                    name={field.name}
                                    width="100%"
                                    error={!!meta.error}
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    onChange={onChange(field.name, isCompany ? 26 : 27, form)}
                                    onRemove={() => onRemoveFile(field.name)}
                                />
                                <S.ErrorText>{meta.error ? meta.error : ''}</S.ErrorText>
                            </S.StyledFileSection>
                        </>
                    );
                }}
            </Field>
        </>
    );
};

export default AssetManagerInput;
