import { createActions } from './index.actions';
import { createReducer } from './index.reducer';
import { PositionTypes } from './index.constants';
import {
    normalizeStocks,
    normalizeFixedIncome,
    normalizeTreasuryDirect,
    normalizeFunds,
    normalizeFutures,
    normalizeEarningsDividends,
    normalizeBtc,
    normalizeCollateral,
    normalizeIntraday,
    normalizeTerms,
    normalizePrivatePension,
} from './index.normalizers';

import { ENDPOINTS } from '../../Utils/Api';

export const createPositionRedux = ({ type, endpoint, normalize }) => {
    const assetName = type.toUpperCase();

    const actions = createActions({ assetName, endpoint, normalize });
    const reducer = createReducer({ assetName, actions });

    return {
        assetName,
        actions,
        reducer,
    };
};

export const Positions = {
    Stocks: createPositionRedux({
        type: PositionTypes.STOCKS,
        endpoint: ENDPOINTS.GET_STOCKS_POSITION,
        normalize: normalizeStocks,
    }),
    FixedIncome: createPositionRedux({
        type: PositionTypes.FIXED_INCOME,
        endpoint: ENDPOINTS.GET_FIXED_INCOME_POSITION,
        normalize: normalizeFixedIncome,
    }),
    TreasuryDirect: createPositionRedux({
        type: PositionTypes.TREASURY_DIRECT,
        endpoint: ENDPOINTS.GET_TREASURY_DIRECT_POSITION,
        normalize: normalizeTreasuryDirect,
    }),
    Funds: createPositionRedux({
        type: PositionTypes.FUNDS,
        endpoint: ENDPOINTS.GET_FUNDS_POSITION,
        normalize: normalizeFunds,
    }),
    Futures: createPositionRedux({
        type: PositionTypes.FUTURES,
        endpoint: ENDPOINTS.GET_FUTURES_POSITION,
        normalize: normalizeFutures,
    }),
    EarningsDividends: createPositionRedux({
        type: PositionTypes.EARNINGS_DIVIDENDS,
        endpoint: ENDPOINTS.GET_INVESTMENT_PROVENTS_DIVIDENDS,
        normalize: normalizeEarningsDividends,
    }),
    Btc: createPositionRedux({
        type: PositionTypes.BTC,
        endpoint: ENDPOINTS.GET_INVESTMENT_BTC,
        normalize: normalizeBtc,
    }),
    Collateral: createPositionRedux({
        type: PositionTypes.COLLATERAL,
        endpoint: ENDPOINTS.GET_INVESTMENT_MARGIN_GUARANTEES,
        normalize: normalizeCollateral,
    }),
    Intraday: createPositionRedux({
        type: PositionTypes.INTRADAY,
        endpoint: ENDPOINTS.GET_INVESTMENT_MARGIN_GUARANTEES_INTRADAY,
        normalize: normalizeIntraday,
    }),
    Terms: createPositionRedux({
        type: PositionTypes.TERMS,
        endpoint: ENDPOINTS.GET_INVESTMENT_TERM,
        normalize: normalizeTerms,
    }),
    PrivatePension: createPositionRedux({
        type: PositionTypes.PRIVATE_PENSION,
        endpoint: ENDPOINTS.GET_INVESTMENT_PRIVATE_PENSION,
        normalize: normalizePrivatePension,
    }),
    combineReducers: () => {
        return Object.entries(Positions).reduce(
            (previous, entry: any[]) => ({
                ...previous,
                [entry[0].toLocaleLowerCase()]: entry[1].reducer,
            }),
            {},
        );
    },
};
