import * as React from 'react';

import { youtubeChannelLink } from './index.constants';
import * as S from './index.styles';

const YoutubeTerra = () => {
    const handleClick = () => {
        window.open(youtubeChannelLink, '_blank');
    };

    return (
        <S.StyledText onClick={handleClick}>
            Cursos Introdutórios Terra Investimentos no <S.Red>Youtube.</S.Red>
        </S.StyledText>
    );
};

export default YoutubeTerra;
