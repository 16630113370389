import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Icon, InputCpfCnpj, InputPassword, H3, Link, Text } from '@terrainvest/react-components';

export const StyledForm = styled.form`
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.xRay};

    box-sizing: border-box;
`;

const validInput = css`
    box-shadow: 0 0 0px 2px ${({ theme }) => theme.global.colors.primary};
`;

export const inputColor = css`
    color: ${({ theme }) => theme.global.colors.white};
    &&:hover,
    &&:active {
        color: ${({ theme }) => theme.global.colors.white};
        ${Icon.Styles.StyledIcon} {
            color: ${({ theme }) => theme.global.colors.white};
        }
    }
`;

export const InputWrapper = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    input {
        background-color: unset;
        border-radius: 4px;
        ${inputColor};

        ${({ valid }) => valid && validInput}
    }
    div {
        text-align: left;
    }
`;

export const StyledInputPassword = styled(InputPassword)`
    ${inputColor};
    :hover {
        && input {
            ${inputColor};
        }
    }
`;

export const StyledInputCpfCnpj = styled(InputCpfCnpj)`
    ${inputColor};
    :hover {
        && input {
            ${inputColor};
        }
    }
`;

export const StyledText = styled(Text)`
    margin: 0;
    margin-bottom: 3px;
`;

export const StyledH3 = styled(H3)`
    margin-bottom: 4px;
    font-weight: ${({ theme }) => theme.global.fontWeights.infrared};
`;

export const ForgotPasswordLink = styled(Link)`
    opacity: 0.8;
    cursor: pointer;
    p {
        box-shadow: none;
    }
`;

export const RegisterLink = styled(Link)`
    opacity: 0.8;
    p {
        color: ${({ theme }) => theme.global.colors.white} !important;
        > b {
            font-weight: ${({ theme }) => theme.global.fontWeights.regular};
            color: ${({ theme }) => theme.global.colors.primary} !important;
        }
    }
    ${media.max.md`
        white-space: break-spaces;
        text-align: center;
        p {
            line-height: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
        }
    `}
`;

export const BottomWrapper = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.global.spaces.xRay};
`;
