import * as React from 'react';
import { StyledSpacingText, StyledInsideTitle } from '../../LoginSide/index.styles';

import userBlockedSVG from './assets/UserBlocked.svg';

import { UserBlockedProps } from './index.types';
import * as S from './index.styles';

const UserBlocked = ({ unlockUser, backToLogin }: UserBlockedProps) => {
    return (
        <S.StyledWrapper>
            <S.StyledImage src={userBlockedSVG} alt="icone usuário bloqueado" />

            <StyledInsideTitle style={{ margin: 0 }}>Acesso bloqueado</StyledInsideTitle>
            <StyledSpacingText>
                Usuário bloqueado, desbloqueie e acesse novamente ou entre com outra conta
            </StyledSpacingText>

            <S.StyledPrimaryButton onClick={unlockUser}>Desbloquear agora</S.StyledPrimaryButton>

            <S.StyledSecondaryButton onClick={backToLogin}>
                Entrar com outra conta
            </S.StyledSecondaryButton>
        </S.StyledWrapper>
    );
};

export default UserBlocked;
