import styled from 'styled-components';
import { Icon } from '@terrainvest/react-components';

import { TokenControlType } from '../../../../Common/DoubleCheck/TokenCheck/index.types';
import { StyledTokenWrapper } from '../../../../Common/DoubleCheck/TokenCheck/index.styles';
import { StyledSpacingText } from '../../index.styles';

const getTokenColorByType = (type: TokenControlType, theme: any) => {
    switch (type) {
        case 'complete':
            return theme.global.colors.primary;

        case 'error':
            return '#FB7185';

        default:
            return '#A1A1AA';
    }
};

export const StyledWrapper = styled.div`
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    input {
        background-color: unset !important;
        color: white !important;
        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }
`;

export const StyledTokenWrapperPassword = styled(StyledTokenWrapper)`
    && input {
        border: 2px solid ${({ typeToken, theme }) => getTokenColorByType(typeToken, theme)} !important;
    }

    && span,
    && i {
        color: #fb7185;
    }
`;

export const StyledMessageWrapper = styled.div`
    margin-top: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
`;

export const BottomWrapper = styled.div`
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyVisible};
`;

export const BottomTexts = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.extremehighFrequencyGammaRay};
`;

export const ResendTokenText = styled(StyledSpacingText)`
    && {
        color: ${({ theme }) => theme.global.colors.primary};
    }
`;

export const ResendToken = styled.div`
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    cursor: pointer;
    :hover {
        ${ResendTokenText} {
            font-weight: ${({ theme }) => theme.global.fontWeights.medium};
        }
    }
`;

export const ResendTokenIcon = styled(Icon)`
    color: ${({ theme }) => theme.global.colors.primary};
`;
