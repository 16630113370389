import styled, { css } from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';

const bottomLine = css`
    ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -35px;
        right: -22px;
        height: 1px;
        background-color: ${({ theme }) => theme.global.colors.separationLineColor};
        opacity: 0.4;
    }
`;

export const StyledHeaderLinkItem = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.global.spaces.extremehighFrequencyGammaRay};
    margin-bottom: ${({ theme }) => theme.global.spaces.xRay};

    cursor: pointer;

    ${({ hasAfter }) => hasAfter && bottomLine};

    ${Text.Styles.StyledText} {
        :hover {
            font-weight: ${({ theme }) => theme.global.fontWeights.medium};
        }
    }

    ${Icon.Styles.StyledIcon} {
        font-size: ${({ theme }) => theme.global.fontSizes.visible};
        color: ${({ theme }) => theme.global.colors.primary};
    }
`;
