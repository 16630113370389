import { fetch, ENDPOINTS } from '../../Utils/Api';
import { Actions } from './index.constants';
import { normalizePartner } from './index.normalize';

export const fetchPartnerInit = () => ({
    type: Actions.PARTNER_FETCH_INIT,
});

export const fetchPartner = () =>
    fetch(ENDPOINTS.GET_PARTNER, {
        method: 'POST',
        body: JSON.stringify({
            Url: window.location.hostname,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
        .then(response => response.json())
        .then(normalizePartner);

export const fetchPartnerSuccess = partner => ({
    type: Actions.PARTNER_FETCH_SUCCESS,
    partner,
});

export const fetchPartnerError = () => ({
    type: Actions.PARTNER_FETCH_ERROR,
});
