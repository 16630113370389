const normalizeAddressFields = assetManager => {
    const normalizedObj = {
        ...assetManager,
        SecondaryAddress: assetManager.Complement,
        CountryId: 'BRA',
    };

    return normalizedObj;
};

const hasAssetManagers = client => {
    return client && client.AssetManagers && client.AssetManagers.length;
};

const normalizeIncomingAssetManager = assetManager => {
    const normalizedAssetManager = { ...assetManager };

    return [normalizedAssetManager].map(normalizeAddressFields).pop();
};

export const normalizeIncomingAssetManagers = client => {
    const normalizedClient = { ...client };
    const normalizedAssetManagers = hasAssetManagers(normalizedClient)
        ? [...normalizedClient.AssetManagers].map(normalizeIncomingAssetManager)
        : [];

    normalizedClient.AssetManagers = [...normalizedAssetManagers];

    return normalizedClient;
};
