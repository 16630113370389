import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledWrapper = styled.div`
    display: flex;
    flex: 1 1 100%;
    height: 100vh;

    align-items: center;
`;

export const StyledContentWrapper = styled.div`
    margin: auto;
    display: flex;
    gap: 24px;

    ${media.max.phone`
        flex-wrap: column;
        flex-flow: ${props => (props.mobileGraphicsOnTop ? 'column' : 'column-reverse')};
    `}
`;

export const StyledAsideArticle = styled.aside`
    display: flex;
`;

export const StyledAsideAsset = styled.img`
    max-width: 80%;
    min-width: 20%;
    margin: auto;

    ${media.max.tablet`
        max-width: 70%;
    `}

    ${media.max.phone`
        max-width: 60%;
    `}
`;

export const StyledMainArticle = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
