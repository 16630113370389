import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from '@terrainvest/react-components';

import { RootState } from '../../store';
import {
    fetchPartnerFunctionalityInit,
    fetchPartnerHighlightsInit,
} from '../../Redux/PartnerFunctionality';
import { fetchColorsModeInit } from '../../Redux/ColorsMode';

import Header from '../../View/Header';
import SideBar from '../../View/SideBarNew';
import { useNotifyAppLoad } from '../../Utils/Hooks';
import { GlobalNotification } from '../../View/Common/GlobalNotification';
import { DueDateNotification } from '../../View/Common/DueDateNotification';
import { UserDataExpirationNotification } from '../../View/UserDataExpiration';
import { StyledContainerNoPadd, StyledRowNoPadd } from '../../View/Common/Grid';

import { StyledSideBarAndHeader, StyledCol } from './index.styles';

export interface StandardTemplateProps {
    children: React.ReactNode;
    defaultSelected?: string;
    id?: string;
}

const StandardTemplate = ({ id = '', children }: StandardTemplateProps) => {
    const { loading: loadingFunctionality } = useSelector(
        (state: RootState) => state.partnerFunctionalityState,
    );
    const { loading: loadingColorsMode } = useSelector((state: RootState) => state.colorsModeState);
    const { loading: loadingClient } = useSelector((state: RootState) => state.clientState);
    const { partner } = useSelector((state: RootState) => state.partnerState);
    const { isTerraApp } = useSelector((state: RootState) => state.mobileState);

    const loading = loadingClient || loadingFunctionality || loadingColorsMode;

    const dispatch = useDispatch();
    useNotifyAppLoad();

    React.useEffect(() => {
        if (partner && partner.id && !loadingClient) {
            dispatch(fetchPartnerFunctionalityInit());
            dispatch(fetchPartnerHighlightsInit());
            if (partner.styleLight) {
                dispatch(fetchColorsModeInit());
            }
        }
    }, [partner]);

    return (
        <StyledContainerNoPadd id={id} fluid>
            <StyledRowNoPadd>
                {!isTerraApp && (
                    <StyledSideBarAndHeader>
                        <Header width="100%" />
                        <SideBar />
                    </StyledSideBarAndHeader>
                )}
                <StyledCol>
                    <UserDataExpirationNotification />
                    <DueDateNotification />
                    {loading ? <Loading visible={loading} /> : children}
                </StyledCol>
            </StyledRowNoPadd>
            <GlobalNotification />
        </StyledContainerNoPadd>
    );
};
export default StandardTemplate;
