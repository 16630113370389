import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchHistoric, fetchHistoricSuccess, fetchHistoricError } from './index.actions';

export const initialState = {
    historic: null,
    loading: false,
    error: null,
    fileUrl: null,
};

export const historicReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_HISTORIC_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchHistoric, {
                    successActionCreator: fetchHistoricSuccess,
                    failActionCreator: fetchHistoricError,
                    args: [action.payload],
                }),
            );

        case Actions.FETCH_HISTORIC_SUCCESS:
            return { ...state, historic: [...action.historic], loading: false, error: false };

        case Actions.FETCH_HISTORIC_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_HISTORIC_RESET:
            return { ...state, historic: null, loading: false, error: false };

        default:
            return state;
    }
};
