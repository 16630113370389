export enum CommonPrefixes {
    StatusRegistrations = 'StatusRegistrations',
    Countries = 'Countries',
    States = 'States',
    Cities = 'Cities',
    Nationalities = 'Nationalities',
    Occupations = 'Occupations',
    MaritalStatus = 'MaritalStatus',
    DocumentTypes = 'DocumentTypes',
    DocumentType = 'DocumentType',
    DocumentIssuerAgencies = 'DocumentIssuerAgencies',
    Genders = 'Genders',
    Receipt = 'Receipt',
    Banks = 'Banks',
    Advisors = 'Advisors',
    RepresentativeCategory = 'RepresentativeCategory',
    BusinessActivities = 'BusinessActivities',
    Fatca = 'Fatca',
    ConstitutionForm = 'ConstitutionForm',
}
