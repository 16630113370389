import { DEFAULT_ERROR_MESSAGE } from '../../Utils/Api';
import { Actions } from './index.constants';

export const postNotification = (message, props) => ({
    type: Actions.NOTIFICATION_POST,
    message,
    props,
});

export const clearNotification = () => ({
    type: Actions.NOTIFICATION_CLEAR,
});

export const postNotificationFromError = (error, props = {}, defaultMsg = null) => {
    const defaultErrorMessage = defaultMsg || DEFAULT_ERROR_MESSAGE;
    const genericError = {
        type: Actions.NOTIFICATION_POST,
        message: defaultErrorMessage,
        props: { type: 'error', ...props },
    };

    try {
        if (typeof error === 'string') {
            return {
                type: Actions.NOTIFICATION_POST,
                message: error,
                props: { type: 'error', ...props },
            };
        }

        if (error.response) {
            const json = error.response.bodyUsed ? error.responseJson : error.response.json();

            return {
                type: Actions.NOTIFICATION_POST,
                message: json.Message || json.message || defaultErrorMessage,
                props: { type: 'error', ...props },
            };
        }

        return genericError;
    } catch {
        return genericError;
    }
};

export const notifySuccessMsg = (message, props = {}) => ({
    type: Actions.NOTIFICATION_POST,
    message,
    props: { type: 'success', ...props },
});

export const notifyErrorMsg = (message, props = {}) => ({
    type: Actions.NOTIFICATION_POST,
    message,
    props: { type: 'error', ...props },
});
