import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';
import { fetchAccounts, fetchAccountsSuccess, fetchAccountsError } from './index.actions';

export const initialState = {
    accounts: null,
    loading: false,
    error: null,
};

export const accountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ACCOUNTS_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchAccounts, {
                    successActionCreator: fetchAccountsSuccess,
                    failActionCreator: fetchAccountsError,
                    args: [Cmd.getState],
                }),
            );
        case Actions.ACCOUNTS_FETCH_SUCCESS:
            return { ...state, accounts: action.accounts, loading: false, error: false };
        case Actions.ACCOUNTS_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case Actions.ACCOUNTS_SELECT:
            return { ...state, selectedAccount: action.selectedAccount };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
