import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { normalizeCustodyExtractDates } from './index.normalize';

export const fetchCustodyDatesInit = account => ({
    type: Actions.FETCH_CUSTODY_DATES_INIT,
    account,
});

export const fetchCustodyDates = account => {
    return fetch(ENDPOINTS.GET_CUSTODY_DATES.replace(':accountNumber', account), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeCustodyExtractDates);
};

export const fetchCustodyDatesSuccess = dates => ({
    type: Actions.FETCH_CUSTODY_DATES_SUCCESS,
    dates,
});

export const fetchCustodyDatesError = error => ({
    type: Actions.FETCH_CUSTODY_DATES_ERROR,
    error: error.message,
});

export const fetchCustodyDatesReset = () => ({
    type: Actions.FETCH_CUSTODY_DATES_RESET,
});
