import { loop, Cmd } from 'redux-loop';

import {
    execFilter,
    getTransfersByLine,
    calculatePagesAndGetFirstTransfers,
    getAllStatus,
} from './index.filter';
import { FiltersTypes, StatusToFilterProps, TransferRequestProps } from './index.types';
import { Actions } from './index.constants';
import {
    fetchWithdrawHistory,
    fetchWithdrawHistorySuccess,
    fetchWithdrawHistoryError,
} from './index.actions';

const initialFiltersState = { commonFilter: '', statusFilter: [] };

export const initialState = {
    history: [] as Array<TransferRequestProps>,
    historyFiltered: [] as Array<TransferRequestProps>,
    historyOnDisplay: [] as Array<TransferRequestProps>,
    loadingHistory: false,
    errorHistory: false,
    selectedIdToCancel: null,
    pages: 0,
    selectedPage: 0,
    statusToFilter: [] as Array<StatusToFilterProps>,
    filters: { ...initialFiltersState } as FiltersTypes,
};

export const withdrawHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.WITHDRAW_HISTORY_FETCH_INIT:
            return loop(
                { ...state, loadingHistory: true },
                Cmd.run(fetchWithdrawHistory, {
                    successActionCreator: fetchWithdrawHistorySuccess,
                    failActionCreator: fetchWithdrawHistoryError,
                    args: [action.data],
                }),
            );

        case Actions.WITHDRAW_HISTORY_FETCH_SUCCESS: {
            const { pages, filteredTransferList } = calculatePagesAndGetFirstTransfers(
                action.history,
            );
            const possibleStatusToFilter = getAllStatus(action.history);
            return {
                ...state,
                history: action.history,
                historyFiltered: action.history,
                historyOnDisplay: filteredTransferList,
                statusToFilter: possibleStatusToFilter,
                pages,
                selectedPage: 0,
                loadingHistory: false,
                filters: { ...initialFiltersState },
            };
        }

        case Actions.WITHDRAW_HISTORY_FETCH_ERROR:
            return {
                ...state,
                loadingHistory: false,
                errorHistory: action.error,
            };

        case Actions.WITHDRAW_HISTORY_SET_TRANSFER_ID_TO_CANCEL:
            return { ...state, selectedIdToCancel: action.transferId };

        case Actions.WITHDRAW_GO_TO_NEXT_PAGE: {
            const nextPage =
                state.selectedPage === state.pages ? state.selectedPage : state.selectedPage + 1;
            const filteredTransferList = getTransfersByLine(state.historyFiltered, nextPage);
            return { ...state, selectedPage: nextPage, historyOnDisplay: filteredTransferList };
        }

        case Actions.WITHDRAW_GO_TO_PREVIOUS_PAGE: {
            const previousPage = state.selectedPage === 0 ? 0 : state.selectedPage - 1;
            const filteredTransferList = getTransfersByLine(state.historyFiltered, previousPage);
            return { ...state, selectedPage: previousPage, historyOnDisplay: filteredTransferList };
        }

        case Actions.WITHDRAW_SET_FILTER: {
            const { filterType, value } = action;
            const newFilters = { ...state.filters, [filterType]: value };
            const historyFiltered = execFilter(state.history, newFilters);
            const { pages, filteredTransferList } = calculatePagesAndGetFirstTransfers(
                historyFiltered,
            );
            return {
                ...state,
                filters: { ...newFilters },
                historyOnDisplay: filteredTransferList,
                selectedPage: 0,
                historyFiltered,
                pages,
            };
        }

        case Actions.WITHDRAW_HISTORY_RESET_TRANSFER_ID_TO_CANCEL:
            return { ...state, selectedIdToCancel: null };

        default:
            return state;
    }
};
