import styled from 'styled-components';
import { Breadcrumb, H1 } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';
import { StyledWrapperPercentageTotalProps } from './index.types';

export const PAGE_OFFSET = 86;

export const StyledH1 = styled(H1)`
    position: fixed;
    font-weight: 500;
    line-height: 1;
    top: 115px;

    ${media.max.lg`
        position: relative;
        top: 0px;
        margin-bottom: 0px;
    `}
`;

export const StyledAccountData = styled.div`
    position: relative;

    ${Breadcrumb.Styles.StyledBreadcrumb} {
        z-index: 0;
    }

    ${media.min.lg`
        bottom: 20px;
        padding-top: 130px;

        ${Breadcrumb.Styles.StyledBreadcrumb} {
            position: fixed;
            width: 100%;
            padding: 20px 0 26px;
            top: 72px;
            margin-bottom: 0;
        }
    `}

    ${media.max.md`
        ${Breadcrumb.Styles.StyledBreadcrumb} {
            display: none;
        }
    `}
`;

export const StyledHR = styled.hr`
    width: 100%;
    background: transparent;
    background-color: transparent;
    border-color: transparent;
    color: transparent;
    opacity: 0.4;
    margin: 10px 0;
`;

const calcuteColorType = percentage => {
    if (percentage > 0 && percentage < 100) {
        return props => props.theme.global.colors.error;
    }

    if (percentage > 100) {
        return props => props.theme.global.colors.error;
    }

    if (percentage === 100) {
        return props => props.theme.global.colors.success;
    }

    return props => props.theme.global.colors.capeCode;
};

export const StyledWrapperPercentageTotal = styled.div<StyledWrapperPercentageTotalProps>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0px;
    border-top: 1px solid gray;
    font-family: 'Montserrat';
    font-size: 14px;
    > strong {
        font-weight: bold;
        font-size: 16px;
        color: ${({ percentageTotal }) => calcuteColorType(percentageTotal)};
    }
`;
