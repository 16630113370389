import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';

export const fetchSuitabilityProfileInit = clientId => {
    return {
        type: Actions.FETCH_SUITABILITY_PROFILE_INIT,
        clientId,
    };
};

export const fetchSuitabilityProfile = clientId => {
    return fetch(ENDPOINTS.GET_SUITABILITY_PROFILE.replace(':clientId', clientId), {
        method: 'GET',
    }).then(response => response.json());
};

export const fetchSuitabilityProfileSuccess = profile => ({
    type: Actions.FETCH_SUITABILITY_PROFILE_SUCCESS,
    profile,
});

export const fetchSuitabilityProfileError = error => ({
    type: Actions.FETCH_SUITABILITY_PROFILE_ERROR,
    error: error.message,
});

export const registerSuitabilityProfile = registerProfileClient =>
    fetch(ENDPOINTS.POST_SUITABILITY_PROFILE, {
        method: 'POST',
        body: JSON.stringify(registerProfileClient),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const updateSuitabilityProfile = updateProfileClient =>
    fetch(ENDPOINTS.PUT_SUITABILITY_PROFILE, {
        method: 'PUT',
        body: JSON.stringify(updateProfileClient),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchSuitabilityProfileByAccountNumberInit = accountNumber => {
    return {
        type: Actions.FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_INIT,
        accountNumber,
    };
};

export const fetchSuitabilityProfileByAccountNumber = accountNumber => {
    return fetch(
        ENDPOINTS.GET_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER.replace(
            ':accountNumber',
            accountNumber,
        ),
        {
            method: 'GET',
        },
    ).then(response => response.json());
};

export const fetchSuitabilityProfileByAccountNumberSuccess = minifiedProfile => ({
    type: Actions.FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_SUCCESS,
    minifiedProfile,
});

export const emptySuitabilityProfile = isEmptyProfile => ({
    type: Actions.EMPTY_SUITABILITY_PROFILE,
    isEmptyProfile,
});

export const setSuitabilityProfile = profile => ({
    type: Actions.SET_SUITABILITY_PROFILE,
    profile,
});

export const fillSuitabilityModal = () => ({
    type: Actions.FILL_SUITABILITY_PROFILE_MODAL,
});

export const setFillSuitabilityModal = shouldFillModal => ({
    type: Actions.SET_FILL_SUITABILITY_PROFILE_MODAL,
    shouldFillModal,
});

export const setLoadingPostSuitability = loadingPostSuitability => ({
    type: Actions.SET_LOADING_POST_SUITABILITY,
    loadingPostSuitability,
});
