import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizeBroker } from './index.normalize';

export const fetchBrokerInit = () => ({
    type: Actions.FETCH_BROKER_INIT,
});
// ACTION BAIXAR MES
export const fetchBroker = () => {
    return fetch(ENDPOINTS.GET_BROKER, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeBroker);
};

export const fetchBrokerSuccess = brokers => ({
    type: Actions.FETCH_BROKER_SUCCESS,
    brokers,
});

export const fetchBrokerError = error => ({
    type: Actions.FETCH_BROKER_ERROR,
    error: error.message,
});

export const fetchBrokerReset = () => ({
    type: Actions.FETCH_BROKER_RESET,
});
