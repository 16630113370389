import * as React from 'react';
import { InputText, InputTel, InputCpf, InputDate, InputCnpj } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import HelpButton from '../../../Common/HelpButton';

import { StyledSelect, StyledCol, StyledRow } from '../index.styles';
import {
    validateFields,
    validateRequiredField,
    validateCpfValue,
    validateDate,
    validateEmail,
    validateLength,
    validatePhone,
    validateNonRequiredCnpj,
} from '../../index.validators';
import { CommonPrefixes } from '../../../../Redux/Common/index.constants';
import { Gender } from '../Gender';
import { CurrentlyWorking } from '../CurrentlyWorking';
import { Field, SelectCityField, SelectField } from '../../Form/Field';
import { FORM_SECTIONS } from '../../Form';
import { fieldLengthLimits } from '../../index.settings';
import { getInnerProperty } from '../../../../Utils/General';

import FormCard from '../../Common/FormCard';
import Flags from '../Flags';

import { FormikParams } from './index.types';
import { mailAndPhoneChangeMessage } from './index.constants';
import { StyledHelpButtonContent } from './index.styles';

export const DetailsPersonForm = () => {
    const { getFieldProps, errors: error } = useFormikContext<FormikParams>();

    const hasSpouse = maritalStatusId => {
        const maritalStatiId = [5, 6, 8];
        return !maritalStatiId.includes(maritalStatusId);
    };

    const [disableSpouseId, setDisableSpouseId] = React.useState<boolean>(
        hasSpouse(getFieldProps('MaritalStatusId').value || true),
    );

    const [disableCompanyFields, setDisableCompanyFields] = React.useState<boolean>(
        !getFieldProps('CurrentlyWorking').value,
    );

    const customTitle = (
        <>
            {FORM_SECTIONS.DETAILS.NAME}
            &nbsp;
            <HelpButton
                content={
                    <StyledHelpButtonContent>{mailAndPhoneChangeMessage}</StyledHelpButtonContent>
                }
                messageWidth="350px"
                icon={['fas', 'circle-info']}
            />
        </>
    );

    return (
        <FormCard title={customTitle} subTitle={FORM_SECTIONS.DETAILS.DETAILS}>
            <StyledRow>
                <StyledCol md={6} sm={12}>
                    <Field name="Name" validate={value => validateFields('name', value)}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="CpfCnpj" validate={validateCpfValue} disabled>
                        {({ inputProps }) => (
                            <InputCpf disabled {...inputProps} label="CPF" fullWidth />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="Gender">
                        {({ field, form }) => <Gender field={field} form={form} />}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="BirthDate" validate={value => validateDate(value)}>
                        {({ inputProps }) => (
                            <InputDate {...inputProps} label="Data de Nascimento" fullWidth />
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <SelectField
                        name="BirthStateId"
                        commonPrefix={CommonPrefixes.States}
                        validate={validateRequiredField}
                    >
                        {({ field, form: { errors }, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Estado de Nascimento"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!getInnerProperty(errors, field.name)}
                                message={getInnerProperty(errors, field.name) || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={onChange}
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <SelectCityField
                        name="BirthCityId"
                        bindTo="BirthStateId"
                        validate={validateRequiredField}
                    >
                        {({ field, form: { errors }, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Cidade de Nascimento"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!getInnerProperty(errors, field.name)}
                                message={getInnerProperty(errors, field.name) || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={onChange}
                            />
                        )}
                    </SelectCityField>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <Field name="Email" validate={validateEmail} disabled>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                disabled
                                label="E-mail"
                                fullWidth
                                maxLength={fieldLengthLimits.email}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="Skype" validate={value => validateLength('skype', value)}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Skype"
                                fullWidth
                                maxLength={fieldLengthLimits.skype}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="MobilePhoneNumber" validate={validatePhone} disabled>
                        {({ inputProps }) => (
                            <InputTel {...inputProps} disabled label="Celular" fullWidth />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <SelectField
                        name="MaritalStatusId"
                        commonPrefix={CommonPrefixes.MaritalStatus}
                        validate={validateRequiredField}
                    >
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Estado Civil"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={(label, id) => {
                                    setDisableSpouseId(hasSpouse(id));
                                    onChange(label);
                                }}
                            />
                        )}
                    </SelectField>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <Field name="SpouseCpf" validate={!disableSpouseId} disabled={disableSpouseId}>
                        {({ inputProps }) => (
                            <>
                                <InputCpf
                                    {...inputProps}
                                    label="CPF do Cônjuge"
                                    fullWidth
                                    disabled={inputProps.disabled}
                                />
                            </>
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <Field
                        name="SpouseName"
                        validate={value => !disableSpouseId && validateFields('spouseName', value)}
                        disabled={disableSpouseId}
                    >
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome do Cônjuge"
                                fullWidth
                                maxLength={fieldLengthLimits.motherName}
                            />
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <Field
                        name="MotherName"
                        validate={value => validateFields('motherName', value)}
                    >
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome da Mãe"
                                fullWidth
                                maxLength={fieldLengthLimits.motherName}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field
                        name="FatherName"
                        validate={value => validateFields('fatherName', value)}
                    >
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome do Pai"
                                fullWidth
                                maxLength={fieldLengthLimits.fatherName}
                            />
                        )}
                    </Field>
                </StyledCol>

                <StyledCol md={6} sm={12}>
                    <SelectField
                        name="OccupationId"
                        commonPrefix={CommonPrefixes.Occupations}
                        validate={validateRequiredField}
                    >
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Profissão"
                                fullWidth
                                suggest
                                suggestLimit={8}
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={onChange}
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field name="CurrentlyWorking">
                        {({ field, form }) => (
                            <CurrentlyWorking
                                field={field}
                                form={form}
                                onChange={currentlyWorking => {
                                    setDisableCompanyFields(!currentlyWorking);
                                }}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field
                        name="CompanyName"
                        disabled={disableCompanyFields}
                        validate={value => false && validateFields('company', value)}
                    >
                        {({ inputProps }) => {
                            return (
                                <InputText
                                    {...inputProps}
                                    label="Empresa"
                                    fullWidth
                                    maxLength={fieldLengthLimits.company}
                                    error={false}
                                    message=""
                                />
                            );
                        }}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <Field
                        name="CompanyCnpj"
                        disabled={disableCompanyFields}
                        validate={value => validateNonRequiredCnpj(value)}
                    >
                        {({ inputProps }) => {
                            return (
                                <InputCnpj
                                    {...inputProps}
                                    label="CNPJ da empresa onde trabalha"
                                    fullWidth
                                    error={error.CompanyCnpj}
                                    message={error.CompanyCnpj}
                                />
                            );
                        }}
                    </Field>
                </StyledCol>
                <StyledCol md={6} sm={12}>
                    <SelectField name="AdvisorId" commonPrefix={CommonPrefixes.Advisors}>
                        {({ field, meta, onChange, options, defaultValue }) => (
                            <StyledSelect
                                name={field.name}
                                label="Assessor"
                                fullWidth
                                error={!!meta.error}
                                message={meta.error || ''}
                                defaultValue={defaultValue}
                                options={options}
                                onChange={onChange}
                                disabled
                            />
                        )}
                    </SelectField>
                </StyledCol>
                <Flags isCompany={false} />
            </StyledRow>
        </FormCard>
    );
};
