import React from 'react';

import { appMsgTypes, postAppMsg } from '../General';

export const useNotifyAppLoad = () => {
    React.useEffect(() => {
        postAppMsg(appMsgTypes.LOADED);
    }, []);
};

export default useNotifyAppLoad;
