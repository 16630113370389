import { validateCpf } from '../../../View/ForgotPassword/utils';

const removeNumbersRegex = new RegExp(/\d+/g);
const removeSpecialCharactersRegex = new RegExp(/[^a-záéíóúàèìòùâêîôûãõç\s]+/g);

const validateEmpty = (value: string) => (value ? '' : 'Campo obrigatório.');

const validateNotNumbers = (value: string) =>
    value.length === value.replace(removeNumbersRegex, '').length
        ? ''
        : 'Campo não aceita números.';

const validateNotSpecialCharacters = (value: string) =>
    value.length === value.toLowerCase().replace(removeSpecialCharactersRegex, '').length
        ? ''
        : 'Campo não aceita caracteres especiais.';

const validateCpfValue = (value: string) => (validateCpf(value) ? '' : 'CPF inválido.');

const validations = {
    AccountDigit: [validateEmpty],
    AccountNumber: [validateEmpty],
    AccountType: [validateEmpty],
    AgencyNumber: [validateEmpty],
    BankNumber: [validateEmpty],
    CpfCoOwner: [validateEmpty, validateCpfValue],
    NameCoOwner: [validateEmpty, validateNotNumbers, validateNotSpecialCharacters],
};

export const validateField = (fieldName: string, value: string) => {
    const errors: Array<string> = [];
    if (validations[fieldName]) {
        validations[fieldName].forEach((validation: Function) => {
            errors.push(validation(value));
        });
    }
    return errors.filter(error => !!error).shift() || '';
};
