import * as React from 'react';

import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';
import AdministratoInput from './AdministratorInput';

export const AdministratorsInfo = () => {
    const { values, setFieldValue } = useFormikContext<any>();

    const onRemoveAdministrator = administrator => {
        const index = values.Administrators.indexOf(administrator);
        if (index >= 0) {
            values.Administrators.splice(index, 1);
            setFieldValue('Administrators', [...values.Administrators]);
        }
    };

    const onAddAdministrator = () => {
        setFieldValue('Administrators', [
            ...values.Administrators,
            {
                Id: `Administrator-${Math.random()}`,
                ClientId: values.ClientId,
                Name: '',
                RG: '',
                CpfCnpj: '',
            },
        ]);
    };

    return (
        <>
            <FormCard
                title={FORM_SECTIONS.ADMINISTRATORS.NAME}
                subTitle={FORM_SECTIONS.ADMINISTRATORS.DETAILS}
            >
                {values &&
                    values.Administrators &&
                    values.Administrators.map((administrator, index) => (
                        <AdministratoInput
                            key={`${administrator.Id}-${index}`}
                            index={index}
                            onRemove={
                                values.Administrators.length > 1
                                    ? () => onRemoveAdministrator(administrator)
                                    : null
                            }
                        />
                    ))}

                {values && (!values.Administrators || !values.Administrators.length) && (
                    <>
                        <Separator />
                        <FeedbackNote
                            color="warn"
                            message={FORM_SECTIONS.ADMINISTRATORS.EMPTY_FEEDBACK}
                        />
                        <Separator />
                    </>
                )}

                <Separator />
                <Button type="button" fullWidth secondary onClick={onAddAdministrator}>
                    ADICIONAR {FORM_SECTIONS.ADMINISTRATORS.NAME}
                    <Icon icon={['fas', 'plus']} padLeft size="xs" />
                </Button>
            </FormCard>
        </>
    );
};

export default AdministratorsInfo;
