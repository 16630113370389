import styled, { css } from 'styled-components';
import { H2, Icon, Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 0 15%;
    padding-bottom: 150px;
    box-sizing: border-box;
`;

export const StyledIcon = styled(Icon)`
    height: 80px;
    font-size: 64px;
    color: ${({ theme }) => theme.global.colors.backgroundTextColor};
`;

export const StyledTitle = styled(H2)`
    margin: 0;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.global.colors.backgroundTextColor};
`;

export const StyledAccountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    border: 1px solid ${({ theme }) => theme.global.colors.primary};
    border-radius: 4px;

    transition: ${({ theme }) => theme.components.header.transition};
    cursor: pointer;

    ${({ selected, theme }) =>
        selected &&
        css`
            cursor: default;
            background-color: ${theme.global.colors.primary};
            p {
                color: ${theme.global.colors.mainBackground} !important;
            }
        `};
`;

export const StyledAccount = styled(Text)`
    margin: 0;
    color: ${({ theme }) => theme.global.colors.primary} !important;
`;

export const StyledAllAccounts = styled(Text)`
    position: absolute;
    bottom: 100px;
    cursor: pointer;
`;
