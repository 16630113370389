import * as React from 'react';
import { Button, Loading, Text, DynamicLogo } from '@terrainvest/react-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../../store';

import {
    isCadastralUpdate,
    needsAgreementUpdate,
    needsSuitabilityUpdate,
} from '../../../../Utils/General';
import { isExpiredDate } from '../../../../Utils/Parsers';
import useChooseAccount from '../../../../Utils/Hooks/useChooseAccount';

import { StyledCol, StyledSelectAccount } from './index.styles';

export const AccountSelection = ({ isLoading, clientData }) => {
    const history = useHistory();

    const { partner } = useSelector((state: RootState) => state.partnerState);
    const { profile: suitabilityProfile } = useSelector(
        (state: RootState) => state.suitabilityProfileState,
    );
    const { agreementByType: agreement } = useSelector((state: RootState) => state.agreementsState);
    const { client } = useSelector((state: RootState) => state.clientState);

    const { onChooseAccount } = useChooseAccount();

    const pushToPath = () => {
        if (
            isCadastralUpdate(client) ||
            isExpiredDate(client) ||
            needsSuitabilityUpdate(suitabilityProfile)
        ) {
            history.push('/cadastro-expirado');
        } else if (needsAgreementUpdate(agreement)) {
            history.push('/termo-expirado');
        } else if (history && history.location && history.location.pathname) {
            history.push(history.location.pathname);
        } else {
            history.push('/');
        }
    };

    const handleChooseAccount = (accountNumber: number) => {
        onChooseAccount(accountNumber, pushToPath);
    };

    const handleAutoChooseWhenOneAccount = () => {
        if (clientData.Accounts && clientData.Accounts.length === 1) {
            handleChooseAccount(clientData.Accounts[0]);
        }
    };

    React.useEffect(() => {
        if (!isLoading && suitabilityProfile && suitabilityProfile.Validity && agreement) {
            handleAutoChooseWhenOneAccount();
        }
    }, [isLoading, suitabilityProfile, agreement]);

    return (
        <StyledCol col={12}>
            <Loading visible={isLoading || !(suitabilityProfile && suitabilityProfile.Validity)} />
            {!isLoading &&
                suitabilityProfile &&
                suitabilityProfile.Validity &&
                clientData.Accounts.length > 1 && (
                    <StyledSelectAccount onSubmit={event => event.preventDefault()}>
                        <DynamicLogo
                            src={partner.logos.login}
                            alt={`Logo ${partner.name}`}
                            width="200px"
                            height="100px"
                        />
                        <Text large weight="medium">
                            Contas
                        </Text>
                        <Text>Selecione a conta que você quer acessar:</Text>
                        {clientData.Accounts.map(accountNumber => (
                            <Button
                                className="account-button"
                                key={accountNumber}
                                fullWidth
                                secondary
                                onClick={() => handleChooseAccount(accountNumber)}
                            >
                                {accountNumber}
                            </Button>
                        ))}
                    </StyledSelectAccount>
                )}
        </StyledCol>
    );
};

export default AccountSelection;
