import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchOpportunities,
    fetchOpportunitiesSuccess,
    fetchOpportunitiesError,
} from './index.actions';

export const initialState = {
    opportunities: null,
    loading: false,
    error: null,
};

export const opportunitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.OPPORTUNITIES_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchOpportunities, {
                    successActionCreator: fetchOpportunitiesSuccess,
                    failActionCreator: fetchOpportunitiesError,
                    args: [action.clientId],
                }),
            );
        case Actions.OPPORTUNITIES_FETCH_SUCCESS:
            return {
                ...state,
                opportunities: action.opportunities,
                loading: false,
                error: false,
            };
        case Actions.OPPORTUNITIES_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};
