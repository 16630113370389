import styled from 'styled-components';
import { Button, Text } from '@terrainvest/react-components';

export const StyledSubmitButton = styled(Button)`
    height: 40px;
    margin-left: auto;
`;

export const StyledAgreementText = styled(Text)`
    color: #807d7d !important;
`;

export const StyledTermsButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
