import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchRecommendation,
    fetchRecommendationSuccess,
    fetchRecommendationError,
    fetchPortfolioOptions,
    fetchPortfolioOptionsSuccess,
    fetchPortfolioOptionsError,
} from './index.actions';

export const initialState = {
    recommendation: null,
    minimalApplication: null,
    loading: false,
    error: null,
    loadingActiveRecommendations: false,
    portfolioActiveRecommendations: [],
    errorActiveRecommendations: null,
};

export const recommendationReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RECOMMENDATION_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchRecommendation, {
                    successActionCreator: fetchRecommendationSuccess,
                    failActionCreator: fetchRecommendationError,
                    args: [action.clientId],
                }),
            );
        case Actions.RECOMMENDATION_FETCH_SUCCESS:
            return {
                ...state,
                recommendation: action.recommendation,
                minimalApplication: action.minimalApplication,
                loading: false,
                error: false,
            };
        case Actions.RECOMMENDATION_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_PORTFOLIO_OPTIONS_INIT:
            return loop(
                { ...state, loadingActiveRecommendations: true },
                Cmd.run(fetchPortfolioOptions, {
                    successActionCreator: fetchPortfolioOptionsSuccess,
                    failActionCreator: fetchPortfolioOptionsError,
                }),
            );

        case Actions.FETCH_PORTFOLIO_OPTIONS_SUCCESS:
            return {
                ...state,
                loadingActiveRecommendations: false,
                errorActiveRecommendations: false,
                portfolioActiveRecommendations: action.options,
            };

        case Actions.FETCH_PORTFOLIO_OPTIONS_ERROR:
            return {
                ...state,
                errorActiveRecommendations: action.error,
                loadingActiveRecommendations: false,
            };

        default:
            return state;
    }
};
