/**
 * Marks user profile registration status.
 */
export enum REGISTRATION_STATUS {
    SIGNUP_UNFINISHED = 1,
    IN_ANALYSIS = 2,
    ACTION_PENDING = 3,
}

export const unfinishedRegistrationStati = [
    REGISTRATION_STATUS.SIGNUP_UNFINISHED,
    REGISTRATION_STATUS.ACTION_PENDING,
];
