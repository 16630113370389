import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';
import { fetchAdvisor, fetchAdvisorSuccess, fetchAdvisorError } from './index.actions';

export const initialState = {
    advisor: null,
    loading: false,
    error: null,
};

export const advisorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_ADVISOR_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchAdvisor, {
                    successActionCreator: fetchAdvisorSuccess,
                    failActionCreator: fetchAdvisorError,
                    args: [action.advisorId],
                }),
            );

        case Actions.FETCH_ADVISOR_SUCCESS:
            return { ...state, advisor: action.advisor, loading: false, error: false };

        case Actions.FETCH_ADVISOR_ERROR:
            return { ...state, error: action.error, loading: false };

        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };

        default:
            return state;
    }
};
