/**
 * Lida com respostas válidas de conteúdo potencialmetne nulo
 * (uma das variações no padrão de respostas da API é uma string "null").
 * @param response Resposta da API
 * @param defaultValue Valor caso resposta seja 'null'
 */
export const validPotentialNullResponseFromAPI = async (response, defaultValue) => {
    if (!response.ok) {
        return Promise.reject();
    }

    const res = await response.text();

    const responseHasContent =
        !!res &&
        String(res)
            .trim()
            .toLowerCase() !== 'null';

    return responseHasContent ? JSON.parse(res) : defaultValue;
};
