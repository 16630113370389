import * as React from 'react';
import { H2, Text, Icon, InputText, InputNumber, InputCnpj } from '@terrainvest/react-components';

import {
    StyledCol,
    StyledRow,
    StyledHeaderAddedItemWrapper,
    StyledRemoveItem,
} from '../../Common/GridStyles';
import Separator from '../../../../Utils/General/index.separator';

import { validateNumber } from '../../index.validators';

import { Field } from '../../Form/Field';
import { fieldLengthLimits } from '../../index.settings';

const ControllerInput = ({ index, onRemove, hasError }) => {
    const preffix = `Controllers[${index}].`;

    const handleRemove = () => {
        onRemove(index);
    };

    return (
        <>
            <StyledHeaderAddedItemWrapper>
                <H2>{`Coligada ${index + 1}`}</H2>
                <StyledRemoveItem onClick={handleRemove}>
                    <Text weight="medium">
                        Remover controladora, controlada ou coligada
                        <Icon icon="xmark" size="sm" padLeft />
                    </Text>
                </StyledRemoveItem>
            </StyledHeaderAddedItemWrapper>
            <Separator />
            <StyledRow>
                <StyledCol xl={4} md={6} sm={12}>
                    <Field name={`${preffix}Name`}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Nome"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={3} md={6} sm={12}>
                    <Field name={`${preffix}CnpjId`}>
                        {({ inputProps }) => (
                            <InputCnpj
                                {...inputProps}
                                label="CNPJ"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={3} md={6} sm={12}>
                    <Field name={`${preffix}Category`}>
                        {({ inputProps }) => (
                            <InputText
                                {...inputProps}
                                label="Categoria"
                                fullWidth
                                maxLength={fieldLengthLimits.name}
                            />
                        )}
                    </Field>
                </StyledCol>
                <StyledCol xl={2} md={6} sm={12}>
                    <Field
                        name={`${preffix}Percentage`}
                        validate={value => validateNumber(value, 'percentage')}
                        error={hasError}
                    >
                        {({ inputProps, form }) => (
                            <InputNumber
                                {...inputProps}
                                onChange={evt => {
                                    const numValue = Number(
                                        String(evt.target.value).replace(',', '.'),
                                    );
                                    form.setFieldValue(inputProps.name, numValue);
                                }}
                                label="Percentual"
                                fullWidth
                                icon={<Icon icon={['fa', 'percent']} size="sm" />}
                            />
                        )}
                    </Field>
                </StyledCol>
            </StyledRow>
        </>
    );
};

export default ControllerInput;
