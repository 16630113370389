export const Actions = {
    AUTH_FETCH_INIT: 'AUTH_FETCH_INIT',
    AUTH_FETCH_SUCCESS: 'AUTH_FETCH_SUCCESS',
    AUTH_MAPPED_ERROR: 'AUTH_MAPPED_ERROR',
    AUTH_OFFER_MANAGER_ERROR: 'AUTH_OFFER_MANAGER_ERROR',
    AUTH_FETCH_ERROR: 'AUTH_FETCH_ERROR',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    SET_AUTH: 'SET_AUTH',
};

export const OFFER_MANAGER_ERROR = 'Portal não disponível para clientes de coordenação de ofertas';

export const SEND_TOKEN_BY_SMS_AND_EMAIL = [1, 2];
export const SEND_TOKEN_JUST_BY_SMS = [2];
