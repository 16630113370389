import styled from 'styled-components';
import { FormGroup, Radio, Checkbox, Link } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledFlagsFormGroup = styled(FormGroup)`
    ${FormGroup.Styles.VerticalStyledFormGroup} {
        // prettier-ignore
        ${Radio.Styles.StyledRadio}:not(:first-child) {
            margin-top: 16px;
        }
    }
`;

export const StyledDescription = styled.div`
    margin-top: 0;
`;

export const StyledAgreementFieldPosition = styled.div`
    width: 100%;
    padding: 0 10px;
`;

export const StyledAgreementField = styled(Checkbox)`
    width: 90%;

    label {
        display: flex;
        flex-direction: row;
    }

    ${Link.Styles.StyledLink} {
        margin-left: 0.3rem;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    label {
        color: ${props => props.theme.global.colors.doveGrayDark};
        display: flex;
        flex-direction: row;
    }
`;

export const StyledLink = styled.span`
    & {
        color: ${props => props.theme.global.colors.primary} !important;
        border-bottom: 1px solid;
    }
`;

export const StyledCheckboxWithInfo = styled.div`
    display: flex;
    flex-wrap: nowrap;

    > div:last-child {
        > i {
            position: absolute;
            margin-top: 18px;
            margin-left: 5px;
        }
        > div {
            top: 35px;
        }
        ${media.max.phone`
            display: none;
        `}
    }
`;
