import styled from 'styled-components';
import { H1, H4 } from '@terrainvest/react-components';

export const StyledTitle = styled(H1)`
    text-align: center;
`;
export const StyledMessage = styled(H4)`
    text-align: center;
    font-size: ${props => props.theme.global.typographyConfig.headings.h3.fontSize} !important;
    line-height: ${props => props.theme.global.typographyConfig.body.large.lineHeight} !important;
`;
