import { loop, Cmd } from 'redux-loop';
// import { initialState } from '../../Balance/index.reducer';
import { Actions } from './index.constants';
import {
    fetchExtractsPeriodView,
    fetchExtractsPeriodViewSuccess,
    fetchExtractsPeriodViewError,
} from './index.actions';

export const initialState = {
    loading: false,
    error: null,
    fileUrl: null,
};

export const extractsReportsPeriodReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_EXTRACTS_PERIOD_INIT:
            return state.loading === true
                ? state
                : loop(
                      { ...state, loading: true, error: null },
                      Cmd.run(fetchExtractsPeriodView, {
                          successActionCreator: fetchExtractsPeriodViewSuccess,
                          failActionCreator: fetchExtractsPeriodViewError,
                          args: [action.data],
                      }),
                  );

        case Actions.FETCH_EXTRACTS_PERIOD_SUCCESS:
            return {
                ...state,
                fileUrl: action.fileUrl,
                loading: false,
                error: false,
            };

        case Actions.FETCH_EXTRACTS_PERIOD_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_EXTRACTS_RESET:
            return { ...state, fileUrl: null, error: null, loading: false };

        default:
            return state;
    }
};
