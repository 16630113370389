import * as React from 'react';
import { Icon, Notification } from '@terrainvest/react-components';
import { useSelector, useDispatch } from 'react-redux';
import { StyledGlobalNotification } from './index.styles';
import { clearNotification } from '../../../Redux/Notification';

export const GlobalNotification = props => {
    const { message, props: notificationProps } = useSelector(state => state.notificationState);
    const dispatch = useDispatch();
    let icon = null;

    if (notificationProps && notificationProps.icon) {
        const iconName = notificationProps.icon;
        delete notificationProps.icon;

        icon = <Icon icon={iconName} />;
    }

    return (
        <StyledGlobalNotification {...props}>
            <Notification
                visible={!!message}
                timeout={5000}
                onClose={() => dispatch(clearNotification())}
                {...notificationProps}
                fixed
                className="global-system-notification"
            >
                {icon}
                {message}
            </Notification>
        </StyledGlobalNotification>
    );
};
