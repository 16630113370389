import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import useWindowSize from '../../../../Utils/Hooks/useWindowSize';

import { StyledHeaderProfileMenuButton } from './index.styles';

interface HeaderProfileMenuButtonProps {
    id: string;
    open: boolean;
    setOpen: Function;
}

export const HeaderProfileMenuButton: React.FC<HeaderProfileMenuButtonProps> = ({
    open,
    setOpen,
    id = '',
}: HeaderProfileMenuButtonProps) => {
    const windowSize = useWindowSize();

    const { dark } = useSelector((state: RootState) => state.colorsModeState);

    const isMobileViewport = windowSize && windowSize.width < 768;

    return (
        <StyledHeaderProfileMenuButton
            id={id}
            open={open}
            onClick={setOpen}
            isMobile={isMobileViewport}
            dark={dark}
        >
            <Icon id={id} icon={['fal', 'circle-user']} size="lg" />
            <Icon id={id} icon={['fal', 'chevron-right']} size="sm" rotation={open ? 180 : 90} />
        </StyledHeaderProfileMenuButton>
    );
};

HeaderProfileMenuButton.defaultProps = {
    open: false,
    setOpen: () => {},
};

export default HeaderProfileMenuButton;
