import styled from 'styled-components';
import { InputFileUpload, Text } from '@terrainvest/react-components';

interface StyledFileSectionProps {
    error: boolean;
}

interface StyledInputUploadFileProps {
    show: boolean;
}

export const CustomInputFileUpload = styled(InputFileUpload)<StyledInputUploadFileProps>`
    input {
        visibility: hidden;
    }
`;

export const ErrorText = styled(Text)`
    text-align: center;
    color: ${props => props.theme.global.colors.error} !important;
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

export const StyledRemove = styled.div`
    cursor: pointer;
    margin-left: auto;
`;

export const StyledFileSection = styled.div<StyledFileSectionProps>`
    width: 100%;

    &:not(:first-child) {
        margin-top: 40px;
    }

    ${InputFileUpload.Styles.StyledDropeZone} {
        margin-top: 28px;
    }

    ${({ error }) => (error ? `border-radius: 2px; padding: 16px; border:1px solid red;` : 'none')};
`;

export const StyledAttachments = styled.div``;
