import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { responseToFile } from '../../../../Utils/Parsers';

export const fetchExtractsDateViewInit = data => ({
    type: Actions.FETCH_EXTRACTS_DATE_INIT,
    data,
});

export async function fetchExtractsDateView(data) {
    const response = await fetch(
        ENDPOINTS.GET_EXTRACTS_DATE_PDF.replace(':accountNumber', data.account).replace(
            ':month',
            data.month,
        ),
        {
            method: 'GET',
        },
    );

    if (response.status && response.status === 200) {
        const defaultFilename = `extrato_mes.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchExtractsDateViewSuccess = fileURL => ({
    type: Actions.FETCH_EXTRACTS_DATE_SUCCESS,
    fileURL,
});

export const fetchExtractsDateViewError = error => ({
    type: Actions.FETCH_EXTRACTS_DATE_ERROR,
    error: error.message,
});

export const fetchExtractsDateViewReset = () => ({
    type: Actions.FETCH_EXTRACTS_RESET,
});
