import { Actions, YearProps } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import {
    normalizeLastBrokerageDates,
    normalizeBrokerageMonths,
    normalizeBrokerageYears,
} from './index.normalize';

export const fetchBrokerageNoteDatesInit = (marketValue, account) => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_INIT,
    marketValue,
    account,
});

const fetchLastNotes = (marketValue, account, headers) =>
    fetch(
        ENDPOINTS.GET_BROKERAGENOTE_DATES.replace(':market', marketValue).replace(
            ':accountNumber',
            account,
        ),
        headers,
    )
        .then(response => response.json())
        .then(normalizeLastBrokerageDates);

const fetchYears = (marketValue, headers) =>
    fetch(ENDPOINTS.GET_BROKERAGENOTE_YEARS.replace(':market', marketValue), headers)
        .then(response => response.json())
        .then(normalizeBrokerageYears);

const fetchMonth = (monthsEndpoint, headers) =>
    fetch(monthsEndpoint, headers)
        .then(response => response.json())
        .then(normalizeBrokerageMonths);

export const fetchBrokerageNoteDates = async (marketValue, account) => {
    const monthsEndpointBASE = ENDPOINTS.GET_BROKERAGENOTE_MONTHS.replace(':market', marketValue);
    const headers = { method: 'GET' };

    const lastNotes = await fetchLastNotes(marketValue, account, headers);
    const years = await fetchYears(marketValue, headers);

    const dates: Array<YearProps> = await Promise.all(
        years.map(async year => {
            const monthsEndpoint = monthsEndpointBASE.replace(':year', year);
            const months = await fetchMonth(monthsEndpoint, headers);
            return {
                year,
                months,
            };
        }),
    );

    return { lastNotes, dates };
};

export const fetchBrokerageNoteDatesSuccess = ({ dates, lastNotes }) => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_SUCCESS,
    dates,
    lastNotes,
});

export const fetchBrokerageNoteDatesError = error => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_ERROR,
    error: error.message,
});

export const fetchBrokerageNoteDatesReset = () => ({
    type: Actions.FETCH_BROKERAGENOTE_DATES_RESET,
});

export const updateDatesAfterRequest = ({ year, month }) => ({
    type: Actions.UPDATE_DATES_AFTER_REQUEST,
    year,
    month,
});
