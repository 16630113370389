import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@terrainvest/react-components';

import { RootState } from '../../../../../store';
import { fetchNewPassword, OFFER_MANAGER_ERROR } from '../../../../../Redux/Auth';
import { postNotificationFromError } from '../../../../../Redux/Notification';

import { validateCpf, validateCnpj } from '../../../../ForgotPassword/utils';

import { BottomWrapper, InputWrapper, StyledInputCpfCnpj } from '../../LoginForm/index.styles';
import { StyledInsideTitle, StyledSpacingText } from '../../index.styles';
import ErrorMessage from '../../ErrorMessage';
import { StyledPrimaryButton } from '../index.styles';

import * as S from './index.styles';
import { errorMessage } from './index.constants';
import { ForgotPasswordIdentificationProps } from './index.types';

const ForgotPasswordIdentification = ({
    onConfirmUsername,
    wrongPartner,
    userBlocked,
}: ForgotPasswordIdentificationProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState(userBlocked || '');
    const [error, setError] = React.useState('');
    const [errorDelay, setErrorDelay] = React.useState(null);

    const { partner } = useSelector((state: RootState) => state.partnerState);

    const isValueValid = (value: string) => value && (validateCpf(value) || validateCnpj(value));

    const handleError = (value: string) => {
        if (errorDelay) clearTimeout(errorDelay);
        if (error) setError('');
        const isValid = isValueValid(value);
        if (!isValid && value) {
            const newDelay = setTimeout(() => setError(errorMessage), 800);
            setErrorDelay(newDelay);
        }
    };

    const handleChangeUsername = (value: string) => {
        setUsername(value);
        handleError(value);
    };

    const onNextStep = async () => {
        if (loading) return;
        setLoading(true);

        const payload = {
            username,
            partnerId: partner.id,
        };
        fetchNewPassword(payload)
            .then(() => onConfirmUsername(username))
            .catch(fetchError => {
                if (fetchError.responseJson.Message === OFFER_MANAGER_ERROR) {
                    history.push('/restricao-coordenacao-oferta');
                }
                if (fetchError.responseJson.PartnerUrl) {
                    wrongPartner(fetchError.responseJson.PartnerUrl);
                    return;
                }
                dispatch(postNotificationFromError(fetchError));
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        if (userBlocked) onNextStep();
    }, []);

    return (
        <S.StyledWrapper data-testid="forgot-password-indentification">
            <StyledInsideTitle style={{ margin: 0 }}>Recuperação de senha</StyledInsideTitle>

            <InputWrapper>
                <StyledSpacingText>
                    Informe seu CPF ou CNPJ para redefinir a senha
                </StyledSpacingText>
                <S.StyledSeparator />
                <StyledInputCpfCnpj
                    name="username"
                    placeholder="Insira seu CPF ou CNPJ"
                    fullWidth
                    defaultValue={username}
                    onChange={(_, formattedValue) =>
                        handleChangeUsername(formattedValue.parsedValue)
                    }
                    error={!!error}
                    message={!!error && <ErrorMessage message={error} />}
                />
            </InputWrapper>

            <BottomWrapper>
                <StyledPrimaryButton onClick={onNextStep} disabled={!isValueValid(username)}>
                    Continuar
                    {loading && <Icon icon="spinner-third" spin size="xs" padRight />}
                </StyledPrimaryButton>
            </BottomWrapper>
        </S.StyledWrapper>
    );
};

export default ForgotPasswordIdentification;
