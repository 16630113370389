import styled from 'styled-components';

export const Separator = styled.div`
    margin: ${props =>
        props.multiplyBy ? `${(props.base || 16) * props.multiplyBy}px;` : `${props.base || 16}px`};
    width: 90%;
    height: 1px;
`;

export default Separator;
