import * as React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@terrainvest/react-components';

import {
    StyledWrapper,
    StyledContentWrapper,
    StyledAsideArticle,
    StyledAsideAsset,
    StyledMainArticle,
} from './index.styles';

export const FeedbackStructureWithGraphics = ({
    title,
    message,
    graphics,
    mobileGraphicsOnTop,
}) => {
    const wrapper = content => {
        return <div>{content}</div>;
    };

    const renderAsideContent = () => (
        <>
            {typeof graphics !== 'string' ? (
                wrapper(graphics)
            ) : (
                <StyledAsideAsset src={graphics} alt="empty feedback icon" />
            )}
        </>
    );

    const renderMainContent = () => (
        <>
            {typeof title !== 'string' ? wrapper(title) : <Text>{title}</Text>}
            {typeof message !== 'string' ? wrapper(message) : <Text>{message}</Text>}
        </>
    );

    return (
        <StyledWrapper>
            <StyledContentWrapper mobileGraphicsOnTop={mobileGraphicsOnTop}>
                <StyledAsideArticle>{renderAsideContent()}</StyledAsideArticle>
                <StyledMainArticle>{renderMainContent()}</StyledMainArticle>
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

FeedbackStructureWithGraphics.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    graphics: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    mobileGraphicsOnTop: PropTypes.bool,
};

FeedbackStructureWithGraphics.defaultProps = {
    title: '',
    message: '',
    graphics: '',
    mobileGraphicsOnTop: false,
};

export default FeedbackStructureWithGraphics;
