import { loop, Cmd } from 'redux-loop';
import { Actions as AuthActions } from '../Auth/index.constants';
import { BankAccount } from '../Banks';

import { Actions } from './index.constants';
import {
    fetchAbleToWithdraw,
    fetchAbleToWithdrawError,
    fetchAbleToWithdrawSuccess,
} from './index.actions';

export const initialState = {
    withdrawValue: 0,
    selectedBankAccount: {} as BankAccount,
    isValidTime: false,
    loadingIsValidTime: false,
    loadedIsValidTime: false,
};

export const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ABLE_TO_WITHDRAW_FETCH_INIT:
            return loop(
                { ...state, loadingIsValidTime: true, loadedIsValidTime: false },
                Cmd.run(fetchAbleToWithdraw, {
                    successActionCreator: fetchAbleToWithdrawSuccess,
                    failActionCreator: fetchAbleToWithdrawError,
                    args: [action.clientId],
                }),
            );

        case Actions.ABLE_TO_WITHDRAW_FETCH_SUCCESS:
            return {
                ...state,
                isValidTime: action.isValidTime,
                loadingIsValidTime: false,
                loadedIsValidTime: true,
            };

        case Actions.ABLE_TO_WITHDRAW_FETCH_ERROR:
            return {
                ...state,
                isValidTime: false,
                loadingIsValidTime: false,
                loadedIsValidTime: true,
            };

        case Actions.ABLE_TO_WITHDRAW_RESET:
            return { ...state, loadedIsValidTime: false };

        case Actions.SET_WITHDRAW_VALUE:
            return { ...state, withdrawValue: action.value };

        case Actions.SET_BANK_ACCOUNT:
            return { ...state, selectedBankAccount: action.bankAccount };

        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
