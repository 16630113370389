import { loop, Cmd } from 'redux-loop';
import { Actions as AuthActions } from '../Auth/index.constants';
import { Actions } from './index.constants';
import {
    fetchClientAgreements,
    fetchClientAgreementsSuccess,
    fetchClientAgreementsError,
    fetchClientAgreementByTypeSuccess,
    fetchClientAgreementByTypeError,
    fetchClientAgreementByType,
} from './index.actions';

export const initialState = {
    agreements: null,
    agreementByType: null,
    loading: false,
    loadingByType: false,
    error: null,
    errorByType: null,
};

export const agreementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CLIENT_AGREEMENTS_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchClientAgreements, {
                    successActionCreator: fetchClientAgreementsSuccess,
                    failActionCreator: fetchClientAgreementsError,
                    args: [action.clientId],
                }),
            );
        case Actions.CLIENT_AGREEMENTS_FETCH_SUCCESS:
            return {
                ...state,
                agreements: action.agreements,
                loading: false,
                error: false,
            };
        case Actions.CLIENT_AGREEMENTS_FETCH_ERROR:
            return { ...state, error: true, loading: false };
        case Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_INIT:
            return loop(
                { ...state, loadingByType: true },
                Cmd.run(fetchClientAgreementByType, {
                    successActionCreator: fetchClientAgreementByTypeSuccess,
                    failActionCreator: fetchClientAgreementByTypeError,
                    args: [action.client],
                }),
            );
        case Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_SUCCESS:
            return {
                ...state,
                agreementByType: action.agreement,
                loadingByType: false,
                errorByType: false,
            };
        case Actions.CLIENT_AGREEMENT_BY_TYPE_FETCH_ERROR:
            return { ...state, errorByType: true, loadingByType: false };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
