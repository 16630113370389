import styled from 'styled-components';
import { InputFileUpload } from '@terrainvest/react-components';

export const StyledFileSection = styled.div`
    width: 100%;

    &:not(:first-child) {
        margin-top: 40px;
    }

    ${InputFileUpload.Styles.StyledDropeZone} {
        margin-top: 28px;
    }
`;

export const StyledAttachments = styled.div``;
