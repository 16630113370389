import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '../../store';

import SideNav from './SideNav';
import { SideNavContext } from './SideNav/SideNavContext';
import useBodyMarginsCalculator from './BodyMarginsCalculator';
import { mobileBreakpoint } from './index.constants';

const SideBar: React.FC<RouteComponentProps> = () => {
    const [navOpen, setNavOpen] = React.useState(false);
    const [mobile, setMobile] = React.useState(false);

    const { dark } = useSelector((state: RootState) => state.colorsModeState);

    useBodyMarginsCalculator(mobile);

    const handleResize = () => {
        setMobile(window.innerWidth < mobileBreakpoint);
    };

    React.useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <SideNavContext.Provider value={{ dark, open: navOpen, setOpen: setNavOpen, mobile }}>
            <SideNav />
        </SideNavContext.Provider>
    );
};

export default withRouter(SideBar);
