import * as React from 'react';
import { PasswordTip } from '../../View/NewPassword/assets';

const hasMinLengthRegex = new RegExp('^(?=.{8,})');
const hasSpecialCharacterRegex = new RegExp('^(?=.*[!@$%*?])');
const hasUpperCaseLetterRegex = new RegExp('^(?=.*[A-Z])');
const hasLowerCaseLetterRegex = new RegExp('^(?=.*[a-z])');
const hasNumberRegex = new RegExp('^(?=.*[0-9])');
const hasOnlyValidCharacters = new RegExp('^[A-Za-z0-9!@$%*?]*$');

export const lengthRule = password =>
    password.length !== 0 ? password.length >= 8 && password.length <= 15 : null;

export const minLengthRule = password =>
    password.length !== 0 ? hasMinLengthRegex.test(password) : null;

export const minElectronicCheckLengthRule = password =>
    password.length !== 0 ? password.length >= 8 && password.length <= 15 : null;

export const specialCharacterRule = password =>
    password.length !== 0
        ? hasSpecialCharacterRegex.test(password) && hasOnlyValidCharacters.test(password)
        : null;

export const upperCaseRule = password =>
    password.length !== 0 ? hasUpperCaseLetterRegex.test(password) : null;

export const lowerCaseRule = password =>
    password.length !== 0 ? hasLowerCaseLetterRegex.test(password) : null;

export const numberRule = password =>
    password.length !== 0 ? hasNumberRegex.test(password) : null;

export const confirmPasswordRule = (password, confirmPassword) =>
    password.length !== 0 || confirmPassword.length !== 0 ? password === confirmPassword : null;

export const DefaultPasswordRules = [
    {
        routine: lengthRule,
        message: 'Conter entre 8 e 15 caracteres',
    },
    {
        routine: specialCharacterRule,
        message: 'Conter ao menos um dos caracteres especiais ( !, @, $, %, *, ?)',
    },
    {
        routine: upperCaseRule,
        message: 'Conter ao menos uma letra maiúscula',
    },
    {
        routine: lowerCaseRule,
        message: 'Conter ao menos uma letra minúscula',
    },
    {
        routine: numberRule,
        message: 'Conter ao menos um número',
    },
    {
        routine: confirmPasswordRule,
        message: 'As senhas devem coincidir',
    },
    {
        routine: () => null,
        message: ' Não pode ser igual a sua assinatura eletrônica',
    },
];

export const DefaultElectronicCheckRules = [
    {
        routine: upperCaseRule,
        message: 'Conter ao menos uma letra maiúscula',
    },
    {
        routine: lowerCaseRule,
        message: 'Conter ao menos uma letra minúscula',
    },
    {
        routine: numberRule,
        message: 'Conter número',
    },
    {
        routine: minElectronicCheckLengthRule,
        message: 'Conter entre 8 e 15 caracteres',
    },
    {
        routine: specialCharacterRule,
        message: 'Conter ao menos um dos caracteres especiais ( !, @, $, %, *, ?)',
    },
    {
        routine: confirmPasswordRule,
        message: 'As assinaturas devem coincidir',
    },
    {
        routine: () => null,
        message: 'Não pode ser igual as suas últimas 6 assinaturas',
    },
    {
        routine: () => null,
        message: 'Não pode ser igual a sua senha de acesso ao portal',
    },
];

export const usePasswordTips = ({
    rules = DefaultPasswordRules,
    title,
    onSuccess,
    onError,
    PasswordTipComponent = PasswordTip,
}) => {
    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [inputErrorProps, setInputErrorProps] = React.useState({});
    const wrappedRules = rules.map(rule => ({
        routine: () => rule.routine(password, confirmPassword),
        message: rule.message,
    }));

    const passwordTipsComponent = <PasswordTipComponent title={title} rules={wrappedRules} />;

    React.useEffect(() => {
        if (!password || !confirmPassword) {
            return;
        }
        const hasError = wrappedRules.some(rule => rule.routine() === false);
        if (hasError) {
            onError();
            setInputErrorProps({
                error: true,
            });
        } else {
            onSuccess();
            setInputErrorProps({
                error: false,
            });
        }
    }, [password, confirmPassword]);

    return {
        password,
        confirmPassword,
        setPassword,
        setConfirmPassword,
        inputErrorProps,
        passwordTipsComponent,
    };
};
