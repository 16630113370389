import { Actions, ERROR_MESSAGES } from '../index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import {
    normalizeEvolutionChartsData,
    normalizeEvolutionChartsPeriods,
    normalizeEvolutionValueChartsData,
} from '../index.normalize';

export const fetchEvolutionVariationInit = accountNumber => ({
    type: Actions.FETCH_EVOLUTION_VARIATION_INIT,
    accountNumber,
});

export const fetchEvolutionVariation = async accountNumber => {
    const url = ENDPOINTS.GET_EVOLUTION_VARIATION.replace(':accountNumber', accountNumber);

    try {
        const response = await fetch(url, { method: 'GET' });
        const content = await response.json();

        return content;
    } catch (error) {
        if (
            error.responseJson &&
            error.response.status === 400 &&
            error.responseJson.Message === ''
        ) {
            throw new Error(ERROR_MESSAGES.FETCH_ERROR_DEFAULT);
        } else {
            throw error;
        }
    }
};

export const fetchEvolutionVariationSuccess = data => ({
    type: Actions.FETCH_EVOLUTION_VARIATION_SUCCESS,
    data,
});

export const fetchEvolutionVariationError = error => ({
    type: Actions.FETCH_EVOLUTION_VARIATION_ERROR,
    error: error.message,
});

export const fetchChartEvolutionPeriod = async () => {
    const url = ENDPOINTS.GET_EVOLUTION_CHART_PERIOD;

    try {
        const response = await fetch(url, { method: 'GET' });
        const content = await response.json();

        return normalizeEvolutionChartsPeriods(content);
    } catch (error) {
        if (error.responseJson) {
            throw new Error(
                error.responseJson.message && error.responseJson.message !== ''
                    ? error.responseJson.message
                    : ERROR_MESSAGES.FETCH_ERROR_DEFAULT,
            );
        } else {
            throw new Error(ERROR_MESSAGES.FETCH_ERROR_DEFAULT);
        }
    }
};

const intervalTypes = {
    DAILY: '1',
    MONTHLY: '2',
};

export const fetchChartEvolutionDataPercent = async (
    periodId,
    accountNumber,
    isMonthly = false,
) => {
    const intervalType = isMonthly ? intervalTypes.MONTHLY : intervalTypes.DAILY;
    const url = ENDPOINTS.GET_EVOLUTION_CHART_DATA_PERCENT.replace(':periodId', periodId)
        .replace(':intervalType', intervalType)
        .replace(':accountNumber', accountNumber);

    try {
        const response = await fetch(url, { method: 'GET' });
        const content = await response.json();

        return normalizeEvolutionChartsData(content);
    } catch (error) {
        if (error.responseJson) {
            throw new Error(
                error.responseJson.message && error.responseJson.message !== ''
                    ? error.responseJson.message
                    : ERROR_MESSAGES.FETCH_ERROR_DEFAULT,
            );
        } else {
            throw new Error(ERROR_MESSAGES.FETCH_ERROR_DEFAULT);
        }
    }
};

export const fetchChartEvolutionDataValue = async ({ periodId, accountNumber }) => {
    const url = ENDPOINTS.GET_EVOLUTION_CHART_DATA_VALUE.replace(':periodId', periodId).replace(
        ':accountNumber',
        accountNumber,
    );

    try {
        const response = await fetch(url, { method: 'GET' });
        const content = await response.json();

        return normalizeEvolutionValueChartsData(content);
    } catch (error) {
        if (error.responseJson) {
            throw new Error(
                error.responseJson.message && error.responseJson.message !== ''
                    ? error.responseJson.message
                    : ERROR_MESSAGES.FETCH_ERROR_DEFAULT,
            );
        } else {
            throw new Error(ERROR_MESSAGES.FETCH_ERROR_DEFAULT);
        }
    }
};
