import { Text } from '@terrainvest/react-components';
import styled from 'styled-components';
import SimpleHR from '../../../../../Utils/General/index.hr';

export const StyledQuestionTitle = styled(Text)`
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const StyledHR = styled(SimpleHR)`
    margin-top: 18px;
    margin-bottom: 18px;
`;
