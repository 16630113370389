import { paidCustodyTypes } from './index.constants';

export const normalizePaidCustodyStatus = status => {
    const normalizedValue = {
        id: status.Id,
        status: paidCustodyTypes.Unset,
    };

    if (status.Active === null || status.Active === undefined) {
        return normalizedValue;
    }

    normalizedValue.status = status.Active ? paidCustodyTypes.Active : paidCustodyTypes.Inactive;
    return normalizedValue;
};
