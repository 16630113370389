import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizer } from './index.normalize';

export const fetchOpportunitiesInit = () => ({
    type: Actions.OPPORTUNITIES_FETCH_INIT,
});

export const fetchOpportunities = () => {
    return fetch(ENDPOINTS.GET_OPPORTUNITIES, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizer);
};

export const fetchOpportunitiesSuccess = opportunities => ({
    type: Actions.OPPORTUNITIES_FETCH_SUCCESS,
    opportunities,
});

export const fetchOpportunitiesError = error => ({
    type: Actions.OPPORTUNITIES_FETCH_ERROR,
    error: error.message,
});
