import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeExtracts } from './index.normalize';

export const fetchExtractsDateInit = account => ({
    type: Actions.FETCH_EXTRACTS_INIT,
    account,
});
// ACTION BAIXAR MES
export const fetchExtractsDate = account => {
    return fetch(ENDPOINTS.GET_EXTRACTS_DATE.replace(':accountNumber', account), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeExtracts);
};

export const fetchExtractsDateSuccess = extracts => ({
    type: Actions.FETCH_EXTRACTS_SUCCESS,
    extracts,
});

export const fetchExtractsDateError = error => ({
    type: Actions.FETCH_EXTRACTS_ERROR,
    error: error.message,
});

export const fetchExtractsDateReset = () => ({
    type: Actions.FETCH_EXTRACTS_RESET,
});

export const changeExtractsTabInit = extractsTab => ({
    type: Actions.CHANGE_EXTRACTS_TAB_INIT,
    extractsTab,
});

export const changeExtractsTabSuccess = extractsTab => ({
    type: Actions.CHANGE_EXTRACTS_TAB_SUCCESS,
    extractsTab,
});

export const changeExtractsTabError = error => ({
    type: Actions.CHANGE_EXTRACTS_TAB_ERROR,
    error: error.message,
});

export const changeExtractsTabReset = () => ({
    type: Actions.CHANGE_EXTRACTS_TAB_RESET,
});
