import { SectionProps } from './index.types';

const includeFilter = (filter: string) => ({ title }) => title.toLowerCase().includes(filter);

const filterByInsideLength = (prop: string) => (obj: any) =>
    !!obj && !!obj[prop] && obj[prop].length;

const validateAlreadyIncluded = (sections: SectionProps[], section: SectionProps) =>
    sections.findIndex(({ title }) => title === section.title) < 0;

export const filterSections = (allSections: SectionProps[], filterBy: string): SectionProps[] => {
    const filter = filterBy.toLowerCase();

    const sectionsWithFilterIncludedInItems: SectionProps[] = allSections
        .map(section => ({
            ...section,
            subSections: section.subSections
                .map(subSection => ({
                    ...subSection,
                    items: subSection.items.filter(includeFilter(filter)),
                }))
                .filter(filterByInsideLength('items')),
        }))
        .filter(filterByInsideLength('subSections'));

    const sectionsWithFilterIncludedInSubSections: SectionProps[] = allSections
        .map(section => ({
            ...section,
            subSections: section.subSections.filter(includeFilter(filter)),
        }))
        .filter(filterByInsideLength('subSections'));

    const sectionsWithFilterIncludedInSections = allSections.filter(includeFilter(filter));

    const newSections = [...sectionsWithFilterIncludedInSections];

    sectionsWithFilterIncludedInSubSections.forEach(
        section => validateAlreadyIncluded(newSections, section) && newSections.push(section),
    );

    sectionsWithFilterIncludedInItems.forEach(
        section => validateAlreadyIncluded(newSections, section) && newSections.push(section),
    );

    return newSections.sort((s1, s2) => s1.order - s2.order);
};
