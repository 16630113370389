import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import {
    StyledButton,
    StyledInsideTitle,
    StyledInsideWrapper,
    StyledSpacingText,
} from '../../LoginSide/index.styles';

import { continueRegistration } from './index.constants';
import * as S from './index.styles';

export const RedirectUnfinishedSignup = ({ clientData }) => {
    const { partner } = useSelector((state: RootState) => state.partnerState);

    const continueRegistrationPartnerLink = `${partner.urlCadastro}${continueRegistration}`;

    const mountClientName = () => {
        const fullName: string = clientData.Name.toLowerCase();
        return fullName
            .split(' ')
            .map((part: string) => part[0].toUpperCase().concat(part.substring(1)))
            .join(' ');
    };

    return (
        <StyledInsideWrapper>
            <S.StyledWrapper>
                <StyledInsideTitle style={{ margin: 0 }}>
                    Olá, {mountClientName()}
                </StyledInsideTitle>
                <S.StyledTexts>
                    <StyledSpacingText>
                        Faltam alguns dados para finalizarmos seu cadastro na Terra Investimentos.
                    </StyledSpacingText>
                    <StyledSpacingText>
                        Para dar continuidade, clique no botão abaixo, preencha seu CPF ou CNPJ e
                        você já será direcionado para o ponto em que parou.
                    </StyledSpacingText>
                </S.StyledTexts>
                <S.StyledLink href={continueRegistrationPartnerLink} border={false} target="_self">
                    <StyledButton>Continuar Cadastro</StyledButton>
                </S.StyledLink>
            </S.StyledWrapper>
        </StyledInsideWrapper>
    );
};

export default RedirectUnfinishedSignup;
