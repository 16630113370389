import styled, { css, keyframes } from 'styled-components';
import { Icon, Loading, Text } from '@terrainvest/react-components';

export const StyledSelectAccounts = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
`;

const fullify = keyframes`
    0% {
        width: 0;
        left: 8px;
    }
    50% {
        width: calc(100% - 16px);
        right: 8px
    }
    100% {
        width: 0;
        right: 8px;
    }
`;

const loadingProps = css`
    :after {
        content: '';
        position: absolute;
        bottom: -2px;
        height: 3px;
        background-color: ${({ theme }) => theme.global.colors.primary};
        animation: ${fullify} 4s ease-in-out infinite;
    }
`;

export const StyledAccountWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) =>
        `${theme.global.spaces.xRay} ${theme.global.spaces.highFrequencyXRay}`};
    border: 1px solid #a1a1aa;
    border-radius: 8px;
    & > ${Text.Styles.StyledText} {
        color: #e4e4e7;
    }
    cursor: pointer;

    ${({ loading }) => loading && loadingProps};

    :hover {
        border: 1px solid ${({ theme }) => theme.global.colors.primary};

        ${Text.Styles.StyledText} {
            color: ${({ theme }) => theme.global.colors.white};
        }
    }
`;

export const StyledAccount = styled(Text)`
    margin: 0;
`;

export const StyledIcon = styled(Icon)`
    color: ${({ theme }) => theme.global.colors.primary};
`;

export const StyledLoading = styled(Loading)`
    > div {
        background-color: unset;
    }
`;
