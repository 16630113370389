import styled from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHeaderSwitchMobileItem = styled.div`
    position: absolute;
    top: 22px;
    right: 22px;
    ${Icon.Styles.StyledIcon} {
        width: 10px;
        height: 10px;
    }
`;
