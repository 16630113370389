import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Icon } from '@terrainvest/react-components';

export const StyledPanelSlideContainer = styled.div<{ open: boolean; invert: boolean }>`
    position: fixed;
    top: ${({ theme, headerMargin }) =>
        headerMargin ? theme.global.spaces.lowFrequencyMicrowave : 0};
    height: ${({ theme, headerMargin }) =>
        headerMargin ? `calc(100vh - ${theme.global.spaces.lowFrequencyMicrowave})` : '100vh'};

    left: 0;
    right: 0;

    display: flex;
    flex-direction: ${({ invert }) => (invert ? 'row-reverse' : 'row')};

    pointer-events: ${({ open }) => (open ? 'initial' : 'none')};

    ${media.max.md`
        top: ${({ theme, headerMargin }) =>
            headerMargin ? theme.global.spaces.highFrequencyRadio : 0};
        height: ${({ theme, headerMargin }) =>
            headerMargin ? `calc(100vh - ${theme.global.spaces.highFrequencyRadio})` : '100vh'};
    `}
`;

export const StyledOverflow = styled.div<{ width: string }>`
    position: relative;
    width: calc(100% - ${({ width }) => width});
    height: 100%;
    transition: ${({ theme }) => theme.components.header.transition} !important;
    background-color: #27272a4d;
    backdrop-filter: blur(4px);
    opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const StyledContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
    height: 100%;
    transition: ${({ theme }) => theme.components.header.transition} !important;
    background-color: ${({ theme }) => theme.global.colors.mainBackground};
    overflow-x: hidden;
`;

export const CloseIcon = styled(Icon)`
    position: absolute;
    top: ${({ headerMargin }) => (headerMargin ? '24px' : '75px')};
    right: calc(2% + 10px);
    font-size: 40px;
    color: white;
    cursor: pointer;
    ${media.max.md`
        top: ${({ headerMargin }) => (headerMargin ? '24px' : '100px')};
    `}
`;
