import styled from 'styled-components';

const getQualifiedInvestidorColor = (theme: any, darkMode: boolean, professional: boolean) => {
    if (darkMode) return theme.global.colors.white;
    return professional ? '#12479D' : '#4285F4';
};

export const StyledHeaderQualifiedInvestorItem = styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.global.spaces.xRay};
    color: ${({ theme, dark, professional }) =>
        getQualifiedInvestidorColor(theme, dark, professional)};
`;

export const StyledSpan = styled.span`
    font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
    font-weight: ${({ theme }) => theme.global.fontWeights.microwave};
`;
