import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { Actions } from './index.constants';
import { normalizeParams } from './index.normalize';

export const postNewBankAccount = params => {
    const normalizedParams = normalizeParams(params);
    return fetch(ENDPOINTS.POST_NEW_BANK_ACCOUNT, {
        method: 'POST',
        body: JSON.stringify(normalizedParams),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const startNewBankAccount = (clientId: number | string) => ({
    type: Actions.NEW_BANK_ACCOUNT_START,
    clientId,
});

export const setFieldValue = (fieldName: string, value: any) => ({
    type: Actions.NEW_BANK_ACCOUNT_SET_FIELD,
    fieldName,
    value,
});

export const validateFieldValue = (fieldName: string) => ({
    type: Actions.NEW_BANK_ACCOUNT_VALIDADE_FIELD,
    fieldName,
});

export const resetFields = (fieldsName: Array<string>) => ({
    type: Actions.NEW_BANK_ACCOUNT_RESET_FIELDS,
    fieldsName,
});
