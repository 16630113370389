import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';

import {
    fetchPartnerFunctionality,
    fetchPartnerFunctionalitySuccess,
    fetchPartnerFunctionalityError,
    fetchPartnerHighlights,
    fetchPartnerHighlightsError,
    fetchPartnerHighlightsSuccess,
} from './index.actions';

export const initialState = {
    sections: [],
    paths: [],
    highlights: [],
    loading: 0,
    error: false,
};

export const partnerFunctionalityReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_PARTNER_FUNCTIONALITY_INIT:
            return loop(
                { ...state, loading: state.loading + 1, error: false },
                Cmd.run(fetchPartnerFunctionality, {
                    successActionCreator: fetchPartnerFunctionalitySuccess,
                    failActionCreator: fetchPartnerFunctionalityError,
                }),
            );
        case Actions.FETCH_PARTNER_FUNCTIONALITY_SUCCESS:
            return {
                ...state,
                sections: action.sections,
                paths: action.paths,
                loading: state.loading - 1,
                error: false,
            };
        case Actions.FETCH_PARTNER_FUNCTIONALITY_ERROR:
            return { ...state, loading: state.loading - 1, error: true };

        case Actions.FETCH_PARTNER_HIGHLIGHTS_INIT:
            return loop(
                { ...state, loading: state.loading + 1, error: false },
                Cmd.run(fetchPartnerHighlights, {
                    successActionCreator: fetchPartnerHighlightsSuccess,
                    failActionCreator: fetchPartnerHighlightsError,
                }),
            );
        case Actions.FETCH_PARTNER_HIGHLIGHTS_SUCCESS:
            return {
                ...state,
                highlights: action.highlights,
                loading: state.loading - 1,
                error: false,
            };
        case Actions.FETCH_PARTNER_HIGHLIGHTS_ERROR:
            return { ...state, loading: state.loading - 1, error: true };
        default:
            return state;
    }
};
