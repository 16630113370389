import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchBanks, fetchBanksSuccess, fetchBanksError } from './index.actions';

export const initialState = {
    banks: [],
    loading: false,
    loaded: false,
    error: null,
    selectedBankAccount: 0,
    showingNewAccount: false,
};

export const banksReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.BANKS_FETCH_INIT:
            return loop(
                { ...state, loading: true, loaded: false },
                Cmd.run(fetchBanks, {
                    successActionCreator: fetchBanksSuccess,
                    failActionCreator: fetchBanksError,
                    args: [action.clientId],
                }),
            );

        case Actions.BANKS_FETCH_SUCCESS:
            return { ...state, banks: action.banks, loading: false, error: false, loaded: true };

        case Actions.BANKS_FETCH_ERROR:
            return { ...state, error: action.error, loading: false, loaded: true };

        case Actions.BANKS_FETCH_RESET:
            return { ...initialState };

        case Actions.BANKS_SELECT_ACCOUNT:
            return { ...state, selectedBankAccount: action.bankAccountIndex };

        case Actions.BANKS_SHOW_NEW_ACCOUNT_ACCOUNT:
            return { ...state, showingNewAccount: action.isShowing };

        default:
            return state;
    }
};
