import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import FinancialOverview from '../../FinancialOverview';
import MobileSidebar from '../MobileSidebar';

import SearchInput from './SearchInput';
import SideNavSection from './SideNavSection';
import { SideNavContext } from './SideNavContext';

import * as S from './index.styles';
import { SectionProps } from './index.types';
import { filterSections } from './index.filter';

const SideNav = () => {
    const { sections: allSections, loading } = useSelector(
        (state: RootState) => state.partnerFunctionalityState,
    );

    const defaultOpenSections = allSections.reduce(
        (obj, section) => ({ ...obj, [section.title]: false }),
        {},
    );

    const { mobile, open } = React.useContext(SideNavContext);

    const [filter, setFilter] = React.useState('');
    const [sections, setSections] = React.useState<SectionProps[]>([]);
    const [openSection, setOpenSection] = React.useState(defaultOpenSections);

    const handleNewOpenSection = (newSection: string) => {
        setOpenSection({ ...defaultOpenSections, [newSection]: !openSection[newSection] });
    };

    const handleMobileOpenSection = (newSection: string) => {
        setOpenSection({ ...defaultOpenSections, [newSection]: true });
    };

    const handleNewFilter = (newFilter: string) => {
        setOpenSection({ ...defaultOpenSections });
        setFilter(newFilter);
        if (!newFilter) {
            setSections([...allSections]);
            return;
        }
        const newSections = filterSections(allSections, newFilter);

        setSections([...newSections]);
    };

    const showFinancialOverview = !mobile && !filter;

    React.useEffect(() => setSections(allSections), [allSections]);

    const renderSections = () =>
        sections.map((section, index) => (
            <SideNavSection
                key={`${section.title}-${filter}`}
                first={index === 0}
                section={section}
                handleOpenSection={handleNewOpenSection}
                sectionOpen={openSection[section.title]}
                singleSection={sections.length === 1}
            />
        ));

    return (
        <>
            <S.StyledNav open={open} mobile={mobile}>
                <S.TopWrapper>
                    <SearchInput
                        filter={filter}
                        disabled={!!loading}
                        setFilter={handleNewFilter}
                        emptyResults={!sections.length}
                    />
                    {showFinancialOverview && <FinancialOverview />}
                </S.TopWrapper>
                {!loading && renderSections()}
            </S.StyledNav>
            {mobile && <MobileSidebar openSideNav={handleMobileOpenSection} />}
        </>
    );
};

export default SideNav;
