import * as React from 'react';
import ErrorFeedback from './ErrorFeedback';

type ErrorBoundaryState = {
    hasError: boolean;
};

type ErrorBoundaryProps = {
    children?: React.ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * Hook contendo o estado de erro da aplicação.
     * @param error
     * @param errorInfo
     */
    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return <ErrorFeedback />;
        }

        return children;
    }
}

export default ErrorBoundary;
