import * as React from 'react';
import { useSelector } from 'react-redux';

import Authorizer from '../BaseAuthorizer';

import withClientFetch from '../../Utils/HOCs/withClientFetch';

const SpecificAuthorizer = ({ children, identifier }) => {
    const { client } = useSelector(state => state.clientState);
    const [authorized, setAuthorized] = React.useState(false);

    React.useEffect(() => {
        if (client) {
            handleAuthorization();
        }
    }, [client]);

    const handleAuthorization = () => {
        if (!(identifier && Authorizer.has(identifier))) {
            setAuthorized(true);
            return;
        }

        Authorizer.get(identifier)
            .run({ client })
            .then(valid => {
                setAuthorized(valid);
            });
    };

    if (authorized) {
        return <>{children}</>;
    }
    return null;
};

export default withClientFetch(SpecificAuthorizer);
