import * as React from 'react';
import { useSelector } from 'react-redux';
import { SwitchColorMode } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import useSwitchMode from '../../../../Utils/Hooks/useSwitchMode';

import { StyledHeaderSwitchMobileItem } from './index.styles';

export const HeaderSwitchMobileItem = () => {
    const { handleChange } = useSwitchMode();

    const { partner } = useSelector((state: RootState) => state.partnerState);
    const { dark, loading: loadingConfig } = useSelector(
        (state: RootState) => state.colorsModeState,
    );

    if (!partner.styleLight) return <></>;

    return (
        <StyledHeaderSwitchMobileItem>
            <SwitchColorMode
                onChange={handleChange}
                disabled={loadingConfig}
                defaultChecked={dark}
                value={dark}
            />
        </StyledHeaderSwitchMobileItem>
    );
};

export default HeaderSwitchMobileItem;
