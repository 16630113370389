export const appMsgTypes = {
    LOADED: 'loaded',
    LOGOUT: 'logout',
    FILE: 'file',
    DIRECT_LINK: 'direct-link',
    DIRECT_LINK_INSURANCE: 'direct-link-insurance',
};

export const postAppMsg = (type, payload = null) => {
    const customWindow = window as any;
    if (customWindow.ReactNativeWebView && customWindow.ReactNativeWebView.postMessage) {
        const message = {
            type,
            payload,
        };
        customWindow.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
};
