import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchRecommendationsReport,
    fetchRecommendationsReportSuccess,
    fetchRecommendationsReportError,
} from './index.actions';

export const initialState = {
    fileUrl: null,
    loading: false,
    error: null,
};

export const recommendationsReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_RECOMMENDATION_REPORT_INIT:
            return loop(
                { ...state, loading: true, error: null, fileUrl: null },
                Cmd.run(fetchRecommendationsReport, {
                    successActionCreator: fetchRecommendationsReportSuccess,
                    failActionCreator: fetchRecommendationsReportError,
                    args: [action.id],
                }),
            );

        case Actions.FETCH_RECOMMENDATION_REPORT_SUCCESS:
            return { ...state, fileUrl: action.fileUrl, loading: false, error: false };

        case Actions.FETCH_RECOMMENDATION_REPORT_ERROR:
            return { ...state, error: null, loading: false };

        default:
            return state;
    }
};
