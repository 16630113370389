export const Actions = {
    LIFE_INSURANCE_FETCH_INIT: 'LIFE_INSURANCE_FETCH_INIT',
    LIFE_INSURANCE_FETCH_SUCCESS: 'LIFE_INSURANCE_FETCH_SUCCESS',
    LIFE_INSURANCE_FETCH_ERROR: 'LIFE_INSURANCE_FETCH_ERROR',
    FETCH_LIFE_INSURANCE_REQUESTS_INIT: 'FETCH_LIFE_INSURANCE_REQUESTS_INIT',
    FETCH_LIFE_INSURANCE_REQUESTS_SUCCESS: 'FETCH_LIFE_INSURANCE_REQUESTS_SUCCESS',
    FETCH_LIFE_INSURANCE_REQUESTS_ERROR: 'FETCH_LIFE_INSURANCE_REQUESTS_ERROR',
    LIFE_INSURANCE_REQUESTS_SET_FILTER: 'LIFE_INSURANCE_REQUESTS_SET_FILTER',
    LIFE_INSURANCE_SET_FILTER: 'LIFE_INSURANCE_SET_FILTER',
};
