import styled from 'styled-components';
import { InputPassword } from '@terrainvest/react-components';

import { inputColor } from '../../LoginForm/index.styles';

export const StyledWrapper = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
`;

export const StyledTop = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const StyledInputPassword = styled(InputPassword)`
    ${inputColor};
    :hover {
        && input {
            ${inputColor};
        }
    }
    input {
        padding: ${({ theme }) =>
            `${theme.global.spaces.lowFrequencyXRay} ${theme.global.spaces.highFrequencyXRay}`};
        padding-right: ${({ theme }) => theme.global.spaces.visible};
    }
    && i {
        top: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    }
`;

export const TipsSpace = styled.div`
    margin-bottom: 40px;
`;
