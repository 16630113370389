import * as React from 'react';
import { useDispatch } from 'react-redux';

import { postNewColorModeSelected, setColorMode } from '../../Redux/ColorsMode';

const useSwitchMode = () => {
    const dispatch = useDispatch();

    const [delay, setDelay] = React.useState(null);

    const handleChange = (checked: boolean) => {
        dispatch(setColorMode(checked));
        if (delay) clearTimeout(delay);

        const newDelay = setTimeout(() => postNewColorModeSelected(checked), 2000);
        setDelay(newDelay);
    };

    return { handleChange };
};

export default useSwitchMode;
