import { fetch } from '../Api';

const isMicrosoftBrowser = () => {
    return window.navigator && window.navigator.msSaveOrOpenBlob;
};

export const getFile = async (url, filename) => {
    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

export const responseToFile = async (content, filename, blobType = 'application/pdf') => {
    const blob: Blob = new Blob([await content.blob()], { type: blobType });
    return blobToFile(blob, filename);
};

export const blobToFile = async (blob, filename) => {
    const objUrl: string = URL.createObjectURL(blob);

    if (isMicrosoftBrowser()) {
        return window.navigator.msSaveOrOpenBlob(blob, filename);
    }

    return window.open(objUrl);
};
