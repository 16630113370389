import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientInit } from '../../../Redux/Client';

const withClientFetch = Component => {
    const ComponentWithClientFetch = ({ ...props }) => {
        const { client, loading: loadingClient } = useSelector(state => state.clientState);
        const dispatch = useDispatch();

        React.useEffect(() => {
            if (!client && !loadingClient) {
                dispatch(fetchClientInit());
            }
        }, [client, loadingClient]);

        return <Component {...props} />;
    };

    return ComponentWithClientFetch;
};

export default withClientFetch;
