/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
export const usPersonDescription = `São considerados US Person aqueles que responderem "sim" a pelo menos uma das seguintes perguntas:

É cidadão(ã) dos Estados Unidos?
Possui autorização de residência permanente válida nos Estados Unidos(Green Card)?
Possui domicílios fiscais nos Estados Unidos?
Possui número de identificação fiscal(TIN) nos EUA?
Permaneceu nos Estados Unidos mais de 180 dias nos últimos 3 anos ou 30 dias no último ano, a contar da data presente?`;

export const pepDescription = `São considerados agentes públicos que desempenham ou tenham desempenhado, nos últimos cinco anos, cargos, empregos ou funções públicas relevantes no Brasil ou outros países, territórios e dependências estratégicas, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.`;

export const rlpDescription = `O RLP, sigla para Retail Liquidity Provider, é uma nova modalidade de oferta no mercado em que a Terra Investimentos se torna contraparte das negociações de compra e de venda da bolsa.`;

export const rlpUnmarkWhileActive = `Para desativar o RLP, acesse o menu lateral e selecione RLP`;

export const PeopleLinkedtoTerraInvestimentos = `
Pessoa natural ou jurídica que é vinculada a determinada corretora de valores ou de mercadorias.
Como administradores, funcionários, operadores e prepostos da Corretora. Os agentes autônomos.
demais profissionais que mantêm contrato de prestação de serviços com a Corretora e são diretamente
relacionados à atividade de intermediação. sócios ou acionistas da Corretora, pessoas físicas.`;

export const PoliticallyExposedPerson = `
São pessoas expostas politicamente (PEP) os ocupantes de cargos e funções públicas listadas nas normas de PLD/FTP editadas pelos órgãos reguladores e fiscalizadores.`;

export const USPerson = `
Caracterizam-se como US PERSON pessoas que possuem cidadania norte-americana, nascidas nos EUA ou com pais norte-americanos.
`;
