import { fetch, ENDPOINTS } from '../../Utils/Api';
import { Actions } from './index.constants';
import { normalizeColorsMode } from './index.normalize';

export const setColorMode = (dark: boolean) => ({
    type: Actions.COLOR_MODE_SET,
    dark,
});

export const invertColorMode = () => ({
    type: Actions.COLOR_MODE_INVERT,
});

export const postNewColorModeSelected = (checked: boolean) => {
    const isLight = checked ? 'false' : 'true';
    const url = ENDPOINTS.POST_NEW_COLOR_MODE_SELECTED.replace(':isLight', isLight);

    return fetch(url, { method: 'POST' });
};

export const fetchColorsModeInit = () => ({
    type: Actions.COLORS_MODE_FETCH_INIT,
});

export const fetchColorsMode = () => {
    return fetch(ENDPOINTS.GET_COLOR_MODE_SELECTED, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeColorsMode);
};

export const fetchColorsModeSuccess = (dark: boolean) => ({
    type: Actions.COLORS_MODE_FETCH_SUCCESS,
    dark,
});

export const fetchColorsModeError = error => ({
    type: Actions.COLORS_MODE_FETCH_ERROR,
    error: error.message,
});
