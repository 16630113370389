import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchBtcAssets, fetchBtcSuccess, fetchBtcError } from './index.actions';

export const initialState = {
    offers: null,
    loading: false,
    error: null,
};

export const investmentBtcReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_BTC_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBtcAssets, {
                    successActionCreator: fetchBtcSuccess,
                    failActionCreator: fetchBtcError,
                    args: [action.accountNumber],
                }),
            );

        case Actions.FETCH_BTC_SUCCESS:
            return { ...state, offers: action.offers, loading: false, error: false };

        case Actions.FETCH_BTC_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
