import { Action, applyMiddleware, compose, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { CmdType, combineReducers, install, LiftedLoopReducer, Loop } from 'redux-loop';
import persistState from 'redux-sessionstorage';
import { accountReducer } from './Redux/Account';
import { accountsReducer } from './Redux/Accounts';
import { agreementsReducer } from './Redux/Agreements';
import { authReducer } from './Redux/Auth';
import { balanceReducer } from './Redux/Balance';
import { banksReducer } from './Redux/Banks';
import { newBankReducer } from './Redux/Banks/NewBankAccount';
import { removeBankReducer } from './Redux/Banks/RemoveBankAccount';
import { bannersReducer } from './Redux/Banners';
import { clientReducer } from './Redux/Client';
import { Commons } from './Redux/Common';
import { complianceReducer } from './Redux/Compliance';
import { brokerReducer } from './Redux/CustodyTransfer/Broker';
import { historicReducer } from './Redux/CustodyTransfer/Historic';
import { positionTransferReasonReducer } from './Redux/CustodyTransfer/Reason';
import { statusReducer } from './Redux/CustodyTransfer/Status';
import { extractsReportsReducer } from './Redux/ExtractsReports';
import { brokerageNoteReducer } from './Redux/ExtractsReports/BrokerageNote';
import { brokerageNoteSubmitReducer } from './Redux/ExtractsReports/BrokerageNote/BrokerageSubmit';
import { brokerageNoteDatesReducer } from './Redux/ExtractsReports/BrokerageNote/SelectDate';
import { custodySubmitReducer } from './Redux/ExtractsReports/CustodyExtract';
import { custodyDatesReducer } from './Redux/ExtractsReports/CustodyExtract/SelectDate';
import { checkingAccountSubmitReducer } from './Redux/ExtractsReports/CheckingAccount';
import { futureReleasesReducer } from './Redux/ExtractsReports/FutureReleases';
import { extractsReportsMonthReducer } from './Redux/ExtractsReports/Extracts/Month';
import { extractsReportsPeriodReducer } from './Redux/ExtractsReports/Extracts/Period';
import { recommendationsReducer } from './Redux/ExtractsReports/Recommendations';
import { recommendationsHistoryReducer } from './Redux/ExtractsReports/Recommendations/History';
import { recommendationsReportReducer } from './Redux/ExtractsReports/Recommendations/Report';
import { investmentBtcReducer } from './Redux/InvestmentBtc';
import { investmentCollateralReducer } from './Redux/InvestmentCollateral';
import { investmentDirectTreasuryReducer } from './Redux/InvestmentDirectTreasury';
import { investmentFundsReducer } from './Redux/InvestmentFunds';
import { investmentOffersReducer } from './Redux/InvestmentOffers';
import { investmentOrdersReducer } from './Redux/InvestmentOrders';
import { investmentProventsDividendsReducer } from './Redux/InvestmentProventsDividends';
import { investmentTermsReducer } from './Redux/InvestmentTerms';
import { notificationReducer } from './Redux/Notification';
import { opportunitiesReducer } from './Redux/Opportunities';
import { platformsReducer } from './Redux/Platforms';
import { Positions } from './Redux/Position';
import { positionFixedIncomeHistoryReducer } from './Redux/PositionFixedIncomeHistory';
import { positionFundsHistoryReducer } from './Redux/PositionFundsHistory';
import { profitabilityReducer } from './Redux/Profitability';
import { recommendationReducer } from './Redux/Recommendation';
import { rlpReducer } from './Redux/RLP';
import { suitabilityProfileReducer } from './Redux/Suitability';
import { withdrawReducer } from './Redux/WithdrawNew';
import { withdrawHistoryReducer } from './Redux/WithdrawNew/TransferRequests';
import { retailGuaranteeReducer } from './Redux/RetailGuarantee';
import { advisorsReducer } from './Redux/Advisors';
import { partnerFunctionalityReducer } from './Redux/PartnerFunctionality';
import { partnerReducer } from './Redux/Partner';
import { colorsModeReducer } from './Redux/ColorsMode';
import { publicOffersReducer } from './Redux/PublicOffers';
import { structuredProductsReducer } from './Redux/StructuredProducts';
import { mobileReducer } from './Redux/Mobile';
import { paidCustodyReducer } from './Redux/PaidCustody';
import { privatePensionReducer } from './Redux/PrivatePension';
import { lifeInsuranceReducer } from './Redux/Insurances';

import { reportsReducer } from './Redux/Reports';
import { subscriptionReducer } from './Redux/Subscription/index.reducer';

const reducers = {
    clientState: clientReducer,
    balanceState: balanceReducer,
    accountState: accountReducer,
    accountsState: accountsReducer,
    banksState: banksReducer,
    newbankState: newBankReducer,
    removebankState: removeBankReducer,
    withdrawState: withdrawReducer,
    withdrawHistoryState: withdrawHistoryReducer,
    authState: authReducer,
    notificationState: notificationReducer,
    complianceState: complianceReducer,
    suitabilityProfileState: suitabilityProfileReducer,
    investmentOffersState: investmentOffersReducer,
    investmentOrdersState: investmentOrdersReducer,
    investmentFundsState: investmentFundsReducer,
    investmentBtcState: investmentBtcReducer,
    investmentTermsState: investmentTermsReducer,
    investmentProventsDividendsState: investmentProventsDividendsReducer,
    investmentCollateralState: investmentCollateralReducer,
    investmentDirectTreasuryState: investmentDirectTreasuryReducer,
    agreementsState: agreementsReducer,
    platformsState: platformsReducer,
    extractsReportsState: extractsReportsReducer,
    extractsReportsMonthState: extractsReportsMonthReducer,
    extractsReportsPeriodState: extractsReportsPeriodReducer,
    brokerageNoteState: brokerageNoteReducer,
    brokerageNoteDatesState: brokerageNoteDatesReducer,
    brokerageNoteSubmitState: brokerageNoteSubmitReducer,
    custodyDatesState: custodyDatesReducer,
    checkingAccountSubmitState: checkingAccountSubmitReducer,
    futureReleasesSubmitState: futureReleasesReducer,
    custodySubmitState: custodySubmitReducer,
    recommendationsState: recommendationsReducer,
    recommendationsReportState: recommendationsReportReducer,
    recommendationsHistoryState: recommendationsHistoryReducer,
    recommendationState: recommendationReducer,
    opportunitiesState: opportunitiesReducer,
    profitabilityState: profitabilityReducer,
    positionState: combineReducers({
        ...Positions.combineReducers(),
    }),
    positionFundsHistoryState: positionFundsHistoryReducer,
    positionFixedIncomeHistoryState: positionFixedIncomeHistoryReducer,
    commonState: combineReducers({
        ...Commons.combineReducers(),
    }),
    bannersState: bannersReducer,
    rlpState: rlpReducer,
    custodyTransferState: positionTransferReasonReducer,
    brokerState: brokerReducer,
    custodyTransferHistoric: historicReducer,
    custodyTransferStatus: statusReducer,
    retailGuaranteeState: retailGuaranteeReducer,
    advisorsState: advisorsReducer,
    partnerFunctionalityState: partnerFunctionalityReducer,
    partnerState: partnerReducer,
    colorsModeState: colorsModeReducer,
    publicOffersState: publicOffersReducer,
    structuredProductsState: structuredProductsReducer,
    reportsState: reportsReducer,
    mobileState: mobileReducer,
    paidCustodyState: paidCustodyReducer,
    privatePensionState: privatePensionReducer,
    lifeInsuranceState: lifeInsuranceReducer,
    subscriptionState: subscriptionReducer,
};

const rootReducer: Reducer<unknown, Action<any>> = combineReducers(reducers);

type TypeWithGeneric<T> = T[];
type ExtractGeneric<Type> = Type extends TypeWithGeneric<infer X> ? X : never;

export type RootReducer = typeof reducers;

export type RootState = {
    [K in keyof RootReducer]: RootReducer[K] extends LiftedLoopReducer<any>
        ? Exclude<ExtractGeneric<ReturnType<RootReducer[K]>>, CmdType<any>>
        : Exclude<ReturnType<RootReducer[K]>, Loop<any, any>>;
};

let composedEnhancers: any = [];

const persistStateSlicer = () => state => ({
    authState: {
        auth: state.authState.auth,
    },
    clientState: {
        selectedAccount: state.clientState.selectedAccount,
    },
    balanceState: {
        visible: state.balanceState.visible,
    },
    bannersState: {
        wasBannerRead: state.bannersState.wasBannerRead,
    },
    mobileState: {
        isTerraApp: state.mobileState.isTerraApp,
    },
});

if (process.env.NODE_ENV !== 'production') {
    composedEnhancers = composeWithDevTools(
        applyMiddleware(),
        install(),
        persistState(null, { slicer: persistStateSlicer }),
    );
} else {
    composedEnhancers = compose(
        applyMiddleware(),
        install(),
        persistState(null, { slicer: persistStateSlicer }),
    );
}

export const store = createStore(rootReducer, {}, composedEnhancers);

export default store;
