import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchPositionFundsHistoryAssets,
    fetchPositionFundsHistorySuccess,
    fetchPositionFundsHistoryError,
} from './index.actions';

export const initialState = {
    history: [],
    loading: false,
    error: undefined,
};

export const positionFundsHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.POSITION_FUNDS_HISTORY_FETCH_INIT:
            return loop(
                {
                    ...state,
                    history: [],
                    loading: true,
                    error: undefined,
                },
                Cmd.run(fetchPositionFundsHistoryAssets, {
                    successActionCreator: fetchPositionFundsHistorySuccess,
                    failActionCreator: fetchPositionFundsHistoryError,
                    args: [action.accountNumber, action.startDate, action.endDate],
                }),
            );
        case Actions.POSITION_FUNDS_HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                history: action.history,
                loading: false,
                error: undefined,
            };
        case Actions.POSITION_FUNDS_HISTORY_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};
