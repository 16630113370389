import { formatCurrency } from '@terrainvest/react-components';

export const parseFinancialValue = value => {
    return value !== undefined ? formatCurrency(value).replace(/[^\d.,]/g, '') : '-';
};

export const parsePercentValue = value => {
    return value ? (100 / value).toFixed(2) : value;
};

export const parseQuantityInput = rawValue => rawValue.replace(',', '.');

export const parseQuantityChangedEvent = evt => {
    return {
        ...evt,
        target: {
            name: evt.target.name,
            value: parseQuantityInput(evt.target.value),
        },
    };
};
