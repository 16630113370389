import React from 'react';
import debounce from 'lodash/debounce';

import { CollectionsFilter } from '../General/Filter';
import { FilterConfig } from './useTableFilter';

export const useButtonFilter = (
    filter,
    assets = [],
    filterConfig?: FilterConfig,
): [any[], (evt: any) => void] => {
    const [selectedValues, setSelectedValues] = React.useState([]);
    const assetsFilter = filter as CollectionsFilter<any>;
    const filteredAssets = assets
        ? assetsFilter
              .setup({
                  collection: assets,
                  ...filterConfig,
              })
              .filterByArray(selectedValues)
        : [];

    const handleSelectButton = values => {
        debounce(val => {
            setSelectedValues(val);
        }, 200)(values);
    };

    return [filteredAssets, handleSelectButton];
};
