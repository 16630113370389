const BANNER_FIRST_TITLE = `Antes de começar a investir no Home Broker`;
const BANNER_SECOND_TITLE = {
    start: `Você conhece as vantagens do `,
    bold: `RLP`,
    end: `?`,
};
const BANNER_LABEL = {
    start: `O `,
    bold: `RLP `,
    end: `(Retail Liquidity Provider) é um mecanismo lançado pela Bolsa que aumenta 
    a liquidez e fortalece o mercado, trazendo benefícios para o investidor pessoa física negociar 
    contratos de mini índice e mini dólar com o melhor preço de execução das operações. `,
};
const BANNER_LINK_LABEL = `Saiba mais!`;
const BANNER_PRIMARY_BUTTON_LABEL = `Ativar o RLP e continuar a investir!`;
const BANNER_SECONDARY_BUTTON_LABEL = `Agora não`;

const RLP_ACTIVATION_SUCCESS = `Sucesso! O tempo de ativação de RLP é de até 24h.`;
const RLP_ACTIVATION_FAILURE = `Falha! Não foi possível a ativação do RLP.`;

export const texts = {
    BANNER_FIRST_TITLE,
    BANNER_SECOND_TITLE,
    BANNER_LABEL,
    BANNER_LINK_LABEL,
    BANNER_PRIMARY_BUTTON_LABEL,
    BANNER_SECONDARY_BUTTON_LABEL,
    RLP_ACTIVATION_SUCCESS,
    RLP_ACTIVATION_FAILURE,
};

export const eBookLink = `https://www.terrainvestimentos.com.br/wp-content/uploads/2020/05/e-book_rlp.pdf`;
