export const Actions = {
    CLIENT_FETCH_INIT: 'CLIENT_FETCH_INIT',
    CLIENT_FETCH_SUCCESS: 'CLIENT_FETCH_SUCCESS',
    CLIENT_FETCH_ERROR: 'CLIENT_FETCH_ERROR',
    CLIENT_SELECT_ACCOUNT_NUMBER: 'CLIENT_SELECT_ACCOUNT_NUMBER',
};

export interface Client {
    ClientId: number;
    CpfCnpj: string;
    MaritalStatusId: number;
    BirthDate: string;
    Name: string;
    SpouseName?: null;
    Email: string;
    MotherName: string;
    FatherName: string;
    CurrentlyWorking: boolean;
    Gender: string;
    PoliticalyExposedPerson: boolean;
    UsPerson: boolean;
    PersonType: string;
    BirthCityId: number;
    BirthStateId: string;
    BirthCountryId: string;
    NationalityId: number;
    DocumentNumber: string;
    DocumentTypeId: string;
    DocumentIssuerName: string;
    DocumentIssuerDate: string;
    OccupationId: number;
    RegistrationStatusId: number;
    KnowledgeSourceId: number;
    OfficeId: number;
    AdvisorId: number;
    UserId: number;
    MobilePhonePrefix: string;
    MobilePhoneNumber: string;
    SecondaryPhonePrefix: string;
    SecondaryPhoneNumber: string;
    CompanyName: string;
    ProcuratorRg: string;
    ProcuratorCpf: string;
    ProcuratorName: string;
    Activity?: null;
    ModificationDate: string;
    AnalysisUserId: number;
    LinkedPerson: boolean;
    Integrated: boolean;
    Imported?: null;
    PhoneSerasa?: null;
    PhoneValidatedBySerasa?: null;
    NotConfirmedSecurity?: null;
    Version: number;
    OficialVersion?: null;
    Address: Address;
    Sections?: SectionsEntity[] | null;
    BankAccounts?: BankAccountsEntity[] | null;
    Patrimony: Patrimony;
    ActivationCode?: null;
    SecurityStatus: SecurityStatus;
    Accounts?: string[] | null;
    Password?: null;
    Password2?: null;
}

export interface Address {
    ClientId: number;
    AddressId: number;
    PostalCode: string;
    MainAddress: string;
    SecondaryAddress?: null;
    CityId: number;
    StateId: string;
    CountryId: string;
    Number: number;
    District: string;
}
export interface SectionsEntity {
    Id: number;
    ClientId: number;
    SectionId: number;
    Ok: boolean;
}
export interface BankAccountsEntity {
    ClientId: number;
    AccountId: number;
    BankNumber: string;
    AgencyNumber: string;
    AccountNumber: string;
    AccountDigit: string;
    AccountType: number;
    CpfCoOwner: string;
    NameCoOwner?: null;
    AgencyDigit: number;
}
export interface Patrimony {
    PatrimonyId: number;
    ClientId: number;
    FinancialInvestments: number;
    RealState: number;
    OtherAssets: number;
    MonthlyIncome: number;
    NetWorth: number;
    WorkingCapital: number;
    MonthlyAverageBilling: number;
    CapitalStock: number;
    Version: number;
}
export interface SecurityStatus {
    StatusSecurityId: number;
}

export const channelIds = {
    PF: 1,
    INSTITUTIONAL: 3,
    PJ: 5,
    OFFER_MANAGER: 6,
};
