import { sortStringProps, SORTING_ORDER } from '../../../Utils/General/index.sort';

const normalizeFiscalYearDateAsOption = date => {
    const normalizedObj =
        {
            id: date.Id,
            value: String(date.Year),
        } || {};

    return normalizedObj;
};

export const normalizeFinancialIncomeAvailableDates = dates => {
    return [...dates]
        .map(date => normalizeFiscalYearDateAsOption(date))
        .sort((a, b) => sortStringProps(a, b, 'value', SORTING_ORDER.DESCENDING));
};
