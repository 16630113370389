import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeHighlights, normalizeNewSections } from './index.normalize';

export const fetchPartnerFunctionalityInit = () => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_INIT,
});

export const fetchPartnerFunctionality = async () => {
    const url = ENDPOINTS.GET_PARTNER_NEW_FUNCTIONALITIES;

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeNewSections);
};

export const fetchPartnerFunctionalitySuccess = ({ sections, paths }) => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_SUCCESS,
    sections,
    paths,
});

export const fetchPartnerFunctionalityError = () => ({
    type: Actions.FETCH_PARTNER_FUNCTIONALITY_ERROR,
});

export const fetchPartnerHighlightsInit = () => ({
    type: Actions.FETCH_PARTNER_HIGHLIGHTS_INIT,
});

export const fetchPartnerHighlights = async () => {
    const url = ENDPOINTS.GET_PARTNER_HIGHLIGHTS;

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeHighlights);
};

export const fetchPartnerHighlightsSuccess = highlights => ({
    type: Actions.FETCH_PARTNER_HIGHLIGHTS_SUCCESS,
    highlights,
});

export const fetchPartnerHighlightsError = () => ({
    type: Actions.FETCH_PARTNER_HIGHLIGHTS_ERROR,
});
