import styled from 'styled-components';
import { Link } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${Link.Styles.StyledLink} {
        align-self: flex-end;

        * {
            font-size: 12px;
        }
    }
`;

export const StyledHiddenInput = styled.input`
    border: none;
    font-size: 0;
    opacity: 0;
    padding: 0;
`;

export const LinkElectronicSignature = styled(Link)<{ signatureLock: boolean }>`
    pointer-events: ${({ signatureLock }) => (signatureLock ? 'none' : 'initial')};
`;
