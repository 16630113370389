import * as React from 'react';
import { useFormikContext } from 'formik';

import { FORM_SECTIONS } from '../../../Form';

interface LimitAssetManagersProps {
    anchor: any;
    children: React.ReactNode;
}

const LimitAssetManagers: React.FC<LimitAssetManagersProps> = ({ anchor, children }) => {
    const { values } = useFormikContext();

    const hideAssetManagers =
        anchor.ID === FORM_SECTIONS.ASSET_MANAGERS.ID &&
        (!values || !values.AuthorizesOrdersByAdministrator);

    return !hideAssetManagers && <>{children}</>;
};

export default LimitAssetManagers;
