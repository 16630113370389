/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useFormikContext } from 'formik';
import {
    Text,
    Icon,
    InputCpfCnpj,
    InputText,
    InputNumber,
    FormGroup,
    Radio,
} from '@terrainvest/react-components';
import { StyledRepresentative, StyledDelete } from './index.styles';
import { StyledRow, StyledCol, StyledSelect } from '../../Details/index.styles';
import {
    validateRequiredField,
    validateNumber,
    validateCpfCnpjValue,
} from '../../index.validators';
import { CommonPrefixes } from '../../../../Redux/Common/index.constants';
import { Field, SelectField } from '../../Form/Field';
import { StyledContainerNoPadd } from '../../../Common/Grid';
import { fieldLengthLimits } from '../../index.settings';

export const Representative = ({ index, onClose, hasError }) => {
    const { setFieldValue } = useFormikContext<any>();
    const preffix = `Representatives[${index}]`;

    return (
        <StyledRepresentative>
            {onClose && (
                <StyledDelete onClick={onClose}>
                    <Text weight="medium">
                        Remover representante <Icon icon="xmark" size="sm" padLeft />
                    </Text>
                </StyledDelete>
            )}
            <StyledContainerNoPadd fluid>
                <StyledRow>
                    <StyledCol xl={4} md={6} sm={12}>
                        <Field name={`${preffix}.Name`} validate={validateRequiredField}>
                            {({ inputProps }) => (
                                <InputText
                                    {...inputProps}
                                    label="Nome"
                                    fullWidth
                                    maxLength={fieldLengthLimits.name}
                                />
                            )}
                        </Field>
                    </StyledCol>
                    <StyledCol xl={4} md={6} sm={12}>
                        <Field name={`${preffix}.CpfCnpj`} validate={validateCpfCnpjValue}>
                            {({ inputProps }) => (
                                <InputCpfCnpj {...inputProps} label="CPF/CNPJ" fullWidth />
                            )}
                        </Field>
                    </StyledCol>
                    <StyledCol xl={2} md={6} sm={12}>
                        <SelectField
                            name={`${preffix}.Category`}
                            commonPrefix={CommonPrefixes.RepresentativeCategory}
                            validate={validateRequiredField}
                        >
                            {({ field, meta, onChange, options, defaultValue }) => (
                                <StyledSelect
                                    name={field.name}
                                    label="Categoria"
                                    fullWidth
                                    suggest
                                    suggestLimit={8}
                                    error={!!meta.error}
                                    message={meta.error || ''}
                                    defaultValue={defaultValue}
                                    options={options}
                                    onChange={onChange}
                                />
                            )}
                        </SelectField>
                    </StyledCol>
                    <StyledCol xl={2} md={6} sm={12}>
                        <Field
                            name={`${preffix}.Percentage`}
                            validate={value => validateNumber(value, 'percentage')}
                            error={hasError}
                        >
                            {({ inputProps, form }) => {
                                return (
                                    <>
                                        <InputNumber
                                            {...inputProps}
                                            onChange={evt => {
                                                const numValue = Number(
                                                    String(evt.target.value).replace(',', '.'),
                                                );
                                                form.setFieldValue(inputProps.name, numValue);
                                            }}
                                            label="Percentual"
                                            fullWidth
                                            icon={<Icon icon={['fa', 'percent']} size="sm" />}
                                        />
                                    </>
                                );
                            }}
                        </Field>
                    </StyledCol>
                    <StyledCol md={12} sm={12}>
                        <Field name={`${preffix}.PoliticallyExposedPerson`}>
                            {({ field }) => (
                                <FormGroup
                                    name={field.name}
                                    border
                                    vertical={false}
                                    fullWidth
                                    onChange={evt => {
                                        field.onChange(evt);
                                    }}
                                >
                                    <Text>É pessoa políticamente exposta?</Text>
                                    <Radio
                                        label="Sim"
                                        defaultChecked={field.value === true}
                                        // eslint-disable-next-line react/jsx-boolean-value
                                        defaultValue={true}
                                        onChange={() => {
                                            setFieldValue(
                                                `${preffix}.PoliticallyExposedPerson` as never,
                                                true,
                                            );
                                        }}
                                    />
                                    <Radio
                                        label="Não"
                                        defaultChecked={field.value === false}
                                        defaultValue={false}
                                        onChange={() => {
                                            setFieldValue(
                                                `${preffix}.PoliticallyExposedPerson` as never,
                                                false,
                                            );
                                        }}
                                    />
                                </FormGroup>
                            )}
                        </Field>
                    </StyledCol>
                </StyledRow>
            </StyledContainerNoPadd>
        </StyledRepresentative>
    );
};

export default Representative;
