import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Notification } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import { resetAuthData } from '../../../../Redux/Auth';

import { LoginNotificationsProps } from './index.types';
import { shouldHideNotification, userBlockedError, wrongPartnerError } from './index.constants';

const LoginNotifications = ({
    userBlocked,
    stopLoading,
    wrongPartner,
}: LoginNotificationsProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { mappedError, error: authError, offerManagerError } = useSelector(
        (state: RootState) => state.authState,
    );

    const { error: clientError } = useSelector((state: RootState) => state.clientState);

    const { error: suitabilityError } = useSelector(
        (state: RootState) => state.suitabilityProfileState,
    );

    const { error: agreementError } = useSelector((state: RootState) => state.agreementsState);

    React.useEffect(() => {
        if (offerManagerError) {
            history.push('/restricao-coordenacao-oferta');
            dispatch(resetAuthData());
        }
    }, [offerManagerError]);

    React.useEffect(() => {
        if (mappedError && mappedError.error === userBlockedError) {
            userBlocked();
        }
        if (mappedError && mappedError.error === wrongPartnerError) {
            wrongPartner(mappedError.error_uri);
        }
    }, [mappedError]);

    React.useEffect(() => {
        if (
            mappedError ||
            authError ||
            offerManagerError ||
            clientError ||
            suitabilityError ||
            agreementError
        ) {
            stopLoading();
        }
    }, [mappedError, authError, offerManagerError, clientError, suitabilityError, agreementError]);

    return (
        <>
            <Notification
                className="login-notification"
                fixed
                icon
                type="error"
                timeout={5000}
                visible={!!mappedError && !shouldHideNotification(mappedError.error)}
            >
                {mappedError && (
                    <>
                        <Icon icon="circle-exclamation" />
                        &nbsp;&nbsp;
                        {mappedError.error_description}
                    </>
                )}
            </Notification>
            <Notification
                className="login-notification"
                fixed
                icon
                type="error"
                timeout={5000}
                visible={
                    !mappedError && (authError || clientError || suitabilityError || agreementError)
                }
            >
                <Icon icon="circle-exclamation" />
                &nbsp;&nbsp;Serviço indisponível. Por favor, tente novamente mais tarde.
            </Notification>
        </>
    );
};

export default LoginNotifications;
