export const Actions = {
    PAID_CUSTODY_FETCH_INIT: 'PAID_CUSTODY_FETCH_INIT',
    PAID_CUSTODY_FETCH_SUCCESS: 'PAID_CUSTODY_FETCH_SUCCESS',
    PAID_CUSTODY_FETCH_ERROR: 'PAID_CUSTODY_FETCH_ERROR',
};

export enum paidCustodyTypes {
    Active,
    Inactive,
    Unset,
}
