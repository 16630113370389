import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import { resetAuthData } from '../../../../Redux/Auth';
import { validateCpf, validateCnpj } from '../../../ForgotPassword/utils';

import ErrorMessage from '../ErrorMessage';
import { StyledButton } from '../index.styles';
import { errorMessage } from './index.constants';
import { LoginFormProps } from './index.types';
import * as S from './index.styles';

const LoginForm = ({ onSubmit, isLoading, forgotPassword }: LoginFormProps) => {
    const dispatch = useDispatch();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [errorDelay, setErrorDelay] = React.useState(null);

    const { partner } = useSelector((state: RootState) => state.partnerState);

    const isUsernameValid = (value: string) => {
        return value && (validateCpf(value) || validateCnpj(value));
    };

    const handleError = (value: string) => {
        if (errorDelay) clearTimeout(errorDelay);
        if (error) setError('');
        const isValid = isUsernameValid(value);
        if (!isValid && value) {
            const newDelay = setTimeout(() => setError(errorMessage), 800);
            setErrorDelay(newDelay);
        }
    };

    const isPasswordValid = () => {
        return !!password;
    };

    const handleChangeUsername = (value: string) => {
        setUsername(value);
        handleError(value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(username, password, partner.id);
    };

    React.useEffect(() => {
        dispatch(resetAuthData());
    }, []);

    return (
        <S.StyledForm onSubmit={handleSubmit}>
            <S.StyledH3>Login</S.StyledH3>

            <S.InputWrapper valid={isUsernameValid(username)}>
                <S.StyledText>CPF ou CNPJ</S.StyledText>
                <S.StyledInputCpfCnpj
                    data-testid="input-cpf-cnpj"
                    id="username"
                    name="username"
                    autcomplete="on"
                    placeholder="Insira seu CPF ou CNPJ"
                    fullWidth
                    defaultValue=""
                    onChange={(_, formattedValue) =>
                        handleChangeUsername(formattedValue.parsedValue)
                    }
                    error={error}
                    message={error && <ErrorMessage message={error} />}
                />
            </S.InputWrapper>

            <S.InputWrapper valid={isPasswordValid()}>
                <S.StyledText>Senha</S.StyledText>
                <S.StyledInputPassword
                    data-testid="input-password"
                    id="password"
                    name="password"
                    autcomplete="on"
                    fullWidth
                    onChange={evt => setPassword(evt.target.value)}
                />
            </S.InputWrapper>

            <S.ForgotPasswordLink
                data-testid="login-password-recovery"
                data-cy="passwordRecovery"
                onClick={forgotPassword}
            >
                Esqueceu a senha?
            </S.ForgotPasswordLink>

            <S.BottomWrapper>
                <StyledButton
                    data-testid="button-login-submit"
                    primary
                    className="login-button"
                    disabled={!isPasswordValid() || !isUsernameValid(username)}
                    fullWidth
                    type="submit"
                >
                    Entrar
                    {isLoading && <Icon icon="spinner-third" spin size="xs" padRight />}
                </StyledButton>

                <S.RegisterLink
                    data-testid="login-register"
                    data-cy="registerLink"
                    href={partner.urlCadastro}
                    border={false}
                >
                    Não é cliente ainda? {'\n'}
                    <b>Cadastre-se agora</b>
                </S.RegisterLink>
            </S.BottomWrapper>
        </S.StyledForm>
    );
};

export default LoginForm;
