import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchEquityFlutuation,
    fetchEquityFlutuationSuccess,
    fetchEquityFlutuationError,
    fetchEvolutionVariation,
    fetchEvolutionVariationSuccess,
    fetchEvolutionVariationError,
} from './index.actions';

export const initialState = {
    loading: false,
    error: null,
    equityFlutuation: {
        content: null,
        loading: false,
        error: null,
    },
    evolutionVariation: {
        content: null,
        loading: false,
        error: null,
    },
};

export const profitabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_EQUITY_FLUTUATION_INIT:
            return loop(
                {
                    ...state,
                    equityFlutuation: {
                        ...state.equityFlutuation,
                        content: null,
                        loading: true,
                        error: null,
                    },
                },
                Cmd.run(fetchEquityFlutuation, {
                    successActionCreator: fetchEquityFlutuationSuccess,
                    failActionCreator: fetchEquityFlutuationError,
                    args: [action.date, action.account],
                }),
            );

        case Actions.FETCH_EQUITY_FLUTUATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                equityFlutuation: {
                    ...state.equityFlutuation,
                    content: action.data,
                    error: false,
                    loading: false,
                },
            };

        case Actions.FETCH_EQUITY_FLUTUATION_ERROR:
            return {
                ...state,
                loading: false,
                equityFlutuation: {
                    ...state.equityFlutuation,
                    content: null,
                    error: action.error,
                    loading: false,
                },
            };

        case Actions.FETCH_EVOLUTION_VARIATION_INIT:
            return loop(
                {
                    ...state,
                    evolutionVariation: {
                        ...state.evolutionVariation,
                        content: null,
                        loading: true,
                        error: null,
                    },
                },
                Cmd.run(fetchEvolutionVariation, {
                    successActionCreator: fetchEvolutionVariationSuccess,
                    failActionCreator: fetchEvolutionVariationError,
                    args: [action.accountNumber],
                }),
            );

        case Actions.FETCH_EVOLUTION_VARIATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                evolutionVariation: {
                    ...state.evolutionVariation,
                    content: action.data,
                    error: false,
                    loading: false,
                },
            };

        case Actions.FETCH_EVOLUTION_VARIATION_ERROR:
            return {
                ...state,
                loading: false,
                evolutionVariation: {
                    ...state.evolutionVariation,
                    content: null,
                    error: action.error,
                    loading: false,
                },
            };

        default:
            return state;
    }
};
