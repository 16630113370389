import { normalizeString } from './stringParsers';

const REDEMPTION_TYPE = new Map<string, string>([
    ['corrido', 'corridos'], //
    ['util', 'úteis'],
]);

export const getRedemptionType = key => {
    const parsedKey = normalizeString(key as string);
    return REDEMPTION_TYPE.get(parsedKey) || undefined;
};

export default getRedemptionType;
