import * as React from 'react';
import { useFormikContext } from 'formik';

import { FORM_SECTIONS } from '../../../Form';

interface LimitProcuratorsProps {
    anchor: any;
    children: React.ReactNode;
}

const LimitProcurators: React.FC<LimitProcuratorsProps> = ({ anchor, children }) => {
    const { values } = useFormikContext();

    const hideProcurators =
        anchor.ID === FORM_SECTIONS.PROCURATORS_INFO.ID &&
        (!values || !values.AuthorizesOrdersByProcurator);

    return !hideProcurators && <>{children}</>;
};

export default LimitProcurators;
