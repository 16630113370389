import { fetch, ENDPOINTS } from '../../Utils/Api';
import { Actions } from './index.constants';
import {
    normalizeLifeInsurance,
    normalizeLifeInsurancesDetails,
    normalizeLifeInsuraceRequests,
} from './index.normalize';

export const fetchLifeInsuranceInit = () => ({
    type: Actions.LIFE_INSURANCE_FETCH_INIT,
});

export const fetchLifeInsurance = () =>
    fetch(ENDPOINTS.GET_LIFE_INSURANCE, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeLifeInsurance);

export const fetchLifeInsuranceDetail = assetId => {
    const url = ENDPOINTS.GET_LIFE_INSURANCE_ID.replace(':id', assetId);
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeLifeInsurancesDetails);
};

export const fetchLifeInsuranceSuccess = pensions => ({
    type: Actions.LIFE_INSURANCE_FETCH_SUCCESS,
    pensions,
});

export const fetchLifeInsuranceError = () => ({
    type: Actions.LIFE_INSURANCE_FETCH_ERROR,
});

export const fetchHiring = (insuranceTypeId, clientId, accountNumber) =>
    fetch(ENDPOINTS.GET_HIRING, {
        method: 'POST',
        body: JSON.stringify({
            insuranceTypeId,
            clientId,
            accountNumber,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchLifeInsuranceRequestsInit = account => ({
    type: Actions.FETCH_LIFE_INSURANCE_REQUESTS_INIT,
    account,
});

export const fetchLifeInsuranceRequests = client => {
    const url = ENDPOINTS.GET_LIFE_INSURANCE_REQUESTS.replace(':client', client);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeLifeInsuraceRequests);
};

export const fetchLifeInsuranceRequestsSuccess = requests => ({
    type: Actions.FETCH_LIFE_INSURANCE_REQUESTS_SUCCESS,
    requests,
});

export const fetchLifeInsuranceRequestsError = error => ({
    type: Actions.FETCH_LIFE_INSURANCE_REQUESTS_ERROR,
    error: error.message,
});

export const setCommonFilter = value => ({
    type: Actions.LIFE_INSURANCE_REQUESTS_SET_FILTER,
    filterType: 'commonFilter',
    value,
});

export const setFilterByStatus = value => ({
    type: Actions.LIFE_INSURANCE_REQUESTS_SET_FILTER,
    filterType: 'statusFilter',
    value,
});

export const setPensionsCommonFilter = value => ({
    type: Actions.LIFE_INSURANCE_SET_FILTER,
    filterType: 'commonFilter',
    value,
});
