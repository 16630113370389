import * as React from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { logout, postUpdateToken, setAuth } from '../../Redux/Auth';
import { selectAccountNumber } from '../../Redux/Client';
import { markBannerAsUnread } from '../../Redux/Banners';
import { notifyErrorMsg } from '../../Redux/Notification';

export const useChooseAccount = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);

    const onChooseAccount = (accountNumber: number, callback: Function) => {
        setLoading(true);
        postUpdateToken(accountNumber)
            .then(res => {
                const jwtData = jwtDecode(res.AccessToken);

                dispatch(
                    setAuth({
                        access_token: res.AccessToken,
                        ...jwtData,
                    }),
                );
                dispatch(selectAccountNumber(jwtData.AccountNumber));
                dispatch(markBannerAsUnread());

                if (res.AccessToken && jwtData.AccountNumber) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(notifyErrorMsg(error));
                dispatch(logout());
            })
            .finally(() => setLoading(false));
    };

    return { onChooseAccount, loading };
};

export default useChooseAccount;
