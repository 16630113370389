export const colors = {
    stocks: '#EE6677',
    fixedincome: '#4477AA',
    funds: '#CCBB44',
    publicOffers: '#1C6E10',
    treasurydirect: '#0BD9CF',
    futures: '#003A3B',
    earnings: '#B48A2B',
    earningsdividends: '#B48A2B',
    investmentClub: '#601A4A',
    collateral: '#D5D5D5',
    balance: '#2BB43D',
    btc: '#882255',
    terms: '#0ab2df',
    privatePension: '#5F4897',
    recommendedPortfolio: '#807D7D',
    privatepension: '#5F4897',
    insurance: '#2AAAA2',
    insurancePrimary: '#00B376',
    insuranceSecundary: '#71717a',
    insuranceGrey: '#a1a1aa',
};

export const profileColors = {
    conservative: '#7CD992',
    moderate: '#F7E463',
    risky: '#DD5853',
    none: '#4D4D4D',
};

export const suitability = {
    conservative: '#00C381',
    moderate: '#F7E463',
    brave: '#D47777',
};

export const exclusivePlatforms = {
    terraEvolution: '#141a33',
};
