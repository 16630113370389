import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { History } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
    HeaderGroup,
    HeaderItem,
    DynamicLogo,
    SwitchColorMode,
} from '@terrainvest/react-components';

import { RootState } from '../../store';
import { fetchClientInit } from '../../Redux/Client';
import { fetchAdvisorInit } from '../../Redux/Advisors';
import useSwitchMode from '../../Utils/Hooks/useSwitchMode';

import { ProfileMenu } from './ProfileMenu';
import { StyledHeaderComponent, StyledLogoHeaderItem } from './index.styles';

interface HeaderProps {
    history: History;
    width: string;
}

export const Header: React.FC<RouteComponentProps & HeaderProps> = ({
    history,
    width,
}: HeaderProps) => {
    const dispatch = useDispatch();
    const { handleChange } = useSwitchMode();

    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const { client, loading: loadingClient } = useSelector((state: RootState) => state.clientState);
    const { partner } = useSelector((state: RootState) => state.partnerState);
    const { dark, loading: loadingConfig } = useSelector(
        (state: RootState) => state.colorsModeState,
    );

    const handleHideHeader: () => void = () => setIsMobile(window.innerWidth < 768);

    React.useEffect(() => {
        if (!client) {
            dispatch(fetchClientInit());
        }

        handleHideHeader();
        window.addEventListener('resize', handleHideHeader);
        return () => {
            window.removeEventListener('resize', handleHideHeader);
        };
    }, []);

    React.useEffect(() => {
        if (client && !loadingClient) {
            dispatch(fetchAdvisorInit(client.AdvisorId));
        }
    }, [client]);

    const handleSetOpen = (value: boolean) => {
        setOpen(value);
    };

    const getMenuLogo = () => (dark ? partner.logos.menuDark : partner.logos.menuLight);

    return (
        <StyledHeaderComponent mobileBreakpoint={768} width={width} shadow>
            <HeaderGroup left>
                <StyledLogoHeaderItem mobile>
                    <DynamicLogo
                        src={getMenuLogo()}
                        alt={`Logo ${partner.name}`}
                        width="127px"
                        height="33px"
                        onClick={() => history.push('/app')}
                    />
                </StyledLogoHeaderItem>
            </HeaderGroup>
            <HeaderGroup right>
                <HeaderItem>
                    {partner.styleLight && (
                        <SwitchColorMode
                            onChange={handleChange}
                            disabled={loadingConfig}
                            defaultChecked={dark}
                            value={dark}
                        />
                    )}
                </HeaderItem>
                <HeaderItem mobile>
                    <ProfileMenu
                        open={open}
                        setOpen={handleSetOpen}
                        history={history}
                        isMobile={isMobile}
                    />
                </HeaderItem>
            </HeaderGroup>
        </StyledHeaderComponent>
    );
};

export default withRouter(Header);
