import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledLogoWrapper = styled.div`
    ${media.max.md`
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15vh;
    `}
`;
