import { parseDateForDisplay } from '../../../Utils/Parsers/timeParsers';

const NORMALIZE_FIELDS = [
    {
        label: 'DocumentIssuerDate',
        normalizer: date => parseDateForDisplay(date),
    },
];

const normalizeProcuratorFields = procurator => {
    const normalizedProcurator = { ...procurator };

    NORMALIZE_FIELDS.forEach(field => {
        const rawField = normalizedProcurator[field.label];
        normalizedProcurator[field.label] = field.normalizer(rawField);
    });

    return normalizedProcurator;
};

const normalizeAddressFields = procurator => {
    const normalizedProcurator = {
        ...procurator,
        SecondaryAddress: procurator.Complement,
        CountryId: 'BRA',
    };

    return normalizedProcurator;
};

const normalizeIncomingProcurator = procurator => {
    const normalizedProcurator = { ...procurator };

    return [normalizedProcurator]
        .map(normalizeAddressFields)
        .map(normalizeProcuratorFields)
        .pop();
};

const hasProcurators = client => {
    return client && client.Procurators && client.Procurators.length;
};

export const normalizeIncomingProcurators = client => {
    const normalizedClient = { ...client };
    const normalizedProcurators = hasProcurators(normalizedClient)
        ? [...normalizedClient.Procurators].map(normalizeIncomingProcurator)
        : [];

    normalizedClient.Procurators = [...normalizedProcurators];

    return normalizedClient;
};
