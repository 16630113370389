import styled, { css } from 'styled-components';
import { HighlightedText, Text } from '@terrainvest/react-components';
import { HiddenBalanceSwitch } from '../Common/HiddenBalance';

export const StyledWrapper = styled.div`
    position: relative;
    ${({ theme }) => css`
        padding: ${theme.global.spaces.lowFrequencyXRay};
        transition: ${theme.components.header.transition} !important;
        background-color: ${theme.global.colors.secondaryBackground};
        border-radius: ${theme.global.spaces.highFrequencyGammaRay};
    `};
`;

export const StyledInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
`;

export const StyledHiddenBalanceSwitch = styled(HiddenBalanceSwitch)`
    position: absolute;
    ${({ theme }) => css`
        top: ${theme.global.spaces.lowFrequencyXRay};
        right: ${theme.global.spaces.lowFrequencyXRay};
        font-size: ${theme.global.fontSizes.ultraviolet};
    `};
    cursor: pointer;
    svg {
        color: ${({ theme }) => theme.global.colors.primary};
    }
`;

export const StyledHighlightedBalanceText = styled(HighlightedText)`
    ${HighlightedText.Styles.StyledTitle} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor} !important;
        font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
        font-family: ${({ theme }) => theme.global.typographyConfig.body.fontFamily};
        font-weight: ${({ theme }) => theme.global.fontWeights.regular};
        ${({ isNegative, theme }) =>
            isNegative && `color: ${theme.global.colorAliases.error} !important;`}
    }
    ${HighlightedText.Styles.StyledSubTitle} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor} !important;
        font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
        font-family: ${({ theme }) => theme.global.typographyConfig.body.fontFamily};
        font-weight: ${({ theme }) => theme.global.fontWeights.microwave};
    }
`;

export const StyledNoDataFeedback = styled(Text)`
    color: ${props => props.theme.global.colors.error};
`;

export const StyledWrapperHorizontal = styled.div`
    width: 100%;
    height: fit-content;

    ${Text.Styles.StyledText} {
        color: #52525b !important;
        font-weight: ${({ theme }) => theme.global.fontWeights.medium};
    }
    ${StyledHighlightedBalanceText} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        ${HighlightedText.Styles.StyledTitle} {
            color: #52525b !important;
            font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
            font-family: ${({ theme }) => theme.global.typographyConfig.body.fontFamily};
            font-weight: ${({ theme }) => theme.global.fontWeights.microwave};
            ${({ isNegative, theme }) =>
                isNegative && `color: ${theme.global.colorAliases.error} !important;`}
        }
        ${HighlightedText.Styles.StyledSubTitle} {
            color: #52525b !important;
            font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
            font-family: ${({ theme }) => theme.global.typographyConfig.body.fontFamily};
            font-weight: ${({ theme }) => theme.global.fontWeights.regular};
        }
    }
`;
