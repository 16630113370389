import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { responseToFile } from '../../../../Utils/Parsers';

export const fetchRecommendationsReportInit = id => ({
    type: Actions.FETCH_RECOMMENDATION_REPORT_INIT,
    id,
});

export async function fetchRecommendationsReport(id) {
    const response = await fetch(ENDPOINTS.GET_RECOMMENDATION_REPORT.replace(':id', id), {
        method: 'GET',
    });

    if (response.status && response.status === 200) {
        const defaultFilename = `relatorio_recomendacao.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchRecommendationsReportSuccess = table => ({
    type: Actions.FETCH_RECOMMENDATION_REPORT_SUCCESS,
    table,
});

export const fetchRecommendationsReportError = error => ({
    type: Actions.FETCH_RECOMMENDATION_REPORT_ERROR,
    error: error.message,
});
