import * as React from 'react';
import { useSelector } from 'react-redux';

import { AccountSelection } from '../RedirectsPostLogin/AccountSelection';
import { RedirectUnfinishedSignup } from '../RedirectsPostLogin/RedirectUnfinishedSignup';

export const LoggedUserTriage = ({ isLoading }) => {
    const { client } = useSelector(state => state.clientState);

    // O Usuário ter contas cadastradas é considerado o final do processo de cadastro
    const userHasAccounts = client.Accounts && client.Accounts.length !== 0;

    const renderContent = () => {
        return userHasAccounts ? (
            <AccountSelection clientData={client} isLoading={isLoading} />
        ) : (
            <RedirectUnfinishedSignup clientData={client} />
        );
    };

    return <>{renderContent()}</>;
};

export default LoggedUserTriage;
