import moment from 'moment';
import { parseDateForDisplay } from '../../Utils/Parsers';
import { STATUS_IN_PROGRESS } from './index.constants';

const formatter = dateAsString => moment(dateAsString).format('DD/MM/YYYY');

export const normalizeStructuredProductsList = data =>
    data && data.map ? data.map(product => normalizeStructuredProduct(product)) : [];

export const normalizeStructuredProduct = product => ({
    name: product.Name,
    description: product.Description,
    strategyTypeName: product.Strategy.Name,
    strategyTypeId: product.Strategy.Id,
    maximumProfit: product.MaximumProfit,
    winningNote: product.WinningNote,
    maximumDamage: product.MaximumDamage,
    damageNote: product.DamageNote,
    file: product.File,
    id: product.Id,
    suitabilityProfileId: product.SuitabilityProfileId,
    suitabilityProfileDescription: product.SuitabilityProfileDescription,
});

export const normalizeCalls = calls =>
    calls?.map(item => ({
        name: item.Name,
        strategyTypeName: 'Vazia',
        referencePriceAsset: item.ReferencePriceAsset,
        dueDate: formatter(item.DueDate),
        minimalQuantity: item.MinimalQuantity,
        aditionalQuantity: item.AditionalQuantity,
        initialUnit: item.InitialUnit,
        typeInitialUnit: item.TypeInitialUnit,
        fileUrl: (item.File && item.File.FileUrl) || '',
        id: item.Id,
        hasCallMargin: item.CallMargin,
        margin: item.Margin,
    }));

export const normalizeStructuredProductRequests = data =>
    data && data.map
        ? data.map(product => ({
              id: product.Id,
              amount: product.Amount,
              productId: product.StructuredProductId,
              registerDate: product.RegisterDate,
              registerDateNormalized: parseDateForDisplay(product.RegisterDate),
              modificationDate: product.ModificationDate,
              modificationDateNormalized: parseDateForDisplay(product.ModificationDate),
              margin: product.Margin,
              quantity: product.Quantity,
              statusName: product.StatusDescription,
              isByQuantity: product.Quantity !== 0,
              isInProgress: product.Status === STATUS_IN_PROGRESS,
          }))
        : [];
