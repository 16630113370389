import * as React from 'react';
import { useTheme } from 'styled-components';

import { HighlightedText } from '@terrainvest/react-components';

import StandardContentCard from '../../../Common/StandardContentCard';

import { StyledHR } from './index.styles';

const FormCard = ({ children, title, subTitle = '', id = '' }) => {
    const theme = useTheme();

    return (
        <StandardContentCard id={id}>
            <HighlightedText
                asideColor={theme.global.colors.primary}
                title={title}
                subTitle={subTitle}
            />
            <StyledHR />
            {children}
        </StandardContentCard>
    );
};

export default FormCard;
