import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';

export const fetchAccountInit = accountNumber => ({
    type: Actions.ACCOUNT_FETCH_INIT,
    accountNumber,
});

export const fetchAccount = accountNumber => {
    return fetch(ENDPOINTS.GET_ACCOUNT.replace(':accountNumber', accountNumber), {
        method: 'GET',
    }).then(response => response.json());
};

export const fetchAccountSuccess = accounts => ({
    type: Actions.ACCOUNT_FETCH_SUCCESS,
    accounts,
});

export const fetchAccountError = error => ({
    type: Actions.ACCOUNT_FETCH_ERROR,
    error: error.message,
});
