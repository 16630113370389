import React from 'react';

export const useScroll = () => {
    const [scroll, setScroll] = React.useState([window.scrollX, window.scrollY]);

    const onScroll = () => {
        setScroll([window.scrollX, window.scrollY]);
    };

    React.useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return scroll;
};
