import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { Text, Button } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.theme.global.colorAliases.background};
`;

export const StyledFeedbackWrapper = styled.div`
    text-align: justify;
    max-width: 60%;

    ${media.max.tablet`
        max-width: 85%;
    `}
`;

export const StyledTitleText = styled(Text)`
    & span {
        text-transform: capitalize;
    }
`;

export const StyledBodyText = styled(Text)``;

export const StyledButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledButton = styled(Button)`
    height: 40px;
    min-width: 262px;
    font-size: 16px;
`;
