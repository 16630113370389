import styled from 'styled-components';
import { H3, Text } from '@terrainvest/react-components';

export const StyledHeaderMenuContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    padding-left: 35px;
    padding-right: 22px;
    overflow-y: auto;

    font-family: ${({ theme }) => theme.global.typographyConfig.body.fontFamily};

    transition: ${({ theme }) => theme.components.header.transition} !important;
    background-color: ${({ theme }) => theme.global.colors.mainBackground};
    box-shadow: none;

    box-sizing: border-box;
    z-index: 50000;
    ${Text.Styles.StyledText} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor};
    }
    ${H3} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor};
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar:hover {
        display: block;
    }
`;
