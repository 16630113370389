import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';
import * as S from './index.styles';

interface HeaderQualifiedInvestorItemProps {
    professional?: boolean;
}

export const HeaderQualifiedInvestorItem: React.FC<HeaderQualifiedInvestorItemProps> = ({
    professional,
}: HeaderQualifiedInvestorItemProps) => {
    const { dark } = useSelector((state: RootState) => state.colorsModeState);

    return (
        <S.StyledHeaderQualifiedInvestorItem professional={professional} dark={dark}>
            <S.StyledSpan>Investidor {professional ? 'Profissional' : 'Qualificado'}</S.StyledSpan>
        </S.StyledHeaderQualifiedInvestorItem>
    );
};

HeaderQualifiedInvestorItem.defaultProps = {
    professional: false,
};

export default HeaderQualifiedInvestorItem;
