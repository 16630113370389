import { Actions } from './index.constants';
import { normalizeOperations } from './index.normalizer';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { parseDateForAPI } from '../../Utils/Parsers';

export const fetchPositionFixedIncomeHistoryInit = (accountNumber, startDate, endDate) => ({
    type: Actions.POSITION_FIXED_INCOME_HISTORY_INIT,
    accountNumber,
    startDate: parseDateForAPI(startDate),
    endDate: parseDateForAPI(endDate),
});

export const fetchPositionFixedIncomeHistoryAssets = (accountNumber, startDate, endDate) => {
    return fetch(
        ENDPOINTS.GET_FIXED_INCOME_HISTORY.replace(':accountNumber', accountNumber)
            .replace(':startDate', startDate)
            .replace(':endDate', endDate),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeOperations);
};

export const fetchPositionFixedIncomeHistorySuccess = history => ({
    type: Actions.POSITION_FIXED_INCOME_HISTORY_SUCCESS,
    history,
});

export const fetchPositionFixedIncomeHistoryError = (error: Error) => ({
    type: Actions.POSITION_FIXED_INCOME_HISTORY_ERROR,
    error: error.message,
});
