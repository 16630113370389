import * as React from 'react';
import { StyledAnchors } from './index.styles';
import { FORM_SECTIONS } from '../Form';

import AnchorDisplay from './AnchorDisplay';

interface AnchorsProps {
    isCompany: boolean;
    focusedAnchor: string;
    showAnchors: boolean;
}

export const Anchors: React.FC<AnchorsProps> = ({ isCompany, focusedAnchor, showAnchors }) => {
    const PERSON_ANCHORS = [
        FORM_SECTIONS.DETAILS,
        FORM_SECTIONS.PROCURATORS_INFO,
        FORM_SECTIONS.ASSET_MANAGERS,
        FORM_SECTIONS.ADDRESSES,
        FORM_SECTIONS.DOCUMENTS,
        FORM_SECTIONS.FINANCIAL_DATA,
        FORM_SECTIONS.SUITABILITY,
        FORM_SECTIONS.ATTACHMENTS,
    ];

    const COMPANY_ANCHORS = [
        FORM_SECTIONS.DETAILS,
        FORM_SECTIONS.PROCURATORS_INFO,
        FORM_SECTIONS.ASSET_MANAGERS,
        FORM_SECTIONS.ADDRESSES,
        FORM_SECTIONS.REPRESENTATIVES,
        FORM_SECTIONS.ORDER_ISSUERS,
        FORM_SECTIONS.FINANCIAL_DATA,
        FORM_SECTIONS.SUITABILITY,
        FORM_SECTIONS.ATTACHMENTS,
    ];

    const getAnchorName = anchor => anchor.ANCHOR_NAME || anchor.NAME;

    const getAnchorCompanyName = anchor => anchor.ANCHOR_NAME || anchor.COMPANY_NAME || anchor.NAME;

    const getPersonAnchors = () =>
        PERSON_ANCHORS.map(anchor => (
            <AnchorDisplay
                key={anchor.ID}
                anchor={anchor}
                focusedAnchor={focusedAnchor}
                name={getAnchorName(anchor)}
            />
        ));

    const getCompanyAnchors = () =>
        COMPANY_ANCHORS.map(anchor => (
            <AnchorDisplay
                key={anchor.ID}
                anchor={anchor}
                focusedAnchor={focusedAnchor}
                name={getAnchorCompanyName(anchor)}
            />
        ));

    return (
        <StyledAnchors showAnchors={showAnchors}>
            {!isCompany ? getPersonAnchors() : getCompanyAnchors()}
        </StyledAnchors>
    );
};
