export const Actions = {
    ACCOUNTS_FETCH_INIT: 'ACCOUNTS_FETCH_INIT',
    ACCOUNTS_FETCH_SUCCESS: 'ACCOUNTS_FETCH_SUCCESS',
    ACCOUNTS_FETCH_ERROR: 'ACCOUNTS_FETCH_ERROR',
    ACCOUNTS_SELECT: 'ACCOUNTS_SELECT',
};

export interface AccountsState {
    accounts: Account[];
    selectedAccount: Account;
    loading: boolean;
    error: boolean;
}

export interface Account {
    IsMaster: boolean;
    Account: string;
    Current: number;
    IsActive: boolean;
}
