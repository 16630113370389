export const riskTypes = {
    VERY_LOW: {
        type: 'Muito Baixo',
        value: 100,
    },
    LOW: {
        type: 'Baixo',
        value: 200,
    },
    AVERAGE: {
        type: 'Médio',
        value: 300,
    },
    HIGH: {
        type: 'Alto',
        value: 400,
    },
    VERY_HIGH: {
        type: 'Muito Alto',
        value: 500,
    },
};
