/* eslint-disable no-bitwise */
// import AuthorizeClientType from '../Common/ClientType';
// import { ClientType } from '../Common/ClientType/index.types';
import { Authorizer } from '../index.types';

const ShareholderClient: Authorizer = () => {
    // ! ATENÇÃO !
    // * O código abaixo é um ajuste temporário que desabilita essa verificação de permissões do usuário
    return {
        run: async () => true,
    };

    // ! ATENÇÃO !
    // O código a seguir será reativado em uma release futura:
    // return {
    //      run: async ({ client }) => {
    //          const clientTypeInstance = AuthorizeClientType(~ClientType.FUNDS);
    //          const result = await clientTypeInstance.run({ client });
    //          return result;
    //      },
    // };
};

export default ShareholderClient;
