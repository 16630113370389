import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { validPotentialNullResponseFromAPI } from '../../Utils/Parsers';
import { normalizeRLPStatusResponse } from './index.normalize';

export const fetchRLPStatusInit = clientId => ({
    type: Actions.RLP_GET_STATUS_INIT,
    clientId,
});

export const fetchRLPStatus = async clientId => {
    const url = ENDPOINTS.GET_RLP_STATUS.replace(':clientId', clientId);

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeRLPStatusResponse);
};

export const fetchRLPStatusSuccess = response => ({
    type: Actions.RLP_GET_STATUS_SUCCESS,
    response,
});

export const fetchRLPStatusError = error => ({
    type: Actions.RLP_GET_STATUS_ERROR,
    error: error.message,
});

export const fetchRLPItems = () => {
    const url = ENDPOINTS.GET_RLP_ITEMS;
    return fetch(url, { method: 'get' }).then(response =>
        validPotentialNullResponseFromAPI(response, []),
    );
};

export const fetchRLPData = refMonth => {
    const url = ENDPOINTS.GET_RLP_DATA.replace(':referenceMonth', refMonth);
    return fetch(url, { method: 'GET' }).then(response =>
        validPotentialNullResponseFromAPI(response, []),
    );
};

export const fetchRLPTimeOMS = refMonth => {
    const url = ENDPOINTS.GET_RLP_TIME_OMS.replace(':referenceMonth', refMonth);
    return fetch(url, { method: 'GET' }).then(response =>
        validPotentialNullResponseFromAPI(response, {}),
    );
};

export const patchRLPStatus = ({ clientId, userId, rlpNewStatus, electronicCheck }) => {
    const url = ENDPOINTS.PATCH_RLP_STATUS;

    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ClientId: clientId,
            UserId: userId,
            Rlp: rlpNewStatus,
            ElectronicCheck: electronicCheck,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => {
        return response.ok ? response : Promise.reject('Something went wrong.');
    });
};

export const patchRLPStatusByProp = (ClientId, Rlp, ElectronicCheck = null) => {
    const url = ENDPOINTS.PATCH_RLP_STATUS;
    const payload = {
        ClientId,
        Rlp,
        ElectronicCheck,
    };
    if (!ElectronicCheck) {
        delete payload.ElectronicCheck;
    }

    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    }).then(response => {
        return response.ok ? response : Promise.reject('Something went wrong.');
    });
};
