import * as React from 'react';

import * as S from './index.styles';

const isElectronicCheckRule = (ruleMessage: string) => {
    return ruleMessage.includes('assinatura eletrônica');
};

const renderIconProps = (ruleValidation: boolean | null, message: string) => {
    if (ruleValidation === true) return { icon: 'check', size: 'sm' };
    if (ruleValidation === false) return { icon: 'xmark', size: 'sm' };

    if (isElectronicCheckRule(message)) return { icon: ['far', 'circle-info'], size: 'xs' };

    return { icon: ['fas', 'caret-right'], size: 'xs' };
};

const PasswordTips = ({ rules, title }) => {
    return (
        <>
            <S.StyledPasswordTip>
                <S.StyledTitle large>{title}</S.StyledTitle>
                <S.StyledRules>
                    {rules &&
                        rules.map(rule => (
                            <S.StyledRule
                                key={rule.message}
                                hasPassedRule={rule.routine()}
                                paint={!isElectronicCheckRule(rule.message)}
                            >
                                <S.StyledIcon {...renderIconProps(rule.routine(), rule.message)} />
                                <S.StyledText>{rule.message}</S.StyledText>
                            </S.StyledRule>
                        ))}
                </S.StyledRules>
            </S.StyledPasswordTip>
        </>
    );
};

export default PasswordTips;
