import * as React from 'react';
import { Link } from '@terrainvest/react-components';

import {
    StyledInsideTitle,
    StyledInsideWrapper,
    StyledSpacingText,
} from '../../LoginSide/index.styles';

import { knowOurInvestmentsLink } from './index.constants';
import * as S from './index.styles';

export const RedirectLoginInAnalysis = () => {
    return (
        <StyledInsideWrapper>
            <S.StyledWrapper>
                <StyledInsideTitle style={{ margin: 0 }}>Cadastro em análise</StyledInsideTitle>
                <StyledSpacingText>
                    Após o processamento dos dados, você receberá um e-mail com as instruções de
                    acesso.
                </StyledSpacingText>
                <Link href={knowOurInvestmentsLink} target="_blank">
                    Conheça nossos Produtos
                </Link>
            </S.StyledWrapper>
        </StyledInsideWrapper>
    );
};

export default RedirectLoginInAnalysis;
