import * as moment from 'moment';
import { formatCurrency } from '@terrainvest/react-components';

const NORMALIZE_DISPLAY_FIELDS = [
    {
        key: 'PaymentEstimation',
        label: 'Data Previsão Pagamento',
        normalizer: value => moment(value).format('DD/MM/YYYY'),
    },
    {
        key: 'GrossValue',
        label: 'Valor Bruto',
        normalizer: value => formatCurrency(value, { currency: 'R$ ' }),
    },
    {
        key: 'NetValue',
        label: 'Valor Líquido',
        normalizer: value => formatCurrency(value, { currency: 'R$ ' }),
    },
];

export const normalizeEarningsDividendsAsset = incomingAsset => {
    const normalizedObj = { ...incomingAsset, normalized: {} };

    NORMALIZE_DISPLAY_FIELDS.forEach(field => {
        const normalizedField = {
            label: field.label,
            rawValue: normalizedObj[field.key],
            value: field.normalizer(normalizedObj[field.key]),
        };

        normalizedObj.normalized = { ...normalizedObj.normalized, [field.key]: normalizedField };
    });

    return normalizedObj;
};

export default normalizeEarningsDividendsAsset;
