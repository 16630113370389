import { Actions } from './index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';

export const initialState = {
    isTerraApp: false,
};

export const mobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_TERRA_APP:
            return {
                ...state,
                isTerraApp: action.isTerraApp,
            };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
