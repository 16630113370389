import { fetch, ENDPOINTS } from '../../Utils/Api';

const ERROR_MSG = 'Erro na obtenção de dados.';

export const fetchHomebrokerToken = async id => {
    if (!id) {
        throw new Error(ERROR_MSG);
    }

    const url = ENDPOINTS.GET_HOMEBROKER_TOKEN.replace(':id', id);
    const response = await fetch(url, { method: 'GET' });
    const content = await response.json();

    return content;
};
