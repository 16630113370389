import { H2, Icon, Text } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { Separator } from '../../../../Utils/General/index.separator';
import * as S from './index.styles';
import ProcuratorInputForCompany from './ProcuratorInputForCompany';
import ProcuratorInputForPerson from './ProcuratorInputForPerson';

export const ProcuratorInput = ({ index, onRemove, isCompany }) => {
    const preffix = `Procurators[${index}]`;

    const { setFieldValue, values } = useFormikContext<any>();

    const hasValidityDate = values.Procurators[index].ValidityIndeterminate === false;
    const [showValidityDate, setShowValidityDate] = React.useState<boolean>(hasValidityDate);

    const handleRemove = () => {
        onRemove(index);
    };

    const handleValidityIndeterminateChangedEnabled = () => {
        setFieldValue(`${preffix}.ValidityIndeterminate` as never, true);
        setShowValidityDate(false);
    };

    const handleValidityIndeterminateChangedDisabled = () => {
        setFieldValue(`${preffix}.ValidityIndeterminate` as never, false);
        setShowValidityDate(true);
    };

    return (
        <>
            <S.StyledHeaderWrapper>
                <H2>{`Procurador ${index + 1}`}</H2>
                <S.StyledRemove onClick={handleRemove}>
                    <Text weight="medium">
                        Remover procurador <Icon icon="xmark" size="sm" padLeft />
                    </Text>
                </S.StyledRemove>
            </S.StyledHeaderWrapper>
            <Separator />
            {isCompany ? (
                <ProcuratorInputForCompany
                    preffix={preffix}
                    index={index}
                    showValidityDate={showValidityDate}
                    validityEnabled={handleValidityIndeterminateChangedEnabled}
                    validityDisabled={handleValidityIndeterminateChangedDisabled}
                />
            ) : (
                <ProcuratorInputForPerson
                    preffix={preffix}
                    index={index}
                    showValidityDate={showValidityDate}
                    validityEnabled={handleValidityIndeterminateChangedEnabled}
                    validityDisabled={handleValidityIndeterminateChangedDisabled}
                />
            )}
        </>
    );
};

export default ProcuratorInput;
