import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InputPassword, Loading, useId } from '@terrainvest/react-components';

import { RootState } from '../../../store';
import { fetchForgotElectronicCheck } from '../../../Redux/Auth';
import { postNotificationFromError, postNotification } from '../../../Redux/Notification';

import { StyledWrapper, StyledHiddenInput, LinkElectronicSignature } from './index.styles';

export const ElectronicPasswordInput = ({
    onChange,
    setLoading,
    label = 'Assinatura eletrônica',
    ...props
}) => {
    const dispatch = useDispatch();
    const { client } = useSelector((state: RootState) => state.clientState);
    const [id] = useId('electronic-check-');

    const [innerLoading, setInnerLoading] = React.useState(false);
    const [signatureResendTime, setSignatureResendTime] = React.useState<number>(0);
    const showLoading = setLoading || setInnerLoading;

    React.useEffect(() => {
        if (signatureResendTime !== 0) {
            const timer = setTimeout(() => {
                setSignatureResendTime(signatureResendTime - 1);
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
        return () => {};
    }, [signatureResendTime]);

    const handleForgotElectronicCheck = async e => {
        e.preventDefault();
        e.stopPropagation();

        showLoading(true);

        try {
            setSignatureResendTime(15);

            await fetchForgotElectronicCheck(client.UserId);

            dispatch(
                postNotification(
                    'Você receberá uma nova assinatura eletrônica no seu email em breve',
                    { timeout: 5000, type: 'warn' },
                ),
            );
        } catch (error) {
            setSignatureResendTime(0);
            dispatch(postNotificationFromError(error));
        } finally {
            showLoading(false);
        }
    };

    return (
        <>
            <Loading visible={innerLoading} />
            <StyledWrapper>
                <StyledHiddenInput type="text" tabIndex={-1} />
                <StyledHiddenInput type="password" tabIndex={-1} />
                <InputPassword
                    id={id}
                    name="electronic-check"
                    autoComplete="off"
                    label={label}
                    {...props}
                    onChange={onChange}
                />
                <LinkElectronicSignature
                    href="."
                    signatureLock={!!signatureResendTime}
                    onClick={e => handleForgotElectronicCheck(e)}
                >
                    {signatureResendTime
                        ? `Reenviar em ${signatureResendTime} segundos`
                        : 'Esqueci minha assinatura eletrônica'}
                </LinkElectronicSignature>
            </StyledWrapper>
        </>
    );
};

export default ElectronicPasswordInput;
