import styled, { css } from 'styled-components';
import { Text, Icon } from '@terrainvest/react-components';

export const StyledError = styled.div`
    display: flex;
    column-gap: ${({ theme }) => theme.global.spaces.xRay};
    padding: ${({ theme }) =>
        `${theme.global.spaces.xRay} ${theme.global.spaces.highFrequencyGammaRay}`};
`;

export const StyledIcon = styled(Icon)`
    color: #be123c;
`;

export const StyledTexts = styled(Text)`
    margin: 0;
    line-height: ${({ theme }) => theme.global.fontSizes.microwave};
`;

export const StyledText = styled.span`
    ${({ bold, theme }) =>
        bold &&
        css`
            font-weight: ${theme.global.fontWeights.semiBold};
        `};
    color: #be123c;
`;
