import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers } from './index.normalize';

export const fetchInvestmentProventsDividendsAssetsInit = accountNumber => ({
    type: Actions.FETCH_PROVENTS_DIVIDENDS_INIT,
    accountNumber,
});

export const fetchProventsDividendsAssets = accountNumber => {
    return fetch(
        ENDPOINTS.GET_INVESTMENT_PROVENTS_DIVIDENDS.replace(':accountNumber', accountNumber),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchProventsDividendsSuccess = offers => ({
    type: Actions.FETCH_PROVENTS_DIVIDENDS_SUCCESS,
    offers,
});

export const fetchProventsDividendsError = error => ({
    type: Actions.FETCH_PROVENTS_DIVIDENDS_ERROR,
    error: error.message,
});
