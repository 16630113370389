import * as React from 'react';

import { FormGroup, Text, Radio } from '@terrainvest/react-components';

import useWindowSize from '../../../../Utils/Hooks/useWindowSize';

export const CurrentlyWorking = ({ field, form, onChange }) => {
    const windowSize = useWindowSize();

    return (
        <FormGroup
            {...field}
            border
            fullWidth
            vertical={false}
            onChange={evt => {
                const checked = evt.target.value === 'true';
                form.setFieldValue(field.name, checked);
                onChange(checked);
            }}
        >
            <Text>Estou trabalhando atualmente</Text>
            <Radio
                defaultValue={true as boolean}
                defaultChecked={field.value === true}
                label={windowSize.width < 1200 ? 'S' : 'Sim'}
            />
            <Radio
                defaultValue={false as boolean}
                defaultChecked={field.value === false}
                label={windowSize.width < 1200 ? 'N' : 'Não'}
            />
        </FormGroup>
    );
};

export default CurrentlyWorking;
