import moment from 'moment';

import { parseQuantityInput } from '../../../Utils/Parsers';

const normalizeBalance = balance => balance && parseFloat(parseQuantityInput(balance));

const normalizeDate = date => date && date.split(' ').shift();

const normalizeFutureReleasesInfo = info => ({
    cedroFutureRelease: info.CedroFutureRelease,
    futureReleaseResult: info.FutureReleaseResult,
});

const normalizeCedroFutureRelease = cedroFutureRelease => ({
    account: cedroFutureRelease.Account,
    availableBalance: cedroFutureRelease.Available,
    balance: normalizeBalanceDays(cedroFutureRelease.Balance),
    projectedBalance: cedroFutureRelease.ProjectedBalance,
});

const normalizeBalanceDays = balance =>
    balance &&
    balance.map &&
    balance.map(day => ({
        date: moment(day.Date).format('DD/MM/YYYY'),
        day: day.Day,
        value: day.Value,
        dayPlus: 'D+'.concat(day.Day),
    }));

const normalizeExtract = extract => ({
    balance: normalizeBalance(extract.Balance),
    balanceCredit: normalizeBalance(extract.BalanceCredit),
    balanceDebt: normalizeBalance(extract.BalanceDebt),
    date: normalizeDate(extract.Date),
    description: extract.Description,
});

const normalizeExtracts = extracts => {
    const extractsData =
        extracts && extracts.map ? extracts.map(extract => normalizeExtract(extract)) : [];

    return extractsData;
};

const addDayPlusToDate = (extracts, days) => {
    const extractsWithDayPlus = extracts.map(extractDay =>
        days
            .map(
                day =>
                    day.date === extractDay.date && {
                        ...extractDay,
                        date: `${day.dayPlus} ${extractDay.date}`,
                    },
            )
            .filter(day => !!day && day),
    );
    const extractsNormalized = extractsWithDayPlus
        .map(j => j[0])
        .filter(extractDay => !!extractDay);
    return extractsNormalized;
};

export const normalizeFutureReleases = futureReleases => {
    const futureReleasesNormalized = normalizeFutureReleasesInfo(futureReleases);
    const extracts = futureReleasesNormalized.futureReleaseResult;
    const cedroInfo = futureReleasesNormalized.cedroFutureRelease;
    const cedroNormalized = normalizeCedroFutureRelease(cedroInfo);
    const extractsNormalized = normalizeExtracts(extracts);
    const extractsNormalizedWithDayPlus = addDayPlusToDate(
        extractsNormalized,
        cedroNormalized.balance,
    );
    return {
        futureReleases: extractsNormalizedWithDayPlus,
        availableBalance: cedroNormalized.availableBalance,
        projectedBalance: cedroNormalized.projectedBalance,
        dPlusInfo: cedroNormalized.balance,
        updatedIn: Date.now(),
    };
};
