import { formatCurrency } from '@terrainvest/react-components';
import { PLATFORM_TYPES } from '../../View/Platforms/index.types';
import { TERRA_PLATFORM_ID } from './index.constants';

const TRADE_MACHINE_PRICE_TAG = 'Compras dentro do App';

const mapTradeMachine = item => {
    const newItem = { ...item };
    newItem.isTradeMachine = newItem.Type === PLATFORM_TYPES.BUY_IN_APP;
    return newItem;
};

const getTradeMachinePrice = () => {
    return TRADE_MACHINE_PRICE_TAG;
};

const getPlatformPrice = item => {
    return item && item.Price > 0 ? formatCurrency(item.Price.toFixed(2)) : 'Gratuito';
};

const normalizePrices = item => {
    const newItem = { ...item, normalized: { Price: '' } };

    newItem.normalized.Price = item.isTradeMachine
        ? getTradeMachinePrice()
        : getPlatformPrice(item);

    return newItem;
};

export const normalizePlatformsAndTypes = ([clientPlatforms, platforms]) => [
    clientPlatforms
        .filter(clientPlatform => platforms.some(p => clientPlatform.Id === p.Id))
        .map(mapTradeMachine)
        .map(normalizePrices)
        .map(clientPlatform => {
            const platform = platforms.find(p => p.Id === clientPlatform.Id);
            return {
                ...clientPlatform,
                ...platform,
            };
        }),
    platforms.map(mapTradeMachine).map(normalizePrices),
];

export const normalizedImagesPlatform = (images: any[]) => {
    const imagesPlatformNormalized = images
        ? images.map(image => ({
              fileUrl: image.FileUrl,
              fileName: image.FileName,
              id: image.Id,
              platformId: image.PlatformId,
              orderNumber: image.OrderNumber,
          }))
        : [];

    return imagesPlatformNormalized;
};

export const normalizePlatform = platform => {
    const platformNormalized = {
        id: platform.Id,
        name: platform.Name,
        summary: platform.Summary,
        description: platform.Description,
        version: platform.Version,
        paymentType: platform.PaymentType,
        price: platform.Price,
        paymentPeriod: platform.PaymentPeriod,
        type: platform.Type,
        active: platform.Active,
        agreementFileName: platform.AgreementFileName,
        agreementFileUrl: platform.AgreementFileUrl,
        revokeAgreementFileName: platform.RevokeAgreementFileName,
        revokeAgreementFileUrl: platform.RevokeAgreementFileUrl,
        imageUrl: platform.ImageUrl,
        image: platform.Image,
        extention: platform.Extention,
        suitabilityProfileId: platform.SuitabilityProfileId,
        suitabilityProfileDescription: platform.SuitabilityProfileDescription,
        rlpMessageType: platform.RlpMessageType,
        groupId: platform.GroupId,
        registerDate: platform.RegisterDate,
        registerUserId: platform.RegisterUserId,
        modificationDate: platform.ModificationDate,
        modificationUserId: platform.ModificationUserId,
        rlpType: {
            id: platform.RlpType?.Id,
            active: platform.RlpType?.Active,
            title: platform.RlpType?.Title,
            description: platform.RlpType?.Description,
            urlText: platform.RlpType?.UrlText,
            urlLink: platform.RlpType?.UrlLink,
            rlpMandatory: platform.RlpType?.RlpMandatory,
            cardType: platform.RlpType?.CardType,
        },
        images: normalizedImagesPlatform(platform.Images),
    };

    return platformNormalized;
};

export const normalizePortfolioPlatforms = data => {
    const portfolioPlatform = data
        ? data.map(value => ({
              id: value.Id,
              name: value.Name,
              fileLogo: value.FileUrl,
              isTerra: value.Id === TERRA_PLATFORM_ID,
              url: value.Url,
          }))
        : [];

    return portfolioPlatform;
};
