import * as React from 'react';

import PanelSlide from '../../../Common/PanelSlide';
import { StyledHeaderMenuContainer } from './index.styles';

interface HeaderMenuContainerProps {
    open: boolean;
    setClose(): void;
    isMobile: boolean;
    children: React.ReactNode;
}

export const HeaderMenuContainer: React.FC<HeaderMenuContainerProps> = ({
    open,
    setClose,
    isMobile,
    children,
}: HeaderMenuContainerProps) => {
    const calculateWidth = () => {
        if (isMobile) return 414;
        return 331;
    };

    return (
        <PanelSlide open={open} setClose={setClose} width={calculateWidth()} headerMargin>
            <StyledHeaderMenuContainer>{children}</StyledHeaderMenuContainer>
        </PanelSlide>
    );
};

HeaderMenuContainer.defaultProps = {
    open: false,
    setClose: () => {},
};

export default HeaderMenuContainer;
