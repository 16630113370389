import moment from 'moment';
import { formatCurrency } from '@terrainvest/react-components';

const OFFER_FIELDS = [
    {
        label: 'TaxPayment',
        normalizer: hasTaxPayment => (hasTaxPayment ? 'Semestral' : '-'),
    },
    {
        label: 'MaturityDate',
        normalizer: date => moment(date, 'YYYY-MM-DDThh:mm:ss').format('YYYY-MM-DD'),
    },
    {
        label: 'MinimalApplication',
        normalizer: value => formatCurrency(value, { currency: '' }),
    },
];

const normalizeOfferFiles = offer => {
    const normalizedOffer = { ...offer };

    OFFER_FIELDS.forEach(field => {
        const rawField = normalizedOffer[field.label];
        normalizedOffer[`${field.label}Normalized`] = field.normalizer(rawField);
    });

    return normalizedOffer;
};

export const normalizeInnerFields = offer => ({
    business: offer.Business,
    id: offer.Id,
    isin: offer.Isin,
    issueDate: offer.IssueDate,
    maturityDate: offer.MaturityDate,
    maturityDays: offer.MaturityDays,
    minimalApplication: offer.MinimalApplication,
    minimalRescue: offer.MinimalRescue,
    name: offer.Name,
    purchasePrice: offer.PurchasePrice,
    purchaseRate: offer.PurchaseRate,
    quantityApplication: offer.QuantityApplication,
    quantityRescue: offer.QuantityRescue,
    rentabilityDescription: offer.RentabilityDescription,
    saleValue: offer.SaleValue,
    selic: offer.Selic,
    sellingRate: offer.SellingRate,
    suitabilityProfileDescription: offer.SuitabilityProfileDescription,
    suitabilityProfileId: offer.SuitabilityProfileId,
    taxPayment: offer.TaxPayment,
    taxPaymentNormalized: offer.TaxPaymentNormalized,
    totalValue: offer.TotalValue,
    type: {
        id: offer.Type.Id,
        name: offer.Type.Name,
    },
    unitaryPrice: offer.UnitaryPrice,
    unitarySaleValue: offer.UnitarySaleValue,
    indexorName: offer.Indexor.Name,
});

export const normalizeOffers = offers => {
    return [...offers]
        .map(offer => normalizeOfferFiles(offer))
        .map(offer => normalizeInnerFields(offer));
};

export const normalizeOffer = incomingOffer => {
    return normalizeOfferFiles({ ...incomingOffer });
};
