import moment from 'moment';
import { stringToDate } from '@terrainvest/react-components';
import { isValidDate as isDateAsMomentValid } from '../../Utils/Parsers/timeParsers';
import { validateCnpj, validateCpf } from '../ForgotPassword/utils';
import { fieldLengthLimits } from './index.settings';

export const Errors = {
    Required: 'Campo obrigatório.',
    InvalidDate: 'Data inválida.',
    DateIsLessThanToday: 'A data de validade deve ser maior que a data atual.',
    InvalidCpf: 'Campo inválido.',
    InvalidEmail: 'E-mail inválido.',
    InvalidPhone: 'Celular inválido.',
    InvalidCurrency: 'Valor inválido.',
    InvalidCEP: 'CEP inválido.',
    InvalidFile: 'Arquivo inválido',
    InvalidPercent: 'Percentual inválido',
};

export const validateLength = (type, value) => {
    const lengthValidator = {
        name: { length: fieldLengthLimits.name, error: 'Nome inválido' },
        email: { length: fieldLengthLimits.email, error: 'Email inválido' },
        skype: { length: fieldLengthLimits.skype, error: 'Campo inválido' },
        motherName: { length: fieldLengthLimits.motherName, error: 'Nome inválido' },
        fatherName: { length: fieldLengthLimits.fatherName, error: 'Nome inválido' },
        spouseName: { length: fieldLengthLimits.fatherName, error: 'Nome inválido' },
        company: { length: fieldLengthLimits.company, error: 'Campo inválido' },
        address: { length: fieldLengthLimits.address, error: 'Endereço inválido' },
        addressNumber: { length: fieldLengthLimits.addressNumber, error: 'Número inválido' },
        addressComplement: {
            length: fieldLengthLimits.addressComplement,
            error: 'Complemento inválido',
        },
        addressNeighborhood: {
            length: fieldLengthLimits.addressNeighborhood,
            error: 'Bairro inválido',
        },
        cnhNumber: { length: fieldLengthLimits.cnhNumber, error: 'CNH inválida' },
        safetyCode: { length: fieldLengthLimits.safetyCode, error: 'Campo inválido' },
        rgNumber: { length: fieldLengthLimits.rgNumber, error: 'RG inválido' },
        rneNumber: { length: fieldLengthLimits.rneNumber, error: 'RNE inválido' },
        bankBranch: { length: fieldLengthLimits.bankBranch, error: 'Agência inválida' },
        bankAccount: { length: fieldLengthLimits.bankAccount, error: 'Conta inválida' },
        bankDigit: { length: fieldLengthLimits.bankDigit, error: 'Dígito inválido' },
        nameCoHolder: { length: fieldLengthLimits.nameCoHolder, error: 'Nome inválido' },
        percentage: { length: fieldLengthLimits.percentage, error: 'Percentual inválido' },
        constitutionFormat: {
            length: fieldLengthLimits.constitutionFormat,
            error: 'Forma de Constituição inválida',
        },
    };

    return value && value.length > lengthValidator[type].length ? lengthValidator[type].error : '';
};

export const validateRequiredField = value => {
    return !value ? Errors.Required : '';
};

export const validateFields = (type, value) => {
    return validateRequiredField(value) || validateLength(type, value);
};

export const validateDate = (value, minAge = 0, customMessage = '') => {
    const requiredError = validateRequiredField(value);

    if (requiredError) {
        return requiredError;
    }

    const date = stringToDate(value);

    if (!date || date.getTime() >= Date.now()) {
        return Errors.InvalidDate;
    }

    const nowMomement = moment();
    const valueAsMoment = moment(date);
    if (minAge && nowMomement.diff(valueAsMoment, 'years') < minAge && customMessage) {
        return customMessage;
    }

    return '';
};

export const dateLessThanToday = value => {
    const requiredError = validateRequiredField(value);

    if (requiredError) {
        return requiredError;
    }

    const date = stringToDate(value) || new Date(value);

    if (!date || date.getTime() < Date.now()) {
        return Errors.DateIsLessThanToday;
    }

    return '';
};

export const isValidDate = value => {
    if (value) {
        return !isDateAsMomentValid(value, ['DD/MM/YYYY', moment.ISO_8601])
            ? Errors.InvalidDate
            : '';
    }

    return '';
};

export const isRequiredValidDate = value => {
    if (value) {
        return !isDateAsMomentValid(value, ['DD/MM/YYYY', moment.ISO_8601])
            ? Errors.InvalidDate
            : '';
    }

    return Errors.Required;
};

export const isValidCEPFormat = value =>
    !/^\d{5}-\d{3}|\d{8}$/.test(value) ? Errors.InvalidCEP : '';

export const isValidCnpjValue = value => (!validateCnpj(value) ? Errors.InvalidCpf : '');

export const isValidEmail = value => {
    if (value) {
        return !/^.+@.+\..+$/.test(value) || validateLength('email', value)
            ? Errors.InvalidEmail
            : '';
    }

    return '';
};

export const isValidPhone = value => {
    if (value) {
        return !/^\d{10,11}$/.test(value.replace(/[^\d]/g, '')) ? Errors.InvalidPhone : '';
    }

    return '';
};

export const validateCEP = value =>
    validateRequiredField(value) || isValidCEPFormat(value) ? Errors.InvalidCEP : '';

export const validateRequiredCpfValue = value =>
    !value ? validateRequiredField(value) : !validateCpf(value) ? Errors.InvalidCpf : '';

export const validateCpfValue = value =>
    validateRequiredField(value) || !validateCpf(value) ? Errors.InvalidCpf : '';

export const validateCnpjValue = value =>
    validateRequiredField(value) || !validateCnpj(value) ? Errors.InvalidCpf : '';

export const validateCpfCnpjValue = value => {
    const onlyDigitsValue = value.replace(/[^\d]+/g, '');
    return onlyDigitsValue.length > 11 ? isValidCnpjValue(value) : validateCpfValue(value);
};

export const validateRequiredCnpj = value =>
    validateRequiredField(value) || !validateCnpj(value) ? Errors.InvalidCpf : '';

export const validateNonRequiredCnpj = value =>
    !!value && !validateCnpj(value) ? Errors.InvalidCpf : '';

export const validateEmail = value => validateRequiredField(value) || isValidEmail(value);

export const validatePhone = value => validateRequiredField(value) || isValidPhone(value);

export const validateCurrency = value =>
    !(typeof value === 'number' && value >= 0) || (value && value.toString().length >= 17)
        ? Errors.InvalidCurrency
        : '';

export const validateNumber = value =>
    !(typeof value === 'number' && value > 0) ? Errors.InvalidPercent : '';

export const validateFile = fileData => {
    if (!fileData || !fileData.file) {
        return Errors.Required;
    }
    if (!/\.(jpg)|(jpeg)|(pdf)|(png)$/i.test(fileData.file.name)) {
        return Errors.InvalidFile;
    }
    return '';
};
