import * as React from 'react';
import { Text } from '@terrainvest/react-components';
import { StyledHeaderProfileNameItem } from './index.styles';

interface HeaderProfileNameItemProps {
    profile?: string;
    profileId?: number;
}

export const HeaderProfileNameItem: React.FC<HeaderProfileNameItemProps> = ({
    profile,
    profileId,
}: HeaderProfileNameItemProps) => {
    const hasProfile = profileId > 0 && profileId < 4;
    return (
        <StyledHeaderProfileNameItem profileId={profileId}>
            {hasProfile ? (
                <Text>
                    Perfil de Investidor | <b>{profile}</b>
                </Text>
            ) : (
                <Text>Sem Perfil</Text>
            )}
        </StyledHeaderProfileNameItem>
    );
};

HeaderProfileNameItem.defaultProps = {
    profile: '',
    profileId: 0,
};

export default HeaderProfileNameItem;
