import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postNotification } from '../../Redux/Notification';
import { isCadastralUpdate, needsSuitabilityUpdate } from '../../Utils/General';
import withClientFetch from '../../Utils/HOCs/withClientFetch';
import { isExpiredDate } from '../../Utils/Parsers';

const UserDataExpiration = () => {
    const { client } = useSelector(state => state.clientState);
    const { minifiedProfile: profile } = useSelector(state => state.suitabilityProfileState);

    const [authorized, setAuthorized] = React.useState<boolean>(undefined);

    const history = useHistory();
    const dispatch = useDispatch();

    const inExpiredDataRoute =
        history && history.location && history.location.pathname === '/cadastro-expirado';

    const handleUserDataExpiration = () => {
        const dataNeedUpdate =
            isCadastralUpdate(client) ||
            isExpiredDate(client.DueDate) ||
            needsSuitabilityUpdate(profile);

        if (dataNeedUpdate) {
            setAuthorized(false);
        } else {
            setAuthorized(true);
        }
    };

    React.useEffect(() => {
        if (authorized === false && !inExpiredDataRoute) {
            dispatch(
                postNotification('A validade dos seus dados cadastrais expirou', {
                    timeout: 5000,
                    type: 'error',
                }),
            );

            history.push('/cadastro-expirado');
        }

        if (authorized === true && inExpiredDataRoute) {
            history.push('/');
        }
    }, [authorized]);

    React.useEffect(() => {
        if (client && profile) {
            handleUserDataExpiration();
        }
    }, [client, profile]);

    return null;
};

export default withClientFetch(UserDataExpiration);
