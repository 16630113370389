import { BankAccount } from '../index.normalize';
import { Actions } from './index.constants';

export const initialState = {
    selectedAccount: {} as BankAccount,
};

export const removeBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.REMOVE_BANK_ACCOUNT_SELECT:
            return { ...state, selectedAccount: action.bankAccount };

        case Actions.REMOVE_BANK_ACCOUNT_RESET:
            return { ...initialState };

        default:
            return state;
    }
};
