import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { orderAccounts } from './index.normalize';

export const fetchAccountsInit = () => ({
    type: Actions.ACCOUNTS_FETCH_INIT,
});

export const fetchAccounts = getState => {
    const {
        clientState: { client },
    } = getState();

    return fetch(ENDPOINTS.GET_ACCOUNTS.replace(':userId', client && client.UserId), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(orderAccounts);
};

export const fetchAccountsSuccess = accounts => ({
    type: Actions.ACCOUNTS_FETCH_SUCCESS,
    accounts,
});

export const fetchAccountsError = error => ({
    type: Actions.ACCOUNTS_FETCH_ERROR,
    error: error.message,
});
