import { responseToFile } from '../../../Utils/Parsers';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { Actions } from './index.constants';
import { normalizeFutureReleases } from './index.normalize';

export const fetchFutureReleasesInit = account => ({
    type: Actions.FETCH_FUTURE_RELEASES_INIT,
    account,
});

export function fetchFutureReleases(account) {
    const url = ENDPOINTS.GET_FUTURE_RELEASES_EXTRACT.replace(':accountNumber', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(futureReleases => normalizeFutureReleases(futureReleases));
}

export const fetchFutureReleasesSuccess = ({
    futureReleases,
    availableBalance,
    projectedBalance,
    dPlusInfo,
    updatedIn,
}) => {
    return {
        type: Actions.FETCH_FUTURE_RELEASES_SUCCESS,
        futureReleases,
        availableBalance,
        projectedBalance,
        dPlusInfo,
        updatedIn,
    };
};

export const fetchFutureReleasesError = error => ({
    type: Actions.FETCH_FUTURE_RELEASES_ERROR,
    error: error.message,
});

export const fetchFutureReleasesReset = () => ({
    type: Actions.FETCH_FUTURE_RELEASES_RESET,
});

export const mountFutureReleasesPdfUrl = account =>
    ENDPOINTS.GET_FUTURE_RELEASES_PDF.replace(':accountNumber', account);

export async function fetchFutureReleasesPdf(account) {
    const url = mountFutureReleasesPdfUrl(account);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = `extrato_lancamentos_futuros.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchFutureReleaseSendToEmail = (payload, setVisibleSendToEmail) => {
    fetch(ENDPOINTS.POST_EMAIL_FUTURE_RELEASES, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
        .then(() => {
            setVisibleSendToEmail(true);
        })
        .catch(() => {
            setVisibleSendToEmail(false);
        });
};
