import styled from 'styled-components';
import { FormGroup } from '@terrainvest/react-components';

export const StyledDocuments = styled.div``;

export const StyledFormGroup = styled(FormGroup)`
    justify-content: space-between;

    & > * {
        flex: 1 1 0;
    }
`;

export const StyledHelperImage = styled.img`
    max-width: calc(100vw - 20px);
    max-height: calc(100vw * 0.7);
    width: 475px;
    height: 330px;
`;
