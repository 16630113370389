import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchCollateralAssets,
    fetchCollateralSuccess,
    fetchCollateralError,
    fetchCollateralIntradayAssets,
    fetchCollateralIntradaySuccess,
    fetchCollateralIntradayError,
} from './index.actions';

export const initialState = {
    offers: null,
    loading: false,
    error: null,
    offersIntraday: null,
    loadingIntraday: false,
    errorIntraday: null,
};

export const investmentCollateralReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_MARGIN_GUARANTEES_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchCollateralAssets, {
                    successActionCreator: fetchCollateralSuccess,
                    failActionCreator: fetchCollateralError,
                    args: [action.accountNumber],
                }),
            );

        case Actions.FETCH_MARGIN_GUARANTEES_SUCCESS:
            return { ...state, offers: action.offers, loading: false, error: false };

        case Actions.FETCH_MARGIN_GUARANTEES_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_INIT:
            return loop(
                { ...state, loadingIntraday: true },
                Cmd.run(fetchCollateralIntradayAssets, {
                    successActionCreator: fetchCollateralIntradaySuccess,
                    failActionCreator: fetchCollateralIntradayError,
                    args: [action.accountNumber],
                }),
            );

        case Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_SUCCESS:
            return {
                ...state,
                offersIntraday: action.offers,
                loadingIntraday: false,
                errorIntraday: false,
            };

        case Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_ERROR:
            return { ...state, errorIntraday: action.error, loadingIntraday: false };

        default:
            return state;
    }
};
