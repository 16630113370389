import * as React from 'react';
import * as PropTypes from 'prop-types';
import { InputText, InputNumber, InputCep, Select } from '@terrainvest/react-components';

import { useFormikContext } from 'formik';

import { getAddress } from '../../../Redux/Common/index.actions';
import { CommonPrefixes } from '../../../Redux/Common/index.constants';
import { StyledCol } from '../Common/GridStyles';

import {
    validateRequiredField,
    validateCEP,
    validateFields,
    validateLength,
    isValidCEPFormat,
} from '../index.validators';
import { fieldLengthLimits } from '../index.settings';

import { Field, SelectField, SelectCityField } from '../Form/Field';

const AddressInput = ({ preffix: id }) => {
    const { setFieldValue } = useFormikContext();
    const [loading, setLoading] = React.useState(false);
    const refNumber = React.useRef();
    const refMainAddress = React.useRef();

    const idHasDotNotation = (value: string) => {
        return value.includes('.');
    };

    const preffix = id && !idHasDotNotation(id) ? `${id}.` : id;

    const isIncomingChangeDistinct = (previous, incoming) => {
        return previous !== incoming;
    };

    const setDelayedFocus = (inputRef, timeout = 500) => {
        setTimeout(() => inputRef.current.focus(), timeout);
    };

    const handleGetAddressFail = () => {
        setDelayedFocus(refMainAddress);
        setLoading(false);
    };

    const handleGetAddressSuccess = data => {
        setFieldValue(`${preffix}MainAddress` as never, data.MainAddress);
        setFieldValue(`${preffix}StateId` as never, data.StateId);
        setFieldValue(`${preffix}CityId` as never, data.City);
        setFieldValue(`${preffix}District` as never, data.District);

        setFieldValue(`${preffix}Number` as never, '', false);
        setFieldValue(`${preffix}SecondaryAddress` as never, '', false);

        setDelayedFocus(refNumber);
        setLoading(false);
    };

    const handlePostalCodeChanged = (inputProps, event) => {
        const target = { ...event.target };
        const previousValue = inputProps.defaultValue;
        const incomingValue = target.checked || target.value;

        if (!isIncomingChangeDistinct(previousValue, incomingValue) || validateCEP(incomingValue)) {
            return;
        }

        setLoading(true);

        getAddress({ code: incomingValue.replace(/\D/g, '') })
            .then(response => response.json())
            .then(handleGetAddressSuccess)
            .catch(handleGetAddressFail)
            .finally(() => {
                setFieldValue(`${preffix}PostalCode` as never, incomingValue);
            });
    };

    return (
        <>
            <StyledCol xl={2} md={4} sm={12}>
                <Field name={`${preffix}PostalCode`} validate={isValidCEPFormat}>
                    {({ inputProps }) => (
                        <InputCep
                            {...inputProps}
                            label="CEP"
                            fullWidth
                            onChange={event => handlePostalCodeChanged(inputProps, event)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol xl={5} md={8} sm={12}>
                <Field
                    name={`${preffix}MainAddress`}
                    loading={loading}
                    validate={value => validateFields('address', value)}
                >
                    {({ inputProps }) => (
                        <InputText
                            inputRef={refMainAddress}
                            {...inputProps}
                            label="Endereço"
                            fullWidth
                            maxLength={fieldLengthLimits.address}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol xl={2} md={6} sm={12}>
                <Field
                    name={`${preffix}Number`}
                    loading={loading}
                    validate={value => validateFields('addressNumber', value)}
                >
                    {({ inputProps }) => (
                        <InputNumber
                            inputRef={refNumber}
                            {...inputProps}
                            label="Número"
                            fullWidth
                            maxLength={fieldLengthLimits.addressNumber}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol xl={3} md={6} sm={12}>
                <Field
                    loading={loading}
                    name={`${preffix}SecondaryAddress`}
                    validate={value => validateLength('addressComplement', value)}
                >
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Complemento"
                            fullWidth
                            maxLength={fieldLengthLimits.addressComplement}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={4} sm={12}>
                <SelectField
                    name={`${preffix}StateId`}
                    commonPrefix={CommonPrefixes.States}
                    validate={validateRequiredField}
                    loading={loading}
                >
                    {({ field, meta, onChange, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Estado"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectField>
            </StyledCol>
            <StyledCol md={4} sm={12}>
                <SelectCityField
                    name={`${preffix}CityId`}
                    bindTo={`${preffix}StateId`}
                    loading={loading}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Cidade"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChange}
                        />
                    )}
                </SelectCityField>
            </StyledCol>
            <StyledCol md={4} sm={12}>
                <Field
                    name={`${preffix}District`}
                    loading={loading}
                    validate={value => validateFields('addressNeighborhood', value)}
                >
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Bairro"
                            fullWidth
                            maxLength={fieldLengthLimits.addressNeighborhood}
                        />
                    )}
                </Field>
            </StyledCol>
        </>
    );
};

AddressInput.propTypes = {
    preffix: PropTypes.string,
};

AddressInput.defaultProps = {
    preffix: '',
};

export default AddressInput;
