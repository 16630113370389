import { SectionProps, SubSectionProps } from '../../View/SideBarNew/SideNav/index.types';
import { Homebroker } from '../../View/SideBarNew/Homebroker';
import YoutubeTerra from '../../View/SideBarNew/YoutubeTerra';

export const Actions = {
    FETCH_PARTNER_FUNCTIONALITY_INIT: 'FETCH_PARTNER_FUNCTIONALITY_INIT',
    FETCH_PARTNER_FUNCTIONALITY_SUCCESS: 'FETCH_PARTNER_FUNCTIONALITY_SUCCESS',
    FETCH_PARTNER_FUNCTIONALITY_ERROR: 'FETCH_PARTNER_FUNCTIONALITY_ERROR',
    FETCH_PARTNER_BUTTONS_INIT: 'FETCH_PARTNER_BUTTONS_INIT',
    FETCH_PARTNER_BUTTONS_SUCCESS: 'FETCH_PARTNER_BUTTONS_SUCCESS',
    FETCH_PARTNER_BUTTONS_ERROR: 'FETCH_PARTNER_BUTTONS_ERROR',
    FETCH_PARTNER_HIGHLIGHTS_INIT: 'FETCH_PARTNER_HIGHLIGHTS_INIT',
    FETCH_PARTNER_HIGHLIGHTS_SUCCESS: 'FETCH_PARTNER_HIGHLIGHTS_SUCCESS',
    FETCH_PARTNER_HIGHLIGHTS_ERROR: 'FETCH_PARTNER_HIGHLIGHTS_ERROR',
};

const myAccountSubSections: Array<SubSectionProps> = [
    { title: 'Minha Carteira', path: 'carteira', items: [] },
    { title: 'Extratos', path: 'extratos', items: [] },
    {
        title: 'Transferências',
        path: 'transferencia',
        items: [],
        diffTitle: 'Transferência',
    },
    {
        title: 'Informe de Rendimentos',
        path: 'informe-rendimentos',
        items: [],
    },
];

const investSubSections: Array<SubSectionProps> = [
    {
        title: 'Renda Fixa',
        items: [
            { title: 'CDB, LC, LCA, e LCI', path: '' },
            { title: 'CRI e CRA', path: '' },
            { title: 'Debênture', path: '' },
            { title: 'Tesouro Direto', path: 'tesouro-direto' },
            { title: 'Letra Financeira', path: '' },
        ],
        path: 'renda-fixa',
        otherProps: { bold: true },
    },
    {
        title: 'Fundos de Investimento',
        items: [
            { title: 'Ações', path: '' },
            { title: 'Fundo Cambial', path: '' },
            { title: 'Fundos de Renda Fixa', path: '' },
            { title: 'Multimercados', path: '' },
        ],
        path: 'fundos',
        diffTitle: 'Fundos',
        otherProps: { bold: true },
    },
    {
        title: 'Renda variável',
        items: [
            { title: 'Ações', path: '' },
            { title: 'BDRs', path: '' },
            { title: 'ETFs', path: '' },
            {
                title: 'Análises Recomendações',
                diffTitle: 'Relatórios/Recomendações',
                path: 'analises-recomendacoes',
            },
            { title: 'Custódia Remunerada', path: 'custodia-remunerada' },
            { title: 'Subscrições', path: 'subscricao', diffTitle: 'Subscrição' },
            {
                title: 'Carteiras Recomendadas',
                path: 'carteiras-recomendadas',
            },
        ],
        otherProps: { bold: true },
    },
    {
        title: 'Trading',
        items: [
            { title: 'Ajuste de limites', path: 'limites', diffTitle: 'Ajustar Limites' },
            { title: 'Fiança Varejo', path: 'fianca-varejo' },
            { title: 'Plataformas', path: 'plataformas' },
            { title: 'Retail Liquidity Provider (RLP)', path: 'rlp', diffTitle: 'RLP' },
        ],
        otherProps: { bold: true, primary: true },
    },
    {
        title: 'Previdência Privada',
        items: [],
        path: 'previdencia-privada',
        otherProps: { bold: true },
    },
    {
        title: 'Seguros',
        items: [],
        path: 'seguros',
        otherProps: { bold: true },
        diffTitle: 'Seguros',
    },
    {
        title: 'Ofertas Públicas',
        items: [
            { title: 'IPOs', path: '' },
            { title: 'Follow-ons', path: '' },
        ],
        path: 'ofertas-publicas',
        otherProps: { bold: true },
    },

    {
        title: 'Produtos Estruturados',
        items: [],
        path: 'produtos-estruturados',
        otherProps: { bold: true },
    },

    {
        title: 'Pagamentos',
        items: [],
        otherProps: { bold: true, soon: true },
    },
    {
        title: 'Câmbio',
        items: [],
        otherProps: { bold: true, soon: true },
    },
];

export const sideNavSections: Array<SectionProps> = [
    {
        order: 1,
        title: 'Home Broker',
        icon: ['far', 'chart-bar'],
        hideMobile: true,
        otherProps: { bold: true, primary: true, customComponent: Homebroker },
        subSections: [],
    },
    {
        order: 2,
        title: 'Minha Conta',
        icon: ['far', 'user'],
        hideMobile: false,
        subSections: myAccountSubSections,
    },
    {
        order: 3,
        title: 'Investir',
        icon: ['far', 'chart-mixed'],
        hideMobile: false,
        subSections: investSubSections,
    },
    {
        order: 4,
        title: 'Educacional',
        icon: ['far', 'graduation-cap'],
        hideMobile: false,
        subSections: [
            { title: 'Educacional', items: [], otherProps: { customComponent: YoutubeTerra } },
        ],
    },
];
