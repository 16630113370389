export const Actions = {
    FETCH_BROKERAGENOTE_DATES_INIT: 'BROKERAGENOTE_DATES_FETCH_INIT',
    FETCH_BROKERAGENOTE_DATES_SUCCESS: 'BROKERAGENOTE_DATES_FETCH_SUCCESS',
    FETCH_BROKERAGENOTE_DATES_ERROR: 'BROKERAGENOTE_DATES_FETCH_ERROR',
    FETCH_BROKERAGENOTE_DATES_RESET: 'BROKERAGENOTE_DATES_FETCH_RESET',
    UPDATE_DATES_AFTER_REQUEST: 'UPDATE_DATES_AFTER_REQUEST',
};

export interface YearProps {
    year: string;
    months: Array<string>;
}
