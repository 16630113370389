const accountTypeIndividual = 0;

export const normalizeParams = params => {
    const accountType = parseInt(params.AccountType, 10);

    const coOwnerFields =
        accountType === accountTypeIndividual
            ? {}
            : { nameCoOwner: params.NameCoOwner, cpfCoOwner: params.CpfCoOwner };

    return {
        accountType,
        accountDigit: params.AccountDigit,
        accountNumber: params.AccountNumber,
        agencyNumber: params.AgencyNumber,
        bankNumber: params.BankNumber,
        electronicCheck: params.electronicCheck,
        ...coOwnerFields,
    };
};
