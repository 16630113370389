import * as React from 'react';
import * as PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import { RootState } from '../../../store';
import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import ProcuratorInput from './ProcuratorInput';
import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';
import { addNewEmptyProcurator, removeProcuratorByIndex } from './index.model';

export const ProcuratorsInfo = ({ isCompany }) => {
    const formikContext = useFormikContext<any>();
    const { values } = formikContext;
    const { ClientId } = useSelector((state: RootState) => state.clientState.client);

    const handleAddProcurator = () => {
        addNewEmptyProcurator(formikContext, ClientId);
    };

    const handleRemoveProcurator = index => {
        removeProcuratorByIndex(formikContext, index);
    };

    return (
        <>
            {values && values.AuthorizesOrdersByProcurator && (
                <FormCard
                    id={FORM_SECTIONS.PROCURATORS_INFO.ID}
                    title={FORM_SECTIONS.PROCURATORS_INFO.NAME}
                    subTitle={FORM_SECTIONS.PROCURATORS_INFO.DETAILS}
                >
                    {values &&
                        values.Procurators &&
                        values.Procurators.map((procurator, index) => (
                            <ProcuratorInput
                                key={`${procurator.ProcuratorId || procurator.key}-${index}`}
                                index={index}
                                onRemove={handleRemoveProcurator}
                                isCompany={isCompany}
                            />
                        ))}

                    {values && (!values.Procurators || !values.Procurators.length) && (
                        <>
                            <Separator />
                            <FeedbackNote
                                color="warn"
                                message="Você precisa adicionar procuradores para continuar."
                            />
                            <Separator />
                        </>
                    )}

                    <Separator />
                    <Button type="button" fullWidth secondary onClick={handleAddProcurator}>
                        <Icon icon={['fas', 'plus']} padRight size="xs" />
                        ADICIONAR PROCURADOR
                    </Button>
                </FormCard>
            )}
        </>
    );
};

ProcuratorsInfo.propTypes = {
    isCompany: PropTypes.bool.isRequired,
};

export default ProcuratorsInfo;
