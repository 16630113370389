import * as React from 'react';
import PlainTemplate from '../../Template/PlainTemplate';
import useWindowSize from '../../Utils/Hooks/useWindowSize';

import { GlobalNotification } from '../Common/GlobalNotification';

import LoginSide from './LoginSide';
import PartnerSide from './PartnerSide';

import background1 from './assets/BG1.jpg';
import background2 from './assets/BG2.jpg';
import background3 from './assets/BG3.jpg';
import background4 from './assets/BG4.jpg';

import * as S from './index.styles';
import PartnerLogo from './PartnerSide/PartnerLogo';
import AppDownload from './PartnerSide/AppDownload';

const breakpoint = 768;
const allBackgroundImages = [background1, background2, background3, background4];

const LoginNew = () => {
    const windowSize = useWindowSize();

    const [showAppDownload, setShowAppDownload] = React.useState(true);
    const [isMobile, setIsMobile] = React.useState(windowSize && windowSize.width < breakpoint);

    React.useEffect(() => {
        setIsMobile(windowSize.width < breakpoint);
    }, [windowSize]);

    const desktopTemplate = () => (
        <PlainTemplate
            data-testid="plain-template"
            content={
                <S.StyledWrapper backgroundImage={background}>
                    <PartnerSide />
                    <LoginSide isMainPage={setShowAppDownload} />
                </S.StyledWrapper>
            }
        />
    );

    const mobileTemplate = () => (
        <PlainTemplate
            data-testid="plain-template-mobile"
            content={
                <S.StyledWrapper backgroundImage={background}>
                    <PartnerLogo />
                    <LoginSide isMainPage={setShowAppDownload} />
                    {showAppDownload ? <AppDownload /> : <div style={{ height: '20vh' }} />}
                </S.StyledWrapper>
            }
        />
    );

    const renderLogin = () => (
        <>
            <GlobalNotification />
            {isMobile ? mobileTemplate() : desktopTemplate()}
        </>
    );

    const background = React.useMemo(
        () => allBackgroundImages[Math.floor(Math.random() * allBackgroundImages.length)],
        [],
    );

    const login = React.useMemo(renderLogin, [isMobile, showAppDownload]);

    return <>{login}</>;
};

export default LoginNew;
