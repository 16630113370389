import * as React from 'react';
import { Text } from '@terrainvest/react-components';
import {
    StyledPasswordTip,
    StyledTitle,
    StyledRules,
    StyledRule,
    StyledIcon,
} from './index.styles';

const renderIconProps = ruleValidation => {
    if (ruleValidation === true) return { icon: 'check', size: 'sm' };
    if (ruleValidation === false) return { icon: 'xmark', size: 'sm' };

    return { icon: 'circle', size: 'xs' };
};

export const PasswordTip = ({ rules, title, ...otherProps }) => {
    return (
        <StyledPasswordTip {...otherProps}>
            <StyledTitle>
                <Text large>{title}</Text>
            </StyledTitle>
            <StyledRules>
                {rules &&
                    rules.map(rule => (
                        <StyledRule key={rule.message} hasPassedRule={rule.routine()}>
                            <StyledIcon {...renderIconProps(rule.routine())} />
                            <Text>{rule.message}</Text>
                        </StyledRule>
                    ))}
            </StyledRules>
        </StyledPasswordTip>
    );
};

export default PasswordTip;
