import * as React from 'react';
import { StyledSideBarAndHeader, StyledCol } from './index.styles';
import { DueDateNotification } from '../../View/Common/DueDateNotification';

import { StyledContainerNoPadd, StyledRowNoPadd } from '../../View/Common/Grid';

export interface TemplateWithSidebarAndHeaderProps {
    header: React.ReactNode;
    sidebar: React.ReactNode;
    content: React.ReactNode;
}

const TemplateWithSidebarAndHeader = ({
    sidebar,
    content,
    header,
    ...otherProps
}: TemplateWithSidebarAndHeaderProps) => {
    return (
        <StyledContainerNoPadd fluid {...otherProps}>
            <StyledRowNoPadd>
                <StyledSideBarAndHeader>
                    {header}
                    {sidebar}
                </StyledSideBarAndHeader>
                <StyledCol col={12}>
                    <DueDateNotification />
                    {content}
                </StyledCol>
            </StyledRowNoPadd>
        </StyledContainerNoPadd>
    );
};

export default TemplateWithSidebarAndHeader;
