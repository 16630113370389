import moment from 'moment';

export const getInnerProperty = (obj, property = '') => {
    if (!obj) {
        return null;
    }
    const propertyArray = property.split('.');
    if (property in obj) {
        return obj[property];
    }
    return getInnerProperty(obj[propertyArray[0]], propertyArray.slice(1).join(''));
};

export const setInnerProperty = (obj, property, value) => {
    if (!obj) {
        return null;
    }
    const propertyArray = property.split('.');
    if (propertyArray.length > 1) {
        const innerProperty = propertyArray.pop();
        const objRoot = getInnerProperty(obj, propertyArray.join('.'));
        if (objRoot) {
            objRoot[innerProperty] = value;
        }
    } else {
        /* eslint-disable-next-line no-param-reassign */
        obj[property] = value;
    }
    return true;
};

export const monthDiff = (dateString: string) => {
    let months = 0;
    const now = new Date();
    const date = new Date(dateString);
    months = (now.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth() + 1;
    months += date.getMonth();
    return months <= 0 ? 0 : months;
};

export const isCadastralUpdate = client => client && monthDiff(client.DueDate) > 23;

const isExpired = ({ Validity }) => {
    return moment(Validity, moment.ISO_8601, true).isBefore(moment.now());
};

export const needsSuitabilityUpdate = suitability => {
    const userHasNoSuitabilityProfile = !suitability || (suitability && suitability.Id === 0);
    const userHasSuitabilityAndValidity = suitability && suitability.Validity;
    const userHasExpiredValidity = userHasSuitabilityAndValidity && isExpired(suitability);

    return userHasNoSuitabilityProfile || userHasExpiredValidity;
};

export const needsAgreementUpdate = agreement =>
    new Date() > moment(agreement.ExpirationDate).toDate();

export const retry = async (func, { delay = 100, tries = 3 } = {}) => {
    try {
        return await Promise.resolve(func());
    } catch (error) {
        if (tries === 0) {
            throw error;
        }

        return retry(func, { delay: delay * 2, tries: tries - 1 });
    }
};

export default retry;

export * from './index.app-msg';
