import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { fetchAuthInit } from '../../../Redux/Auth';
import { fetchSuitabilityProfileInit } from '../../../Redux/Suitability';
import { fetchClientAgreementByTypeInit } from '../../../Redux/Agreements';
import { helpLink } from '../../../Utils/General/CommonInfo';

import UserBlocked from '../RedirectsPostLogin/UserBlocked';
import AccountSelection from '../RedirectsPostLogin/AccountSelection';
import RedirectLoginInAnalysis from '../RedirectsPostLogin/RedirectLoginInAnalysis';
import RedirectUnfinishedSignup from '../RedirectsPostLogin/RedirectUnfinishedSignup';

import LoginNotifications from './LoginNotifications';
import ForgotPassword from './ForgotPassword';
import LoginForm from './LoginForm';

import * as S from './index.styles';
import { forgotPasswordPath, getNextPage, PAGES } from './index.constants';
import { FeedbackWrongPartner } from './Feedback';

const LoginSide = ({ isMainPage }) => {
    const dispatch = useDispatch();

    const [page, setPage] = React.useState(PAGES.PAGE_LOGIN);
    const [loading, setLoading] = React.useState(false);
    const [firstEntry, setFirstEntry] = React.useState(true);
    const [partnerUrl, setPartnerUrl] = React.useState('');

    const { auth, loading: authLoading } = useSelector((state: RootState) => state.authState);

    const { client, loading: clientLoading } = useSelector((state: RootState) => state.clientState);

    const { profile: suitabilityProfile, loading: suitabilityLoading } = useSelector(
        (state: RootState) => state.suitabilityProfileState,
    );

    const { agreementByType: agreement, loadingByType: agreementLoading } = useSelector(
        (state: RootState) => state.agreementsState,
    );

    const onSubmitLogin = (username: string, password: string, partnerId: string) => {
        setLoading(true);
        dispatch(
            fetchAuthInit({
                username,
                password,
                partner_id: partnerId,
            }),
        );
    };

    const onWrongPartner = (partnerRedirectLink: string) => {
        setPartnerUrl(partnerRedirectLink);
        setPage(PAGES.PAGE_WRONG_PARTNER);
    };

    const stopLoading = () => setLoading(false);

    React.useEffect(() => {
        if (firstEntry) return;
        if (!auth || !client) {
            return;
        }

        if (!client.Accounts || !client.Accounts.length) {
            setPage(getNextPage(client));
            return;
        }

        if (!suitabilityProfile && !suitabilityLoading) {
            dispatch(fetchSuitabilityProfileInit(client.ClientId));
        }

        if (!agreement && !agreementLoading) {
            dispatch(fetchClientAgreementByTypeInit(client));
        }
    }, [auth, client, suitabilityProfile, suitabilityLoading, agreement, agreementLoading]);

    React.useEffect(() => {
        if (firstEntry) return;
        if (authLoading || !auth) {
            return;
        }
        if (clientLoading || !client) {
            return;
        }
        if (suitabilityLoading || !suitabilityProfile) {
            return;
        }
        if (agreementLoading || !agreement) {
            return;
        }
        stopLoading();
        setPage(getNextPage(client));
    }, [
        auth,
        authLoading,
        client,
        clientLoading,
        suitabilityProfile,
        suitabilityLoading,
        agreement,
        agreementLoading,
    ]);

    React.useEffect(() => {
        if (window.location.hash.includes(forgotPasswordPath)) {
            window.location.hash = '';
            setPage(PAGES.PAGE_FORGOT_PASSWORD);
        }
        setFirstEntry(false);
    }, []);

    React.useEffect(() => {
        isMainPage(page === PAGES.PAGE_LOGIN);
    }, [page]);

    const renderPage = () => {
        switch (page) {
            case PAGES.PAGE_SELECT_ACCOUNT:
                return <AccountSelection clientData={client} />;

            case PAGES.PAGE_UNFINISHED_LOGIN:
                return <RedirectUnfinishedSignup clientData={client} />;

            case PAGES.PAGE_LOGIN_IN_ANALYSIS:
                return <RedirectLoginInAnalysis />;

            case PAGES.PAGE_FORGOT_PASSWORD:
                return (
                    <ForgotPassword
                        backToLogin={() => setPage(PAGES.PAGE_LOGIN)}
                        wrongPartner={onWrongPartner}
                    />
                );

            case PAGES.PAGE_WRONG_PARTNER:
                return <FeedbackWrongPartner callBack={() => window.open(partnerUrl, '_self')} />;

            case PAGES.PAGE_USER_BLOCKED:
                return (
                    <UserBlocked
                        backToLogin={() => setPage(PAGES.PAGE_LOGIN)}
                        unlockUser={() => setPage(PAGES.PAGE_FORGOT_PASSWORD)}
                    />
                );

            case PAGES.PAGE_LOGIN:
            default:
                return (
                    <LoginForm
                        onSubmit={onSubmitLogin}
                        forgotPassword={() => setPage(PAGES.PAGE_FORGOT_PASSWORD)}
                        isLoading={loading}
                    />
                );
        }
    };

    const pageMemoized = React.useMemo(renderPage, [page, loading]);

    return (
        <S.StyledLoginWrapper>
            <LoginNotifications
                stopLoading={stopLoading}
                userBlocked={() => setPage(PAGES.PAGE_USER_BLOCKED)}
                wrongPartner={onWrongPartner}
            />
            <S.StyledHelpButton
                data-testid="button-help"
                onClick={() => window.open(helpLink, '_blank')}
            >
                <S.StyledHelpButtonText>Precisa de ajuda?</S.StyledHelpButtonText>
                <S.StyledHelpButtonIcon icon={['far', 'headset']} />
            </S.StyledHelpButton>

            <S.StyledPartnerWrapper>{pageMemoized}</S.StyledPartnerWrapper>
        </S.StyledLoginWrapper>
    );
};

export default LoginSide;
