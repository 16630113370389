export const Actions = {
    FETCH_EXTRACTS_INIT: 'EXTRACTS_FETCH_INIT',
    FETCH_EXTRACTS_SUCCESS: 'EXTRACTS_FETCH_SUCCESS',
    FETCH_EXTRACTS_ERROR: 'EXTRACTS_FETCH_ERROR',
    FETCH_EXTRACTS_RESET: 'EXTRACTS_FETCH_RESET',
    CHANGE_EXTRACTS_TAB_INIT: 'CHANGE_EXTRACTS_TAB_INIT',
    CHANGE_EXTRACTS_TAB_SUCCESS: 'CHANGE_EXTRACTS_TAB_SUCCESS',
    CHANGE_EXTRACTS_TAB_ERROR: 'CHANGE_EXTRACTS_TAB_ERROR',
    CHANGE_EXTRACTS_TAB_RESET: 'CHANGE_EXTRACTS_TAB_RESET',
};

export enum INNER_TABS_NAMES {
    tab1 = 'Últimas corretagens',
    tab2 = 'Minhas solicitações',
}
