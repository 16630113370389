import * as React from 'react';

import SideNavSubSections from '../SideNavSubSections';
import { SideNavContext } from '../SideNavContext';

import { SideNavSectionProps } from './index.types';
import * as S from './index.styles';

const SideNavSection = ({
    first,
    section,
    sectionOpen,
    singleSection,
    handleOpenSection,
}: SideNavSectionProps) => {
    const { mobile } = React.useContext(SideNavContext);

    const rightIcon = sectionOpen ? 'chevron-up' : 'chevron-down';
    const hasSubSections = !!section.subSections && !!section.subSections.length;

    const handleClick = () => handleOpenSection(section.title);

    let sectionTitle = <S.StyledTitle>{section.title}</S.StyledTitle>;

    if (mobile && section.hideMobile) return <></>;

    if (section.otherProps && section.otherProps.customComponent)
        sectionTitle = <section.otherProps.customComponent sectionInfo={section} />;

    return (
        <S.SectionWrapper first={first}>
            <S.WrapperTop {...section.otherProps}>
                <S.StyledIcon icon={section.icon} padLeft />
                {sectionTitle}
                {hasSubSections && !singleSection && (
                    <S.StyledIcon icon={['far', rightIcon]} onClick={handleClick} />
                )}
            </S.WrapperTop>
            {hasSubSections && (
                <SideNavSubSections
                    subSections={section.subSections}
                    open={singleSection || sectionOpen}
                />
            )}
        </S.SectionWrapper>
    );
};

export default SideNavSection;
