/* eslint-disable no-bitwise */

/**
 * Generates a randomized GUID.
 * Intended to use as "key" prop for runtime-created instances passed to React Array nodes.
 * */
export const getGUID = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    });
};

export default getGUID;
