import * as React from 'react';

import FeedbackStructureWithGraphics from '../Common/FeedbackStructureWithGraphics';

import * as SessionClosed from './Assets/session-closed.png';

import { StyledTitle, StyledMessage } from './index.styles';

export const HomebrokerExpiredSession = () => (
    <FeedbackStructureWithGraphics
        title={<StyledTitle>Sessão Expirada</StyledTitle>}
        message={
            <StyledMessage>
                Sua sessão no home broker foi expirada. <br />
                Faça o login novamente.
            </StyledMessage>
        }
        graphics={SessionClosed}
        mobileGraphicsOnTop
    />
);

export default HomebrokerExpiredSession;
