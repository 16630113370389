import * as React from 'react';
import { Text, Link } from '@terrainvest/react-components';
import { StyledFooter } from './index.styles';

const currentYear = new Date().getFullYear();

export interface DisclaimerProps {
    textColor: string;
}

const Disclaimer = ({ textColor }: DisclaimerProps) => (
    <StyledFooter textColor={textColor}>
        <Text>
            {`© ${currentYear} `}
            Terra Investimentos DTVM. Todos os direitos reservados.
        </Text>
        <br />
        <Text>
            Toda transmissão de ordem pela internet está sujeita a interrupções ou atrasos, podendo
            impedir ou prejudicar o envio de ordens ou a recepção de informações relacionadas a: (a)
            status de ordens; (b) posições de custódia, de operações e de limites; e (c) cotação de
            ativos nos termos da instrução CVM 612.
        </Text>
        <Text>
            A Terra Investimentos é remunerada pela distribuição do produto. Para maiores detalhes,{' '}
            <Link href="https://www.terrainvestimentos.com.br/compliance" target="_blank">
                clique aqui
            </Link>{' '}
            e consulte o documento{' '}
            <strong>Remuneração na Distribuição de Produtos de Investimentos</strong>.
        </Text>
    </StyledFooter>
);

export default Disclaimer;
