import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Text, H3 } from '@terrainvest/react-components';

import { NAV_MOBILE_MAX_WIDTH, NAV_WIDTH_DEFAULT } from './index.constants';

export const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    margin-bottom: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
`;

const getNavWidth = (open, mobile) => {
    if (!mobile) return NAV_WIDTH_DEFAULT;
    return open ? NAV_MOBILE_MAX_WIDTH : '0';
};

export const StyledNav = styled.nav`
    position: fixed;
    top: ${({ theme }) => theme.global.spaces.lowFrequencyMicrowave};
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: ${({ open, mobile }) => getNavWidth(open, mobile)};
    overflow: scroll;
    transition: ${({ theme }) => theme.components.header.transition} !important;
    padding: ${({ theme }) =>
        `${theme.global.spaces.highFrequencyXRay} ${theme.global.spaces.lowFrequencyUltraviolet}`};
    background-color: ${({ theme }) => theme.global.colors.mainBackground};
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    z-index: 800;

    ${media.max.md`
        top: ${({ theme }) => theme.global.spaces.highFrequencyRadio};
        left: -${({ open, mobile }) => getNavWidth(!open, mobile)};
        bottom: 60px;
    `}
    ${Text.Styles.StyledText} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor};
    }
    ${H3} {
        color: ${({ theme }) => theme.global.colors.backgroundTextColor};
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar:hover {
        display: block;
    }
`;
