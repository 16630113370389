import * as React from 'react';

import { Button, H2, Text } from '@terrainvest/react-components';

import { ENDPOINTS } from '../../Utils/Api';

import * as noPartner from './assets/no-partner.svg';
import * as terraLogo from './assets/terra-logo.svg';

import {
    StyledContainer,
    StyledContent,
    StyledFirstText,
    StyledInfoText,
    StyledLogo,
} from './index.styles';

const NoPartnerView = () => (
    <StyledContainer>
        <StyledLogo src={terraLogo} alt="terra-logo" />
        <StyledContent>
            <img src={noPartner} alt="no-partner" />
            <StyledInfoText>
                <H2>Erro ao localizar o parceiro</H2>
                <StyledFirstText>
                    Não conseguimos localizar o parceiro solicitado, verifique se o endereço está
                    correto.
                </StyledFirstText>
                <Text>Para acessar o Site da Terra clique no botão abaixo</Text>
            </StyledInfoText>
        </StyledContent>
        <Button
            outline
            fullWidth
            onClick={() => {
                const anchor = document.createElement('a');
                anchor.href = ENDPOINTS.TERRA_SITE;
                anchor.target = '_self';

                if (!(window as any).Cypress) {
                    anchor.click();
                }
            }}
        >
            ACESSAR SITE TERRA INVESTIMENTOS
        </Button>
    </StyledContainer>
);

export default NoPartnerView;
