import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';

import {
    normalizeRecommendationsActive,
    normalizeRecommendationActive,
    normalizeRecommendationAssets,
    normalizeRecommendationCharts,
} from './index.normalize';

export const fetchRecommendationInit = () => ({
    type: Actions.RECOMMENDATION_FETCH_INIT,
});

export const fetchRecommendation = async () => {
    const responses = await Promise.all([
        fetch(ENDPOINTS.GET_RECOMMENDATION, {
            method: 'GET',
        }),
        fetch(ENDPOINTS.GET_RECOMMENDATION_MINIMAL_APPLICATION, {
            method: 'GET',
        }),
    ]);
    return Promise.all(responses.map(response => response.json()));
};

export const fetchRecommendationSuccess = ([recommendation, { minimalApplication }]) => ({
    type: Actions.RECOMMENDATION_FETCH_SUCCESS,
    recommendation,
    minimalApplication,
});

export const fetchRecommendationError = error => ({
    type: Actions.RECOMMENDATION_FETCH_ERROR,
    error: error.message,
});

export const signRecommendation = data => {
    return fetch(ENDPOINTS.POST_RECOMMENDATION_SIGN, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const fetchAgreementFile = async id => {
    const url = ENDPOINTS.GET_AGREEMENT_FILE.replace(':agreementId', id);

    const response = await fetch(url, { method: 'GET' });

    const blob = new Blob([await response.blob()], { type: 'application/pdf' });
    const objUrl: string = URL.createObjectURL(blob);

    return objUrl;
};

export const fetchRecommendationActive = () => {
    const url = ENDPOINTS.GET_RECOMMENDATION_ACTIVE;
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeRecommendationsActive);
};

export const fetchRecommendationAssets = id => {
    const url = ENDPOINTS.GET_RECOMMENDATION_ASSETS.replace(':id', id);
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeRecommendationAssets);
};

export const fetchMarketStatus = () => {
    const url = ENDPOINTS.GET_MARKET_STATUS;
    return fetch(url, { method: 'GET' }).then(response => response.json());
};

export const fetchRecommendationCharts = id => {
    const url = ENDPOINTS.GET_RECOMMENDATION_CHARTS.replace(':id', id);
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeRecommendationCharts);
};

export const fetchPortfolioOptionsInit = () => ({
    type: Actions.FETCH_PORTFOLIO_OPTIONS_INIT,
});

export const fetchPortfolioOptions = () => {
    return fetchRecommendationActive();
};

export const fetchPortfolioOptionsSuccess = options => ({
    type: Actions.FETCH_PORTFOLIO_OPTIONS_SUCCESS,
    options,
});

export const fetchPortfolioOptionsError = error => ({
    type: Actions.FETCH_PORTFOLIO_OPTIONS_ERROR,
    error: error.message,
});

export const fetchPortfolioOption = id => {
    const url = ENDPOINTS.GET_RECOMMENDATION_BY_ID.replace(':id', id);

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeRecommendationActive);
};
