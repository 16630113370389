import styled, { css } from 'styled-components';
import { H1, Skeleton, Card } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

import { profileColors } from '../../Common/Colors';

export const profileIdToColor = id =>
    [
        'color: gray',
        `color: ${profileColors.conservative}`,
        `color: ${profileColors.moderate}`,
        `color: ${profileColors.risky}`,
    ][id > 0 && id < 4 ? id : 0];

export const StyledSuitability = styled.div`
    ${H1} {
        margin-top: 40px;
        margin-bottom: 16px;
    }
    #sub-title {
        margin-top: 20px;
        margin-bottom: 40px;
    }
`;

export const StyledSkeleton = styled(Skeleton)`
    ${media.min.md`
        max-width: 100vw;
    `}
    display: inline-block;
`;

export const StyledDetails = styled.div`
    & > p {
        display: inline-block;
    }
    & > :first-child {
        padding-right: 60px;
    }
`;

export const StyledProfileName = styled.span`
    ${props =>
        css`
            font-weight: 600;
            ${profileIdToColor(props.profile)}
        `}
`;

export const StyledCard = styled(Card)`
    width: 100%;
    margin-bottom: 18px;
    padding: 16px;
`;

export const StyledContainer = styled.div`
    margin-bottom: 18px;
`;
