import styled, { css } from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledSubSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${Text.Styles.StyledText} {
        ${({ theme, open }) => open && `margin: ${theme.global.spaces.gammaRay} 0;`};
    }
`;

const hasOnClickStyles = css`
    cursor: pointer;
    transition: color 0.4s;
    :hover {
        color: ${({ theme }) => theme.global.colors.primary} !important;
    }
`;

export const StyledSubSectionTitle = styled(Text)`
    font-size: ${({ theme }) => theme.global.typographyConfig.body.small.fontSize};
    ${({ onClick }) => !!onClick && hasOnClickStyles};
    ${({ theme, bold, primary }) =>
        css`
            ${primary && `color: ${theme.global.colors.primary} !important`};
            ${bold && `font-weight: ${theme.global.fontWeights.microwave} !important`};
        `};
`;

export const StyledSubSectionitem = styled(Text)`
    font-size: ${({ theme }) => theme.global.typographyConfig.body.small.fontSize};
    ${({ onClick }) => !!onClick && hasOnClickStyles};
`;

export const StyledSoon = styled(Text)`
    margin-left: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    padding: 1px 4px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.global.fontWeights.medium};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.global.colors.primary};
    color: white !important;
`;
