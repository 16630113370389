import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchDirectTreasuryAssets,
    fetchDirectTreasurySuccess,
    fetchDirectTreasuryError,
} from './index.actions';

export const initialState = {
    offers: null,
    loading: false,
    error: null,
};

export const investmentDirectTreasuryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_DIRECT_TREASURY_INIT:
            return loop(
                initialState,
                Cmd.run(fetchDirectTreasuryAssets, {
                    successActionCreator: fetchDirectTreasurySuccess,
                    failActionCreator: fetchDirectTreasuryError,
                }),
            );

        case Actions.FETCH_DIRECT_TREASURY_SUCCESS:
            return { ...state, offers: action.offers, loading: false, error: false };

        case Actions.FETCH_DIRECT_TREASURY_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
