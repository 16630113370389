import { Actions } from './index.constants';
import { normalizeOffers, normalizeOffer, normalizeHistory } from './index.normalize';
import { fetch, fetchFile, ENDPOINTS } from '../../Utils/Api';
import { responseToFile } from '../../Utils/Parsers';
import { fetchNoAuthorization } from '../../Utils/Api/fetchAWS';

export const fetchPublicOffersInit = () => ({
    type: Actions.FETCH_PUBLIC_OFFERS_INIT,
});

export const fetchPublicOffers = () => {
    return fetch(ENDPOINTS.GET_PUBLIC_OFFERS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchPublicOffersSuccess = offers => ({
    type: Actions.FETCH_PUBLIC_OFFERS_SUCCESS,
    offers,
});

export const fetchPublicOffersError = error => ({
    type: Actions.FETCH_PUBLIC_OFFERS_ERROR,
    error: error.message,
});

export const fetchPublicOffer = async (id: string) => {
    const url = ENDPOINTS.GET_PUBLIC_OFFER.replace(':id', id);
    try {
        const response = await fetch(url, { method: 'GET' });
        const offer = await response.json();
        return normalizeOffer(offer);
    } catch (error) {
        return error;
    }
};

export const bookPublicOffer = payload =>
    fetch(ENDPOINTS.POST_BOOK_PUBLIC_OFFER, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });

export const fetchRiskScienceTerm = ({ ClientId: clientId, AccountNumber: account }) => {
    const url = ENDPOINTS.PUBLIC_OFFERS_RISK_SCIENCE_TERM.replace(':clientId', clientId).replace(
        ':account',
        account,
    );
    return fetchFile(url, {
        method: 'GET',
    });
};

export const getTermFile = async (url, filename, useBaseUrl) => {
    const response = await fetchNoAuthorization(url, {
        method: 'GET',
        useBaseUrl,
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

export const fetchOfferHistoryInit = account => ({
    type: Actions.FETCH_OFFER_HISTORY_INIT,
    account,
});

export const fetchOfferHistory = account => {
    const url = ENDPOINTS.GET_OFFER_HISTORY.replace(':account', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeHistory);
};

export const fetchOfferHistorySuccess = offers => ({
    type: Actions.FETCH_OFFER_HISTORY_SUCCESS,
    offers,
});

export const fetchOfferHistoryError = error => ({
    type: Actions.FETCH_OFFER_HISTORY_ERROR,
    error: error.message,
});

export const cancelBookedOffer = payload =>
    fetch(ENDPOINTS.POST_CANCEL_PUBLIC_OFFER, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
