import { capitalizeAllWords } from '../../Utils/Parsers';
import { FiltersTypes, StatusToFilterProps, PensionRequestProps } from './index.types';

const options = {
    commonFilter: {
        fieldsToFilter: [
            'name',
            'admTax',
            'initialApplication',
            'rentabilityValueMonth',
            'rentabilityValue12Months',
            'suitabilityProfileDescription',
            'strategy',
        ],
    },
    statusFilter: {
        fieldsToFilter: ['strategyId'],
    },
};

export const getAllStrategies = (pensions: Array<PensionRequestProps>) => {
    const status = {};
    pensions.forEach(transfer => {
        if (!transfer.strategy) return;
        status[transfer.strategyId] = transfer.strategy;
    });
    const allStatus: Array<StatusToFilterProps> = Object.entries(status).map(
        ([value, text]: [string, string]) => ({
            value: parseInt(value, 10),
            text: capitalizeAllWords(text),
        }),
    );

    return allStatus;
};

const mountStringFromPensionByFields = (pension: PensionRequestProps, fields: Array<string>) => {
    return fields
        .map(field => pension[field], '')
        .join('-')
        .toLowerCase();
};

export const execFilter = (
    pensions: Array<PensionRequestProps>,
    filters: FiltersTypes,
): Array<PensionRequestProps> => {
    const { commonFilter, statusFilter } = filters;

    const pensionsFilteredByStatusId = statusFilter.length
        ? pensions.filter(transfer => statusFilter.includes(transfer.strategyId))
        : pensions;

    const commonFilterLower = commonFilter.toLowerCase();

    const filteredPensions = pensionsFilteredByStatusId.filter(transfer =>
        mountStringFromPensionByFields(transfer, options.commonFilter.fieldsToFilter).includes(
            commonFilterLower,
        ),
    );

    return filteredPensions;
};
