import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledText = styled(Text)`
    max-width: 130px;
    line-height: 30px;
    font-size: ${({ theme }) => theme.global.typographyConfig.body.small.fontSize};
    text-decoration: underline;
    cursor: pointer;
`;

export const Red = styled.span`
    color: red;
    font-weight: ${({ theme }) => theme.global.fontWeights.microwave};
    text-decoration: underline;
`;
