import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Button, Icon } from '@terrainvest/react-components';

import { RootState } from '../../../store';
import FormCard from '../Common/FormCard';
import { FORM_SECTIONS } from '../Form';

import Separator from '../../../Utils/General/index.separator';
import FeedbackNote from '../../Common/FeedbackNote';
import ControllerInput from './ControllerInput';
import { usePercentage, viewPercentage } from '../utils.percentage';
import * as S from './index.styles';

export const ControllersInfo = () => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { ClientId } = useSelector((state: RootState) => state.clientState.client);
    const { Controllers: ControllersValue } = values;

    const [percentageTotal, setPercentageTotal] = React.useState<string | number>(0);

    const percentSum =
        values && ControllersValue
            ? ControllersValue.reduce(
                  (sum, representative) => (representative ? representative.Percentage : 0) + sum,
                  0,
              )
            : 0;

    const handleRemoveController = index => {
        if (index >= -1) {
            const newControllers = [...ControllersValue];
            newControllers.splice(index, 1);

            setFieldValue('Controllers' as never, newControllers);
        }
    };

    const handleAdd = () => {
        const controllers = [
            ...(ControllersValue || []),
            {
                Id: `Controller-${Math.random()}`,
                ClientId,
                Name: '',
                CnpjId: '',
                Category: '',
            },
        ];

        setFieldValue('Controllers' as never, controllers);
    };

    usePercentage(ControllersValue, setPercentageTotal);

    return (
        <FormCard
            title={FORM_SECTIONS.CONTROLLERS.NAME}
            subTitle={FORM_SECTIONS.CONTROLLERS.DETAILS}
        >
            {values &&
                values.Controllers &&
                values.Controllers.map((controller, index) => (
                    <ControllerInput
                        key={`${controller.Id}-${index}`}
                        index={index}
                        hasError={percentSum !== 100}
                        onRemove={handleRemoveController}
                    />
                ))}
            {percentSum !== 100 && values.Controllers.length > 0 && (
                <S.StyledNotification type="error" visible={percentSum !== 100} icon={false}>
                    O somatório do percentual das Controladoras, Controladas ou Coligadas Deve ser
                    exatemente 100.
                </S.StyledNotification>
            )}
            {values && (!values.Controllers || !values.Controllers.length) && (
                <>
                    <Separator />
                    <FeedbackNote color="warn" message={FORM_SECTIONS.CONTROLLERS.EMPTY_FEEDBACK} />
                    <Separator />
                </>
            )}

            <Separator />
            {viewPercentage(percentageTotal)}
            <Button type="button" fullWidth secondary onClick={handleAdd}>
                ADICIONAR {FORM_SECTIONS.CONTROLLERS.NAME}
                <Icon icon={['fas', 'plus']} padLeft size="xs" />
            </Button>
        </FormCard>
    );
};

export default ControllersInfo;
