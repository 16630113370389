import { HighlightedText } from '@terrainvest/react-components';
import * as React from 'react';
import { useTheme } from 'styled-components';
import { StyledHR, StyledQuestionTitle } from './index.styles';

const QuestionTitle = ({ title, innerQuestion = false }) => {
    const theme = useTheme();

    return innerQuestion ? (
        <StyledQuestionTitle>{title}</StyledQuestionTitle>
    ) : (
        <>
            <HighlightedText asideColor={theme.global.colors.primary} title={title} />
            <StyledHR />
        </>
    );
};

export default QuestionTitle;
