import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../store';

import { StyledSkeleton } from '../Common/index.styles';

const SuitabilityAuthorizer = ({ children, allowRedirect, suitabilityRestriction }) => {
    const {
        minifiedProfile,
        loading: loadingMinifiedProfile,
        loadingPostSuitability,
    } = useSelector((state: RootState) => state.suitabilityProfileState);

    const [authorize, setAuthorize] = React.useState<boolean>();

    React.useEffect(() => {
        if (suitabilityRestriction !== 0) {
            if (loadingMinifiedProfile || !minifiedProfile || !minifiedProfile.UserProfile) {
                return;
            }

            setAuthorize(() => minifiedProfile.UserProfile >= suitabilityRestriction);
        }
    }, [minifiedProfile]);

    if (suitabilityRestriction === 0) {
        return <>{children}</>;
    }

    if ((loadingMinifiedProfile && !minifiedProfile) || loadingPostSuitability) {
        return <StyledSkeleton width={170} height={25} />;
    }

    if (authorize === true) {
        return <>{children}</>;
    }

    if (allowRedirect && authorize === false) {
        return <Redirect to="/app" />;
    }

    return null;
};

SuitabilityAuthorizer.propTypes = {
    children: PropTypes.node.isRequired,
    allowRedirect: PropTypes.bool,
    suitabilityRestriction: PropTypes.number,
};

SuitabilityAuthorizer.defaultProps = {
    allowRedirect: false,
    suitabilityRestriction: 0,
};

export default SuitabilityAuthorizer;
