import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    postClientAgreement,
    postClientAgreementSuccess,
    postClientAgreementError,
} from './index.actions';

export const initialState = {
    loading: false,
    error: null,
};

export const complianceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.COMPLIANCE_CLIENT_AGREEMENT_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(postClientAgreement, {
                    successActionCreator: postClientAgreementSuccess,
                    failActionCreator: postClientAgreementError,
                    args: [action.data],
                }),
            );
        case Actions.COMPLIANCE_CLIENT_AGREEMENT_SUCCESS:
            return { ...state, loading: false, error: false };
        case Actions.COMPLIANCE_CLIENT_AGREEMENT_ERROR:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};
