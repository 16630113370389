import styled, { css } from 'styled-components';
import { Icon, Button, Notification } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledDueDateNotification = styled.div`
    ${() => css`
        ${Notification.Styles.StyledNotification} {
            padding: 16px 16px;
        }

        ${Button.Styles.StyledButton} {
            margin-left: 32px;
            ${media.max.lg`
                margin-top: 24px;
                margin-left: 0px;
            `}
        }
    `}
`;

export const StyledWarnIcon = styled.div`
    ${props =>
        css`
            ${Icon.Styles.StyledIcon} {
                margin-right: 12px;
                font-size: 24px;
                color: ${props.theme.global.colors.info};
            }
        `}
`;

export const StyledMessage = styled.div`
    ${() =>
        css`
            ${media.max.lg`
                margin-top: 16px;
    `}
        `}
`;
