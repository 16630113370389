import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { appMsgTypes, postAppMsg } from '../../../Utils/General';
import { fetchFile } from '../../../Utils/Api';

import { notifyErrorMsg } from '../../../Redux/Notification';

const ENTER_KEY_CODE = 13;

const HybridLink = ({ children, href, isDirectLink, overrideGetUrl }) => {
    const { isTerraApp } = useSelector(state => state.mobileState);
    const dispatch = useDispatch();

    const displayFile = async url =>
        fetchFile(url, { method: 'GET', useBaseUrl: false })
            .then(data =>
                data
                    ? Promise.resolve(window.open(data, '_blank', 'noopener noreferrer'))
                    : Promise.reject(),
            )
            .catch(() =>
                dispatch(
                    notifyErrorMsg(
                        'Ocorreu um erro ao carregar o arquivo. Tente novamente mais tarde.',
                    ),
                ),
            );

    const handleClick = evt => {
        if (isTerraApp) {
            evt.stopPropagation();
            evt.preventDefault();
            if (isDirectLink) {
                postAppMsg(appMsgTypes.DIRECT_LINK, { url: href });
            } else {
                postAppMsg(appMsgTypes.FILE, { url: href, method: 'GET' });
            }

            return null;
        }

        if (!isTerraApp && overrideGetUrl) {
            evt.stopPropagation();
            evt.preventDefault();

            return displayFile(href);
        }

        return null;
    };

    const handleKeyUp = evt => {
        if ((evt.charCode || evt.keyCode) === ENTER_KEY_CODE) {
            handleClick(evt);
        }
    };

    return (
        <span role="link" tabIndex={-1} onClick={handleClick} onKeyUp={handleKeyUp}>
            {children}
        </span>
    );
};

HybridLink.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    isDirectLink: PropTypes.bool,
    overrideGetUrl: PropTypes.bool,
};

HybridLink.defaultProps = {
    isDirectLink: false,
    overrideGetUrl: false,
};

export default HybridLink;
