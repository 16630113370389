import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

import ForgotPasswordIdentification from './ForgotPasswordIdentification';
import ForgotPasswordToken from './ForgotPasswordToken';
import { FeedbackSuccess, FeedbackError } from '../Feedback';
import NewPassword from './NewPassword';

import { STEPS } from './index.constants';
import { ForgotPasswordProps } from './index.types';

const ForgotPassword = ({ backToLogin, wrongPartner }: ForgotPasswordProps) => {
    const { username: userBlocked } = useSelector((state: RootState) => state.authState);

    const [username, setUsername] = React.useState(userBlocked || '');
    const [token, setToken] = React.useState('');
    const [step, setStep] = React.useState<STEPS>(STEPS.STEP_IDENTIFICATION);

    const onConfirmUsername = (value: string) => {
        setUsername(value);
        setStep(STEPS.STEP_TOKEN);
    };

    const onTokenAccept = (value: string) => {
        setToken(value);
        setStep(STEPS.STEP_NEW_PASSWORD);
    };

    const onPasswordChange = () => {
        setStep(STEPS.STEP_SUCCESS);
    };

    const onError = () => {
        setStep(STEPS.STEP_ERROR);
    };

    const renderStep = () => {
        switch (step) {
            case STEPS.STEP_TOKEN:
                return <ForgotPasswordToken username={username} onTokenAccept={onTokenAccept} />;

            case STEPS.STEP_NEW_PASSWORD:
                return (
                    <NewPassword
                        token={token}
                        username={username}
                        onPasswordChange={onPasswordChange}
                        onPasswordError={onError}
                    />
                );

            case STEPS.STEP_SUCCESS:
                return <FeedbackSuccess callBack={backToLogin} />;

            case STEPS.STEP_ERROR:
                return <FeedbackError callBack={backToLogin} />;

            case STEPS.STEP_IDENTIFICATION:
            default:
                return (
                    <ForgotPasswordIdentification
                        userBlocked={userBlocked}
                        onConfirmUsername={onConfirmUsername}
                        wrongPartner={wrongPartner}
                    />
                );
        }
    };

    const stepMemoized = React.useMemo(renderStep, [step]);

    return stepMemoized;
};

export default ForgotPassword;
