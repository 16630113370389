import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchFundsAssets, fetchFundsSuccess, fetchFundsError } from './index.actions';

export const initialState = {
    offers: null,
    loading: false,
    error: null,
};

export const investmentFundsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_FUNDS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchFundsAssets, {
                    successActionCreator: fetchFundsSuccess,
                    failActionCreator: fetchFundsError,
                }),
            );

        case Actions.FETCH_FUNDS_SUCCESS:
            return { ...state, offers: action.offers, loading: false, error: false };

        case Actions.FETCH_FUNDS_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
