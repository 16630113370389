import { getGUID } from '../../../Utils/General/GUID';

/**
 * Adds new AssetManager to given form populating with given clientId.
 * Centralizes actions for AssetManager which are used throughout Formik forms.
 * */
export const addNewEmptyAssetManager = (formContext, clientId) => {
    const assetManagers = [...formContext.values.AssetManagers, getEmptyInstance(clientId)];
    formContext.setFieldValue('AssetManagers' as never, assetManagers);
};

/**
 * Removes specific AssetManager from given form, selecting by index
 * Centralizes actions for AssetManager which are used throughout Formik forms.
 * */
export const removeAssetManagerByIndex = (formContext, index) => {
    if (index >= -1) {
        const newAssetManagers = [...formContext.values.AssetManagers];
        newAssetManagers.splice(index, 1);

        formContext.setFieldValue('AssetManagers' as never, newAssetManagers);
    }
};

/**
 * Clears AssetManager array field from given form.
 * Centralizes actions for AssetManager which are used throughout Formik forms
 * */
export const clearAssetManagers = formContext => {
    const assetManagers = [];
    formContext.setFieldValue('AssetManagers' as never, assetManagers);
};

/**
 * Provides empty instance of AssetManager.
 * */
export const getEmptyInstance = clientId => ({
    key: getGUID(),
    CustomerId: clientId,
    AssetManagerName: '',
    AssetManagerCpfCnpj: '',
    MainAddress: '',
    Number: null,
    Complement: '',
    District: '',
    StateId: '',
    PostalCode: '',
    DDDMobilePhone: null,
    MobilePhone: '',
    Email: '',
    Skype: '',
    CityId: null,
    CityName: '',
    ValidityIndeterminate: true,
    ValidityDate: null,
});
