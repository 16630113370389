import * as React from 'react';

import { Anchor } from '../Anchor';
import LimitAttachments from './LimitAttachments';
import LimitProcurators from './LimitProcurators';
import LimitAssetManagers from './LimitAssetManagers';

interface AnchorDisplayProps {
    anchor: any;
    focusedAnchor: string;
    name: string;
}

const AnchorDisplay: React.FC<AnchorDisplayProps> = ({ anchor, focusedAnchor, name }) => (
    <LimitAttachments anchor={anchor}>
        <LimitProcurators anchor={anchor}>
            <LimitAssetManagers anchor={anchor}>
                <Anchor bindTo={anchor.ID} focus={focusedAnchor === anchor.ID}>
                    {name}
                </Anchor>
            </LimitAssetManagers>
        </LimitProcurators>
    </LimitAttachments>
);

export default AnchorDisplay;
