import { formatCurrency } from '@terrainvest/react-components';

export const formatNumber = value => {
    const formattedValue =
        value || value === 0 ? formatCurrency(Number(value).toFixed(2), { currency: '' }) : '-';

    return value < 0 ? -formattedValue : formattedValue;
};

export const formatComplexValues = (
    value,
    {
        showCurrency = true,
        locale = 'pt-BR',
        style = 'currency',
        currency = 'BRL',
        minimumFractionDigits = 2,
    },
) => {
    const formatter = new Intl.NumberFormat(locale, {
        style,
        currency,
        minimumFractionDigits,
    });

    let formattedValue = formatter.format(value);
    if (!showCurrency) {
        formattedValue = formattedValue.split(/\s/).pop();
        if (value < 0) {
            formattedValue = `-${formattedValue}`;
        }
    }

    return formattedValue;
};

export const currency2Number = value => {
    const numbers = value.split(/\s/).pop();
    const cleanDots = numbers.replace('.', '');
    const replaceComma = cleanDots.replace(',', '.');

    return parseFloat(replaceComma);
};
