import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeAdvisor } from './index.normalize';

export const fetchAdvisorInit = advisorId => ({
    type: Actions.FETCH_ADVISOR_INIT,
    advisorId,
});

export const fetchAdvisor = advisorId => {
    return fetch(ENDPOINTS.GET_ADVISOR.replace(':advisorId', advisorId), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeAdvisor);
};

export const fetchAdvisorSuccess = advisor => ({
    type: Actions.FETCH_ADVISOR_SUCCESS,
    advisor,
});

export const fetchAdvisorError = error => ({
    type: Actions.FETCH_ADVISOR_ERROR,
    error: error.message,
});
