import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchAuth, fetchAuthSuccess, fetchAuthError } from './index.actions';

import { appMsgTypes, postAppMsg } from '../../Utils/General';

export const initialState = {
    auth: null,
    username: null,
    loading: false,
    mappedError: null,
    offerManagerError: false,
    error: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.AUTH_FETCH_INIT:
            return loop(
                {
                    ...state,
                    username: action.credentials.username,
                    loading: true,
                    mappedError: null,
                    error: null,
                },
                Cmd.run(fetchAuth, {
                    successActionCreator: fetchAuthSuccess,
                    failActionCreator: fetchAuthError,
                    args: [action.credentials, action.callback, Cmd.dispatch],
                }),
            );
        case Actions.AUTH_FETCH_SUCCESS:
            return { ...state, auth: action.auth, loading: false, mappedError: null, error: null };
        case Actions.AUTH_MAPPED_ERROR:
            return { ...state, mappedError: action.mappedError, loading: false };
        case Actions.AUTH_OFFER_MANAGER_ERROR:
            return { ...state, offerManagerError: true, loading: false };
        case Actions.AUTH_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case Actions.AUTH_LOGOUT:
            postAppMsg(appMsgTypes.LOGOUT);
            return { ...initialState };
        case Actions.SET_AUTH:
            return { ...state, auth: action.auth };
        default:
            return state;
    }
};
