import { loop, Cmd } from 'redux-loop';

export const createReducer = ({ prefix, actions }) => {
    const initialState = {
        data: null,
        loading: false,
        error: null,
    };

    const reducer = (state: any = initialState, action) => {
        switch (action.type) {
            case `COMMON_${prefix}_FETCH_INIT`:
                return loop(
                    { ...state, loading: true },
                    Cmd.run(actions.fetch, {
                        successActionCreator: actions.fetchSuccess,
                        failActionCreator: actions.fetchError,
                        args: [action.param],
                    }),
                );
            case `COMMON_${prefix}_FETCH_SUCCESS`:
                return {
                    ...state,
                    data: action.data,
                    error: null,
                    loading: false,
                };
            case `COMMON_${prefix}_FETCH_ERROR`:
                return { ...state, error: action.error, loading: false };
            default:
                return state;
        }
    };

    return reducer;
};
