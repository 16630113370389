import * as moment from 'moment';
import { formatCurrency } from '@terrainvest/react-components';
import { parseDateForDisplay } from '../../../Utils/Parsers/timeParsers';

const NORMALIZE_DISPLAY_FIELDS = [
    {
        key: 'DataLancamento',
        label: 'Data Lançamento',
        normalizer: value => parseDateForDisplay(value, [moment.ISO_8601]),
    },
    {
        key: 'DataLiquidacao',
        label: 'Data Liquidação',
        normalizer: value => parseDateForDisplay(value, [moment.ISO_8601]),
    },
    {
        key: 'Valor',
        label: 'Valor',
        normalizer: value => formatCurrency(value),
    },
];

export const normalizeIntradayAsset = incomingAsset => {
    const normalizedObj = { ...incomingAsset, normalized: {} };

    NORMALIZE_DISPLAY_FIELDS.forEach(field => {
        const normalizedField = {
            label: field.label,
            rawValue: normalizedObj[field.key],
            value: field.normalizer(normalizedObj[field.key]),
        };

        normalizedObj.normalized = { ...normalizedObj.normalized, [field.key]: normalizedField };
    });

    return normalizedObj;
};

export default normalizeIntradayAsset;
