import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@terrainvest/react-components';

import { RootState } from '../../../store';
import { logout } from '../../../Redux/Auth';
import { helpLink } from '../../../Utils/General/CommonInfo';

import HeaderLinkItem from '../assets/HeaderLinkItem';
import HeaderLogOutItem from '../assets/HeaderLogOutItem';
import HeaderAdvisorItem from '../assets/HeaderAdvisorItem';
import HeaderMenuContainer from '../assets/HeaderMenuContainer';
import HeaderTEDAccountItem from '../assets/HeaderTEDAccountItem';
import HeaderProfileNameItem from '../assets/HeaderProfileNameItem';
import HeaderSwitchMobileItem from '../assets/HeaderSwitchMobileItem';
import HeaderAccountSelection from '../assets/HeaderAccountSelection';
import HeaderProfileMenuButton from '../assets/HeaderProfileMenuButton';
import HeaderQualifiedInvestorItem from '../assets/HeaderQualifiedInvestorItem';

import { StyledBlockWrapper, StyledTitle } from './index.styles';

export const ProfileMenu = ({ open, setOpen, history, isMobile }) => {
    const id = 'header-menu-button';
    const dispatch = useDispatch();

    const [accountSelectionOpen, setAccountSelectionOpen] = React.useState(false);

    const { client } = useSelector((state: RootState) => state.clientState);
    const { minifiedProfile } = useSelector((state: RootState) => state.suitabilityProfileState);
    const { ProfessionalInvestor, QualifiedInvestor } = client;

    const getClientFirstName = () => {
        const clientFirstName =
            client.Name && client.Name.split && client.Name.split(' ')[0].toLowerCase();
        const clientFirstNameCapitalized =
            clientFirstName.charAt(0).toUpperCase() + clientFirstName.slice(1);
        return clientFirstNameCapitalized;
    };

    const accounts = client && client.Accounts ? client.Accounts : [];

    const handleCloseHeaderMenu = () => setOpen(false);
    const handleInvertHeaderMenu = () => setOpen(!open);

    const hasAccountsAndMinifiedProfile = accounts.length && minifiedProfile !== undefined;

    return (
        <>
            <HeaderProfileMenuButton id={id} open={open} setOpen={handleInvertHeaderMenu} />
            <HeaderMenuContainer open={open} setClose={handleCloseHeaderMenu} isMobile={isMobile}>
                {hasAccountsAndMinifiedProfile ? (
                    <>
                        <StyledBlockWrapper>
                            {isMobile && <HeaderSwitchMobileItem />}
                            <StyledTitle>Olá, {getClientFirstName()}</StyledTitle>

                            <HeaderTEDAccountItem />
                            <HeaderAdvisorItem />
                        </StyledBlockWrapper>

                        <StyledBlockWrapper>
                            {QualifiedInvestor && (
                                <HeaderQualifiedInvestorItem professional={ProfessionalInvestor} />
                            )}

                            <HeaderLinkItem
                                customText={
                                    <HeaderProfileNameItem
                                        profile={
                                            (minifiedProfile || { UserProfileName: null })
                                                .UserProfileName
                                        }
                                        profileId={
                                            (minifiedProfile || { UserProfile: null }).UserProfile
                                        }
                                    />
                                }
                                name="Perfil de Investidor"
                                dispatch={() => history.push('/app/perfil-de-investidor')}
                                clickedCallback={handleCloseHeaderMenu}
                            />

                            <HeaderLinkItem
                                name="Dados Pessoais"
                                dispatch={() => history.push('/app/dados-cadastrais')}
                                clickedCallback={handleCloseHeaderMenu}
                            />

                            <HeaderLinkItem
                                name="Termos e Contratos"
                                dispatch={() => history.push('/app/contratos')}
                                clickedCallback={handleCloseHeaderMenu}
                            />

                            <HeaderLinkItem
                                name="Segurança"
                                dispatch={() => history.push('/app/dados-de-acesso')}
                                clickedCallback={handleCloseHeaderMenu}
                            />

                            <HeaderLinkItem
                                name="Ajuda"
                                dispatch={() => window.open(helpLink, '_blank')}
                                clickedCallback={handleCloseHeaderMenu}
                            />

                            <HeaderLinkItem
                                name="Trocar Conta"
                                hideLineBottom
                                dispatch={() => setAccountSelectionOpen(true)}
                            />
                            <HeaderAccountSelection
                                isMobile={isMobile}
                                open={accountSelectionOpen}
                                setClose={() => setAccountSelectionOpen(false)}
                                afterSelection={() => {
                                    handleCloseHeaderMenu();
                                    setAccountSelectionOpen(false);
                                }}
                                allAccounts={() => history.push('/app/contas-e-sub-contas')}
                            />
                        </StyledBlockWrapper>

                        <HeaderLogOutItem logout={() => dispatch(logout())} />
                    </>
                ) : (
                    <Skeleton height={300} width={230} />
                )}
            </HeaderMenuContainer>
        </>
    );
};

export default ProfileMenu;
