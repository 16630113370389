export interface ColorsTypeProps {
    colored: string;
    base: string;
    type: 'info' | 'success' | 'error' | 'warn';
    animation?: string;
    animationDuration?: number;
}

export const getInfoType = (type): ColorsTypeProps => {
    switch (type) {
        case 'success':
            return {
                colored: 'success',
                base: '#16A34A',
                type: 'success',
                animation: 'https://assets10.lottiefiles.com/packages/lf20_ig9lwtj1.json',
                animationDuration: 2000,
            };

        case 'processing':
            return {
                colored: 'warndark',
                base: '#F97316',
                type: 'warn',
                animation: 'https://assets2.lottiefiles.com/packages/lf20_s8i5rm9c.json',
                animationDuration: 2000,
            };

        case 'cancel':
            return {
                colored: 'error',
                base: '#F43F5E',
                type: 'error',
                animation: 'https://assets5.lottiefiles.com/packages/lf20_cvaj1e0l.json',
                animationDuration: 2000,
            };

        case 'success-remove':
            return {
                colored: 'error',
                base: '#F43F5E',
                type: 'error',
                animation: 'https://assets6.lottiefiles.com/packages/lf20_th3i9zsv.json',
                animationDuration: 2000,
            };

        case 'error':
            return {
                colored: 'errordark',
                base: '#BE123C',
                type: 'error',
                animation: 'https://assets6.lottiefiles.com/packages/lf20_x97noxyj.json',
                animationDuration: 2000,
            };

        default:
            return { colored: 'white', base: '#000000', type: 'info' };
    }
};

export const myRequestsTexts = {
    p1: 'Você pode acompanhar os detalhes em ',
    p2: 'Minhas Solicitações',
};
