import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from '@terrainvest/react-components';

import { getExpirationCountInDays } from '../../../Utils/Parsers';
import { StyledNotification, StyledButton } from './index.styles';

export const UserDataExpirationNotification = () => {
    const { client } = useSelector(state => state.clientState);
    const [daysToExpire, setDaysToExpire] = React.useState<number>();
    const [feedbackMsg, setFeedbackMsg] = React.useState('');
    const [showNotification, setShowNotification] = React.useState(false);

    const history = useHistory();

    const userRegistrationPathname = '/app/dados-cadastrais';
    const expirationWarningDays = 10;
    const shouldDisplayNotification = daysToExpire && daysToExpire <= expirationWarningDays;

    React.useEffect(() => {
        if (client && client.DueDate) {
            calculateDaysToExpire();
        }
    }, []);

    React.useEffect(() => {
        setFeedbackMsg(getExpirationFeedbackMsg());
    }, [daysToExpire]);

    React.useEffect(() => {
        handleShowNotification();
    });

    const handleShowNotification = () => {
        const userIsAtRegistration = history.location.pathname === userRegistrationPathname;

        if (shouldDisplayNotification && !userIsAtRegistration) {
            setShowNotification(true);
        }

        if (shouldDisplayNotification && userIsAtRegistration) {
            setShowNotification(false);
        }
    };

    const calculateDaysToExpire = () => {
        const daysCount = getExpirationCountInDays(client.DueDate);
        if (daysCount) {
            setDaysToExpire(daysCount);
        }
    };

    const getExpirationFeedbackMsg = () => {
        const moreThanOneDay = daysToExpire > 1;
        const expiresToday = daysToExpire === 1;

        if (moreThanOneDay) {
            return `Seu cadastro expira em ${daysToExpire} dias.`;
        }

        if (expiresToday) {
            return 'Seu cadastro expira hoje.';
        }

        return '';
    };

    return (
        <StyledNotification fixed icon type="warn" visible={showNotification}>
            <Icon icon="circle-exclamation" padRight />

            {feedbackMsg}

            <StyledButton tertiary onClick={() => history.push(userRegistrationPathname)}>
                atualizar cadastro
                <Icon icon="chevron-right" size="sm" padLeft />
            </StyledButton>
        </StyledNotification>
    );
};

export default UserDataExpirationNotification;
