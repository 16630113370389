import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchCheckingAccountSubmit,
    fetchCheckingAccountSubmitSuccess,
    fetchCheckingAccountSubmitError,
    fetchPatrimonyInfo,
    fetchPatrimonyInfoError,
    fetchPatrimonyInfoSuccess,
} from './index.actions';

export const initialState = {
    loading: false,
    loadingInfo: false,
    loaded: false,
    error: null,
    extract: null,
    availableBalance: null,
    patrimony: null,
    consolidatedPatrimony: null,
    updatedIn: null,
    balanceFuture: 0,
};

export const checkingAccountSubmitReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case Actions.FETCH_CHECKING_ACCOUNT_INIT:
            return state.loading === true
                ? state
                : loop(
                      { ...state, loading: true, error: null },
                      Cmd.run(fetchCheckingAccountSubmit, {
                          successActionCreator: fetchCheckingAccountSubmitSuccess,
                          failActionCreator: fetchCheckingAccountSubmitError,
                          args: [action.data],
                      }),
                  );

        case Actions.FETCH_CHECKING_ACCOUNT_SUCCESS:
            return {
                ...state,
                extract: action.extract,
                loading: false,
                loaded: true,
                error: false,
            };

        case Actions.FETCH_CHECKING_ACCOUNT_ERROR:
            return { ...state, error: action.error, loading: false, loaded: true };

        case Actions.FETCH_CHECKING_ACCOUNT_RESET:
            return {
                ...initialState,
                availableBalance: state.availableBalance,
                patrimony: state.patrimony,
                consolidatedPatrimony: state.consolidatedPatrimony,
                updatedIn: state.updatedIn,
            };

        case Actions.FETCH_PATRIMONY_INFO_INIT:
            return state.loadingInfo === true
                ? state
                : loop(
                      { ...state, loadingInfo: true, error: null },
                      Cmd.run(fetchPatrimonyInfo, {
                          successActionCreator: fetchPatrimonyInfoSuccess,
                          failActionCreator: fetchPatrimonyInfoError,
                          args: [action.account],
                      }),
                  );

        case Actions.FETCH_PATRIMONY_INFO_SUCCESS:
            return {
                ...state,
                balanceFuture: action.balanceFuture,
                availableBalance: action.availableBalance,
                patrimony: action.patrimony,
                consolidatedPatrimony: action.consolidatedPatrimony,
                updatedIn: action.updatedIn,
                loadingInfo: false,
                error: false,
            };

        case Actions.FETCH_PATRIMONY_INFO_ERROR:
            return { ...state, error: action.error, loadingInfo: false };

        case Actions.FETCH_PATRIMONY_INFO_RESET:
            return { ...initialState, extract: state.extract };

        default:
            return state;
    }
};
