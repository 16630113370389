import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import {
    isCadastralUpdate,
    needsAgreementUpdate,
    needsSuitabilityUpdate,
} from '../../../../Utils/General';
import { isExpiredDate } from '../../../../Utils/Parsers';
import useChooseAccount from '../../../../Utils/Hooks/useChooseAccount';

import { StyledInsideWrapper, StyledInsideTitle } from '../../LoginSide/index.styles';
import * as S from './index.styles';

export const AccountSelection = ({ clientData }) => {
    const history = useHistory();

    const [selectedAccount, setSelectedAccount] = React.useState(0);

    const { profile: suitabilityProfile } = useSelector(
        (state: RootState) => state.suitabilityProfileState,
    );
    const { agreementByType: agreement } = useSelector((state: RootState) => state.agreementsState);
    const { client } = useSelector((state: RootState) => state.clientState);

    const { onChooseAccount, loading } = useChooseAccount();

    const showAccountsSelection =
        suitabilityProfile && suitabilityProfile.Validity && clientData.Accounts.length > 1;

    const pushToPath = () => {
        if (
            isCadastralUpdate(client) ||
            isExpiredDate(client) ||
            needsSuitabilityUpdate(suitabilityProfile)
        ) {
            history.push('/cadastro-expirado');
        } else if (needsAgreementUpdate(agreement)) {
            history.push('/termo-expirado');
        } else if (history && history.location && history.location.pathname) {
            history.push(history.location.pathname);
        } else {
            history.push('/');
        }
    };

    const handleChooseAccount = (accountNumber: number) => {
        setSelectedAccount(accountNumber);
        onChooseAccount(accountNumber, pushToPath);
    };

    const handleAutoChooseWhenOneAccount = () => {
        if (clientData.Accounts && clientData.Accounts.length === 1) {
            handleChooseAccount(clientData.Accounts[0]);
        }
    };

    React.useEffect(() => {
        if (suitabilityProfile && suitabilityProfile.Validity && agreement) {
            handleAutoChooseWhenOneAccount();
        }
    }, [suitabilityProfile, agreement]);

    return (
        <StyledInsideWrapper>
            {showAccountsSelection ? (
                <S.StyledSelectAccounts>
                    <StyledInsideTitle>Escolha a conta que deseja acessar</StyledInsideTitle>
                    {clientData.Accounts.map((accountNumber: number) => (
                        <S.StyledAccountWrapper
                            key={accountNumber}
                            onClick={() => handleChooseAccount(accountNumber)}
                            loading={loading && selectedAccount === accountNumber}
                        >
                            <S.StyledAccount>{accountNumber}</S.StyledAccount>
                            <S.StyledIcon icon={['far', 'chevron-right']} />
                        </S.StyledAccountWrapper>
                    ))}
                </S.StyledSelectAccounts>
            ) : (
                <S.StyledLoading visible />
            )}
        </StyledInsideWrapper>
    );
};

export default AccountSelection;
