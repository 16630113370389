import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Text } from '@terrainvest/react-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${media.max.phone`
        ${props => (props.mobileWidth ? `width: ${props.mobileWidth};` : '')}
    `}
`;

export const StyledMainLogo = styled.img`
    width: 150px;
    height: 45px;
`;

export const StyledSideLogo = styled.img``;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 60px;
    max-width: 100%;

    ${media.max.tablet`
        flex-direction: column;
    `}
`;

export const StyledInfoText = styled.div`
    margin-left: 50px;
    margin-top: 50px;
    width: 440px;

    ${media.max.phone`
        margin-left: 0;
        margin-top: 0;
        width: 100%;
    `}
`;

export const StyledMainText = styled(Text)`
    ${props => `
        font-weight: ${props.theme.global.fontWeights.semiBold};
        margin-bottom: 20px;
    `}
`;

export const StyledDescription = styled.div`
    ${props => `
        font-family: ${props.theme.components.typography.bodyFontFamily};
        font-size: ${props.theme.components.typography.largeFontSize};
        line-height: ${props.theme.components.typography.largeLineHeight};
        font-weight: ${props.theme.global.fontWeights.regular};
        color: ${props.theme.components.typography.color};
        margin: ${props.theme.components.typography.textMargin};
    `}
`;
