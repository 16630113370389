import { fetch } from './fetch';

export const postData = (payload, url, method = 'POST', options = {}) =>
    fetch(url, {
        method,
        body: JSON.stringify({ ...payload }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
        ...options,
    }).then(response => {
        if (response.status && response.status !== 200) {
            throw new Error(response.status.toString());
        }
        return response;
    });
