import * as React from 'react';
import {
    InputCurrency,
    InputDate,
    parseCurrency,
    Notification,
    stringToDate,
} from '@terrainvest/react-components';
import { useFormikContext, useField } from 'formik';
import { StyledFinalData, Wrapper } from './index.styles';
import { StyledRow, StyledCol } from '../Details/index.styles';
import { StyledContainerNoPadd } from '../../Common/Grid';
import { validateCurrency } from '../index.validators';
import { Field } from '../Form/Field';
import { FORM_SECTIONS } from '../Form';

import FormCard from '../Common/FormCard';

export const StyledNotification = (props: any) => (
    <Wrapper>
        <Notification {...props} />
    </Wrapper>
);

const FinancialData = ({ isCompany }) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

    let totalPatrimony = 0;
    const patrimonyMessage = 'O somatório do patrimônio deve ser maior que zero.';

    if (values && values.Patrimony) {
        totalPatrimony =
            values.Patrimony.FinancialInvestments +
            values.Patrimony.RealState +
            values.Patrimony.OtherAssets +
            values.Patrimony.MonthlyIncome +
            values.Patrimony.NetWorth +
            values.Patrimony.WorkingCapital +
            values.Patrimony.CapitalStock;
    }

    useField({
        name: 'PatrimonyValidation',
        value: totalPatrimony,
        validate: () => (totalPatrimony <= 0 ? patrimonyMessage : null),
    });

    const handleCurrencyFieldChanged = (field, event) => {
        const changedValue = event.target.value ? parseCurrency(event.target.value) : null;
        setFieldValue(field.name, changedValue);
        setFieldTouched(field.name, !!changedValue, true);
    };

    const getPersonForm = () => (
        <>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.FinancialInvestments" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Aplicações Financeiras"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyIncome" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Renda Mensal"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
        </>
    );

    const getCompanyForm = () => (
        <>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.NetWorth" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Patrimônio Líquido"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.WorkingCapital" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Capital de Giro"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.CapitalStock" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Capital Social"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.NetWorthDate">
                    {({ inputProps, form }) => (
                        <InputDate
                            {...inputProps}
                            id="patrimony-validate-net-worth-date"
                            label="Data Último Balanço"
                            fullWidth
                            icon={false}
                            onChange={evt => {
                                form.setFieldValue(inputProps.name, stringToDate(evt));
                            }}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyAverageBilling" validate={validateCurrency}>
                    {({ field, inputProps }) => (
                        <InputCurrency
                            {...inputProps}
                            label="Faturamento médio mensal (últimos 12 meses)"
                            fullWidth
                            onChange={evt => handleCurrencyFieldChanged(field, evt)}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name="Patrimony.MonthlyAverageBillingDate">
                    {({ inputProps, form }) => (
                        <InputDate
                            {...inputProps}
                            id="patrimony-validate-monthly-billing-date"
                            label="Data Faturamento Médio Mensal"
                            fullWidth
                            icon={false}
                            onChange={evt => {
                                form.setFieldValue(inputProps.name, stringToDate(evt));
                            }}
                        />
                    )}
                </Field>
            </StyledCol>
        </>
    );

    return (
        <StyledFinalData id={FORM_SECTIONS.FINANCIAL_DATA.ID}>
            <FormCard title={FORM_SECTIONS.FINANCIAL_DATA.NAME}>
                <StyledContainerNoPadd fluid>
                    <StyledRow>
                        <StyledCol md={6} sm={12}>
                            <Field name="Patrimony.RealState" validate={validateCurrency}>
                                {({ field, inputProps }) => (
                                    <InputCurrency
                                        {...inputProps}
                                        label="Bens Imóveis"
                                        fullWidth
                                        onChange={evt => handleCurrencyFieldChanged(field, evt)}
                                    />
                                )}
                            </Field>
                        </StyledCol>
                        <StyledCol md={6} sm={12}>
                            <Field name="Patrimony.OtherAssets" validate={validateCurrency}>
                                {({ field, inputProps }) => (
                                    <InputCurrency
                                        {...inputProps}
                                        label="Outros Bens"
                                        fullWidth
                                        onChange={evt => handleCurrencyFieldChanged(field, evt)}
                                    />
                                )}
                            </Field>
                        </StyledCol>
                        {isCompany ? getCompanyForm() : getPersonForm()}
                    </StyledRow>
                    <StyledNotification type="error" visible={totalPatrimony <= 0} icon={false}>
                        {patrimonyMessage}
                    </StyledNotification>
                </StyledContainerNoPadd>
            </FormCard>
        </StyledFinalData>
    );
};

export default FinancialData;
