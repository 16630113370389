import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchPartner, fetchPartnerSuccess, fetchPartnerError } from './index.actions';

export const initialState = {
    partner: null,
    clientStatus: true,
    loading: false,
    error: false,
};

export const partnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.PARTNER_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchPartner, {
                    successActionCreator: fetchPartnerSuccess,
                    failActionCreator: fetchPartnerError,
                }),
            );
        case Actions.PARTNER_FETCH_SUCCESS:
            return {
                ...state,
                partner: action.partner,
                loading: false,
                error: false,
            };
        case Actions.PARTNER_FETCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
