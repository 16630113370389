import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as ClientActions } from '../Client/index.constants';
import {
    fetchBalance,
    fetchBalanceSuccess,
    fetchBalanceError,
    fetchBalanceInit,
} from './index.actions';
import { Actions as AuthActions } from '../Auth/index.constants';

export const initialState = {
    balance: null,
    visible: false,
    loading: false,
    error: null,
};

export const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.BALANCE_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBalance, {
                    successActionCreator: fetchBalanceSuccess,
                    failActionCreator: fetchBalanceError,
                    args: [action.accountNumber],
                }),
            );
        case Actions.BALANCE_FETCH_SUCCESS:
            return { ...state, balance: action.balance, loading: false, error: false };
        case Actions.BALANCE_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case ClientActions.CLIENT_SELECT_ACCOUNT_NUMBER:
            return loop(
                { ...state, loading: true },
                Cmd.action(fetchBalanceInit(action.accountNumber)),
            );
        case Actions.BALANCE_TOGGLE_VISIBILITY:
            return { ...state, visible: !state.visible };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
