import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizePositionTransferReason } from './index.normalize';

export const fetchPositionTransferReasonInit = () => ({
    type: Actions.FETCH_POSITION_TRANSFER_REASON_INIT,
});
// ACTION BAIXAR MES
export const fetchPositionTransferReason = () => {
    return fetch(ENDPOINTS.GET_POSITION_TRANSFER_REASON, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePositionTransferReason);
};

export const fetchPositionTransferReasonSuccess = reasons => ({
    type: Actions.FETCH_POSITION_TRANSFER_REASON_SUCCESS,
    reasons,
});

export const fetchPositionTransferReasonError = error => ({
    type: Actions.FETCH_POSITION_TRANSFER_REASON_ERROR,
    error: error.message,
});

export const fetchPositionTransferReasonReset = () => ({
    type: Actions.FETCH_POSITION_TRANSFER_REASON_RESET,
});
