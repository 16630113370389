const mappedErrors = [
    'A senha não atende aos requisitos mínimos',
    'A senha é obrigatória',
    'A senha não pode ser igual à assinatura eletrônica.',
    'Senha já utilizada anteriormente.',
];

export const isKnownError = (error: { message: string }) => {
    return mappedErrors.includes(error.message);
};
