export const Actions = {
    FETCH_EQUITY_FLUTUATION_INIT: 'FETCH_EQUITY_FLUTUATION_INIT',
    FETCH_EQUITY_FLUTUATION_SUCCESS: 'FETCH_EQUITY_FLUTUATION_SUCCESS',
    FETCH_EQUITY_FLUTUATION_ERROR: 'FETCH_EQUITY_FLUTUATION_ERROR',

    FETCH_EVOLUTION_VARIATION_INIT: 'FETCH_EVOLUTION_VARIATION_INIT',
    FETCH_EVOLUTION_VARIATION_SUCCESS: 'FETCH_EVOLUTION_VARIATION_SUCCESS',
    FETCH_EVOLUTION_VARIATION_ERROR: 'FETCH_EVOLUTION_VARIATION_ERROR',
};

export const ERROR_MESSAGES = {
    FETCH_ERROR_DEFAULT: 'Nenhum dado disponível nessa data.',
};
