import * as React from 'react';

import { StyledCard } from './index.styles';

const StandardContentCard = ({ children, id = '' }) => (
    <StyledCard light shadow id={id}>
        {children}
    </StyledCard>
);

export default StandardContentCard;
