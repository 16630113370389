import styled from 'styled-components';
import { Link } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
`;

export const StyledTexts = styled.div``;

export const StyledLink = styled(Link)`
    width: 100%;
    > p {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;
