export const Actions = {
    FETCH_CHECKING_ACCOUNT_INIT: 'CHECKING_ACCOUNT_DATE_FETCH_INIT',
    FETCH_CHECKING_ACCOUNT_SUCCESS: 'CHECKING_ACCOUNT_DATE_FETCH_SUCCESS',
    FETCH_CHECKING_ACCOUNT_ERROR: 'CHECKING_ACCOUNT_DATE_FETCH_ERROR',
    FETCH_CHECKING_ACCOUNT_RESET: 'CHECKING_ACCOUNT_FETCH_RESET',
    FETCH_PATRIMONY_INFO_INIT: 'PATRIMONY_INFO_DATE_FETCH_INIT',
    FETCH_PATRIMONY_INFO_SUCCESS: 'PATRIMONY_INFO_DATE_FETCH_SUCCESS',
    FETCH_PATRIMONY_INFO_ERROR: 'PATRIMONY_INFO_DATE_FETCH_ERROR',
    FETCH_PATRIMONY_INFO_RESET: 'PATRIMONY_INFO_FETCH_RESET',
};
