export const Actions = {
    BALANCE_FETCH_INIT: 'BALANCE_FETCH_INIT',
    BALANCE_FETCH_SUCCESS: 'BALANCE_FETCH_SUCCESS',
    BALANCE_FETCH_ERROR: 'BALANCE_FETCH_ERROR',
    BALANCE_TOGGLE_VISIBILITY: 'BALANCE_TOGGLE_VISIBILITY',
};

export interface BalanceState {
    balance: Balance;
    visible: boolean;
    loading: boolean;
    error: boolean;
}

export interface Balance {
    Account: number;
    Initial: number;
    Available: number;
    Current: number;
    InvestmentClub: number;
    TreasuryDirect: number;
    FinancialCredit: number;
    Earnings: number;
    PortfolioInitialBalance: number;
    PortfolioOnlineBalance: number;
    BalanceFuture: number;
    ProjectedBalance: number;
    FixedIncome: number;
    Stocks: number;
    Futures: number;
    Custody: number;
    Funds: number;
    Profit: number;
    Collateral: number;
    Total: number;
    Credits: Credit[];
    Debits: Debit[];
}

export interface Credit {
    Day: number;
    Date: string;
    Value: number;
}

export interface Debit {
    Day: number;
    Date: string;
    Value: number;
}
