import styled from 'styled-components';
import { DisclaimerProps } from '.';

export const StyledFooter = styled.div<DisclaimerProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 1rem;

    > p {
        color: ${props => props.textColor};
    }
`;
