import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeMarginGuarrantees, normalizeMarginGuarranteeIntradays } from './index.normalize';

export const fetchInvestmentCollateralAssetsInit = accountNumber => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_INIT,
    accountNumber,
});

export const fetchCollateralAssets = accountNumber => {
    return fetch(
        ENDPOINTS.GET_INVESTMENT_MARGIN_GUARANTEES.replace(':accountNumber', accountNumber),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeMarginGuarrantees);
};

export const fetchCollateralSuccess = offers => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_SUCCESS,
    offers,
});

export const fetchCollateralError = error => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_ERROR,
    error: error.message,
});

export const fetchInvestmentCollateralIntradayAssetsInit = accountNumber => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_INIT,
    accountNumber,
});

export const fetchCollateralIntradayAssets = accountNumber => {
    return fetch(
        ENDPOINTS.GET_INVESTMENT_MARGIN_GUARANTEES_INTRADAY.replace(
            ':accountNumber',
            accountNumber,
        ),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeMarginGuarranteeIntradays);
};

export const fetchCollateralIntradaySuccess = offers => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_SUCCESS,
    offers,
});

export const fetchCollateralIntradayError = error => ({
    type: Actions.FETCH_MARGIN_GUARANTEES_INTRADAY_ERROR,
    error: error.message,
});
