import { ENDPOINTS, fetch } from '../../../Utils/Api';
import { Actions } from './index.constants';
import { normalizeHistoric } from './index.normalize';

export const fetchHistoricInit = payload => ({
    type: Actions.FETCH_HISTORIC_INIT,
    payload,
});
// ACTION BAIXAR MES
export const fetchHistoric = payload => {
    return fetch(
        ENDPOINTS.GET_CUSTODY_TRANSFER_HISTORIC.replace(':clientId', payload.clientId)
            .replace(':startDate', payload.startDate)
            .replace(':endDate', payload.endDate),
        {
            method: 'GET',
        },
    )
        .then(response => response.json())
        .then(normalizeHistoric);
};

export const fetchHistoricSuccess = historic => ({
    type: Actions.FETCH_HISTORIC_SUCCESS,
    historic,
});

export const fetchHistoricError = error => ({
    type: Actions.FETCH_HISTORIC_ERROR,
    error: error.message,
});

export const fetchHistoricReset = () => ({
    type: Actions.FETCH_HISTORIC_RESET,
});
