import * as React from 'react';
import { PAGE_OFFSET } from '../../index.styles';
import { StyledAnchor } from './index.styles';
import { useScroll } from '../../../../Utils/Hooks/useScroll';

interface AnchorProps {
    children: React.ReactNode;
    bindTo: string;
    focus: boolean;
}

const FORM_OFFSET = 96;

export const Anchor: React.FC<AnchorProps> = ({ children, bindTo, focus }) => {
    const [active, setActive] = React.useState(false);
    const scroll = useScroll();

    const scrollTo = () => {
        const element = document.getElementById(bindTo);

        if (element && window.scrollTo) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop - PAGE_OFFSET,
            });
        }
    };

    React.useEffect(() => {
        const element = document.getElementById(bindTo);

        if (element) {
            const scrollY = scroll[1] + window.innerHeight / 2 - FORM_OFFSET;

            setActive(
                scrollY >= element.offsetTop && scrollY <= element.offsetTop + element.offsetHeight,
            );
        }
    }, [scroll]);

    React.useEffect(() => {
        if (focus) {
            scrollTo();
        }
    }, [focus]);

    return (
        <StyledAnchor
            active={active}
            onClick={() => scrollTo()}
            weight={active ? 'semiBold' : 'regular'}
        >
            {children}
        </StyledAnchor>
    );
};
