export const disclaimerTexts = {
    userSuitabilityMismatch: {
        info: `O Termo de Desenquadramento é utilizado quando o investidor desejar fazer um
        investimento ou reserva fora de seu Perfil de Suitability, em um produto cujo risco é maior.`,
    },
    dataExpiration: {
        reviewAndAcceptToContinue: `Em cumprimento das normas da Comissão de Valores Mobiliários (CVM),
        que delega às Corretoras de Valores Mobiliários a responsabilidade de manter os cadastros de
        seus clientes válidos e atualizados, é necessário que você revise e/ou atualize as informações
        abaixo para normalização de seu cadastro para continuar seu acesso.`,
    },
    fgc: {
        garantee: `ESTOU CIENTE QUE OS RECURSOS INVESTIDOS CONJUNTAMENTE EM
        ATIVOS FINANCEIROS DESTA MODALIDADE SERÃO GARANTIDOS
        PELO FUNDO GARANTIDOR DE CRÉDITOS – FGC ATÉ O VALOR DE
        R$ 250.000,00. RESSALTAMOS QUE A QUANTIDADE É POR
        EMISSOR, INDEPENDENTEMENTE DA QUANTIDADE DE OPERAÇÕES E
        DA INSTITUIÇÃO QUE É DISTRIBUÍDO`,
        noGarantee: `ESTOU CIENTE QUE OS RECURSOS INVESTIDOS EM ATIVOS
        FINANCEIROS DESTA MODALIDADE NÃO CONTA COM GARANTIA DO
        FUNDO GARANTIDOR DE CRÉDITOS – FGC, DOS PRESTADORES DE
        SERVIÇOS, SE HOUVER, OU, AINDA, DE QUALQUER MECANISMO DE
        SEGURO.`,
        helpText: `Renda garantida pelo FGC (Fundo Garantidor de Crédito), até o valor de R$250.000,00`,
        noGaranteeGeneralInfo: `ESTOU CIENTE QUE OS RECURSOS INVESTIDOS EM ATIVOS FINANCEIROS DESTA MODALIDADE
        NÃO CONTA COM GARANTIA DO FUNDO GARANTIDOR DE CRÉDITOS – FGC, DOS PRESTADORES DE SERVIÇOS, SE HOUVER,
        OU, AINDA, DE QUALQUER MECANISMO DE SEGURO.`,
        garanteeGeneralInfo: `OS RECURSOS INVESTIDOS CONJUNTAMENTE EM ATIVOS FINANCEIROS DESTA MODALIDADE
        SERÃO GARANTIDOS PELO FUNDO GARANTIDOR DE CRÉDITOS – FGC ATÉ O VALOR DE R$ 250.000,00.
        RESSALTAMOS QUE A QUANTIDADE É POR EMISSOR, INDEPENDENTEMENTE DA QUANTIDADE DE OPERAÇÕES E DA
        INSTITUIÇÃO QUE É DISTRIBUÍDO.`,
    },
    liquidity: {
        daily: `Para os Ativos de liquidez diária, é cobrada taxa de
        corretagem no momento da aplicação, conforme tabela: De R$0
        a R$25.000,00: 0,20% sobre volume aplicado Acima de
        R$25.000,01: 0,15% sobre volume aplicado`,
    },
    procurator: {
        authorization: `Fica autorizado para em seu nome emitir, verbalmente e/ou por escrito,
        ordens para compra ou venda de títulos e valores mobiliários e/ou registro de contratos
        nos mercados administrados pela BOLSA BRASIL BALCÃO (B3) e demais bolsas e/ou mercados de
        balcão organizados ou não, incluindo, mas não limitado ao SELIC, abrangendo inclusive
        outras câmaras de compensação, liquidação e custódia; ordens estas a serem executadas
        pela Terra Investimentos DTVM Ltda., que para todos os fins e efeitos de direito serão
        consideradas como se tivessem sido transmitidas pelo(a) próprio(a) OUTORGANTE, podendo
        ainda dito procurador, negociar, realizar e assinar acordos de pagamento, transacionar,
        confessar, enfim, tudo o mais para o fiel cumprimento do presente mandato. Fica convencionado
        que é terminantemente proibido substabelecer qualquer um dos poderes da referida, tendo a
        mesma validade.`,
    },
    assetManager: {
        authorization: `Fica autorizado para em seu nome emitir, verbalmente e/ou por escrito,
        ordens para compra ou venda de títulos e valores mobiliários e/ou registro de contratos
        nos mercados administrados pela BOLSA BRASIL BALCÃO (B3) e demais bolsas e/ou mercados de
        balcão organizados ou não, incluindo, mas não limitado ao SELIC, abrangendo inclusive
        outras câmaras de compensação, liquidação e custódia; ordens estas a serem executadas
        pela Terra Investimentos DTVM Ltda., que para todos os fins e efeitos de direito serão
        consideradas como se tivessem sido transmitidas pelo(a) próprio(a) OUTORGANTE, podendo
        ainda dito procurador, negociar, realizar e assinar acordos de pagamento, transacionar,
        confessar, enfim, tudo o mais para o fiel cumprimento do presente mandato. Fica convencionado
        que é terminantemente proibido substabelecer qualquer um dos poderes da referida, tendo a
        mesma validade.`,
    },
    fatca: {
        helpContent: `Incluindo, mas não se limitando a: Custodiante, Banco, Instituição de
        Investimentos, Fundos de Investimentos, Consórcios ou Companhia de Seguros e/ou Previdência,
        Gestão de Títulos ou Valores Mobiliários, Securitização, Depositário Central,
        bolsas ou Entidades de Balcão Organizado.`,
    },
    extracts: {
        highVolumeOperations: `Caso suas operações detenham alto volume, será necessário a extração
        de arquivos parciais, ou seja, mês a mês.`,
    },
    publicOffers: {
        helperTitle: 'Compra à vista para investidor de varejo: ',
        helperContent: `é o tipo de modalidade para todo investidor não institucional, quer seja
        pessoa física ou pessoa jurídica, que não deseja participar das modalidades com prioridade
        de alocação.`,
    },
    marketIsClosed: {
        text: `A plataforma de renda fixa da Terra Investimentos está encerrada
        para aplicação. Para verificar a disponibilidade deste produto,
        por favor acesse novamente a partir das 9h do próximo dia útil.`,
    },
    funds: {
        rentability:
            'Rentabilidades atualizadas diariamente, podendo diferir das lâminas disponibilizadas.',
        offerClosed: `Fundo fechado para captação`,
        offerRestrictedToQualifiedInvestor: 'Fundo restrito à investidores Qualificados',
    },
    blockOfferManager: {
        mainText: `Nosso Portal não está disponível para o seu perfil como cliente de Coordenação
        de Oferta Pública.`,
        description: `Caso tenha alguma dúvida, entre em contato conosco! Nosso horário de atendimento
        é das 08:30 às 18:30 de segunda a sexta e nosso e-mail é`,
        email: 'atendimento@terrainvestimentos.com.br',
    },
    recommendedPortfolio: {
        pageInfo: `Nosso time de especialistas tem sempre a melhor opção para você investir sem
            preocupações. Veja o que temos para você e faça sua alocação.`,
        noDataMainText: 'Nenhuma carteira recomendada no momento!',
        noDataDescription: `Fique atento às nossas comunicações,
                            dessa maneira você não perde as melhores oportunidades
                            de carteiras recomendadas assim que forem anunciadas!`,
    },
    opportunities: {
        ledeText: `As melhores oportunidades de investimento para o seu perfil, atualizadas
        semanalmente de acordo com nossos especialistas.`,
        labels: {
            oportunityPlural: 'oportunidades',
            portfolioPlural: 'carteiras recomendadas',
            publicOffersPlural: 'ofertas públicas',
        },
        emptyTitle: {
            opportunities: 'Nenhuma oferta em destaque encontrada no momento!',
            portfolio: 'Nenhuma carteira recomendada encontrada no momento!',
            publicOffers: 'Nenhuma oferta pública encontrada no momento!',
        },
    },
    privatePension: {
        investmentTypeHelp: `A contratação feita antes das 16h ocorre no mesmo dia ou no máximo
            na manhã do dia seguinte. Após as 16h a implantação ocorre após as 16h do dia seguinte
            ou no máximo na manhã do outro dia seguinte (D+2).`,
        taxationTypeProgressive: `Indicado para quem vai aplicar a curto prazo. No resgate, faz-se
            um desconto-padrão de 15%, e a diferença (maior ou menor) será acertada na declaração de
            ajuste anual de IRPF.`,
        taxationTypeRegressive: `Indicado para quem vai aplicar a longo prazo. No resgate, o
            desconto começa com tributação de 35% (até 2 anos de aplicação), caindo 5% a cada 2 anos
            até chegar em 10% (acima de 10 anos).`,
        categoryPGBL: `Indicado para quem faz a declaração completa do IR, pois permite abater suas
            contribuições anuais da base de cálculo do ajuste anual de IRPF. No PGBL o IR será cobrado
            sobre o preço total do investimento.`,
        categoryVGBL: `Indicado para quem faz a declaração simplificada do IR. No VGBL o IR será
            cobrado sobre o valor da rentabilidade do investimento.`,
        notificationSuccess: `Successo! O investimento foi solicitado, os detalhes serão exibidos
            na suas Solicitações e você receberá um e-mail com maiores informações.`,
    },
};

export const popoverTexts = {
    fundsTable: {
        helper:
            'Este fundo possui menos de 6 meses, o rendimento será exibido a partir do sexto mês.',
    },
};

export const feedbackNoFinancialData =
    'Os dados financeiros não foram carregados corretamente. Tente novamente mais tarde.';

export * from './index.styles';
