import { normalizeString } from './stringParsers';

export const getObjectValues = (object, targetFields, skipKeys: string[] = []) => {
    return Object.keys(object)
        .filter(key => targetFields.includes(key))
        .filter(key => !skipKeys.includes(key))
        .map((key: string) => object[key]);
};

export const parseObjectForFiltering = (item, targetFields?: string[], skipFields?: string[]) => {
    const targets = targetFields.length > 0 ? targetFields : Object.keys(item);

    let parsed: any = { ...getObjectValues(item, targets, skipFields) };
    parsed = JSON.stringify(parsed);
    parsed = normalizeString(parsed);

    return parsed;
};
