import styled from 'styled-components';
import { Button, Notification } from '@terrainvest/react-components';

export const StyledButton = styled(Button)`
    margin-left: 40px;
`;

export const StyledNotification = styled(Notification)`
    & {
        min-height: 64px;
    }
`;
