import styled from 'styled-components';

export const StyledPartnerWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.global.spaces.highFrequencyMicrowave};
    box-sizing: border-box;
`;
