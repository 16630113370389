import { fetch, ENDPOINTS } from '../../Utils/Api';

export const postBuyOrder = payload =>
    fetch(ENDPOINTS.POST_BUY_ORDER, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
