import * as React from 'react';
import * as PropTypes from 'prop-types';

import { StyledDetails } from './index.styles';
import { FORM_SECTIONS } from '../Form';
import { StyledRowNoPadd, StyledColNoPadd } from '../../Common/Grid';

import { DetailsPersonForm } from './DetailsPersonForm';
import { DetailsCompanyForm } from './DetailsCompanyForm';

const Details = ({ isCompany }) => {
    return (
        <StyledDetails id={FORM_SECTIONS.DETAILS.ID}>
            <StyledRowNoPadd>
                <StyledColNoPadd lg={12} sm={12}>
                    {isCompany ? <DetailsCompanyForm /> : <DetailsPersonForm />}
                </StyledColNoPadd>
            </StyledRowNoPadd>
        </StyledDetails>
    );
};

Details.propTypes = {
    isCompany: PropTypes.bool,
};

Details.defaultProps = {
    isCompany: false,
};

export default Details;
