import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchRetailGuarantee,
    fetchRetailGuaranteeSuccess,
    fetchRetailGuaranteeError,
} from './index.actions';
import { Actions as AuthActions } from '../Auth/index.constants';

import { statusTypes } from '../../View/RetailGuarantee/index.constants';

export const initialState = {
    retail: null,
    loading: false,
    error: false,
};

export const retailGuaranteeReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RETAIL_GUARANTEE_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchRetailGuarantee, {
                    successActionCreator: fetchRetailGuaranteeSuccess,
                    failActionCreator: fetchRetailGuaranteeError,
                    args: [action.account],
                }),
            );
        case Actions.RETAIL_GUARANTEE_FETCH_SUCCESS:
            return {
                ...state,
                retail: action.retail,
                loading: false,
                error: false,
            };
        case Actions.RETAIL_GUARANTEE_FETCH_ERROR:
            return {
                ...state,
                retail: { status: statusTypes.NOT_ASKED },
                error: true,
                loading: false,
            };
        case Actions.SET_RETAIL_GUARANTEE_STATUS:
            return { ...state, retail: { ...state.retail, status: action.status } };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
