import * as React from 'react';

import MobileFinancialOverview from '../MobileFinancialOverview';
import { SideNavContext } from '../SideNav/SideNavContext';
import * as S from './index.styles';

export const MobileSidebar = ({ openSideNav }) => {
    const [financialOpen, setFinancialOpen] = React.useState(false);

    const { open, setOpen } = React.useContext(SideNavContext);

    const handleMenuClick = (openSection: string, invert = false) => {
        setOpen(!invert || !open);
        if (openSection) {
            openSideNav(openSection);
        }
        if (financialOpen) {
            setFinancialOpen(false);
        }
    };

    const handleFinancialClick = () => {
        setOpen(false);
        setFinancialOpen(!financialOpen);
    };

    return (
        <>
            <S.StyledFinancialOverview open={financialOpen}>
                <MobileFinancialOverview reset={financialOpen} />
            </S.StyledFinancialOverview>
            <S.StyledOverflow open={open} onClick={() => setOpen(false)} />
            <S.StyledMobileSidebarWrapper open={open}>
                <S.StyledMobileSidebarItem open={open} onClick={() => handleMenuClick('', true)}>
                    <S.StyledIcon icon={open ? 'xmark' : 'bars'} />
                    <S.StyledText>{open ? 'Fechar' : 'Menu'}</S.StyledText>
                </S.StyledMobileSidebarItem>
                <S.StyledMobileSidebarItem open={financialOpen} onClick={handleFinancialClick}>
                    <S.StyledIcon icon={['far', 'magnifying-glass-chart']} />
                    <S.StyledText>Resumo financeiro</S.StyledText>
                </S.StyledMobileSidebarItem>
            </S.StyledMobileSidebarWrapper>
        </>
    );
};

export default MobileSidebar;
