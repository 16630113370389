import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { responseToFile } from '../../../Utils/Parsers';

export const fetchCustodySubmitInit = data => ({
    type: Actions.FETCH_CUSTODY_SUBMIT_INIT,
    data,
});

export const mountCustodyExtractUrl = data =>
    ENDPOINTS.GET_CUSTODY_SUBMIT.replace(':accountNumber', data.account).replace(
        ':id',
        data.custodyIdValue,
    );

export async function fetchCustodySubmit(data) {
    const url = mountCustodyExtractUrl(data);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = `extrato_custodia.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchCustodySubmitSuccess = fileURL => ({
    type: Actions.FETCH_CUSTODY_SUBMIT_SUCCESS,
    fileURL,
});

export const fetchCustodySubmitError = error => ({
    type: Actions.FETCH_CUSTODY_SUBMIT_ERROR,
    error: error.message,
});

export const fetchCustodySubmitReset = () => ({
    type: Actions.FETCH_CUSTODY_SUBMIT_RESET,
});
