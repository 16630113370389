import React from 'react';
import { Loading } from '@terrainvest/react-components';

import { SuspenseLoadingContainer } from './styles';

const SuspenseLoading: React.FC = () => (
    <SuspenseLoadingContainer>
        <Loading visible />
    </SuspenseLoadingContainer>
);

export default SuspenseLoading;
