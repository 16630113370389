import { Actions } from './index.constants';

export const initialState = {
    message: null,
    props: null,
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.NOTIFICATION_POST:
            return { ...state, message: action.message, props: action.props };
        case Actions.NOTIFICATION_CLEAR:
            return { ...state, message: null, props: null };
        default:
            return state;
    }
};
