import * as React from 'react';
import { Button } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import * as S from './index.styles';
import Representative from './Representative';
import { FORM_SECTIONS } from '../Form';

import FormCard from '../Common/FormCard';
import { usePercentage, viewPercentage } from '../utils.percentage';

const Representatives = () => {
    const { values, setFieldValue } = useFormikContext<any>();
    const { Representatives: RepresentativesValue } = values;
    const [percentageTotal, setPercentageTotal] = React.useState<string | number>(0);

    const percentSum =
        values && values.Representatives
            ? values.Representatives.reduce(
                  (sum, representative) => (representative ? representative.Percentage : 0) + sum,
                  0,
              )
            : 0;

    const onRemoveRepresentative = representative => {
        const index = values.Representatives.indexOf(representative);
        if (index >= 0) {
            values.Representatives.splice(index, 1);
            setFieldValue('Representatives', [...values.Representatives]);
        }
    };

    const onAddRepresentative = () => {
        setFieldValue('Representatives', [
            ...values.Representatives,
            {
                Id: `Representative-${Math.random()}`,
                ClientId: values.ClientId,
                CpfCnpj: '',
                Name: '',
                Percentage: 0,
                Category: null,
            },
        ]);
    };

    usePercentage(RepresentativesValue, setPercentageTotal);

    return (
        <S.StyledRepresentatives id={FORM_SECTIONS.REPRESENTATIVES.ID}>
            <FormCard title={FORM_SECTIONS.REPRESENTATIVES.NAME}>
                {values &&
                    values.Representatives &&
                    values.Representatives.map((representative, index) => (
                        <Representative
                            key={`${representative.Id}-${index}`}
                            index={index}
                            hasError={percentSum !== 100}
                            onClose={
                                values.Representatives.length > 1
                                    ? () => onRemoveRepresentative(representative)
                                    : null
                            }
                        />
                    ))}
                {percentSum !== 100 && (
                    <S.StyledNotification type="error" visible={percentSum !== 100} icon={false}>
                        O somatório do percentual dos representantes deve ser exatemente 100.
                    </S.StyledNotification>
                )}
                {viewPercentage(percentageTotal)}
                <Button type="button" secondary fullWidth onClick={onAddRepresentative}>
                    ADICIONAR REPRESENTANTES +
                </Button>
            </FormCard>
        </S.StyledRepresentatives>
    );
};

export default Representatives;
