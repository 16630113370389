export interface BankAccount {
    AccountDigit: string;
    AccountId: number;
    AccountNumber: string;
    AccountType: number;
    AgencyDigit: number;
    AgencyNumber: string;
    BankLogoUrl: string;
    BankName: string;
    BankNumber: string;
    ClientId: number;
    CpfCoOwner?: string;
    NameCoOwner?: null;
}

const normalizeBank = (bank: any): BankAccount => ({
    ...bank,
});

export const normalizeBanks = (banks: any): Array<BankAccount> => {
    if (!banks || !banks.map) {
        return [];
    }
    const banksNormalized: Array<BankAccount> = banks.map((bank: any) => normalizeBank(bank));

    return banksNormalized;
};
