import { Actions, ERROR_MESSAGES } from '../index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizePortfolioVariation } from '../index.normalize';

export const fetchEquityFlutuationInit = (date, account) => ({
    type: Actions.FETCH_EQUITY_FLUTUATION_INIT,
    date,
    account,
});

export const fetchEquityFlutuation = async (date, account) => {
    const url = ENDPOINTS.GET_EQUITY_FLUTUATION.replace(':date', date).replace(':account', account);

    try {
        const response = await fetch(url, { method: 'GET' });
        const content = await response.json();

        return normalizePortfolioVariation(content);
    } catch (error) {
        if (
            error.responseJson &&
            error.response.status === 400 &&
            error.responseJson.Message === ''
        ) {
            throw new Error(ERROR_MESSAGES.FETCH_ERROR_DEFAULT);
        } else {
            throw error;
        }
    }
};

export const fetchEquityFlutuationSuccess = data => ({
    type: Actions.FETCH_EQUITY_FLUTUATION_SUCCESS,
    data,
});

export const fetchEquityFlutuationError = error => ({
    type: Actions.FETCH_EQUITY_FLUTUATION_ERROR,
    error: error.message,
});
