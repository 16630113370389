import { formatCurrency } from '@terrainvest/react-components';
import { getRedemptionType } from '../../Utils/Parsers/redemptionType.parser';
import { colors } from '../../View/Common/Colors';

const fixedIncomeRentabilityNormalizer = value => (value.includes('%') ? value : `${value}%`);

const NORMALIZE_DISPLAY_FIELDS_FIXED_INCOME = [
    {
        key: 'Rentability',
        label: 'Rentabilidade',
        normalizer: fixedIncomeRentabilityNormalizer,
    },
    {
        key: 'MinimalApplication',
        label: 'Aplicação Mínima',
        normalizer: value => formatCurrency(value),
    },
    { key: 'Tax', label: 'Imposto de Renda', normalizer: value => value || '-' },
];

const fundsRentabilityNormalizer = value => (value.includes('%') ? value : value.concat('%'));

const NORMALIZE_DISPLAY_FIELDS_FUNDS = [
    {
        key: 'MinimalApplication',
        label: 'Aplicação Mínima',
        normalizer: value => formatCurrency(value),
    },
    {
        key: 'Rentability',
        label: 'Rentabilidade (12 Meses)',
        normalizer: fundsRentabilityNormalizer,
    },
    { key: 'Suitability', label: 'Perfil Suitability', normalizer: value => value || '-' },
];

const NORMALIZE_CARD_FIELDS = [
    {
        key: 'Rentability',
        normalizer: item =>
            isFixedIncome(item)
                ? fixedIncomeRentabilityNormalizer(item.Rentability)
                : fundsRentabilityNormalizer(item.Rentability),
    },
    {
        key: 'MinimalApplication',
        normalizer: item => formatCurrency(item.MinimalApplication),
    },
    {
        key: 'Liquidity',
        normalizer: item => (isFixedIncome(item) ? getLiquidityField(item).value : ''),
    },
];

const normalizeCardFields = opportunity => {
    const normalizedObj = { ...opportunity };

    NORMALIZE_CARD_FIELDS.forEach(field => {
        normalizedObj[`normalized${field.key}`] = field.normalizer(opportunity);
    });

    return normalizedObj;
};

const isFixedIncome = asset => asset.OpportunityType.Id === 1;
const isFunds = asset => asset.OpportunityType.Id === 2;

const getRedemptionLabel = opportunity => {
    return opportunity.OpportunityType.Id === 1 ? 'Vencimento' : 'Prazo para Cotização';
};

const getRedemptionLiquidationLabel = opportunity => {
    return opportunity.OpportunityType.Id === 1 ? 'Liquidez' : 'Prazo de Liquidação';
};

const normalizeRedemption = opportunity => {
    const normalizedObj = { ...opportunity };

    const type = getRedemptionType(normalizedObj.RedemptionType);
    const period = `${normalizedObj.RedemptionDays} dias${type ? ` (${type})` : ''}`;

    const newField = {
        label: getRedemptionLabel(normalizedObj),
        rawValue: '',
        value: period,
    };

    normalizedObj.normalized = {
        ...normalizedObj.normalized,
        Redemption: { ...newField },
    };

    return normalizedObj;
};

const getLiquidityField = item => {
    const type = getRedemptionType(item.RedemptionTypeLiquidation);
    const period = `${item.RedemptionDaysLiquidation} dias${type ? ` (${type})` : ''}`;
    const isDailyLiquidity = item.DialyLiquidity ? 'Diária' : 'No vencimento';

    return {
        label: getRedemptionLiquidationLabel(item),
        rawValue: '',
        value: isFixedIncome(item) ? isDailyLiquidity : period,
    };
};

const normalizeRedemptionLiquidation = opportunity => {
    const normalizedObj = { ...opportunity };

    if (!normalizedObj || normalizedObj.RedemptionDaysLiquidation === undefined) {
        return opportunity;
    }

    const newField = getLiquidityField(normalizedObj);
    normalizedObj.normalized = {
        ...normalizedObj.normalized,
        RedemptionLiquidation: { ...newField },
    };

    return normalizedObj;
};

const normalizeDisplayFields = opportunity => {
    const normalizedObj = { ...opportunity };

    normalizedObj.DISPLAY_FIELDS.forEach(field => {
        const normalizedField = {
            label: field.label,
            rawValue: normalizedObj[field.key],
            value: field.normalizer(normalizedObj[field.key]),
        };

        normalizedObj.normalized = {
            ...normalizedObj.normalized,
            [field.key]: { ...normalizedField },
        };
    });

    return normalizedObj;
};

const initialTriage = opportunity => {
    const normalizedObj = { ...opportunity, normalized: {}, DISPLAY_FIELDS: [] };

    if (isFixedIncome(normalizedObj)) {
        normalizedObj.DISPLAY_FIELDS = NORMALIZE_DISPLAY_FIELDS_FIXED_INCOME;
        normalizedObj.displayColor = colors.fixedincome;
    }

    if (isFunds(normalizedObj)) {
        normalizedObj.DISPLAY_FIELDS = NORMALIZE_DISPLAY_FIELDS_FUNDS;
        normalizedObj.displayColor = colors.funds;
    }

    return {
        ...normalizedObj,
        normalizedOpportunityTypeDescription: normalizedObj.OpportunityType.Description,
    };
};

const finalCleanup = opportunity => {
    const normalizedObj = { ...opportunity };
    delete normalizedObj.DISPLAY_FIELDS;

    return normalizedObj;
};

export const normalizer = opportunities => {
    return [...opportunities]
        .map(initialTriage)
        .map(normalizeDisplayFields)
        .map(normalizeRedemption)
        .map(normalizeRedemptionLiquidation)
        .map(normalizeCardFields)
        .map(finalCleanup);
};
