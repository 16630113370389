import * as React from 'react';
import { helpLink } from '../../../../Utils/General/CommonInfo';

import * as S from './index.styles';

const SearchInput = ({ filter, disabled, setFilter, emptyResults }) => {
    const renderEmptyResults = emptyResults && !!filter;

    const handleHelpClick = () => window.open(helpLink, '_blank');

    return (
        <S.StyledWrapper>
            <S.StyledInputText
                value={filter}
                disabled={disabled}
                onChange={e => setFilter(e.target.value)}
                placeholder="Buscar no menu"
                placeholderShown
                icon={<S.SearchIcon icon={['far', 'magnifying-glass']} isClear={!filter} />}
                fullWidth
            />
            {renderEmptyResults && (
                <S.StyledEmptyResults>
                    <S.StyledText>
                        Não encontramos nenhum resultado para <b>“{filter}”</b>.
                    </S.StyledText>
                    <br />
                    <S.StyledSmallText>
                        <b>Dicas de pesquisa:</b>
                    </S.StyledSmallText>
                    <S.StyledUl>
                        <S.StyledLi>
                            <S.StyledSmallText>Verifique a ortografia</S.StyledSmallText>
                        </S.StyledLi>
                        <S.StyledLi>
                            <S.StyledSmallText>Mude sua pesquisa</S.StyledSmallText>
                        </S.StyledLi>
                        <S.StyledLi>
                            <S.StyledSmallText>Seja menos específico</S.StyledSmallText>
                        </S.StyledLi>
                    </S.StyledUl>
                    <br />
                    <S.StyledSmallText>
                        <b>Se não encontrar o que está procurando:</b>
                    </S.StyledSmallText>
                    <S.StyledHelpButton>
                        <S.StyledSmallText>Ajuda</S.StyledSmallText>
                        <S.StyledIcon icon={['far', 'chevron-right']} onClick={handleHelpClick} />
                    </S.StyledHelpButton>
                </S.StyledEmptyResults>
            )}
        </S.StyledWrapper>
    );
};

export default SearchInput;
