import { formatCurrency } from '@terrainvest/react-components';
import { parseDateForDisplay } from '../../Utils/Parsers/timeParsers';

export const normalizeOperations = operations => {
    return operations.map(operation => {
        return {
            ...operation,
            OrderDateNormalized: parseDateForDisplay(operation.OrderDate),
            AmountNormalized: formatCurrency(operation.Amount),
        };
    });
};
