import { fetch, ENDPOINTS, postData } from '../../Utils/Api';
import { Actions } from './index.constants';
import { normalizePaidCustodyStatus } from './index.normalize';

export const postClientAgreement = data =>
    postData(data, ENDPOINTS.POST_COMPLIANCE_CLIENT_AGREEMENT);

export const fetchPaidCustodyStatusInit = account => ({
    type: Actions.PAID_CUSTODY_FETCH_INIT,
    account,
});

export const fetchPaidCustodyStatus = account => {
    const url = ENDPOINTS.GET_PAID_CUSTODY_STATUS.replace(':account', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePaidCustodyStatus);
};

export const fetchPaidCustodyStatusSuccess = paidCustody => ({
    type: Actions.PAID_CUSTODY_FETCH_SUCCESS,
    paidCustody,
});

export const fetchPaidCustodyStatusError = () => ({
    type: Actions.PAID_CUSTODY_FETCH_ERROR,
});

export const setPaidCustody = data => {
    const method = data.Id ? 'PUT' : 'POST';
    return postData(data, ENDPOINTS.SET_PAID_CUSTODY_STATUS, method);
};
