import styled from 'styled-components';
import { Button } from '@terrainvest/react-components';
import { StyledLogin } from '../../index.styles';
import { StyledColNoPadd } from '../../../Common/Grid';

export const StyledSelectAccount = styled(StyledLogin)`
    & > :nth-child(2) {
        margin-bottom: 20px;
    }

    ${Button.Styles.StyledButton} {
        margin-bottom: 8px;
        font-size: 16px;
    }
`;

export const StyledCol = styled(StyledColNoPadd)`
    height: 100vh;
`;
