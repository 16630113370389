export const Actions = {
    WITHDRAW_HISTORY_FETCH_INIT: 'WITHDRAW_HISTORY_FETCH_INIT',
    WITHDRAW_HISTORY_FETCH_SUCCESS: 'WITHDRAW_HISTORY_FETCH_SUCCESS',
    WITHDRAW_HISTORY_FETCH_ERROR: 'WITHDRAW_HISTORY_FETCH_ERROR',
    WITHDRAW_HISTORY_SET_TRANSFER_ID_TO_CANCEL: 'WITHDRAW_HISTORY_SET_TRANSFER_ID_TO_CANCEL',
    WITHDRAW_HISTORY_RESET_TRANSFER_ID_TO_CANCEL: 'WITHDRAW_HISTORY_RESET_TRANSFER_ID_TO_CANCEL',
    WITHDRAW_GO_TO_NEXT_PAGE: 'WITHDRAW_GO_TO_NEXT_PAGE',
    WITHDRAW_GO_TO_PREVIOUS_PAGE: 'WITHDRAW_GO_TO_PREVIOUS_PAGE',
    WITHDRAW_SET_FILTER: 'WITHDRAW_SET_FILTER',
};
