import * as React from 'react';
import { H1, Text } from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import SuitabilityQuestion from './SuitabilityQuestion';
import {
    StyledSuitability,
    StyledSkeleton,
    StyledDetails,
    StyledProfileName,
    StyledCard,
    StyledContainer,
} from './index.styles';
import { StyledCol, StyledRow } from '../Details/index.styles';
import useWindowSize from '../../../Utils/Hooks/useWindowSize';
import { FORM_SECTIONS } from '../Form';

const Suitability = ({ noCards = false }) => {
    const { values } = useFormikContext<any>();
    const windowSize = useWindowSize();

    return values && ((values || {}).SuitabilityProfile || {} || []).Questions ? (
        <StyledSuitability id={FORM_SECTIONS.SUITABILITY.ID}>
            <H1>{FORM_SECTIONS.SUITABILITY.NAME}</H1>
            <StyledDetails>
                <Text>
                    Perfil atual:{' '}
                    <StyledProfileName profile={values.SuitabilityProfile.UserProfile}>
                        {values.SuitabilityProfile.UserProfileName || 'Sem Perfil'}
                    </StyledProfileName>
                </Text>

                <Text>
                    Válido até:{' '}
                    {
                        new Date(values.SuitabilityProfile.Validity)
                            .toLocaleString('pt-BR')
                            .split(' ')[0]
                    }
                </Text>
            </StyledDetails>

            <Text large id="sub-title">
                Questionário abaixo:
            </Text>
            {values.SuitabilityProfile.Questions.map((question, index) =>
                !noCards ? (
                    <StyledCard key={index.toString()} light shadow>
                        <SuitabilityQuestion question={question} index={`[${index}]`} />
                    </StyledCard>
                ) : (
                    <StyledContainer>
                        <SuitabilityQuestion question={question} index={`[${index}]`} />
                    </StyledContainer>
                ),
            )}
        </StyledSuitability>
    ) : (
        <StyledSuitability id={FORM_SECTIONS.SUITABILITY.ID}>
            <H1>{FORM_SECTIONS.SUITABILITY.NAME}</H1>
            <StyledDetails>
                <Text>
                    Perfil atual: <StyledSkeleton height={14} width={91} duration={2} />
                </Text>

                <Text>
                    Válido até: <StyledSkeleton height={14} width={70} duration={2} />
                </Text>
            </StyledDetails>
            <Text id="sub-title">Questionário abaixo:</Text>
            <StyledRow>
                <StyledCol col={12}>
                    <StyledSkeleton height={144} width={windowSize.width} duration={2} />
                </StyledCol>
            </StyledRow>
        </StyledSuitability>
    );
};

export default Suitability;
