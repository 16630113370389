import styled from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
`;

export const StyledDescription = styled(Text)`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
    color: #52525b !important;
`;

export const StyledItemWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 2fr 20px;
    margin-bottom: 8px;
    cursor: pointer;
`;

export const StyledItemText = styled(Text)`
    margin: 0;
    font-size: ${({ theme }) => theme.global.fontSizes.ultraviolet};
    color: #52525b !important;
`;

export const StyledItemTextRight = styled(StyledItemText)`
    font-weight: ${({ theme }) => theme.global.fontWeights.medium};
    text-align: right;
`;

export const StyledIcon = styled(Icon)`
    text-align: right;
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
    line-height: ${({ theme }) => theme.global.fontSizes.xRay};
    color: ${({ theme }) => theme.global.colors.primary};
`;
