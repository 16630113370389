import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchAvailableReports,
    fetchAvailableReportsSuccess,
    fetchAvailableReportsError,
} from './index.actions';

export const initialState = {
    availableReports: undefined,
    loading: false,
    error: false,
};

export const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_AVAILABLE_REPORTS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchAvailableReports, {
                    successActionCreator: fetchAvailableReportsSuccess,
                    failActionCreator: fetchAvailableReportsError,
                }),
            );

        case Actions.FETCH_AVAILABLE_REPORTS_SUCCESS:
            return {
                ...state,
                availableReports: action.reports,
                loading: false,
                error: false,
            };

        case Actions.FETCH_AVAILABLE_REPORTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};
