import * as React from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { H2, Text, Link, Button } from '@terrainvest/react-components';

import { StyledContainerNoPadd } from '../../View/Common/Grid';
import Separator from '../../Utils/General/index.separator';

import {
    StyledWrapper,
    StyledTextFeedbackWrapper,
    StyledImageWrapper,
    StyledImage,
    StyledContentWrapper,
} from './index.styles';

import * as errorImg from './Assets/error-img.svg';

export const ErrorFeedback = () => {
    const history = useHistory();
    const homeUrlFragment = '/app';

    React.useEffect(() => {
        const unregisterListenerCallback = listenForLocationChange();

        return () => {
            unregisterListenerCallback();
        };
    }, [history]);

    const listenForLocationChange = () => {
        return history.listen(refreshWhenLocationIsHome);
    };

    const refreshWhenLocationIsHome = location => {
        const isUserAtHome = location.pathname === homeUrlFragment;

        if (isUserAtHome) {
            refreshApplication();
        }
    };

    const refreshApplication = () => {
        window.location.reload();
    };

    return (
        <StyledContainerNoPadd fluid>
            <StyledWrapper>
                <StyledContentWrapper>
                    <StyledTextFeedbackWrapper>
                        <H2>Ops, algo deu errado!</H2>
                        <Text>Você pode tentar carregar a página novamente.</Text>
                        <Text>Caso o problema persista, entre em contato com nosso suporte.</Text>
                        <Separator />
                        <Link to="/app" border={false} wrapper={RouterLink}>
                            Retornar à página inicial
                        </Link>
                        <Separator base={4} />
                        <Button primary onClick={refreshApplication}>
                            Recarregar a página
                        </Button>
                    </StyledTextFeedbackWrapper>
                    <StyledImageWrapper>
                        <StyledImage src={errorImg} />
                    </StyledImageWrapper>
                </StyledContentWrapper>
            </StyledWrapper>
        </StyledContainerNoPadd>
    );
};

export default ErrorFeedback;
