import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SpecificAuthorizer from '../../../Authorization/SpecificAuthorizer';
import UserDataExpiration from '../../../Authorization/UserDataExpiration';
import SuitabilityAuthorizer from '../../../Authorization/SuitabilityAuthorizer';
import ChannelAuthorizer from '../../../Authorization/ChannelAuthorizer';

interface AuthorizerInterface {
    authIdentifier?: string;
    allowRedirect?: boolean;
    suitabilityRestriction?: number;
    blockInstitutional?: boolean;
    children?: React.ReactNode;
}

/**
 * Wraps a component with authorization rule.
 * The returned component will have a new prop **authIdentifier**
 *
 * Use this prop to match the predefined authorizations.
 *
 * @see src/Authorization/index.authorizations.ts
 */
export const withAuth = (
    Component: React.FC<AuthorizerInterface> | React.ComponentClass<AuthorizerInterface>,
) => {
    const ComponentWithAuth = ({
        authIdentifier,
        allowRedirect,
        suitabilityRestriction,
        blockInstitutional,
        ...otherProps
    }: AuthorizerInterface | any) => {
        const { auth: sessionAuth } = useSelector(state => state.authState);

        const history = useHistory();

        if (!sessionAuth) {
            history.push('/login');
            return null;
        }

        return (
            <SpecificAuthorizer identifier={authIdentifier}>
                <SuitabilityAuthorizer
                    allowRedirect={allowRedirect}
                    suitabilityRestriction={suitabilityRestriction}
                >
                    <ChannelAuthorizer
                        allowRedirect={allowRedirect}
                        blockInstitutional={blockInstitutional}
                    >
                        <Component {...otherProps} />
                        <UserDataExpiration />
                    </ChannelAuthorizer>
                </SuitabilityAuthorizer>
            </SpecificAuthorizer>
        );
    };

    return ComponentWithAuth;
};
