import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { normalizeRecommendations } from './index.normalize';

export const fetchRecommendationsHistoryInit = id => ({
    type: Actions.FETCH_RECOMMENDATIONS_HISTORY_INIT,
    id,
});

export const fetchRecommendationsHistory = id => {
    return fetch(ENDPOINTS.GET_RECOMMENDATION_HISTORY.replace(':id', id), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeRecommendations);
};

export const fetchRecommendationsHistorySuccess = table => ({
    type: Actions.FETCH_RECOMMENDATIONS_HISTORY_SUCCESS,
    table,
});

export const fetchRecommendationsHistoryError = error => ({
    type: Actions.FETCH_RECOMMENDATIONS_HISTORY_ERROR,
    error: error.message,
});

export const fetchRecommendationsHistoryReset = () => ({
    type: Actions.FETCH_RECOMMENDATIONS_HISTORY_RESET,
});
