export enum BannerImageOrientation {
    PORTRAIT = 1,
    LANDSCAPE = 2,
}

export interface BannerImage {
    Id: number;
    Name: string;
    Extension: string;
    Orientation: BannerImageOrientation;
    OrientationDescription: string;
    BannerId: number;
    FileUrl: string;
}

export interface BannerResponse {
    StartDate: string;
    EndDate: string;
    Title: string;
    Message: string;
    Type: number;
    TypeDescription: string;
    Url: string;
    UserRegisterId: number;
    RegisterDate: string;
    UserModificationId: number;
    ModificationDate: string;
    Id: number;
    Files?: BannerImage[];
}

export interface BannerResponseNormalized extends BannerResponse {
    startDateNormalized: string;
    endDateNormalized: string;
    startDateObject: Date;
    endDateObject: Date;
}

export interface BannersState {
    banners: BannerResponseNormalized[];
    wasBannerRead: boolean;
    loading: boolean;
    error?: string | Error;
}
