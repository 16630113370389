import * as React from 'react';

import { FORM_SECTIONS, FormContext } from '../../../Form';

interface LimitAttachmentsProps {
    anchor: any;
    children: React.ReactNode;
}

const LimitAttachments: React.FC<LimitAttachmentsProps> = ({ anchor, children }) => {
    const { isUploadVisible } = React.useContext(FormContext);

    const hideAttachments = anchor.ID === FORM_SECTIONS.ATTACHMENTS.ID && !isUploadVisible;

    return !hideAttachments && <>{children}</>;
};

export default LimitAttachments;
