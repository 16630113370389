import moment from 'moment';
import { parseDateForDisplay } from '../../Utils/Parsers';

const normalizeDates = dates => {
    return [...dates].map(d => ({
        id: d.Id,
        value: parseDateForDisplay(d.Date, ['DD/MM/YYYY', moment.ISO_8601]),
    }));
};

export const normalizeReport = rawReport => {
    return {
        id: rawReport.Id,
        type: rawReport.Type,
        description: rawReport.Description,
        active: rawReport.Active,
        periodDays: rawReport.PeriodDays,
        dates: rawReport.Dates,
        normalizedDates: normalizeDates(rawReport.Dates),
    };
};

export const normalizeAvailableReports = (incoming: Array<any>) => {
    const normalized = [...incoming].filter(r => r.Active).map(normalizeReport);

    return normalized;
};
