import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 80vh;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;

    ${media.max.tablet`
        justify-content: center;
        align-items: center;
        flex-flow: column-reverse; 

        & button { 
            width: 100%;
        }
    `}
`;

export const StyledTextFeedbackWrapper = styled.div`
    max-width: 64%;
    margin-right: 40px;

    ${media.max.tablet`
       margin: 0;
       margin-top: 80px;
       max-width: 100%;
    `}
`;

export const StyledImageWrapper = styled.div`
    ${media.max.tablet`
        margin-top: -20vh;
    `}
`;

export const StyledImage = styled.img`
    width: 200px;
    height: 200px;

    ${media.max.sm`
        margin: 8px;
    `}

    ${media.max.tablet`
       width: 80vw;
    `}
`;
