import { stringToDate, dateToString } from '@terrainvest/react-components';
import { denormalizeOutgoingAssetManagers } from '../../Redux/Client/AssetManager/index.denormalize';
import { denormalizeOutgoingProcurators } from '../../Redux/Client/Procurator/index.denormalize';
import { channelIds } from '../../Redux/Client';
import { normalizeString } from '../../Utils/Parsers';
import { UploadFile } from './index.types';

const deepCopy = value => JSON.parse(JSON.stringify(value || null));

export const normalize = values => {
    if (!values) {
        return null;
    }

    const BirthDate = dateToString(new Date(values.BirthDate)) || '';
    const DocumentIssuerDate = dateToString(new Date(values.DocumentIssuerDate)) || '';

    const MobilePhoneNumber = `${values.MobilePhonePrefix || ''}${values.MobilePhoneNumber || ''}`;

    const sanitizedValues = Object.entries(values).reduce(
        (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue[0]]:
                typeof currentValue[1] === 'string' ? currentValue[1].trim() : currentValue[1],
        }),
        {},
    );

    const BankAccounts = values.BankAccounts.map(bankAccount => ({
        ...bankAccount,
        AccountType: bankAccount.NameCoOwner ? 1 : 0,
    }));

    return deepCopy({
        ...sanitizedValues,
        BirthDate,
        DocumentIssuerDate,
        MobilePhoneNumber,
        BankAccounts,
    });
};

const deNormalizeBankAccounts = values => {
    const { BankAccounts } = values;
    return BankAccounts.map(e =>
        e.CpfCoOwner ? { ...e, CpfCoOwner: normalizeString(e.CpfCoOwner) } : e,
    );
};

const removeSuitabilityProfile = values => {
    const payload = { ...values };

    if (payload.SuitabilityProfile) {
        delete payload.SuitabilityProfile;
    }

    return { ...payload };
};

const removeUnnecessaryProps = values => {
    const valuesParsed = { ...values };

    if (values.DocumentTypeId === 'RN') {
        delete valuesParsed.DocumentIssuerName;
    }

    delete valuesParsed.DocumentSecondaryNumber;

    return { ...valuesParsed };
};

const denormalizeWorkingStatusAndInfo = values => {
    const payload = { ...values };

    if (!payload.CurrentlyWorking) {
        payload.CompanyCnpj = '';
        payload.CompanyName = '';
    }

    return { ...payload };
};

const removeRPLStatus = values => {
    const payload = { ...values };
    if (payload && payload.RLP) {
        delete payload.RLP;
    }

    return { ...payload };
};

const addChannelId = values => {
    if (values.isCompany) {
        return {
            ...values,
            ChannelId: values.StatusFatcaId ? channelIds.INSTITUTIONAL : channelIds.PJ,
        };
    }
    return values;
};

export const deNormalizeRepresentatives = values => {
    const representativeNormalized = values.Representatives.map(representative => {
        const payload = { ...representative };

        if (payload && payload.DocumentType === 'Cnpj') {
            delete payload.Cpf;
        } else {
            delete payload.Cnpj;
        }

        return { ...payload };
    });

    return representativeNormalized;
};

export const deNormalize = (values: any = {}, ElectronicCheck: string) => {
    const BirthDate = stringToDate(values.BirthDate) || '';
    const DocumentIssuerDate = stringToDate(values.DocumentIssuerDate) || '';
    const normalizeRepresentatives = deNormalizeRepresentatives(values);

    const matches = /(\d{2})(.+)/.exec(values.MobilePhoneNumber) || [];
    const MobilePhonePrefix = matches[1] || '';
    const MobilePhoneNumber = (matches[2] || '').replace(/[^\d]/g, '');
    const denormalizedBankAccounts = values.BankAccounts ? deNormalizeBankAccounts(values) : null;
    const denormalizedProcurators = values.Procurators
        ? denormalizeOutgoingProcurators(values)
        : null;

    const denormalizedAssetManagers = values.AssetManagers
        ? denormalizeOutgoingAssetManagers(values)
        : null;

    const denormalizedSpouseCpf = values.SpouseCpf
        ? values.SpouseCpf.replace(/[^\d]/g, '')
        : undefined;

    let payload = { ...values };

    payload = removeSuitabilityProfile(payload);
    payload = removeRPLStatus(payload);
    payload = denormalizeWorkingStatusAndInfo(payload);
    payload = addChannelId(payload);
    payload = removeUnnecessaryProps(payload);

    return deepCopy({
        ...payload,
        BirthDate,
        DocumentIssuerDate,
        MobilePhoneNumber,
        MobilePhonePrefix,
        ElectronicCheck,
        Representatives: normalizeRepresentatives || values.Representatives,
        BankAccounts: denormalizedBankAccounts || values.BankAccounts,
        Procurators: denormalizedProcurators || values.Procurators,
        AssetManagers: denormalizedAssetManagers || values.AssetManagers,
        SpouseCpf: denormalizedSpouseCpf,
        files: undefined,
    });
};

export const deNormalizeNoDataChanged = (
    values: any = {},
    NoChangesRegistrationData: boolean,
    ElectronicCheck: string,
) => {
    return deepCopy({
        ClientId: values.ClientId,
        UserId: values.UserId,
        NoChangesRegistrationData,
        ElectronicCheck,
    });
};

export const normalizeFiles = (filesObject: Record<string, any> | null): Array<UploadFile> => {
    if (!filesObject) return [];
    const files = Object.values(filesObject);
    return files.filter(file => !!file.clientId && !!file.type && !!file.file);
};
