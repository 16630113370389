import { parseDate } from '../../Utils/Parsers';
import { formatComplexValues } from '../../Utils/Parsers/numberParsers';

export const normalizeEvolutionChartsPeriods = data =>
    data.map(item => ({
        value: item.Description,
        id: item.Id,
    }));

export const normalizeEvolutionChartsData = data => {
    return data.map(item => ({
        ...item,
        Carteira: item.Portfolio,
        Mes: parseDate(item.Date, 'MM/YYYY'),
    }));
};

export const normalizePortfolioVariation = data => ({
    patrimonyDay: formatComplexValues(data.PatrimonyDay, {}),
    patrimonyLastDay: formatComplexValues(data.PatrimonyLastDay, {}),
    variationFinancial: data.VariationFinancial,
    variationPercentage: `${data.VariationPercentage.toFixed(2)} %`,
    pendingLiquidation: formatComplexValues(data.PendingLiquidation, {}),
    quantityQuotas: data.QuantityQuotas.toFixed(2),
    startDate: parseDate(data.StartDate, 'DD/MM/YYYY'),
});

export const normalizeEvolutionValueChartsData = data =>
    data.map(item => ({
        Carteira: item.Portfolio,
        Date: item.Date,
    }));
