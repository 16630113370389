import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizeStatus } from './index.normalize';

export const fetchStatusInit = () => ({
    type: Actions.FETCH_STATUS_INIT,
});
// ACTION BAIXAR MES
export const fetchStatus = () => {
    return fetch(ENDPOINTS.GET_POSITION_TRANSFER_STATUS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeStatus);
};

export const fetchStatusSuccess = status => ({
    type: Actions.FETCH_STATUS_SUCCESS,
    status,
});

export const fetchStatusError = error => ({
    type: Actions.FETCH_STATUS_ERROR,
    error: error.message,
});

export const fetchStatusReset = () => ({
    type: Actions.FETCH_STATUS_RESET,
});
