import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import FinancialOverview from '../../FinancialOverview';

import TEDAccount from './TEDAccount';
import * as S from './index.styles';

type FinancialTypes = 'Financial' | 'Account' | '';

const MobileFinancialOverview = ({ reset }) => {
    const { sections: allSections } = useSelector(
        (state: RootState) => state.partnerFunctionalityState,
    );

    const shouldShowInfo = (infoTitle: string) => {
        if (!allSections) return false;
        return JSON.stringify(allSections).includes(infoTitle);
    };

    const [open, setOpen] = React.useState<FinancialTypes>('Financial');

    const handleClick = (type: FinancialTypes) => {
        if (open === type) {
            setOpen('');
            return;
        }
        setOpen(type);
    };

    React.useEffect(() => {
        if (reset) setOpen('Financial');
    }, [reset]);

    const renderFinancial = (financialOpen: boolean, type: FinancialTypes) => (
        <S.StyledInfoWrapper>
            <S.StyledTitleWrapper open={financialOpen}>
                <S.StyledTitle>Resumo Financeiro</S.StyledTitle>
                <S.StyledHiddenBalanceSwitch iconType="far" />
                {!financialOpen && (
                    <S.StyledIcon
                        icon={['far', 'chevron-down']}
                        onClick={() => handleClick(type)}
                    />
                )}
            </S.StyledTitleWrapper>
            {financialOpen && <FinancialOverview onlyValues />}
        </S.StyledInfoWrapper>
    );

    const renderTEDAccount = (accountOpen: boolean, type: FinancialTypes) => (
        <S.StyledInfoWrapper>
            <S.StyledTitleWrapper open={accountOpen}>
                <S.StyledTitle>Minha conta para TED</S.StyledTitle>
                <S.StyledIcon
                    icon={['far', accountOpen ? 'chevron-up' : 'chevron-down']}
                    onClick={() => handleClick(type)}
                />
            </S.StyledTitleWrapper>
            {accountOpen && <TEDAccount />}
        </S.StyledInfoWrapper>
    );

    return (
        <S.StyledWrapper>
            {renderFinancial(open === 'Financial', 'Financial')}
            {renderTEDAccount(open === 'Account', 'Account')}

            {shouldShowInfo('Pagamentos') && (
                <S.StyledInfoWrapper>
                    <S.StyledTitleWrapper>
                        <S.StyledTitle>Terra Pagamentos</S.StyledTitle>
                        <S.StyledSoon>Em breve</S.StyledSoon>
                    </S.StyledTitleWrapper>
                </S.StyledInfoWrapper>
            )}

            {shouldShowInfo('Câmbio') && (
                <S.StyledInfoWrapper>
                    <S.StyledTitleWrapper>
                        <S.StyledTitle>Terra Câmbio</S.StyledTitle>
                        <S.StyledSoon>Em breve</S.StyledSoon>
                    </S.StyledTitleWrapper>
                </S.StyledInfoWrapper>
            )}
        </S.StyledWrapper>
    );
};

export default MobileFinancialOverview;
