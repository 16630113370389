import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers } from './index.normalize';

export const fetchInvestmentOffersInit = () => ({
    type: Actions.FETCH_OFFERS_INIT,
});

export const fetchInvestmentOffers = () => {
    return fetch(ENDPOINTS.GET_INVESTMENT_OFFERS, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchOffersSuccess = offers => ({
    type: Actions.FETCH_OFFERS_SUCCESS,
    offers,
});

export const fetchOffersError = error => ({
    type: Actions.FETCH_OFFERS_ERROR,
    error: error.message,
});

export const fetchFixedIncomeAsset = assetId => {
    const url = ENDPOINTS.GET_INVESTMENT_OFFER.replace(':id', assetId);
    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(offer => normalizeOffers([offer])[0]);
};
