import { formatCurrency } from '@terrainvest/react-components';
import { parseDateForDisplay } from '../../Utils/Parsers/timeParsers';

export const normalizeFundsHistory = operations => {
    return operations.map(operation => {
        return {
            ...operation,
            OperationDateNormalized: parseDateForDisplay(operation.OperationDate),
            QuotationDateNormalized: operation.QuotationDate
                ? parseDateForDisplay(operation.QuotationDate)
                : '-',
            LiquidationDateNormalized: parseDateForDisplay(operation.LiquidationDate),
            AmountNormalized: formatCurrency(operation.Amount),
        };
    });
};
