import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { logout } from '../../Redux/Auth';

export const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro ao processar sua requisição';

interface FetchProps {
    disableAutoLogout: boolean;
    useBaseUrl: boolean;
}

class RequestError extends Error {
    public response: Response;

    public responseJson: any;
}

export const fetch = (url, input: Partial<RequestInit & FetchProps>): Promise<Response> =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line
        const { store } = require('../../store'); // Avoid runtime circular dependency errors

        const token = store.getState().authState.auth
            ? store.getState().authState.auth.access_token
            : '';
        return fetchPolyfill(input.useBaseUrl !== false ? `${process.env.API_URL}${url}` : url, {
            ...input,
            headers: {
                ...input.headers,
                Authorization: `Bearer ${token}`,
            },
        }).then(async response => {
            if (!input.disableAutoLogout && response.status === 401) {
                store.dispatch(logout());
            }

            if (response.status !== 200 && response.status !== 201) {
                const defaultError = `${DEFAULT_ERROR_MESSAGE} (${response.status})`;
                let errorMessage = '';
                let json;

                try {
                    json = await response.json();
                    errorMessage = json.Message || defaultError;
                } catch {
                    errorMessage = defaultError;
                }

                const error = new RequestError(errorMessage);
                error.response = response;
                error.responseJson = json;

                reject(error);
            }

            resolve(response);
        });
    });
