import { Actions } from './index.constants';
import { ENDPOINTS, postData } from '../../Utils/Api';

export const postClientAgreementInit = data => ({
    type: Actions.COMPLIANCE_CLIENT_AGREEMENT_INIT,
    data,
});

export const postClientAgreement = data => {
    return postData(data, ENDPOINTS.POST_COMPLIANCE_CLIENT_AGREEMENT);
};

export const postClientAgreementSuccess = () => ({
    type: Actions.COMPLIANCE_CLIENT_AGREEMENT_SUCCESS,
});

export const postClientAgreementError = error => ({
    type: Actions.COMPLIANCE_CLIENT_AGREEMENT_ERROR,
    error: error.message,
});

export const postProfessionalInvestorDeclaration = data => {
    return postData(data, ENDPOINTS.POST_COMPLIANCE_CLIENT_AGREEMENT);
};
