import moment from 'moment';

const valueOrZero = value => value || 0;

const NORMALIZE_NULL_FIELDS = [
    {
        label: 'NetWorth',
        normalizer: valueOrZero,
    },
    {
        label: 'MonthlyAverageBilling',
        normalizer: valueOrZero,
    },
    {
        label: 'MonthlyAverageBillingDate',
        normalizer: date => (date ? moment(date).format('DD/MM/YYYY') : undefined),
    },
    {
        label: 'NetWorthDate',
        normalizer: date => (date ? moment(date).format('DD/MM/YYYY') : undefined),
    },
];

const normalizeNullFinancialFields = client => {
    const denormalizedObj = { ...client };

    denormalizedObj.Patrimony = denormalizedObj.Patrimony || {};

    NORMALIZE_NULL_FIELDS.forEach(field => {
        const rawField = denormalizedObj.Patrimony[field.label];
        denormalizedObj.Patrimony[field.label] = field.normalizer(rawField);
    });

    return denormalizedObj;
};

export const normalizePatrimony = client => {
    const normalizedClient = { ...client };

    return normalizeNullFinancialFields(normalizedClient);
};
