import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchPositionTransferReason,
    fetchPositionTransferReasonSuccess,
    fetchPositionTransferReasonError,
} from './index.actions';

export const initialState = {
    reasons: null,
    loading: false,
    error: null,
    fileUrl: null,
};

export const positionTransferReasonReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_POSITION_TRANSFER_REASON_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchPositionTransferReason, {
                    successActionCreator: fetchPositionTransferReasonSuccess,
                    failActionCreator: fetchPositionTransferReasonError,
                }),
            );

        case Actions.FETCH_POSITION_TRANSFER_REASON_SUCCESS:
            return { ...state, reasons: [...action.reasons], loading: false, error: false };

        case Actions.FETCH_POSITION_TRANSFER_REASON_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_POSITION_TRANSFER_REASON_RESET:
            return { ...state, reasons: null, loading: false, error: false };

        default:
            return state;
    }
};
