import { YearProps } from './index.constants';

export const normalizeBrokerage = dates => {
    const datesData = [...dates];
    return [...datesData].map(e => {
        return {
            id: e.Id,
            value: e.MovementDate,
        };
    });
};

export const normalizeLastBrokerageDates = (dates: any) => {
    const datesData = normalizeBrokerage(dates);
    const lastNotes = datesData.slice(0, 5);
    return lastNotes;
};

export const normalizeBrokerageYears = (years: any): Array<string> => {
    if (Array.isArray(years)) {
        return [...years];
    }
    return [];
};

export const normalizeBrokerageMonths = (months: any): Array<string> => {
    if (Array.isArray(months)) {
        return months.map(month => month.Id);
    }
    return [];
};

export const removeRequestedDate = (
    dates: Array<YearProps>,
    selectedYear: string,
    selectedMonth: string,
) => {
    const newDates = [...dates];
    const yearIndex = newDates.findIndex(date => date.year === selectedYear);

    const newMonths = newDates[yearIndex].months.filter(month => month !== selectedMonth);

    if (!newMonths.length) newDates.splice(yearIndex, 1);
    else newDates[yearIndex].months = newMonths;

    return newDates;
};
