import { Button } from '@terrainvest/react-components';
import * as React from 'react';
import { Separator } from '../../Utils/General/index.separator';

import * as backgroundImage from './assets/rlp-banner.png';
import {
    BANNER_TITLE,
    BANNER_INFOS,
    BANNER_ACCEPT_BUTTON_LABEL,
    BANNER_REFUSE_BUTTON_LABEL,
} from './index.constants';
import * as S from './index.styles';

const RLPInfoModal = ({ visible, handleClose, afterSubmit }) => {
    const onSubmit = () => afterSubmit();

    return (
        <S.StyledModal
            id="modal-rlp-info"
            closeOnClickOutside
            width={670}
            onClose={handleClose}
            visible={visible}
        >
            <S.StyledHeader image={backgroundImage}>{BANNER_TITLE}</S.StyledHeader>
            <Separator />
            <S.StyledWrapper>
                {BANNER_INFOS.map((info, index) => (
                    <S.StyledParagraph key={index.toString()}>
                        <S.StyledInfoTitle>{info.title}</S.StyledInfoTitle>
                        <S.StyledInfoSubtitle>
                            {info.subtitle}
                            {info.subtitle_link && (
                                <S.StyledLink
                                    href={info.link}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    border={false}
                                >
                                    {info.subtitle_link}
                                </S.StyledLink>
                            )}
                        </S.StyledInfoSubtitle>
                    </S.StyledParagraph>
                ))}
                <Separator />
                <S.StyledContainer>
                    <Button outline onClick={handleClose}>
                        {BANNER_REFUSE_BUTTON_LABEL}
                    </Button>
                    <Button onClick={onSubmit} primary>
                        {BANNER_ACCEPT_BUTTON_LABEL}
                    </Button>
                </S.StyledContainer>
            </S.StyledWrapper>
        </S.StyledModal>
    );
};

export default RLPInfoModal;
