import {
    SectionProps,
    SubSectionItemProps,
    SubSectionProps,
} from '../../View/SideBarNew/SideNav/index.types';
import { sideNavSections } from './index.constants';

const ICON_SPLIT_WORD = ' fa-';

// The icon fullname follows the pattern "fas fa-icon", but FontAwesomeIcon expects ['fas', 'icon']
const getIconArray = fullIconName => fullIconName.split(ICON_SPLIT_WORD);

export const normalizeFunctionalities = functionalities =>
    functionalities && functionalities.map
        ? functionalities.map(functionality => ({
              id: functionality.id,
              name: functionality.name,
              tooltip: functionality.tooltip,
              url: functionality.url,
              icon: getIconArray(functionality.icon),
              suitability: functionality.suitability,
              blockInstitutional: functionality.notAllowedInstitutional,
              bottomMenu: functionality.bottomMenu,
          }))
        : [];

export const normalizeSections = sections =>
    sections && sections.map
        ? sections.map(section => ({
              id: section.id,
              name: section.name,
              functionalities: normalizeFunctionalities(section.functionalities),
          }))
        : [];

const normalizeName = (name: string) =>
    name
        .replace(/\s/g, '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

export const normalizeHighlights = highlights =>
    highlights && highlights.map
        ? highlights.map(highlight => ({
              ...highlight,
              identifier: normalizeName(highlight.name),
          }))
        : [];

const validateIfIncludes = (
    allSectionsToShow: string[],
    validatable: SectionProps | SubSectionProps | SubSectionItemProps,
) =>
    allSectionsToShow.includes(validatable.title) ||
    allSectionsToShow.includes(validatable.diffTitle);

const createPath = (creatable: SubSectionProps | SubSectionItemProps) => {
    const { path, title } = creatable;
    return { path, title };
};

export const normalizeNewSections = sections => {
    if (!sections || !sections.length || !sections.map) return { sections: [], paths: [] };

    const showSections: string[] = sections.map(section => section.name);

    const sideNavSectionsMapped: Array<SectionProps> = sideNavSections.map(section => {
        const subSections = section.subSections.map(subSection => {
            const items = subSection.items.filter(item => validateIfIncludes(showSections, item));
            return { ...subSection, items };
        });

        const subSectionsValidated = subSections.filter(
            subSection => validateIfIncludes(showSections, subSection) || subSection.items.length,
        );

        return { ...section, subSections: subSectionsValidated };
    });

    const sideNavSectionsValidated = sideNavSectionsMapped.filter(
        section => validateIfIncludes(showSections, section) || section.subSections.length,
    );

    const paths: Array<{ path: string; title: string }> = [];

    sideNavSectionsValidated.forEach(section =>
        section.subSections.forEach(subSection => {
            if (subSection.path) paths.push(createPath(subSection));
            subSection.items.forEach(item => {
                if (item.path) paths.push(createPath(item));
            });
        }),
    );

    return { sections: sideNavSectionsValidated, paths };
};
