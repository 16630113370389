import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchBrokerageNoteDates,
    fetchBrokerageNoteDatesSuccess,
    fetchBrokerageNoteDatesError,
} from './index.actions';
import { removeRequestedDate } from './index.normalize';

export const initialState = {
    dates: [],
    lastNotes: [],
    loading: false,
    loaded: false,
    error: null,
    fileUrl: null,
};

export const brokerageNoteDatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_BROKERAGENOTE_DATES_INIT:
            return loop(
                { ...state, loading: true, loaded: false },
                Cmd.run(fetchBrokerageNoteDates, {
                    successActionCreator: fetchBrokerageNoteDatesSuccess,
                    failActionCreator: fetchBrokerageNoteDatesError,
                    args: [action.marketValue, action.account],
                }),
            );

        case Actions.FETCH_BROKERAGENOTE_DATES_SUCCESS:
            return {
                ...state,
                dates: action.dates,
                lastNotes: action.lastNotes,
                loading: false,
                loaded: true,
                error: false,
            };

        case Actions.FETCH_BROKERAGENOTE_DATES_ERROR:
            return { ...state, error: action.error, loading: false, loaded: false };

        case Actions.UPDATE_DATES_AFTER_REQUEST: {
            const newDates = removeRequestedDate(state.dates, action.year, action.month);
            return { ...state, dates: newDates };
        }

        case Actions.FETCH_BROKERAGENOTE_DATES_RESET:
            return { ...initialState };

        default:
            return state;
    }
};
