import moment from 'moment';
import { formatCurrency } from '@terrainvest/react-components';

const OFFER_FIELDS = [
    { label: 'PaymentEstimation', normalizer: value => moment(value).format('DD/MM/YYYY') },
    { label: 'GrossValue', normalizer: value => formatCurrency(value, { currency: '' }) },
    { label: 'NetValue', normalizer: value => formatCurrency(value, { currency: '' }) },
];

export const normalizeOffer = incomingOffer => {
    const normalizedOffer = { ...incomingOffer };

    OFFER_FIELDS.forEach(field => {
        const rawField = normalizedOffer[field.label];
        normalizedOffer[`${field.label}Normalized`] = field.normalizer(rawField);
    });

    return normalizedOffer;
};

export const normalizeOffers = offers => {
    return [...offers].map(offer => normalizeOffer(offer));
};
