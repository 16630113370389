import styled, { css } from 'styled-components';
import { Text, InputText, Icon } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledEmptyResults = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.global.spaces.highFrequencyXRay} 0;
    ${Text.Styles.StyledText} {
        margin: 0;
    }
`;

export const StyledInputText = styled(InputText)`
    width: 100%;
    padding: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    padding-right: ${({ theme }) => theme.global.spaces.lowFrequencyUltraviolet};

    background-color: unset;
    border-radius: ${({ theme }) => theme.global.spaces.highFrequencyGammaRay};
    color: ${({ theme }) => theme.global.colors.backgroundTextColor} !important;
    &::placeholder {
        color: ${({ theme }) => theme.components.input.unselected};
    }
    box-shadow: ${({ value, theme }) =>
        value ? `0 0 0 ${theme.global.spaces.gammaRay} ${theme.global.colors.primary}` : ''};
`;

export const SearchIcon = styled(Icon)`
    top: 50% !important;
    transform: translateY(-50%);
    color: ${({ isClear, theme }) =>
        isClear ? 'inherit' : theme.global.colors.primary} !important;
`;

export const StyledText = styled(Text)`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSmallText = styled(Text)`
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
`;

export const StyledUl = styled.ul`
    margin: 0;
    padding-left: ${({ theme }) => theme.global.spaces.highFrequencyGammaRay};
    padding-top: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    list-style: none;
`;

export const StyledLi = styled.li`
    display: flex;
    align-items: center;
    :before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        margin: 2px 4px;
        margin-bottom: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.global.colors.backgroundTextColor};
    }
`;

export const StyledHelpButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => css`
        margin-top: ${theme.global.spaces.lowFrequencyXRay} !important;
        padding: ${theme.global.spaces.lowFrequencyXRay} ${theme.global.spaces.xRay} !important;
        background-color: ${theme.global.colors.secondaryBackground};
        color: ${theme.global.colors.backgroundTextColor};
        transition: ${theme.components.header.transition};
    `};
    border-radius: 4px;
    text-decoration: none;
`;

export const StyledIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.global.colors.primary};
        margin-right: ${theme.global.spaces.lowFrequencyXRay};
    `};
    cursor: pointer;
`;
