export const Actions = {
    PARTNER_FETCH_INIT: 'PARTNER_FETCH_INIT',
    PARTNER_FETCH_SUCCESS: 'PARTNER_FETCH_SUCCESS',
    PARTNER_FETCH_ERROR: 'PARTNER_FETCH_ERROR',
};

export const FileTypes = {
    MenuDark: 1,
    Icone: 2,
    Login: 3,
    Email: 4,
    MenuLight: 5,
};
