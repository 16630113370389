import * as React from 'react';

import { Text } from '@terrainvest/react-components';

import NoDataFound from '../NoDataFound';

import { disclaimerTexts } from '../Common/TextualInfo';

import * as Bullhorn from './assets/bullhorn.svg';
import * as terraLogo from './assets/terra-logo.svg';

import { StyledLink } from './index.styles';

const { blockOfferManager } = disclaimerTexts;

const BlockOfferManager = () => (
    <NoDataFound
        mainLogo={terraLogo}
        sideLogo={Bullhorn}
        mainText={blockOfferManager.mainText}
        description={
            <Text large>
                {blockOfferManager.description}
                <StyledLink href={`mailto:${blockOfferManager.email}`}>
                    {blockOfferManager.email}
                </StyledLink>
            </Text>
        }
        mobileWidth="90%"
    />
);

export default BlockOfferManager;
