import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers } from './index.normalize';

export const fetchInvestmentTermAssetsInit = accountNumber => ({
    type: Actions.FETCH_TERM_INIT,
    accountNumber,
});

export const fetchTermAssets = accountNumber => {
    return fetch(ENDPOINTS.GET_INVESTMENT_TERM.replace(':accountNumber', accountNumber), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchTermSuccess = offers => ({
    type: Actions.FETCH_TERM_SUCCESS,
    offers,
});

export const fetchTermError = error => ({
    type: Actions.FETCH_TERM_ERROR,
    error: error.message,
});
