import * as React from 'react';
import { getInfoType } from '../../../Withdraw/Common/FeedbackCardWithdraw/index.constants';

import FeedbackColoredCard from '../../../Common/FeedbackColoredCard';

import * as S from './index.styles';
import { FeedbackBaseProps } from './index.types';

export const FeedbackSuccess = ({ callBack }: FeedbackBaseProps) => {
    const info = getInfoType('success');

    return (
        <S.FeedbackWrapper color="#4ADE80">
            <FeedbackColoredCard
                type={info.type}
                colored={info.colored}
                baseColor={info.base}
                title="Sucesso"
                subtitle="Sua senha foi alterada, acesse sua conta."
                animation={info.animation}
                animationDuration={info.animationDuration}
                animationProps={{ style: { height: '80px', width: '80px' } }}
                notificationText="Sua senha foi alterada, acesse sua conta."
                redirectTo=""
                breakpoint={0}
                bottom={
                    <S.StyledPrimaryButton onClick={callBack}>
                        Ir para o login
                    </S.StyledPrimaryButton>
                }
            />
        </S.FeedbackWrapper>
    );
};

export const FeedbackError = ({ callBack }: FeedbackBaseProps) => {
    const info = getInfoType('error');

    React.useEffect(() => {
        setTimeout(() => {
            callBack();
        }, 3000);
    }, []);

    return (
        <S.FeedbackWrapper color="#FB7185">
            <FeedbackColoredCard
                type={info.type}
                colored={info.colored}
                baseColor={info.base}
                title="Erro"
                subtitle="Algo deu errado, nós já estamos resolvendo!"
                animation={info.animation}
                animationDuration={info.animationDuration}
                animationProps={{ style: { height: '80px', width: '80px' } }}
                notificationText="Algo deu errado, nós já estamos resolvendo!"
                redirectTo=""
                breakpoint={0}
                bottom={<S.StyledBoldText>Tente novamente mais tarde.</S.StyledBoldText>}
            />
        </S.FeedbackWrapper>
    );
};

export const FeedbackWrongPartner = ({ callBack }: FeedbackBaseProps) => {
    const info = getInfoType('processing');

    const [seconds, setSeconds] = React.useState(3);

    React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        } else {
            callBack();
        }
    }, [seconds]);

    const getSecondsText = () => `Redirecionando para parceiro em ${seconds}s`;
    const getAbleText = () => `Redirecionando...`;

    return (
        <S.FeedbackWrapper color="#FB923C" spacingText>
            <FeedbackColoredCard
                type={info.type}
                colored={info.colored}
                baseColor={info.base}
                title="Você possui cadastro em um de nossos parceiros."
                subtitle="Vamos te redirecionar em alguns segundos"
                icon={['far', 'triangle-exclamation']}
                iconProps={{ style: { height: '40px', width: '40px' } }}
                notificationText={`Você possui cadastro em um de nossos parceiros.
                 Vamos te redirecionar em alguns segundos`}
                redirectTo=""
                breakpoint={0}
                bottom={
                    <S.StyledPrimaryButton onClick={callBack}>
                        {seconds ? getSecondsText() : getAbleText()}
                    </S.StyledPrimaryButton>
                }
            />
        </S.FeedbackWrapper>
    );
};
