import styled from 'styled-components';
import { Skeleton } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledSkeleton = styled(Skeleton)`
    ${media.min.md`
        max-width: 50vw;
    `}
`;

export const StyledForm = styled.form`
    ${media.min.lg`
        margin-top: 0;
        margin-left: ${props => (props.fullWidth ? 0 : '240px')} ;
    `}

    ${media.max.lg`
        margin-top: 50px;
    `}
`;

export const StyledParagraph = styled.p`
    margin: 0;
`;

export const StyledMsgContainer = styled.div`
    & > p:last-child {
        margin-top: 5px;
    }
`;

export const StyledLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
