import styled from 'styled-components';
import { Modal, H2, H3, Text, Button } from '@terrainvest/react-components';

export const StyledModal = styled(Modal)`
    > div {
        padding: 0;
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    background: linear-gradient(180deg, #00c381 65%, #ffffff 35%);
    padding: 20px;
`;

export const StyledTextsWrapper = styled.div`
    width: 70%;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledImageWrapper = styled.div`
    margin-top: 50px;
    width: 30%;
`;

export const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const StyledH2 = styled(H2)`
    margin: 8px 0;
    font-weight: ${props => props.theme.global.typographyConfig.fontWeight.regular};
    color: ${props => props.theme.global.colors.white};
`;

export const StyledH3 = styled(H3)`
    margin: 0;
    font-weight: ${props => props.theme.global.typographyConfig.fontWeight.regular};
    color: ${props => props.theme.global.colors.white};
`;

export const StyledText = styled(Text)`
    margin-right: 60px;
    margin-bottom: 48px;
    line-height: 22px;
    font-size: ${props => props.theme.global.typographyConfig.body.large.fontSize};
    font-family: ${props => props.theme.global.typographyConfig.body.fontFamily};
    color: ${props => props.theme.global.colors.white};
`;

export const StyledLink = styled.a`
    margin-left: 2px;
    font-weight: bold;
    text-decoration: underline;
    font-family: ${props => props.theme.global.typographyConfig.body.fontFamily};
    color: ${props => props.theme.global.colors.white};
`;

export const StyledPrimaryButton = styled(Button)`
    font-size: ${props => props.theme.global.typographyConfig.body.large.fontSize};
    font-weight: ${props => props.theme.global.typographyConfig.fontWeight.regular};
    font-family: ${props => props.theme.global.typographyConfig.body.fontFamily};
    text-transform: none;
`;

export const StyledSecondaryButton = styled.button`
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: ${props => props.theme.global.typographyConfig.body.large.fontSize};
    font-weight: ${props => props.theme.global.typographyConfig.fontWeight.regular};
    font-family: ${props => props.theme.global.typographyConfig.body.fontFamily};
    text-decoration: underline;
    text-transform: none;
    background: none;
    border: 0;
    color: #807d7d;
    cursor: pointer;
`;
