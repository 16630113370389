import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchPaidCustodyStatus,
    fetchPaidCustodyStatusSuccess,
    fetchPaidCustodyStatusError,
} from './index.actions';

export const initialState = {
    paidCustody: null,
    loading: false,
    error: false,
};

export const paidCustodyReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.PAID_CUSTODY_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchPaidCustodyStatus, {
                    successActionCreator: fetchPaidCustodyStatusSuccess,
                    failActionCreator: fetchPaidCustodyStatusError,
                    args: [action.account],
                }),
            );
        case Actions.PAID_CUSTODY_FETCH_SUCCESS:
            return {
                ...state,
                paidCustody: action.paidCustody,
                loading: false,
                error: false,
            };
        case Actions.PAID_CUSTODY_FETCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
