export const normalizeBroker = brokers => {
    const brokersData = [...brokers];
    return [...brokersData].map(e => {
        return {
            id: e.Id,
            value: e.Name,
            cnpj: e.CpfCnpj,
            address: `${e.MainAddress}, ${e.NumberAddress}  ${e.SecondaryAddress} ${e.District} ${e.StateId}`,
            postalCode: e.PostalCode,
        };
    });
};
