import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { responseToFile } from '../../../../Utils/Parsers';

export const fetchBrokerageSubmitInit = data => ({
    type: Actions.FETCH_BROKERAGEDATE_SUBMIT_INIT,
    data,
});

export async function fetchBrokerageSubmit(data) {
    const response = await fetch(
        ENDPOINTS.GET_BROKERAGENOTE_SUBMIT.replace(':accountNumber', data.account)
            .replace(':market', data.marketValue)
            .replace(':date', data.dateValue),
        {
            method: 'GET',
        },
    );

    if (response.status && response.status === 200) {
        const defaultFilename = `relatorio_nota_corretagem.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchBrokerageSubmitSuccess = fileURL => ({
    type: Actions.FETCH_BROKERAGEDATE_SUBMIT_SUCCESS,
    fileURL,
});

export const fetchBrokerageSubmitError = error => ({
    type: Actions.FETCH_BROKERAGEDATE_SUBMIT_ERROR,
    error: error.message,
});

export const fetchBrokerageSubmitReset = () => ({
    type: Actions.FETCH_BROKERAGEDATE_SUBMIT_RESET,
});
