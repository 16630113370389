import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { Actions } from './index.constants';
import { normalizeExtracts, normalizePatrimonyInfo } from './index.normalize';

export const fetchCheckingAccountSubmitInit = data => ({
    type: Actions.FETCH_CHECKING_ACCOUNT_INIT,
    data,
});

export function fetchCheckingAccountSubmit(data: { account; startDate; endDate; orderAsc? }) {
    const url = ENDPOINTS.GET_CHECKING_ACCOUNT_EXTRACT.replace(':accountNumber', data.account)
        .replace(':startDate', data.startDate)
        .replace(':endDate', data.endDate)
        .replace(':orderAsc', data.orderAsc || false);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(extracts => normalizeExtracts(extracts));
}

export const fetchCheckingAccountSubmitSuccess = extract => ({
    type: Actions.FETCH_CHECKING_ACCOUNT_SUCCESS,
    extract,
});

export const fetchCheckingAccountSubmitError = error => ({
    type: Actions.FETCH_CHECKING_ACCOUNT_ERROR,
    error: error.message,
});

export const fetchCheckingAccountSubmitReset = () => ({
    type: Actions.FETCH_CHECKING_ACCOUNT_RESET,
});

export const fetchPatrimonyInfoInit = account => ({
    type: Actions.FETCH_PATRIMONY_INFO_INIT,
    account,
});

export function fetchPatrimonyInfo(account) {
    const url = ENDPOINTS.GET_PATRIMONY_INFO.replace(':accountNumber', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(patrimonyInfo => normalizePatrimonyInfo(patrimonyInfo));
}

export const fetchPatrimonyInfoSuccess = ({
    availableBalance,
    patrimony,
    consolidatedPatrimony,
    updatedIn,
    balanceFuture,
}) => ({
    type: Actions.FETCH_PATRIMONY_INFO_SUCCESS,
    availableBalance,
    patrimony,
    consolidatedPatrimony,
    updatedIn,
    balanceFuture,
});

export const fetchPatrimonyInfoError = error => ({
    type: Actions.FETCH_PATRIMONY_INFO_ERROR,
    error: error.message,
});

export const fetchPatrimonyInfoReset = () => ({
    type: Actions.FETCH_PATRIMONY_INFO_RESET,
});
