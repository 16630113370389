import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { normalizeRecommendations } from './index.normalize';

export const fetchRecommendationsInit = () => ({
    type: Actions.FETCH_RECOMMENDATIONS_INIT,
});

export const fetchRecommendations = () => {
    return fetch(ENDPOINTS.GET_RECOMMENDATION_TABLE, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeRecommendations);
};

export const fetchRecommendationsSuccess = table => ({
    type: Actions.FETCH_RECOMMENDATIONS_SUCCESS,
    table,
});

export const fetchRecommendationsError = error => ({
    type: Actions.FETCH_RECOMMENDATIONS_ERROR,
    error: error.message,
});

export const fetchRecommendationsReset = () => ({
    type: Actions.FETCH_RECOMMENDATIONS_RESET,
});
