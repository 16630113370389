import styled from 'styled-components';

export const StyledWrapper = styled.div`
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
`;

export const StyledSeparator = styled.div`
    margin-top: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
`;
