import * as React from 'react';

import { StyledAddress } from './index.styles';

import { FORM_SECTIONS } from '../Form';
import { StyledContainerNoPadd } from '../../Common/Grid';

import FormCard from '../Common/FormCard';
import AddressInput from '../AdressInput';
import { StyledRow } from '../Common/GridStyles';

const Addresses = ({ isCompany }) => {
    return (
        <StyledAddress id={FORM_SECTIONS.ADDRESSES.ID}>
            <StyledContainerNoPadd fluid>
                <FormCard
                    title={
                        isCompany
                            ? FORM_SECTIONS.ADDRESSES.COMPANY_NAME
                            : FORM_SECTIONS.ADDRESSES.NAME
                    }
                    subTitle={
                        isCompany
                            ? FORM_SECTIONS.ADDRESSES.COMPANY_DETAILS
                            : FORM_SECTIONS.ADDRESSES.DETAILS
                    }
                >
                    <StyledRow>
                        <AddressInput preffix="Address" />
                    </StyledRow>
                </FormCard>
            </StyledContainerNoPadd>
        </StyledAddress>
    );
};

export default Addresses;
