import styled from 'styled-components';
import { Icon } from '@terrainvest/react-components';

export const StyledHelperContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const StyledIcon = styled(Icon)`
    ${props => `
        height: ${props.size};
        width: ${props.size};
        font-size: calc(calc(7 * ${props.size}) / 10);
        color: ${props.theme.global.colors.primary};
        cursor: pointer;
        
        ${
            props.padLeft
                ? `
            margin-left: 10px;
        `
                : ''
        }

        ${
            props.padRight
                ? `
            margin-right: 10px;
        `
                : ''
        }
    `}
`;
