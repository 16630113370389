import moment from 'moment';

import { BannerResponse, BannerResponseNormalized } from './index.types';
import { parseDateForDisplay } from '../../Utils/Parsers';

export const normalizeBanner = (banner: BannerResponse): BannerResponseNormalized => {
    return {
        ...banner,
        startDateNormalized: parseDateForDisplay(banner.StartDate),
        endDateNormalized: parseDateForDisplay(banner.EndDate),
        startDateObject: moment(banner.StartDate).toDate(),
        endDateObject: moment(banner.EndDate).toDate(),
    };
};

export const normalizeBanners = (banners: BannerResponse[]): BannerResponseNormalized[] | any => {
    return banners && banners.map ? banners.map(normalizeBanner) : banners;
};
