import { Icons } from '@terrainvest/react-components';

import * as fasIcons from './fas';
import * as farIcons from './far';
import * as falIcons from './fal';
import * as fadIcons from './fad';
import * as fabIcons from './fab';

const getIconsFromImport = iconsImport => Object.values(iconsImport);

export const setupIcons = () => {
    Icons.setup(
        ...getIconsFromImport(fasIcons),
        ...getIconsFromImport(farIcons),
        ...getIconsFromImport(falIcons),
        ...getIconsFromImport(fadIcons),
        ...getIconsFromImport(fabIcons),
    );
};
