import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledLogo = styled.img`
    width: 150px;
    height: 45px;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-top: 40px;
    margin-bottom: 60px;
    width: 100%;
`;

export const StyledInfoText = styled.div`
    margin-left: 50px;
    margin-top: 50px;
    width: 380px;

    & > p {
        margin: 0;
    }
`;

export const StyledFirstText = styled(Text)`
    line-height: 20px;
`;
