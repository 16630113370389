import styled from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledTitle = styled.div`
    width: 299px;
    height: 18px;
    max-width: 100%;
    color: #42555d;
    ${Text.Styles.StyledText} {
        margin: 0;
        line-height: 22px;
    }
    margin-bottom: 8px;
`;

export const StyledRules = styled.div`
    max-width: 100%;
    color: #42555d;
    ${Text.Styles.StyledText} {
        margin: 0;
        line-height: 24px;
    }
`;

export const StyledRule = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;

    color: ${props => props.theme.global.colorAliases.body}
        ${props =>
            props.hasPassedRule === true ? `color: ${props.theme.global.colorAliases.success}` : ''}
        ${props =>
            props.hasPassedRule === false ? `color: ${props.theme.global.colorAliases.error}` : ''}
        ${Icon.Styles.StyledIcon} {
        margin-right: 8px;
    }

    ${Text.Styles.StyledText} {
        color: currentColor;
    }
`;

export const StyledPasswordTip = styled.div`
    border-radius: 4px;
    background-color: ${props => props.theme.global.colors.porcelainLight};
    position: absolute;
    margin-top: 60px;
    transform: translateX(390px);

    @-moz-document url-prefix() {
        margin-top: 160px;
    }

    padding: 16px 20px;
    white-space: nowrap;

    ${media.max.xl`
        position: relative;
        margin-left: 0;
        margin-top: 0;
        height: auto;
        max-width: 100%;
        white-space: pre-line;
        padding: 14px 14px 14px 6px;
        transform: none;

        &:before {
            display: none;
        }

        ${StyledTitle}{
            ${Text.Styles.StyledText}{
                font-size: 13px;
                font-weight: 600;
            }

            margin-bottom: 12px;
        }

        ${StyledRule}{
            ${Text.Styles.StyledText}{
                font-size: 11px;
            }
        }
    `}
`;

export const StyledIcon = styled(Icon)`
    width: 16px;
`;
