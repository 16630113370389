import * as React from 'react';
import PlainTemplate from './PlainTemplate';
import TemplateWithSidebarAndHeader from './TemplateWithSidebarAndHeader';
import { GlobalNotification } from '../View/Common/GlobalNotification';

export interface TemplateProps {
    header: React.ReactNode;
    sidebar: React.ReactNode;
    children: React.ReactNode;
    id?: string;
}

const Template = ({ header, sidebar, children, ...otherProps }: TemplateProps) => (
    <>
        {!sidebar && !header ? (
            <PlainTemplate content={children} {...otherProps} />
        ) : (
            <TemplateWithSidebarAndHeader
                header={header}
                sidebar={sidebar}
                content={children}
                {...otherProps}
            />
        )}
        <GlobalNotification />
    </>
);

Template.defaultProps = {
    header: false,
    sidebar: false,
};

export default Template;
