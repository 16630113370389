import { FormGroup } from '@terrainvest/react-components';
import * as React from 'react';
import { Field } from '../../Form/Field';
import { StyledCheckbox, StyledRadio, StyledSuitabilityQuestion } from './index.styles';
import QuestionTitle from './QuestionTitle';

const singleAlternativeQuestion = ({ index, innerQuestion }) => (
    <Field name={`SuitabilityProfile.Questions${index}`}>
        {({ field, form }) => (
            <>
                <QuestionTitle
                    title={`${field.value.QuestionNumber}. ${field.value.Description}`}
                    innerQuestion={innerQuestion}
                />
                <FormGroup
                    {...field}
                    vertical
                    onChange={evt => {
                        form.setFieldValue(field.name, {
                            ...field.value,
                            Answers: [{ Id: Number(evt.target.value) }],
                        });
                    }}
                >
                    {field.value.Alternatives.map(option => (
                        <StyledRadio
                            key={option.Id.toString()}
                            highlighted
                            defaultChecked={(field.value.Answers[0] || {}).Id === option.Id}
                            label={option.Description}
                            defaultValue={option.Id}
                        />
                    ))}
                </FormGroup>
            </>
        )}
    </Field>
);

const multipleAlternativeQuestion = ({ index, innerQuestion }) => (
    <Field name={`SuitabilityProfile.Questions${index}`}>
        {({ field, form }) => (
            <>
                <QuestionTitle
                    title={`${field.value.QuestionNumber}. ${field.value.Description}`}
                    innerQuestion={innerQuestion}
                />
                <FormGroup
                    {...field}
                    vertical
                    onChange={evt => {
                        form.setFieldValue(field.name, {
                            ...field.value,
                            Answers: evt.target.checked
                                ? [
                                      ...(field.value.Alternatives.find(
                                          el => el.Id === Number(evt.target.value),
                                      ).Description === 'Nenhum.'
                                          ? []
                                          : field.value.Answers),
                                      { Id: Number(evt.target.value) },
                                  ]
                                : field.value.Answers.filter(
                                      element => element.Id !== Number(evt.target.value),
                                  ),
                        });
                    }}
                >
                    {field.value.Alternatives.map(option => (
                        <StyledCheckbox
                            key={option.Id.toString()}
                            highlighted
                            disabled={
                                field.value.Answers.map(answer => answer.Id).includes(
                                    (
                                        field.value.Alternatives.find(
                                            el => el.Description === 'Nenhum.',
                                        ) || {}
                                    ).Id,
                                ) && option.Description !== 'Nenhum.'
                            }
                            label={option.Description}
                            defaultChecked={field.value.Answers.map(answer => answer.Id).includes(
                                option.Id,
                            )}
                            defaultValue={option.Id}
                        />
                    ))}
                </FormGroup>
            </>
        )}
    </Field>
);

const renderParentQuestion = ({ question, index }) => (
    <>
        <QuestionTitle title={`${question.QuestionNumber}. ${question.Description}`} />
        {question.Questions.map((childrenQuestion, childrenIndex) => (
            <SuitabilityQuestion
                key={`${childrenQuestion.Id}`}
                question={childrenQuestion}
                index={`${index}.Questions[${childrenIndex}]`}
                innerQuestion
            />
        ))}
    </>
);

const renderQuestion = ({ question, index, innerQuestion }) =>
    [singleAlternativeQuestion, multipleAlternativeQuestion, renderParentQuestion][question.Type]({
        question,
        index,
        innerQuestion,
    });

const SuitabilityQuestion = ({ question, index, innerQuestion = false }) => (
    <StyledSuitabilityQuestion>
        {renderQuestion({ question, index, innerQuestion })}
    </StyledSuitabilityQuestion>
);

export default SuitabilityQuestion;
