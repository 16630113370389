import * as React from 'react';
import { UserDataExpirationNotification } from '../../View/UserDataExpiration';
import { DueDateNotification } from '../../View/Common/DueDateNotification';

import { StyledContainerNoPadd, StyledRowNoPadd, StyledColNoPadd } from '../../View/Common/Grid';

export interface PlainTemplateProps {
    content: React.ReactNode;
}

const PlainTemplate = ({ content }: PlainTemplateProps) => (
    <StyledContainerNoPadd fluid>
        <StyledRowNoPadd>
            <StyledColNoPadd col={12} sm={12} md={12}>
                <UserDataExpirationNotification />
                <DueDateNotification />
                {content}
            </StyledColNoPadd>
        </StyledRowNoPadd>
    </StyledContainerNoPadd>
);

export default PlainTemplate;
