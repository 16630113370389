import styled from 'styled-components';
import { Text, FormGroup } from '@terrainvest/react-components';

export const StyledQuestion = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    ${FormGroup.Styles.StyledFormGroup} {
        height: 20px;
    }
`;

export const StyledText = styled(Text)`
    line-height: 20px;
    margin: 0;
`;
