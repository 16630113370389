import { normalizeString } from '../../Utils/Parsers';
import { FileTypes } from './index.constants';

const getFileUrlFromLocation = (files: Array<any>, location: number) => {
    const fileInfo = files.find(file => file.location === location);
    if (fileInfo) return fileInfo.fileUrl;
    return '';
};

const normalizeLogosData = partner => {
    if (!partner || !partner.files || !partner.files.length) {
        return undefined;
    }

    const logoMenuDark = getFileUrlFromLocation(partner.files, FileTypes.MenuDark);
    const logoMenuLight = getFileUrlFromLocation(partner.files, FileTypes.MenuLight);
    const logoIcon = getFileUrlFromLocation(partner.files, FileTypes.Icone);
    const logoLogin = getFileUrlFromLocation(partner.files, FileTypes.Login);

    return { logoLogin, logoMenuDark, logoMenuLight, logoIcon };
};

const normalizeLightMode = partner => {
    const hasLightMode = !!partner.lightModeStyle;
    if (!hasLightMode) return null;

    const styleLightContent = JSON.parse(partner.lightModeStyle.css);
    const { colors, typographyConfig } = styleLightContent;
    const { id, name } = partner.lightModeStyle;

    return { id, name, colors, typographyConfig };
};

export const normalizePartner = partner => {
    const styleContent = JSON.parse(partner.style.css);
    const logosData = normalizeLogosData(partner);

    const styleLight = normalizeLightMode(partner);

    return {
        id: partner.id,
        advisorId: partner.advisorId,
        officeId: partner.officeId,
        name: partner.name,
        cnpj: normalizeString(partner.cnpj),
        logos: {
            login: logosData.logoLogin,
            menuDark: logosData.logoMenuDark,
            menuLight: logosData.logoMenuLight,
            icon: logosData.logoIcon,
        },
        style: {
            id: partner.style.id,
            name: partner.style.name,
            ...styleContent,
        },
        styleLight,
        urlCadastro: partner.urlCadastro,
        newAccountButton: partner.newAccountButton,
        isTerraPartner: partner.isTerraPartner,
    };
};
