import { Actions } from './index.constants';
import {
    normalizeStructuredProductRequests,
    normalizeStructuredProductsList,
    normalizeStructuredProduct,
    normalizeCalls,
} from './index.normalize';
import { fetch, ENDPOINTS } from '../../Utils/Api';

export const fetchStructuredProductsRequestsInit = account => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_INIT,
    account,
});

export const fetchStructuredProductsRequests = account => {
    const url = ENDPOINTS.GET_STRUCTURED_PRODUCTS_REQUESTS.replace(':account', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeStructuredProductRequests);
};

export const fetchStructuredProductsRequestsSuccess = products => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_SUCCESS,
    products,
});

export const fetchStructuredProductsRequestsError = error => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_ERROR,
    error: error.message,
});

export const fetchStructuredProductsInit = () => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_INIT,
});

export const fetchStructuredProducts = () => {
    return fetch(ENDPOINTS.GET_STRUCTURED_PRODUCTS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeStructuredProductsList);
};

export const fetchStructuredProductsSuccess = products => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_SUCCESS,
    products,
});

export const fetchStructuredProductsError = error => ({
    type: Actions.FETCH_STRUCTURED_PRODUCTS_ERROR,
    error: error.message,
});

export const selectStructuredProduct = productId => ({
    type: Actions.SELECT_STRUCTURED_PRODUCT,
    productId,
});

export const cleanStructuredProductSelected = () => ({
    type: Actions.CLEAN_STRUCTURED_PRODUCT_SELECTED,
});

export const fetchStructuredProductDetails = async productId => {
    const url = ENDPOINTS.GET_STRUCTURED_PRODUCTS_DETAILS.replace(':productId', productId);

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeStructuredProduct);
};

export const fetchStructuredProductDetailsSuccess = product => ({
    type: Actions.FETCH_STRUCTURED_PRODUCT_DETAILS_SUCCESS,
    product,
});

export const fetchStructuredProductDetailsError = error => ({
    type: Actions.FETCH_STRUCTURED_PRODUCT_DETAILS_ERROR,
    error: error.message,
});

export const fetchStructuredProductStatusClient = clientId => ({
    type: Actions.FETCH_STRUCTURED_PRODUCT_STATUS_INIT,
    clientId,
});

export const fetchStructuredProductsStatus = async clientId => {
    const url = ENDPOINTS.GET_STRUCTURED_PRODUCTS_STATUS.replace(':clientId', clientId);

    return fetch(url, { method: 'GET' }).then(response => response.json());
};

export const fetchStructuredProductsStatusSuccess = response => {
    return {
        type: Actions.FETCH_STRUCTURED_PRODUCT_STATUS_SUCCESS,
        response,
    };
};

export const fetchStructuredProductsStatusError = error => ({
    type: Actions.FETCH_STRUCTURED_PRODUCT_STATUS_ERROR,
    error: error.message,
});

export const fetchStructuredProductsApply = payload => {
    return fetch(ENDPOINTS.POST_STRUCTURED_PRODUCTS_APPLY, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

export const fetchStructuredProductTypeInit = productId => ({
    type: Actions.GET_STRUCTURED_PRODUCTS_TYPE_INIT,
    productId,
});

export const fetchStructuredProductType = async productId => {
    const url = ENDPOINTS.GET_STRUCTURED_PRODUCTS_TYPE.replace(':productId', productId);

    return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(normalizeCalls);
};

export const fetchStructuredProductTypeSuccess = types => {
    return {
        type: Actions.GET_STRUCTURED_PRODUCTS_TYPE_SUCCESS,
        types,
    };
};

export const fetchStructuredProductTypeError = error => ({
    type: Actions.GET_STRUCTURED_PRODUCTS_TYPE_ERROR,
    error: error.message,
});

export const acquireStructuredProducts = payload => createOrUpdateStructuredProducts(payload);
export const revokeStructuredProducts = payload => createOrUpdateStructuredProducts(payload);

const createOrUpdateStructuredProducts = payload => {
    const isEntityCreated = payload.Id;
    const sendMethod = isEntityCreated ? putToUrl : postToUrl;

    return sendMethod(payload, ENDPOINTS.POST_STRUCTURED_PRODUCTS).then(res =>
        res.ok ? res : Promise.reject(res),
    );
};

const postToUrl = async (data, url) => {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

const putToUrl = async (data, url) => {
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
