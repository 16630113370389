const analyzingIds = [2, 11];

export enum PAGES {
    PAGE_LOGIN = 1,
    PAGE_SELECT_ACCOUNT = 2,
    PAGE_UNFINISHED_LOGIN = 3,
    PAGE_FORGOT_PASSWORD = 4,
    PAGE_LOGIN_IN_ANALYSIS = 7,
    PAGE_WRONG_PARTNER = 8,
    PAGE_USER_BLOCKED = 9,
}

export const forgotPasswordPath = 'recuperar-senha';

export const isInAnalyzing = (registrationStatusId: number) =>
    analyzingIds.includes(registrationStatusId);

export const getNextPage = (client: any): PAGES => {
    if (client.Accounts && client.Accounts.length > 0) {
        return PAGES.PAGE_SELECT_ACCOUNT;
    }
    if (isInAnalyzing(client.RegistrationStatusId)) {
        return PAGES.PAGE_LOGIN_IN_ANALYSIS;
    }
    return PAGES.PAGE_UNFINISHED_LOGIN;
};
