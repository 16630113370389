import {
    FiltersTypes,
    StatusToFilterProps,
    InsuranceRequestProps,
    InsuranceProps,
} from './index.types';

const options = {
    maxPerLine: 6,
    commonFilter: {
        fieldsToFilter: ['insuranceTypeDescription', 'statusDescription', 'startDateNormalized'],
    },
    commonPensionFilter: {
        fieldsToFilter: ['title'],
    },
    statusFilter: {
        fieldsToFilter: ['StatusId'],
    },
};

export const getAllStatus = (requests: Array<InsuranceRequestProps>) => {
    const status = {};
    requests.forEach(request => (status[request.status] = request.statusDescription));
    const allStatus: Array<StatusToFilterProps> = Object.entries(status).map(
        ([value, text]: [string, string]) => ({
            value: parseInt(value, 10),
            text,
        }),
    );
    return allStatus;
};

const mountStringFromRequestByFields = (request: InsuranceRequestProps, fields: Array<string>) => {
    return fields
        .map(field => request[field], '')
        .join('-')
        .toLowerCase();
};

const mountStringFromPensionsByFields = (pension: InsuranceProps, fields: Array<string>) => {
    return fields
        .map(field => pension[field], '')
        .join('-')
        .toLowerCase();
};

export const execFilter = (
    requests: Array<InsuranceRequestProps>,
    filters: FiltersTypes,
): Array<InsuranceRequestProps> => {
    const { commonFilter, statusFilter } = filters;

    const requestsFilteredByStatusId = statusFilter.length
        ? requests.filter(request => statusFilter.includes(request.status))
        : requests;

    const commonFilterLower = commonFilter.toLowerCase();

    const filteredRequests = requestsFilteredByStatusId.filter(request =>
        mountStringFromRequestByFields(request, options.commonFilter.fieldsToFilter).includes(
            commonFilterLower,
        ),
    );

    return filteredRequests;
};

export const execPensionsFilter = (
    pensions: Array<InsuranceProps>,
    filters: FiltersTypes,
): Array<InsuranceProps> => {
    const { commonFilter } = filters;

    const commonFilterLower = commonFilter.toLowerCase();

    const filteredRequests = pensions.filter(pension =>
        mountStringFromPensionsByFields(
            pension,
            options.commonPensionFilter.fieldsToFilter,
        ).includes(commonFilterLower),
    );

    return filteredRequests;
};
