import { getGUID } from '../../../Utils/General/GUID';

/**
 * Adds new Procurator to given form populating with given clientId.
 * Centralizes actions for Procurator which are used throughout Formik forms.
 * */
export const addNewEmptyProcurator = (formContext, clientId) => {
    const procurators = [...formContext.values.Procurators, getEmptyProcurator(clientId)];
    formContext.setFieldValue('Procurators' as never, procurators);
};

/**
 * Removes specific Procurator from given form, selecting by index
 * Centralizes actions for Procurator which are used throughout Formik forms.
 * */
export const removeProcuratorByIndex = (formContext, index) => {
    if (index >= -1) {
        const newProcurators = [...formContext.values.Procurators];
        newProcurators.splice(index, 1);

        formContext.setFieldValue('Procurators' as never, newProcurators);
    }
};

/**
 * Clears Procurators array field from given form.
 * Centralizes actions for Procurator which are used throughout Formik forms
 * */
export const clearProcurators = formContext => {
    const procurators = [];
    formContext.setFieldValue('Procurators' as never, procurators);
};

/**
 * Provides instance of Procurator.
 * */
export const getEmptyProcurator = clientId => ({
    key: getGUID(),
    CustomerId: clientId,
    ProcuratorName: '',
    DocumentNumber: '',
    DocumentSecondaryNumber: '',
    DocumentTypeId: 'RG',
    DocumentIssuerName: '',
    DocumentIssuerState: '',
    DocumentIssuerDate: '',
    ProcuratorCpfCnpj: '',
    MainAddress: '',
    Number: '',
    Complement: '',
    District: '',
    StateId: '',
    PostalCode: '',
    DDDMobilePhone: '',
    MobilePhone: '',
    Email: '',
    CityId: 0,
    CityName: '',
    PoliticallyExposedPerson: false,
    Skype: '',
    ValidityDate: '',
    ValidityIndeterminate: true,
});
