import * as React from 'react';
import { Icon, Text } from '@terrainvest/react-components';
import { StyledHeaderLinkItem } from './index.styles';

export interface HeaderLinkItemProps {
    name: string;
    customText?: JSX.Element;
    hideLineBottom?: boolean;
    dispatch?: () => void;
    clickedCallback?: () => void;
}

export const HeaderLinkItem: React.FC<HeaderLinkItemProps> = ({
    name,
    customText = null,
    hideLineBottom = false,
    dispatch = () => undefined,
    clickedCallback = () => undefined,
}: HeaderLinkItemProps) => {
    const handleClicked = () => {
        dispatch();
        clickedCallback();
    };

    return (
        <StyledHeaderLinkItem onClick={handleClicked} hasAfter={!hideLineBottom}>
            {customText || <Text>{name}</Text>}
            <Icon icon={['fas', 'chevron-right']} />
        </StyledHeaderLinkItem>
    );
};

export default HeaderLinkItem;
