import styled from 'styled-components';
import { H3 } from '@terrainvest/react-components';

export const StyledAnchor = styled(H3)`
    transition: 0.1s all ease-in-out;
    line-height: 1;
    margin: 0;
    cursor: pointer;
    user-select: none;
    padding: 16px;
    color: ${props => (props.active ? '#42555D' : '#9ba6a7')};
`;
