export const Actions = {
    FETCH_PUBLIC_OFFERS_INIT: 'FETCH_PUBLIC_OFFERS_INIT',
    FETCH_PUBLIC_OFFERS_SUCCESS: 'FETCH_PUBLIC_OFFERS_SUCCESS',
    FETCH_PUBLIC_OFFERS_ERROR: 'FETCH_PUBLIC_OFFERS_ERROR',
    FETCH_OFFER_HISTORY_INIT: 'FETCH_OFFER_HISTORY_INIT',
    FETCH_OFFER_HISTORY_SUCCESS: 'FETCH_OFFER_HISTORY_SUCCESS',
    FETCH_OFFER_HISTORY_ERROR: 'FETCH_OFFER_HISTORIC_ERROR',
};

export const STATUS_IN_PROGRESS = 1;
