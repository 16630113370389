import moment from 'moment';

export const parseHours = value => {
    const parsed = (value as string).replace(/:00/g, '');
    return parsed.length === 2 ? `${parsed}h` : parsed.replace(':', 'h');
};

export const parseTimeForDisplay = (
    date,
    formatIs: any[] = ['YYYY-MM-DDTHH:mm:ss'],
    formatTo = 'HH:mm',
) => {
    return date ? moment(date, [...formatIs]).format(formatTo) : '--';
};

export const getDiffInDays = (fromDate, toDate = moment()) => {
    const data = moment(fromDate);
    return data.diff(toDate, 'days');
};

export const parseDateForDisplay = (
    date,
    formatIs: any[] = ['YYYY-MM-DD'],
    formatTo = 'DD/MM/YYYY',
) => {
    return date ? moment(date, [...formatIs]).format(formatTo) : '--';
};

export const parseDate = (date, formatTo = 'DD/MM/YYYY') =>
    date ? moment(date).format(formatTo) : '--';

export const parseDateForAPI = (
    date,
    formatIs: any[] = ['DD/MM/YYYY'],
    formatTo = 'YYYY-MM-DD',
) => {
    if (date === undefined) throw new Error(`ERROR: Date should not be null.`);

    return moment(date, [...formatIs]).format(formatTo);
};

export const isValidDate = (date, format: any[] = ['DD/MM/YYYY']) => {
    return date && moment(date, [...format]).isValid();
};

export const parseDisplayDateToObject = (date, formatIs: any[] = ['DD/MM/YYYY']) => {
    return moment(date, [...formatIs]).toDate();
};

export const isExpiredDate = rawValidityDateLimit => {
    const validityDateLimit = moment(rawValidityDateLimit, 'YYYY-MM-DD');

    const today = moment();
    return today.isAfter(validityDateLimit);
};

export const getExpirationCountInDays = rawValidityDateLimit => {
    const validityDateLimit = moment(rawValidityDateLimit, 'YYYY-MM-DD');
    const today = moment();
    const offset = 1;

    const diff = validityDateLimit.diff(today, 'days');

    return diff + offset;
};
