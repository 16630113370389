export const validateCpf = (maskedCpf: string) => {
    const cpf = maskedCpf.replace(/\D/g, '') || '';

    if (cpf === '') return false;

    if (cpf.length !== 11) return false;

    let sum = 0;
    let rest = 0;
    if (
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    )
        return false;

    for (let i = 1; i <= 9; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10), 10)) return false;

    sum = 0;
    for (let i = 1; i <= 10; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11), 10)) return false;

    return true;
};

export const validateCnpj = (maskedCnpj: string) => {
    if (!maskedCnpj) {
        return true;
    }

    const cnpj = maskedCnpj.replace(/[^\d]+/g, '').substring(0, 14);

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    )
        return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i -= 1) {
        soma += Number(numeros.charAt(tamanho - i)) * pos;
        pos -= 1;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(1))) return false;

    return true;
};
