import styled from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 57px 2fr 1fr;
    margin-bottom: 8px;
    cursor: pointer;
`;

export const StyledItemText = styled(Text)`
    margin: 0;
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
`;

export const StyledIcon = styled(Icon)`
    text-align: right;
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
    line-height: ${({ theme }) => theme.global.fontSizes.xRay};
    color: ${({ theme }) => theme.global.colors.primary};
`;
