import React from 'react';

import debounce from 'lodash/debounce';
import { CollectionsFilter } from '../General/Filter';
import { normalizeString } from '../Parsers/index';

export interface FilterConfig {
    collection?: any[];
    skipFields?: string[];
    targetFields?: string[];
    itemParser?: Function;
}

export const useTableFilter = (
    filter,
    assets = [],
    fullTable,
    skeletonTable,
    emptyTable,
    loading,
    filterConfig?: FilterConfig,
): [any[], (evt: any) => void, () => void] => {
    const [inputSearchValue, setInputSearchValue] = React.useState('');
    const assetsFilter = filter as CollectionsFilter<any>;
    const filteredAssets = assets
        ? assetsFilter
              .setup({
                  collection: assets,
                  ...filterConfig,
              })
              .filter(inputSearchValue)
        : [];

    const handleSearch = debounce(value => {
        setInputSearchValue(normalizeString(value));
    }, 200);

    const handleSearchInputChanged = evt => {
        handleSearch(evt.target.value);
    };

    const renderAsset = () => {
        if (loading) {
            return skeletonTable();
        }
        if (filteredAssets && filteredAssets.length > 0) {
            return fullTable();
        }
        return emptyTable();
    };

    return [filteredAssets, handleSearchInputChanged, renderAsset];
};

export const useTableFilterSimple = (
    filter,
    assets = [],
    filterConfig?: FilterConfig,
): [any[], (evt: any) => void] => {
    const [inputSearchValue, setInputSearchValue] = React.useState('');
    const assetsFilter = filter as CollectionsFilter<any>;
    const filteredAssets = assets
        ? assetsFilter
              .setup({
                  collection: assets,
                  ...filterConfig,
              })
              .filter(inputSearchValue)
        : [];

    const handleSearchInputChanged = evt => {
        debounce(value => {
            setInputSearchValue(normalizeString(value));
        }, 200)(evt.target.value);
    };

    return [filteredAssets, handleSearchInputChanged];
};
