import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { FormGroup, Radio, Select, InputInteger } from '@terrainvest/react-components';

import HelpButton from '../../../../Common/HelpButton';
import { StyledCol } from '../../../Common/GridStyles';
import { Field, SelectField } from '../../../Form/Field';
import { disclaimerTexts } from '../../../../Common/TextualInfo';

import { Commons } from '../../../../../Redux/Common';
import { CommonPrefixes } from '../../../../../Redux/Common/index.constants';

import { validateRequiredField } from '../../../index.validators';
import { StyledText, StyledQuestion } from './index.styles';

const { fatca } = disclaimerTexts;

const Institutional = () => {
    const [isInstitutional, setIsInstitutional] = React.useState('0');
    const [isGiin, setIsGiin] = useState(false);

    const { Fatca } = useSelector(state => state.commonState);

    const { values, setFieldValue } = useFormikContext<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Commons.Fatca.actions.fetchInit());
    }, []);

    useEffect(() => {
        if (values.StatusFatcaId && Fatca && Fatca.data) {
            enableGiinBasedOnFatca(values.StatusFatcaId);
            setIsInstitutional('1');
            return;
        }
        setFieldValue('Giin', null);
    }, [Fatca, values.StatusFatcaId]);

    const handleChangeIsInstitutional = e => {
        setIsInstitutional(e.target.value);
        if (e.target.value === '0') {
            setFieldValue('StatusFatcaId', null);
            setFieldValue('Giin', null);
            setIsGiin(false);
        }
    };

    const handleChangeFatca = (value, id, onChange) => {
        enableGiinBasedOnFatca(id);
        onChange(value, id);
    };

    const enableGiinBasedOnFatca = fatcaId => {
        const chosenFatca = Fatca.data.find(el => el.id === fatcaId);
        setIsGiin(chosenFatca.hasGiin);
    };

    return (
        <>
            <StyledCol col={12}>
                <StyledQuestion>
                    <StyledText>
                        A empresa exerce alguma atividade correlata à atividade financeira?
                        <HelpButton
                            padLeft
                            content={fatca.helpContent}
                            size="18px"
                            messageWidth="400px"
                        />
                    </StyledText>
                    <FormGroup name="isInstitutional" onChange={handleChangeIsInstitutional}>
                        <Radio checked={isInstitutional === '0'} label="Não" defaultValue={0} />
                        <Radio checked={isInstitutional === '1'} label="Sim" defaultValue={1} />
                    </FormGroup>
                </StyledQuestion>
            </StyledCol>
            {isInstitutional === '1' && (
                <>
                    <StyledCol md={6} sm={12}>
                        <SelectField
                            name="StatusFatcaId"
                            commonPrefix={CommonPrefixes.Fatca}
                            validate={validateRequiredField}
                        >
                            {({ field, form: { errors }, onChange, options, defaultValue }) => (
                                <Select
                                    name={field.name}
                                    error={!!errors[field.name]}
                                    message={errors[field.name] || ''}
                                    defaultValue={defaultValue}
                                    options={options}
                                    fullWidth
                                    onChange={(value, id) => handleChangeFatca(value, id, onChange)}
                                    label="Atividade Financeira"
                                />
                            )}
                        </SelectField>
                    </StyledCol>
                    {isGiin && (
                        <StyledCol md={6} sm={12}>
                            <Field name="Giin" validate={validateRequiredField}>
                                {({ inputProps }) => (
                                    <InputInteger {...inputProps} label="Número GIIN" fullWidth />
                                )}
                            </Field>
                        </StyledCol>
                    )}
                </>
            )}
        </>
    );
};

export default Institutional;
