import moment from 'moment';
import { formatCurrency } from '@terrainvest/react-components';

export const normalizeMarginGuarrantee = item => {
    const normalizedOffer = { ...item };

    normalizedOffer.ValorAceitoGarantiaNormalized = formatCurrency(item.ValorAceitoGarantia);

    return normalizedOffer;
};

export const normalizeMarginGuarrantees = items => {
    return [...items].map(offer => normalizeMarginGuarrantee(offer));
};

export const normalizeMarginGuarranteeIntraday = item => {
    const normalizedOffer = { ...item };

    normalizedOffer.DataLancamentoNormalized = moment(item.DataLancamento).format('DD/MM/YYYY');
    normalizedOffer.DataLiquidacaoNormalized = moment(item.DataLiquidacao).format('DD/MM/YYYY');
    normalizedOffer.ValorNormalized = formatCurrency(item.ValorAceitoGarantia);

    return normalizedOffer;
};

export const normalizeMarginGuarranteeIntradays = items => {
    return [...items].map(offer => normalizeMarginGuarranteeIntraday(offer));
};
