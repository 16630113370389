import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Text } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    margin-bottom: ${({ theme }) => theme.global.spaces.highFrequencyUltraviolet};
    padding: 4px;
    box-sizing: border-box;
    ${media.max.md`
        width: 100%;
        justify-content: center;
    `}
`;

export const StyledQRCode = styled.img`
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 4px;
    user-select: none;
    -webkit-user-drag: none;
    ${media.max.md`
        width: 102px;
        height: 102px;
    `}
    ${media.max.sm`
        display: none;
    `}
`;

export const StyledAppInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledText = styled(Text)`
    font-weight: ${({ theme }) => theme.global.fontWeights.infrared};
    color: ${({ theme }) => theme.global.colors.white};
    white-space: break-spaces;
`;

export const StyledServicesImages = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.global.spaces.xRay};
`;

export const StyledServicesImage = styled.img`
    cursor: pointer;
    -webkit-user-drag: none;
    ${media.max.md`
        width: 150px;
    `}
    ${media.max.sm`
        width: 40vw;
        max-width: 150px;
    `}
`;
