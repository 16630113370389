import { FiltersTypes, StatusToFilterProps, TransferRequestProps } from './index.types';

const options = {
    maxPerLine: 6,
    commonFilter: {
        fieldsToFilter: [
            'DateNormalized',
            'TimeNormalized',
            'Amount',
            'AmountNormalized',
            'BankName',
            'BankAgency',
            'BankAccount',
            'BankAccountDigit',
            'Status',
        ],
    },
    statusFilter: {
        fieldsToFilter: ['StatusId'],
    },
};

export const getAllStatus = (transfers: Array<TransferRequestProps>) => {
    const status = {};
    transfers.forEach(transfer => (status[transfer.StatusId] = transfer.Status));
    const allStatus: Array<StatusToFilterProps> = Object.entries(status).map(
        ([value, text]: [string, string]) => ({
            value: parseInt(value, 10),
            text,
        }),
    );
    return allStatus;
};

const mountStringFromTransferByFields = (transfer: TransferRequestProps, fields: Array<string>) => {
    return fields
        .map(field => transfer[field], '')
        .join('-')
        .toLowerCase();
};

export const execFilter = (
    transfers: Array<TransferRequestProps>,
    filters: FiltersTypes,
): Array<TransferRequestProps> => {
    const { commonFilter, statusFilter } = filters;

    const transfersFilteredByStatusId = statusFilter.length
        ? transfers.filter(transfer => statusFilter.includes(transfer.StatusId))
        : transfers;

    const commonFilterLower = commonFilter.toLowerCase();

    const filteredTransfers = transfersFilteredByStatusId.filter(transfer =>
        mountStringFromTransferByFields(transfer, options.commonFilter.fieldsToFilter).includes(
            commonFilterLower,
        ),
    );

    return filteredTransfers;
};

export const getTransfersByLine = (transfers: Array<any>, page: number) => {
    const transfersList = [...transfers];

    return transfersList.splice(page * options.maxPerLine, options.maxPerLine);
};

export const calculatePagesAndGetFirstTransfers = (transfers: Array<any>) => {
    const transfersList = [...transfers];
    const pages = Math.ceil(transfersList.length / options.maxPerLine);

    const filteredTransferList = getTransfersByLine(transfersList, 0);

    return {
        filteredTransferList,
        pages,
    };
};
