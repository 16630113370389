import * as React from 'react';

import QRCode from './assets/QRCode_App.png';
import AppStore from './assets/App_Store.svg';
import PlayStore from './assets/Play_Store.svg';

import * as S from './index.styles';
import { appInAppStoreLink, appInPlayStoreLink } from './index.constants';

const AppDownload = () => {
    return (
        <S.StyledWrapper>
            <S.StyledQRCode src={QRCode} alt="QR Code Aplicativo" />
            <S.StyledAppInfoWrapper>
                <S.StyledText>Baixe nosso app {'\n'}e comece a investir.</S.StyledText>
                <S.StyledServicesImages>
                    <a href={appInPlayStoreLink} target="blank">
                        <S.StyledServicesImage src={PlayStore} alt="Aplicativo na Play Store" />
                    </a>
                    <a href={appInAppStoreLink} target="blank">
                        <S.StyledServicesImage src={AppStore} alt="Aplicativo na App Store" />
                    </a>
                </S.StyledServicesImages>
            </S.StyledAppInfoWrapper>
        </S.StyledWrapper>
    );
};

export default AppDownload;
