import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { FiltersTypes } from './index.types';
import {
    fetchLifeInsurance,
    fetchLifeInsuranceSuccess,
    fetchLifeInsuranceError,
    fetchLifeInsuranceRequests,
    fetchLifeInsuranceRequestsError,
    fetchLifeInsuranceRequestsSuccess,
} from './index.actions';
import { execFilter, execPensionsFilter } from './index.filter';
import { hasInsurencePolicy } from './index.normalize';

const initialFiltersState = { commonFilter: '', statusFilter: [] };

export const initialState = {
    pensions: [],
    pensionsFiltered: [],
    hasInsurencePolicy: false,
    lifeInsuranceRequests: [],
    lifeInsuranceRequestsFiltered: [],
    loading: false,
    error: false,
    filters: { ...initialFiltersState } as FiltersTypes,
    pensionsFilters: { ...initialFiltersState } as FiltersTypes,
};

export const lifeInsuranceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LIFE_INSURANCE_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchLifeInsurance, {
                    successActionCreator: fetchLifeInsuranceSuccess,
                    failActionCreator: fetchLifeInsuranceError,
                }),
            );
        case Actions.LIFE_INSURANCE_FETCH_SUCCESS:
            return {
                ...state,
                pensions: action.pensions,
                pensionsFiltered: action.pensions,
                loading: false,
                error: false,
            };
        case Actions.LIFE_INSURANCE_FETCH_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_INIT:
            return loop(
                { ...state, loading: true, error: false, hasInsurencePolicy: false },
                Cmd.run(fetchLifeInsuranceRequests, {
                    successActionCreator: fetchLifeInsuranceRequestsSuccess,
                    failActionCreator: fetchLifeInsuranceRequestsError,
                    args: [action.account],
                }),
            );
        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_SUCCESS:
            return {
                ...state,
                hasInsurencePolicy: action.requests && hasInsurencePolicy(action.requests),
                lifeInsuranceRequests: action.requests,
                lifeInsuranceRequestsFiltered: action.requests,
                loading: false,
                errorStatus: false,
            };
        case Actions.FETCH_LIFE_INSURANCE_REQUESTS_ERROR:
            return { ...state, errorStatus: action.error, loading: false };

        case Actions.LIFE_INSURANCE_REQUESTS_SET_FILTER: {
            const { filterType, value } = action;
            const newFilters = { ...state.filters, [filterType]: value };
            const lifeInsuranceRequestsFiltered = execFilter(
                state.lifeInsuranceRequests,
                newFilters,
            );
            return {
                ...state,
                filters: { ...newFilters },
                lifeInsuranceRequestsFiltered,
            };
        }

        case Actions.LIFE_INSURANCE_SET_FILTER: {
            const { filterType, value } = action;
            const newFilters = { ...state.pensionsFilters, [filterType]: value };
            const pensionsFiltered = execPensionsFilter(state.pensions, newFilters);
            return {
                ...state,
                pensionsFilters: { ...newFilters },
                pensionsFiltered,
            };
        }

        default:
            return state;
    }
};
