export const normalizeDates = dates => {
    const datesData = [...dates];
    return [...datesData].map(e => {
        return {
            id: e.Id,
            value: e.Date,
        };
    });
};

export const normalizeCustodyExtractDates = dates => {
    const datesData = normalizeDates(dates);
    const datesNormalized = datesData.map(date => ({
        month: date.value.split('/')[0],
        year: date.value.split('/')[1],
    }));
    const separatedDates = [
        ...new Map(datesNormalized.map(date => [date.year + date.month, date])).values(),
    ];
    const years = [...new Map(separatedDates.map(date => [date.year, date.year])).values()];
    const yearsWithMonths = years.map(year => ({
        year,
        months: separatedDates.filter(date => date.year === year).map(date => date.month),
    }));
    return yearsWithMonths;
};
