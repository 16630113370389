import styled from 'styled-components';
import { Modal, Link } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledModal = styled(Modal)`
    > div {
        padding: 0;
    }
`;

export const StyledHeader = styled.div`
    padding: 25px 20px;
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 20px;
    line-height: 1.2em;
    color: white;

    ${media.max.tablet`
        padding: 15px 25px;
    `}
`;

export const StyledLink = styled(Link)`
    & p {
        margin: 0;
        font-family: 'Montserrat';
        text-decoration: underline;
    }
`;

export const StyledWrapper = styled.div`
    padding: 0px 20px 20px 20px;
`;

export const StyledParagraph = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const StyledInfoTitle = styled.p`
    margin: 0;
    margin-bottom: 5px;
    font-size: ${props => props.theme.global.typographyConfig.body.large.fontSize};
    font-weight: bold;
    line-height: 1.2em;
`;

export const StyledInfoSubtitle = styled.p`
    margin: 0;
    font-size: ${props => props.theme.global.typographyConfig.body.regular.fontSize};
    font-weight: ${props => props.theme.global.typographyConfig.fontWeight.regular};
    color: grey;
    line-height: 1.2em;
`;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    margin-top: 10px;

    button {
        height: 40px;
        width: 268px;
        max-width: 100%;
        text-transform: none;
        font-size: ${props => props.theme.global.typographyConfig.body.large.fontSize};
    }

    ${media.max.phone`
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 10px;
    `}
`;
