import { Actions, BankAccountFields, createErrors, createFields } from './index.constants';
import { validateField } from './index.validator';

export const initialState = {
    fields: {} as BankAccountFields,
    errors: {},
};

export const newBankReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.NEW_BANK_ACCOUNT_START: {
            const fields: BankAccountFields = createFields(action.clientId);
            const errors: BankAccountFields = createErrors();
            return { ...state, fields, errors };
        }

        case Actions.NEW_BANK_ACCOUNT_SET_FIELD: {
            const isNewValueValid = validateField(action.fieldName, action.value);
            return {
                ...state,
                fields: { ...state.fields, [action.fieldName]: action.value },
                errors: { ...state.errors, [action.fieldName]: isNewValueValid },
            };
        }

        case Actions.NEW_BANK_ACCOUNT_RESET_FIELDS: {
            const resetedFields = {};
            action.fieldsName.forEach((field: string) => (resetedFields[field] = ''));
            return {
                ...state,
                fields: { ...state.fields, ...resetedFields },
                errors: { ...state.errors, ...resetedFields },
            };
        }

        case Actions.NEW_BANK_ACCOUNT_VALIDADE_FIELD: {
            const value = state.fields[action.fieldName];
            const error = validateField(action.fieldName, value);
            return {
                ...state,
                errors: { ...state.errors, [action.fieldName]: error },
            };
        }

        default:
            return state;
    }
};
