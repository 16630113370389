import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Button, H2, H3, Icon, Text } from '@terrainvest/react-components';

export const StyledLoginWrapper = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.max.md`
        position: initial;
        width: 100%;
    `}
`;

export const StyledPartnerWrapper = styled.div`
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Text.Styles.StyledText} {
        color: ${({ theme }) => theme.global.colors.white};
    }
    ${H2} {
        color: ${({ theme }) => theme.global.colors.white};
    }
    ${H3} {
        color: ${({ theme }) => theme.global.colors.white};
    }
    input {
        :-webkit-autofill,
        :-webkit-autofill:focus,
        :-webkit-autofill:hover {
            transition: background-color 600000s 0s, color 600000s 0s;
            -webkit-text-fill-color: white;
        }
    }
    ${media.max.md`
        width: 90%;
        max-width: 450px;
    `}
`;

export const StyledInsideWrapper = styled.div`
    width: 100%;
    max-width: 450px;
    display: flex;
    align-items: center;
`;

export const StyledInsideTitle = styled(H2)`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    color: ${({ theme }) => theme.global.colors.white};
`;

export const StyledSpacingText = styled(Text)`
    margin: 0;
    line-height: 20px;
    letter-spacing: 0.9px;
`;

export const StyledButton = styled(Button)`
    width: 90%;
    max-width: 300px;
    text-transform: none;
    font-weight: ${({ theme }) => theme.global.fontWeights.regular};
    border-radius: 4px;
    ${Icon.Styles.StyledIcon} {
        margin-left: ${({ theme }) => theme.global.spaces.xRay};
    }
`;

export const StyledHelpButton = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.global.spaces.highFrequencyMicrowave};
    right: ${({ theme }) => theme.global.spaces.highFrequencyMicrowave};
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.global.spaces.xRay};
    padding: ${({ theme }) => `${theme.global.spaces.xRay} ${theme.global.spaces.ultraviolet}`};
    background-color: #1c1c1e4d;
    cursor: pointer;

    ${media.max.md`
        top: ${({ theme }) => theme.global.spaces.xRay};
        right: ${({ theme }) => theme.global.spaces.xRay};
        border-radius: 8px;
    `}
`;

export const StyledHelpButtonText = styled(Text)`
    margin: 0;
    color: white;
    font-weight: ${({ theme }) => theme.global.fontWeights.bold};
`;

export const StyledHelpButtonIcon = styled(Icon)`
    color: ${({ theme }) => theme.global.colors.primary};
`;
