import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchPositionFixedIncomeHistoryAssets,
    fetchPositionFixedIncomeHistorySuccess,
    fetchPositionFixedIncomeHistoryError,
} from './index.actions';

export const initialState = {
    history: [],
    loading: false,
    error: undefined,
};

export const positionFixedIncomeHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.POSITION_FIXED_INCOME_HISTORY_INIT:
            return loop(
                { ...state, history: [], loading: true, error: undefined },
                Cmd.run(fetchPositionFixedIncomeHistoryAssets, {
                    successActionCreator: fetchPositionFixedIncomeHistorySuccess,
                    failActionCreator: fetchPositionFixedIncomeHistoryError,
                    args: [action.accountNumber, action.startDate, action.endDate],
                }),
            );

        case Actions.POSITION_FIXED_INCOME_HISTORY_SUCCESS:
            return { ...state, history: action.history, loading: false, error: undefined };

        case Actions.POSITION_FIXED_INCOME_HISTORY_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
