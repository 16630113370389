import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, Text, Radio } from '@terrainvest/react-components';
import { CommonPrefixes } from '../../../../Redux/Common/index.constants';
import useWindowSize from '../../../../Utils/Hooks/useWindowSize';
import { DefaultLoading } from '../../Form';

export const Gender = ({ field, form }) => {
    const { data } = useSelector(state => state.commonState[CommonPrefixes.Genders]);
    const windowSize = useWindowSize();

    if (!data || !data.length) {
        return <DefaultLoading />;
    }

    const options = data.map(option => ({
        id: option.Value,
        value: option.Description,
    }));

    return (
        <FormGroup
            {...field}
            border
            vertical={false}
            fullWidth
            onChange={evt => {
                const option = options.find(opt => opt.value === evt.target.value);
                if (option) {
                    form.setFieldValue(field.name, option.id);
                }
            }}
        >
            <Text>Sexo:</Text>
            {options.map(option => (
                <Radio
                    name={field.name}
                    key={option.id}
                    defaultChecked={option.id === field.value}
                    label={windowSize.width < 1200 ? option.value.charAt(0) : option.value}
                    defaultValue={option.value}
                />
            ))}
        </FormGroup>
    );
};
