import * as React from 'react';
import CodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@terrainvest/react-components';

import { RootState } from '../../../../../store';
import { fetchNewPassword, fetchValidateToken } from '../../../../../Redux/Auth';
import { postNotificationFromError } from '../../../../../Redux/Notification';

import StepError from '../../../../Common/DoubleCheck/StepError';
import { TokenControlType } from '../../../../Common/DoubleCheck/TokenCheck/index.types';

import { StyledInsideTitle, StyledSpacingText } from '../../index.styles';
import { StyledPrimaryButton } from '../index.styles';

import { ForgotPasswordTokenProps } from './index.types';
import * as S from './index.styles';

const ForgotPasswordToken = ({ username, onTokenAccept }: ForgotPasswordTokenProps) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [tokenLoading, setTokenLoading] = React.useState(false);
    const [token, setToken] = React.useState('');
    const [tokenControlType, setTokenControlType] = React.useState<TokenControlType>('normal');

    const { partner } = useSelector((state: RootState) => state.partnerState);

    const handleChangeToken = (value: string) => {
        if (value.toString().length === 6) setTokenControlType('complete');
        else setTokenControlType('normal');
        setToken(value);
    };

    const generateToken = () => {
        if (tokenLoading || loading) return;
        setTokenLoading(true);
        const payload = {
            username,
            partnerId: partner.id,
        };
        fetchNewPassword(payload)
            .catch(fetchError => {
                dispatch(postNotificationFromError(fetchError));
            })
            .finally(() => setTokenLoading(false));
    };

    const onContinue = () => {
        if (loading) return;
        setLoading(true);
        const payload = {
            username,
            token,
        };
        fetchValidateToken(payload)
            .then(() => onTokenAccept(token))
            .catch(() => setTokenControlType('error'))
            .finally(() => setLoading(false));
    };

    return (
        <S.StyledWrapper>
            <StyledInsideTitle style={{ margin: 0 }}>Código de verificação</StyledInsideTitle>

            <StyledSpacingText>
                Enviamos um token para seu e-mail e número de celular cadastrados.
            </StyledSpacingText>

            <S.StyledTokenWrapperPassword typeToken={tokenControlType}>
                <CodeInput
                    onChange={handleChangeToken}
                    name="code"
                    type="number"
                    inputMode="numeric"
                    fields={6}
                    value={token}
                />
                {tokenControlType === 'error' && (
                    <StepError
                        errorTexts={[
                            {
                                text: 'Código incorreto. Tente novamente ou clique em “',
                                bold: false,
                            },
                            {
                                text: 'reenviar código',
                                bold: true,
                            },
                            {
                                text: '” para receber um novo.',
                                bold: false,
                            },
                        ]}
                    />
                )}
            </S.StyledTokenWrapperPassword>

            <S.BottomWrapper>
                <StyledPrimaryButton
                    onClick={onContinue}
                    disabled={tokenControlType !== 'complete'}
                >
                    Continuar
                    {loading && <Icon icon="spinner-third" spin size="xs" padRight />}
                </StyledPrimaryButton>

                <S.BottomTexts>
                    <StyledSpacingText>AINDA NÃO RECEBEU O CÓDIGO?</StyledSpacingText>
                    <S.ResendToken onClick={generateToken}>
                        <S.ResendTokenIcon
                            spin={tokenLoading}
                            icon={['fal', 'arrow-rotate-right']}
                        />
                        <S.ResendTokenText>REENVIAR CÓDIGO</S.ResendTokenText>
                    </S.ResendToken>
                </S.BottomTexts>
            </S.BottomWrapper>
        </S.StyledWrapper>
    );
};

export default ForgotPasswordToken;
