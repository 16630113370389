import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as ClientActions } from '../Client/index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';
import {
    fetchAccount,
    fetchAccountSuccess,
    fetchAccountError,
    fetchAccountInit,
} from './index.actions';

export const initialState = {
    accounts: null,
    loading: false,
    error: null,
};

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ACCOUNT_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchAccount, {
                    successActionCreator: fetchAccountSuccess,
                    failActionCreator: fetchAccountError,
                    args: [action.accountNumber],
                }),
            );
        case Actions.ACCOUNT_FETCH_SUCCESS:
            return {
                ...state,
                accounts: action.accounts,
                loading: false,
                error: false,
            };
        case Actions.ACCOUNT_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case ClientActions.CLIENT_SELECT_ACCOUNT_NUMBER:
            return loop(
                { ...state, loading: true },
                Cmd.action(fetchAccountInit(action.accountNumber)),
            );
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
