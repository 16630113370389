export const BANNER_TITLE = `Você tem muito a ganhar operando ofertas RLP`;

export const BANNER_INFOS = [
    {
        title: `Maior liquidez`,
        subtitle: `Para realizar negociações nos mercados operados pela B3;`,
    },
    {
        title: `Negociações pelo melhor preço`,
        subtitle: `As operações terão sempre igual ou menor valor comparado ao book de ofertas;`,
    },
    {
        title: `Ajuste contínuo e automático pela B3`,
        subtitle: `Garantindo as melhores condições de liquidez e preço;`,
    },
    {
        title: `Ficou alguma dúvida?`,
        subtitle: `Saiba mais `,
        subtitle_link: `clicando aqui.`,
        link: `https://www.terrainvestimentos.com.br/wp-content/uploads/2020/05/e-book_rlp.pdf`,
    },
];

export const BANNER_ACCEPT_BUTTON_LABEL = `Ativar o RLP e continuar`;
export const BANNER_REFUSE_BUTTON_LABEL = `Continuar sem ativar o RLP`;
