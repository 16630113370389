import moment from 'moment';

export const normalizeOffer = incomingOffer => {
    const normalizedOffer = { ...incomingOffer };

    normalizedOffer.DueDateNormalized = moment(incomingOffer.DueDate).format('DD/MM/YYYY');
    normalizedOffer.QuantityNormalized = parseInt(incomingOffer.Quantity, 10);

    return normalizedOffer;
};

export const normalizeOffers = offers => {
    return [...offers].map(offer => normalizeOffer(offer));
};
