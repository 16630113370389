import moment from 'moment';

export const normalizeRecommendationActive = recommendation => {
    return {
        id: recommendation.Id,
        name: recommendation.Name,
        description: recommendation.Description,
        profile: recommendation.Suitability,
        profileDesc: recommendation.SuitabilityDescription,
        minApplication: recommendation.MinimalApplication,
        isQualifiedInvestor: recommendation.QualifiedInvestor,
        isProfessionalInvestor: recommendation.ProfessionalInvestor,
        index: recommendation.Index,
        startDate: recommendation.StartDate,
        startDateNormalized: moment(recommendation.StartDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        simulationDate: recommendation.Simulation,
        simulationDateNormalized: moment(recommendation.Simulation, 'YYYY-MM-DD').format(
            'DD/MM/YYYY',
        ),
    };
};

export const normalizeRecommendationsActive = recommendations =>
    recommendations && recommendations.map
        ? recommendations.map(normalizeRecommendationActive)
        : [];

export const normalizeRecommendationAssets = assets =>
    assets && assets.map
        ? assets.map(asset => ({
              id: asset.Id,
              recommendationId: asset.RecommendationId,
              assetId: asset.AssetId,
              allocation: asset.Allocation,
              company: asset.Company,
          }))
        : [];

export const normalizeRecommendationCharts = chartData =>
    chartData && chartData.map
        ? chartData.map(value => ({
              id: value.Id,
              recommendationId: value.RecommendationId,
              recommendationValue: value.ValueRecommendation,
              indexValue: value.ValueComparation,
              timePeriod: value.Description,
          }))
        : [];
