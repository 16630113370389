const BALANCE_FIELDS = [
    'TreasuryDirect',
    'Earnings',
    'ProjectedBalance',
    'FixedIncome',
    'Stocks',
    'Futures',
    'Funds',
    'Collateral',
    'Btc',
    'Term',
    'Available',
    'InvestmentClub',
];

export const normalizeBalance = balance => {
    const normalizedBalance = { ...balance };

    BALANCE_FIELDS.forEach(field => {
        normalizedBalance[`${field}Percentage`] =
            (Number.isNaN(normalizedBalance[field]) ? 0 : Number(normalizedBalance[field])) /
            normalizedBalance.Total;
        normalizedBalance[`${field}Percent`] = normalizedBalance[`${field}Percentage`]
            ? `${(normalizedBalance[`${field}Percentage`] * 100).toFixed(0)}%`
            : '-';
    });

    return normalizedBalance;
};
