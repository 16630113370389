import { loop, Cmd } from 'redux-loop';

import { Actions } from './index.constants';
import {
    fetchStructuredProducts,
    fetchStructuredProductsSuccess,
    fetchStructuredProductsError,
    fetchStructuredProductDetails,
    fetchStructuredProductDetailsSuccess,
    fetchStructuredProductDetailsError,
    fetchStructuredProductsStatus,
    fetchStructuredProductsStatusSuccess,
    fetchStructuredProductsStatusError,
    fetchStructuredProductsRequests,
    fetchStructuredProductsRequestsSuccess,
    fetchStructuredProductsRequestsError,
    fetchStructuredProductType,
    fetchStructuredProductTypeError,
    fetchStructuredProductTypeSuccess,
} from './index.actions';

export const initialState = {
    structuredProducts: [],
    structuredProductsRequests: [],
    structuredProductsTypes: [],
    selectedProductDetails: {},
    structuredProductsStatus: {},
    loading: false,
    error: false,
    errorStatus: false,
};

export const structuredProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_STRUCTURED_PRODUCTS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchStructuredProducts, {
                    successActionCreator: fetchStructuredProductsSuccess,
                    failActionCreator: fetchStructuredProductsError,
                }),
            );

        case Actions.FETCH_STRUCTURED_PRODUCTS_SUCCESS:
            return { ...state, structuredProducts: action.products, loading: false, error: false };

        case Actions.FETCH_STRUCTURED_PRODUCTS_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.SELECT_STRUCTURED_PRODUCT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchStructuredProductDetails, {
                    successActionCreator: fetchStructuredProductDetailsSuccess,
                    failActionCreator: fetchStructuredProductDetailsError,
                    args: [action.productId],
                }),
            );
        case Actions.FETCH_STRUCTURED_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                selectedProductDetails: action.product,
                loading: false,
                error: false,
            };
        case Actions.FETCH_STRUCTURED_PRODUCT_DETAILS_ERROR:
            return { ...state, error: action.error, loading: false };
        case Actions.CLEAN_STRUCTURED_PRODUCT_SELECTED:
            return { ...state, selectedProductDetails: {}, error: false, loading: false };
        case Actions.FETCH_STRUCTURED_PRODUCT_STATUS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchStructuredProductsStatus, {
                    successActionCreator: fetchStructuredProductsStatusSuccess,
                    failActionCreator: fetchStructuredProductsStatusError,
                    args: [action.clientId],
                }),
            );
        case Actions.FETCH_STRUCTURED_PRODUCT_STATUS_SUCCESS:
            return {
                ...state,
                structuredProductsStatus: action.response,
                loading: false,
                errorStatus: false,
            };
        case Actions.FETCH_STRUCTURED_PRODUCT_STATUS_ERROR:
            return { ...state, errorStatus: action.error, loading: false };

        case Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchStructuredProductsRequests, {
                    successActionCreator: fetchStructuredProductsRequestsSuccess,
                    failActionCreator: fetchStructuredProductsRequestsError,
                    args: [action.account],
                }),
            );
        case Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_SUCCESS:
            return {
                ...state,
                structuredProductsRequests: action.products,
                loading: false,
                errorStatus: false,
            };
        case Actions.FETCH_STRUCTURED_PRODUCTS_REQUESTS_ERROR:
            return { ...state, errorStatus: action.error, loading: false };

        case Actions.GET_STRUCTURED_PRODUCTS_TYPE_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchStructuredProductType, {
                    successActionCreator: fetchStructuredProductTypeSuccess,
                    failActionCreator: fetchStructuredProductTypeError,
                    args: [action.productId],
                }),
            );
        case Actions.GET_STRUCTURED_PRODUCTS_TYPE_SUCCESS:
            return {
                ...state,
                structuredProductsTypes: action.types,
                loading: false,
                errorStatus: false,
            };
        case Actions.GET_STRUCTURED_PRODUCTS_TYPE_ERROR:
            return { ...state, errorStatus: action.error, loading: false };

        default:
            return state;
    }
};
