import styled from 'styled-components';
import { Select, H1 } from '@terrainvest/react-components';
import { StyledRowNoPadd, StyledColNoPadd } from '../../Common/Grid';

export const StyledRow = styled(StyledRowNoPadd)`
    margin: 8px -16px 0 -16px;
`;

export const StyledCol = styled(StyledColNoPadd)`
    margin-bottom: 1rem;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
`;

export const StyledSelect = styled(Select)``;

export const StyledDetails = styled.div`
    ${H1} {
        margin-top: 0 !important;
    }
`;

export const SelectConstitutionFormat = styled(Select)`
    input {
        text-transform: capitalize;
    }
`;
