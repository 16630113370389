import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchFutureReleases,
    fetchFutureReleasesSuccess,
    fetchFutureReleasesError,
} from './index.actions';

export const initialState = {
    loading: false,
    loaded: false,
    error: null,
    futureReleases: null,
    availableBalance: null,
    projectedBalance: null,
    dPlusInfo: [],
    updatedIn: null,
};

export const futureReleasesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case Actions.FETCH_FUTURE_RELEASES_INIT:
            return state.loading === true
                ? state
                : loop(
                      { ...state, loading: true, error: null },
                      Cmd.run(fetchFutureReleases, {
                          successActionCreator: fetchFutureReleasesSuccess,
                          failActionCreator: fetchFutureReleasesError,
                          args: [action.account],
                      }),
                  );

        case Actions.FETCH_FUTURE_RELEASES_SUCCESS:
            return {
                ...state,
                futureReleases: action.futureReleases,
                availableBalance: action.availableBalance,
                projectedBalance: action.projectedBalance,
                dPlusInfo: action.dPlusInfo,
                updatedIn: action.updatedIn,
                loading: false,
                loaded: true,
                error: false,
            };

        case Actions.FETCH_FUTURE_RELEASES_ERROR:
            return { ...state, error: action.error, loading: false, loaded: true };

        case Actions.FETCH_FUTURE_RELEASES_RESET:
            return { ...initialState };

        default:
            return state;
    }
};
