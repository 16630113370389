import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';
import useChooseAccount from '../../../../Utils/Hooks/useChooseAccount';

import PanelSlide from '../../../Common/PanelSlide';

import * as S from './index.styles';

const HeaderAccountSelection = ({ open, setClose, isMobile, afterSelection, allAccounts }) => {
    const { client, selectedAccount } = useSelector((state: RootState) => state.clientState);

    const { onChooseAccount } = useChooseAccount();

    const handleChooseAccount = (accountNumber: number) => {
        if (isAccountSelected(accountNumber)) return;
        onChooseAccount(accountNumber, afterSelection);
    };

    const isAccountSelected = (accountNumber: number) => selectedAccount === accountNumber;

    const calculateWidth = () => {
        if (isMobile) return 414;
        return 331;
    };

    return (
        <PanelSlide open={open} setClose={setClose} width={calculateWidth()}>
            <S.StyledWrapper>
                <S.StyledIcon icon={['fal', 'money-bill-transfer']} />
                <S.StyledTitle>Escolha uma conta</S.StyledTitle>
                {client.Accounts.map((accountNumber: number) => (
                    <S.StyledAccountWrapper
                        key={accountNumber}
                        onClick={() => handleChooseAccount(accountNumber)}
                        selected={isAccountSelected(accountNumber)}
                    >
                        <S.StyledAccount>{accountNumber}</S.StyledAccount>
                    </S.StyledAccountWrapper>
                ))}
                <S.StyledAllAccounts
                    onClick={() => {
                        allAccounts();
                        afterSelection();
                    }}
                >
                    Ver todas as contas
                </S.StyledAllAccounts>
            </S.StyledWrapper>
        </PanelSlide>
    );
};

export default HeaderAccountSelection;
