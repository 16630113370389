import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Icon, Text } from '@terrainvest/react-components';
import { Player } from '@lottiefiles/react-lottie-player';

import { postNotification } from '../../../Redux/Notification';
import useWindowSize from '../../../Utils/Hooks/useWindowSize';
import { Card } from '../Card';

import * as S from './index.styles';

export const FeedbackColoredCard = ({
    type,
    colored,
    baseColor,
    title,
    redirectTo,
    redirectAfterSomeTime = 0,
    notificationText,
    subtitle = '',
    animation = null,
    animationDuration = 0,
    animationProps = {},
    icon = null,
    iconProps = {},
    bottom = null,
    breakpoint = 768,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    const [animationInstance, setAnimationInstance] = React.useState(null);
    const [isMobile, setIsMobile] = React.useState(windowSize && windowSize.width < breakpoint);

    const showNotification = () => {
        dispatch(postNotification(notificationText, { type }));
    };

    React.useEffect(() => {
        setIsMobile(windowSize.width < breakpoint);
    }, [windowSize]);

    React.useEffect(() => {
        if (!animationInstance) return () => {};
        const timeout = setTimeout(() => animationInstance.pause(), animationDuration);
        return () => clearTimeout(timeout);
    }, [animationInstance]);

    React.useEffect(() => {
        if (!redirectAfterSomeTime || isMobile) return () => {};
        const timeout = setTimeout(() => history.push(redirectTo), redirectAfterSomeTime);
        return () => clearTimeout(timeout);
    }, [redirectAfterSomeTime]);

    if (isMobile)
        return (
            <>
                {showNotification()}
                <Redirect to={redirectTo} />
            </>
        );

    return (
        <Card colored={colored}>
            <S.StyledWrapper>
                {animation && (
                    <Player
                        autoplay
                        src={animation}
                        lottieRef={instance => setAnimationInstance(instance)}
                        {...animationProps}
                    />
                )}
                {icon && !animation && <Icon icon={icon} color={baseColor} {...iconProps} />}
                <S.ColoredTitle color={baseColor}>{title}</S.ColoredTitle>
                {subtitle && <Text>{subtitle}</Text>}
                {bottom}
            </S.StyledWrapper>
        </Card>
    );
};

export default FeedbackColoredCard;
