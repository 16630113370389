import * as React from 'react';
import { useSelector } from 'react-redux';
import { StyledHiddenBalance } from './index.styles';
import HiddenBalanceSwitch from './HiddenBalanceSwitch';

export interface HiddenBalanceProps {
    align?: string;
    icon?: boolean;
    value?: string;
    displayValue?: (val: string) => string | React.ReactNode;
    hifen?: boolean;
    replaceWith?: string;
}

export const HiddenBalance: React.FC<HiddenBalanceProps> = ({
    align = 'flex-start',
    icon = true,
    value,
    displayValue = val => val,
    hifen = false,
    replaceWith = 'R$',
    ...otherProps
}) => {
    const { visible } = useSelector(state => state.balanceState);
    const hiddenString = hifen ? '-' : replaceWith;

    return (
        <StyledHiddenBalance align={align} {...otherProps}>
            {visible ? displayValue(value) : displayValue(hiddenString)}

            {icon && (
                <>
                    &nbsp;
                    <HiddenBalanceSwitch />
                </>
            )}
        </StyledHiddenBalance>
    );
};

export * from './HiddenBalanceSwitch';
export default HiddenBalance;
