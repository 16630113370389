import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchBrokerageNote,
    fetchBrokerageNoteSuccess,
    fetchBrokerageNoteError,
} from './index.actions';

export const initialState = {
    markets: null,
    loading: false,
    error: null,
    fileUrl: null,
};

export const brokerageNoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_BROKERAGENOTE_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBrokerageNote, {
                    successActionCreator: fetchBrokerageNoteSuccess,
                    failActionCreator: fetchBrokerageNoteError,
                }),
            );

        case Actions.FETCH_BROKERAGENOTE_SUCCESS:
            return { ...state, markets: [...action.markets], loading: false, error: false };

        case Actions.FETCH_BROKERAGENOTE_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_BROKERAGENOTE_RESET:
            return { ...state, markets: null, loading: null, error: null };

        default:
            return state;
    }
};
