import styled from 'styled-components';
import { H3 } from '@terrainvest/react-components';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding: 125px 0;
    text-align: center;
    box-sizing: border-box;
`;

export const ColoredTitle = styled(H3)`
    color: ${({ color }) => color};
`;
