import * as React from 'react';
import { HighlightedText, Icon, Text } from '@terrainvest/react-components';
import { StyledErrorFeedback } from './index.styles';

export const FeedbackNote = ({
    id = '',
    message = null,
    color = 'info',
    noIcon = false,
    largeText = true,
    withHighlight = true,
    children = null,
}) => {
    const setsIcon = !noIcon;
    const content = message ? <Text large={largeText}>{message}</Text> : <div>{children}</div>;

    return (
        <StyledErrorFeedback id={id} withHighlight>
            {withHighlight ? (
                <HighlightedText
                    asideColor={color}
                    title={
                        <>
                            {setsIcon ? <Icon icon="circle-exclamation" /> : null}
                            {content}
                        </>
                    }
                />
            ) : (
                <>
                    {setsIcon ? <Icon icon={['far', 'circle-exclamation']} /> : null}
                    {content}
                </>
            )}
        </StyledErrorFeedback>
    );
};

export default FeedbackNote;
