import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';

/**
 * Expose elements with margin/paddings zero in order to allow use of standard bootstrap grid
 * This elements replaced old elements with zero margin/padding
 * */
export const StyledContainerNoPadd = styled(Container)`
    padding: 0;
`;

export const StyledRowNoPadd = styled(Row)`
    margin: 0;
`;

export const StyledColNoPadd = styled(Col)`
    padding: 0;
`;
