import { responseToFile } from '../../Utils/Parsers';
import { fetch, ENDPOINTS, postData } from '../../Utils/Api';
import { Actions } from './index.constants';
import {
    normalizePrivatePension,
    normalizePensionDetails,
    normalizeRelatives,
    normalizeFinancialInstitutionForSelect,
    normalizePaymentMethods,
    normalizePrivatePensionHistory,
} from './index.normalize';
import { fetchNoAuthorization } from '../../Utils/Api/fetchAWS';

export const fetchPrivatePensionInit = () => ({
    type: Actions.PRIVATE_PENSION_FETCH_INIT,
});

export const fetchPrivatePension = () =>
    fetch(ENDPOINTS.GET_PRIVATE_PENSION, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePrivatePension);

export const fetchPrivatePensionSuccess = pensions => ({
    type: Actions.PRIVATE_PENSION_FETCH_SUCCESS,
    pensions,
});

export const fetchPrivatePensionError = () => ({
    type: Actions.PRIVATE_PENSION_FETCH_ERROR,
});

export const fetchPensionDetails = id => {
    const url = ENDPOINTS.GET_PENSION_DETAILS.replace(':id', id);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePensionDetails);
};

export const fetchRelatives = () =>
    fetch(ENDPOINTS.GET_RELATIVES, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeRelatives);

export const fetchBanks = () =>
    fetch(ENDPOINTS.GET_PRIVATE_PENSION_BANKS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeFinancialInstitutionForSelect);

export const fetchPortabilityBanks = () =>
    fetch(ENDPOINTS.GET_PORTABILITY_BANKS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeFinancialInstitutionForSelect);

export const fetchPortabilityInsurances = () =>
    fetch(ENDPOINTS.GET_PORTABILITY_INSURANCES, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeFinancialInstitutionForSelect);

export const fetchPaymentMethods = () =>
    fetch(ENDPOINTS.GET_PAYMENT_METHODS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePaymentMethods);

export const fetchPensionRescue = (
    clientId,
    accountNumber,
    assetId,
    flagFullReclaim,
    reclaimValue,
    userId,
    electronicCheck,
) => {
    return fetch(ENDPOINTS.POST_PRIVATE_PENSION_RESCUE, {
        method: 'POST',
        body: JSON.stringify({
            clientId,
            accountNumber,
            assetId,
            flagFullReclaim,
            reclaimValue,
            userId,
            electronicCheck,
        }),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};
export const postHirePension = payload => postData(payload, ENDPOINTS.POST_HIRE_PRIVATE_PENSION);

export const postTransferPension = payload =>
    postData(payload, ENDPOINTS.POST_TRANSFER_PRIVATE_PENSION);

export const fetchPrivatePensionHistoryInit = account => ({
    type: Actions.PRIVATE_PENSION_HISTORY_FETCH_INIT,
    account,
});

export const fetchPrivatePensionHistory = account => {
    const url = ENDPOINTS.GET_PRIVATE_PENSION_HISTORY.replace(':account', account);
    return fetch(url, {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizePrivatePensionHistory);
};

export const fetchPrivatePensionHistorySuccess = history => ({
    type: Actions.PRIVATE_PENSION_HISTORY_FETCH_SUCCESS,
    history,
});

export const fetchPrivatePensionHistoryError = () => ({
    type: Actions.PRIVATE_PENSION_HISTORY_FETCH_ERROR,
});

export const getTermFile = async (url, filename, useBaseUrl) => {
    const response = await fetchNoAuthorization(url, {
        method: 'GET',
        useBaseUrl,
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

export const getTermFileWithAuthorization = async (url, filename) => {
    const response = await fetch(url, {
        method: 'GET',
    });

    if (response.status && response.status === 200) {
        return responseToFile(response, filename);
    }

    throw new Error(response.status.toString());
};

/* Filter */
export const setCommonFilter = value => ({
    type: Actions.PRIVATE_PENSION_SET_FILTER,
    filterType: 'commonFilter',
    value,
});

export const setFilterByStatus = value => ({
    type: Actions.PRIVATE_PENSION_SET_FILTER,
    filterType: 'statusFilter',
    value,
});
