import styled, { css } from 'styled-components';
import { Text } from '@terrainvest/react-components';

const openProps = css`
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    transition: font-size 0.4s, margin 0.4s, padding 0.4s, line-height 0.4s, opacity 0.6s 0.2s;
`;

const baseCloseProps = css`
    font-size: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    line-height: 0;
    gap: 0;
    transition: font-size 0.4s, margin 0.4s, padding 0.4s, line-height 0.4s, opacity 0.2s;
`;

const closeProps = css`
    ${baseCloseProps};
    ${Text.Styles.StyledText} {
        ${baseCloseProps};
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: ${({ big, theme }) =>
        big ? theme.global.spaces.lowFrequencyXRay : theme.global.spaces.highFrequencyXRay};
    padding-left: ${({ theme }) => theme.global.spaces.highFrequencyVisible};
    ${({ open }) => (open ? openProps : closeProps)};
`;
