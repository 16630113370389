import * as React from 'react';
import { useSelector } from 'react-redux';
import { DynamicLogo } from '@terrainvest/react-components';

import { RootState } from '../../../../store';

import * as S from './index.styles';

const PartnerLogo = () => {
    const { partner } = useSelector((state: RootState) => state.partnerState);

    return (
        <S.StyledLogoWrapper>
            <DynamicLogo
                src={partner.logos.login}
                alt={`Logo ${partner.name}`}
                width="144px"
                height="42px"
            />
        </S.StyledLogoWrapper>
    );
};

export default PartnerLogo;
