import styled, { css } from 'styled-components';
import { H3, Icon, Text } from '@terrainvest/react-components';

import { HiddenBalanceSwitch } from '../../Common/HiddenBalance';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    width: 100%;
    box-sizing: border-box;
`;

export const StyledInfoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: ${({ theme }) => theme.global.spaces.highFrequencyXRay};
    background-color: white;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const StyledTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme, open }) => (open ? theme.global.spaces.lowFrequencyXRay : 0)};
    box-sizing: border-box;
`;

export const StyledTitle = styled(H3)`
    ${({ theme }) => css`
        font-size: ${theme.global.fontSizes.ultraviolet};
        font-weight: ${theme.global.fontWeights.medium};
        font-family: ${theme.global.typographyConfig.body.fontFamily};
        color: #52525b !important;
    `};
    margin: 0;
`;

export const StyledHiddenBalanceSwitch = styled(HiddenBalanceSwitch)`
    ${({ theme }) => css`
        font-size: ${theme.global.fontSizes.xRay};
        & > svg {
            color: ${theme.global.colors.primary};
        }
    `};
    margin: 0;
`;

export const StyledIcon = styled(Icon)`
    margin-left: auto;
    color: #a1a1aa;
    cursor: pointer;
`;

export const StyledSoon = styled(Text)`
    margin: 0;
    margin-left: auto;
    padding: 5px;
    font-size: ${({ theme }) => theme.global.fontSizes.xRay};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.global.colors.primary};
    color: white !important;
`;
