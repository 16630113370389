import React from 'react';
import * as S from './index.styles';

export const usePercentage = (RepresentativesValue, setPercentageTotal) => {
    const isolatedReducer = (previousValue, currentValue) => {
        return Number(previousValue) + Number(currentValue.Percentage);
    };

    const calculatePercentage = () => {
        const total =
            RepresentativesValue &&
            RepresentativesValue.map(representative => representative).reduce(isolatedReducer, 0);

        // eslint-disable-next-line no-restricted-globals
        if (total === undefined || total === null || isNaN(total)) {
            setPercentageTotal('Aguardando inserção de percentual...');
        } else {
            setPercentageTotal(total);
        }
    };

    const totalPercentage = React.useCallback(calculatePercentage, [RepresentativesValue]);

    React.useEffect(() => {
        totalPercentage();
    }, [totalPercentage]);
};

export const viewPercentage = percentageTotal => {
    return (
        <S.StyledWrapperPercentageTotal percentageTotal={percentageTotal}>
            Percentual Total:&nbsp;
            <strong>{`${percentageTotal}%`}</strong>
        </S.StyledWrapperPercentageTotal>
    );
};
