import { Cmd, loop } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as AuthActions } from '../Auth/index.constants';
import { fetchColorsMode, fetchColorsModeError, fetchColorsModeSuccess } from './index.actions';

export const initialState = {
    dark: true,
    loading: false,
    error: '',
};

export const colorsModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.COLOR_MODE_SET:
            return { ...state, dark: !!action.dark };

        case Actions.COLOR_MODE_INVERT:
            return { ...state, dark: !state.dark };

        case Actions.COLORS_MODE_FETCH_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchColorsMode, {
                    successActionCreator: fetchColorsModeSuccess,
                    failActionCreator: fetchColorsModeError,
                    args: [action.clientId],
                }),
            );

        case Actions.COLORS_MODE_FETCH_SUCCESS:
            return { ...state, dark: action.dark, loading: false };

        case Actions.COLORS_MODE_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };

        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };

        default:
            return state;
    }
};
