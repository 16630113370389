import styled, { css } from 'styled-components';
import { Text, Icon } from '@terrainvest/react-components';

export const SectionWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.global.spaces.highFrequencyXRay} 0;
    ${({ first }) =>
        !first &&
        css`
            ::after {
                content: '';
                position: absolute;
                height: 1px;
                top: 0;
                left: -${({ theme }) => theme.global.spaces.lowFrequencyUltraviolet};
                right: 0;
                transition: ${({ theme }) => theme.components.header.transition};
                background-color: ${({ theme }) => theme.global.colors.separationLineColor};
            }
        `};
`;

export const WrapperTop = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ theme }) => theme.global.spaces.highFrequencyVisible} 1fr 30px;
    align-items: center;
    ${({ theme, bold, primary }) =>
        css`
            ${StyledTitle} {
                ${primary && `color: ${theme.global.colors.primary} !important`};
                ${bold && `font-weight: ${theme.global.fontWeights.microwave} !important`};
            }
        `};
`;

export const StyledIcon = styled(Icon)`
    color: ${({ theme }) => theme.global.colors.primary};
    font-size: ${({ theme }) => theme.global.fontSizes.infrared};
    ${({ onClick }) => !!onClick && 'cursor: pointer;'};
`;

export const StyledTitle = styled(Text)`
    ${({ onClick }) => !!onClick && 'cursor: pointer;'}
    ${({ loading }) => !!loading && 'cursor: progress;'}
`;
