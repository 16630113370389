import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledAnchors = styled.div`
    ${props => `
        ${
            props.showAnchors
                ? `
            position: fixed;
            background: white;
            z-index: 100;
            width: 200px;
            box-sizing: border-box;
        `
                : `
            display:none;
        `
        }
    `}

    ${media.max.lg`
        display: none;
    `}
`;
