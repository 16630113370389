import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { Actions as ClientActions } from '../Client/index.constants';
import {
    fetchSuitabilityProfile,
    fetchSuitabilityProfileError,
    fetchSuitabilityProfileSuccess,
    fetchSuitabilityProfileByAccountNumberInit,
    fetchSuitabilityProfileByAccountNumber,
    fetchSuitabilityProfileByAccountNumberSuccess,
} from './index.actions';
import { Actions as AuthActions } from '../Auth/index.constants';

export const initialState = {
    profile: undefined,
    minifiedProfile: undefined,
    loading: false,
    loadingPostSuitability: false,
    error: null,
    isEmptyProfile: false,
    shouldFillModal: false,
};

export const suitabilityProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_SUITABILITY_PROFILE_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchSuitabilityProfile, {
                    successActionCreator: fetchSuitabilityProfileSuccess,
                    failActionCreator: fetchSuitabilityProfileError,
                    args: [action.clientId],
                }),
            );
        case Actions.FETCH_SUITABILITY_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.profile,
                loading: false,
                error: false,
            };
        case Actions.FETCH_SUITABILITY_PROFILE_ERROR:
            return { ...state, error: action.error, loading: false };
        case Actions.FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchSuitabilityProfileByAccountNumber, {
                    successActionCreator: fetchSuitabilityProfileByAccountNumberSuccess,
                    failActionCreator: fetchSuitabilityProfileError,
                    args: [action.accountNumber],
                }),
            );
        case ClientActions.CLIENT_SELECT_ACCOUNT_NUMBER:
            return loop(
                { ...state, loading: true },
                Cmd.action(fetchSuitabilityProfileByAccountNumberInit(action.accountNumber)),
            );
        case Actions.FETCH_SUITABILITY_PROFILE_BY_ACCOUNT_NUMBER_SUCCESS:
            return {
                ...state,
                minifiedProfile: action.minifiedProfile,
                loading: false,
                error: false,
            };
        case Actions.EMPTY_SUITABILITY_PROFILE:
            return {
                ...state,
                isEmptyProfile: action.isEmptyProfile,
            };
        case Actions.SET_SUITABILITY_PROFILE:
            return {
                ...state,
                profile: action.profile,
            };
        case Actions.FILL_SUITABILITY_PROFILE_MODAL:
            return {
                ...state,
                shouldFillModal: true,
                isEmptyProfile: false,
            };
        case Actions.SET_FILL_SUITABILITY_PROFILE_MODAL:
            return {
                ...state,
                shouldFillModal: action.shouldFillModal,
            };
        case Actions.SET_LOADING_POST_SUITABILITY:
            return {
                ...state,
                loadingPostSuitability: action.loadingPostSuitability,
            };
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
