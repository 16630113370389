import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import { appMsgTypes, postAppMsg } from '../General';

export const useFileHandler = () => {
    const { isTerraApp } = useSelector((state: RootState) => state.mobileState);

    const handleFile = (
        desktopAction: any,
        url: string,
        method = 'GET',
        body = null,
        useBaseURL = true,
    ) => {
        if (isTerraApp) {
            const customUrl = useBaseURL ? `${process.env.API_URL}${url}` : url;
            const payload = {
                url: customUrl,
                method,
                body,
            };
            postAppMsg(appMsgTypes.FILE, payload);
        } else {
            desktopAction();
        }
    };

    return handleFile;
};

export default useFileHandler;
