import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeOffers } from './index.normalize';

export const fetchInvestmentBtcAssetsInit = accountNumber => ({
    type: Actions.FETCH_BTC_INIT,
    accountNumber,
});

export const fetchBtcAssets = accountNumber => {
    return fetch(ENDPOINTS.GET_INVESTMENT_BTC.replace(':accountNumber', accountNumber), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeOffers);
};

export const fetchBtcSuccess = offers => ({
    type: Actions.FETCH_BTC_SUCCESS,
    offers,
});

export const fetchBtcError = error => ({
    type: Actions.FETCH_BTC_ERROR,
    error: error.message,
});
