import { loop, Cmd } from 'redux-loop';

import { Actions } from './index.constants';
import {
    fetchPublicOffers,
    fetchPublicOffersSuccess,
    fetchPublicOffersError,
    fetchOfferHistory,
    fetchOfferHistorySuccess,
    fetchOfferHistoryError,
} from './index.actions';

export const initialState = {
    publicOffers: [],
    offerHistory: [],
    loading: false,
    error: false,
};

export const publicOffersReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_PUBLIC_OFFERS_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchPublicOffers, {
                    successActionCreator: fetchPublicOffersSuccess,
                    failActionCreator: fetchPublicOffersError,
                }),
            );

        case Actions.FETCH_PUBLIC_OFFERS_SUCCESS:
            return { ...state, publicOffers: action.offers, loading: false, error: false };

        case Actions.FETCH_PUBLIC_OFFERS_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_OFFER_HISTORY_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchOfferHistory, {
                    successActionCreator: fetchOfferHistorySuccess,
                    failActionCreator: fetchOfferHistoryError,
                    args: [action.account],
                }),
            );

        case Actions.FETCH_OFFER_HISTORY_SUCCESS:
            return { ...state, offerHistory: action.offers, loading: false, error: false };

        case Actions.FETCH_OFFER_HISTORY_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
