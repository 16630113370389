import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledOrderIssuer = styled.div`
    position: relative;
    margin-top: 28px;

    & > :first-child {
        margin-top: 0;
    }
`;

export const StyledDelete = styled.div`
    position: absolute;
    top: -40px;
    right: 0;
    cursor: pointer;

    ${Text.Styles.StyledText} {
        margin: 0;
        display: flex;
        align-items: center;

        & > :last-child {
            margin-left: 8px;
        }
    }
`;
