import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchClient,
    fetchClientSuccess,
    fetchClientError,
    selectAccountNumber,
    fetchClientInit,
} from './index.actions';
import { Actions as AuthActions } from '../Auth/index.constants';

export const initialState = {
    client: null,
    selectedAccount: null,
    loading: false,
    error: false,
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CLIENT_FETCH_INIT:
            return loop(
                { ...state, loading: true, error: false },
                Cmd.run(fetchClient, {
                    successActionCreator: fetchClientSuccess,
                    failActionCreator: fetchClientError,
                    args: [action.refresh, Cmd.getState],
                }),
            );
        case Actions.CLIENT_FETCH_SUCCESS:
            return !state.client && state.selectedAccount
                ? loop(
                      { ...state, client: action.client, loading: false, error: false },
                      Cmd.action(selectAccountNumber(state.selectedAccount)),
                  )
                : { ...state, client: action.client, loading: false, error: false };
        case Actions.CLIENT_SELECT_ACCOUNT_NUMBER:
            return { ...state, selectedAccount: action.accountNumber };
        case Actions.CLIENT_FETCH_ERROR:
            return { ...state, error: action.error, loading: false };
        case AuthActions.AUTH_FETCH_SUCCESS:
            return loop({ ...state }, Cmd.action(fetchClientInit()));
        case AuthActions.AUTH_LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};
