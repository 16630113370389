import styled from 'styled-components';
import { H3, Text } from '@terrainvest/react-components';

export const SubtitleWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
    margin-top: -12px;

    text-transform: initial;
`;

export const StyledTitle = styled(H3)`
    margin-top: 53px;
    margin-bottom: ${({ theme }) => theme.global.spaces.highFrequencyUltraviolet};
    font-weight: ${({ theme }) => theme.global.fontWeights.semiBold};
`;

export const StyledSubtitle = styled(Text)`
    font-weight: ${({ theme }) => theme.global.fontWeights.semiBold};
    color: ${({ theme }) => theme.global.colors.primary} !important;
`;

export const StyledBlockWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.global.spaces.ultraviolet};
`;
