import styled from 'styled-components';
import { Button, Link, Text } from '@terrainvest/react-components';
import { media } from 'styled-bootstrap-grid';

export const StyledLoginBase = styled.form`
    background-color: ${props => props.theme.global.colors.white};
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const StyledNotificationButton = styled(Button)`
    margin-left: 40px;
`;

export const StyledLogin = styled(StyledLoginBase)`
    box-sizing: border-box;

    ${media.max.lg`
        height: calc(100vh - 48px);
    `}

    ${media.max.xs`
        padding: 20px 16px;
    `}

    & > :first-child {
        margin-bottom: 48px;
    }

    & > :nth-child(n + 2) {
        box-sizing: border-box;
        margin-bottom: 30px;
        width: 350px;
        max-width: 100%;
    }

    ${Button.Styles.StyledButton} {
        height: 48px;
    }

    ${Link.Styles.StyledLink} {
        display: flex;
        justify-content: center;
    }

    ${Text.Styles.StyledText} {
        margin-top: 0;
    }
`;

export const StyledSignup = styled(StyledLoginBase)`
    background-color: ${props => props.theme.global.colors.capeCodDarker};

    ${media.max.lg`
        display: none;
    `}
`;

export const StyledSignupContent = styled.div`
    width: 300px;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 6rem;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    ${Text.Styles.StyledText} {
        display: ${props => (props.display ? 'flex' : 'none')};
        margin: 0;
    }

    ${Button.Styles.StyledButton} {
        display: ${props => (props.display ? 'flex' : 'none')};
        margin-top: 20px;
    }

    & > * {
        color: white;
    }
`;

export const StyledCTAText = styled(Text)`
    color: ${props => props.theme.global.colors.primary};
`;

export const StyledRegisterText = styled(Text)`
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 27px;
`;

export const StyledRegisterLink = styled(Text)`
    font-family: 'Montserrat';
    font-size: 12px;
    line-height: 14px;
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;

    ${media.max.lg`
        display: inline-flex;
    `}

    ${Link.Styles.StyledLink} {
        display: flex;
        justify-content: center;
        margin-left: 5px;
        line-height: 14px;
        cursor: pointer;
    }
`;
