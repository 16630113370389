import * as React from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard, Skeleton } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import { baseBankAccount } from '../../../../Utils/General/CommonInfo';

import * as S from './index.styles';

const TEDAccount = () => {
    const { selectedAccount } = useSelector((state: RootState) => state.clientState);
    const { accounts } = useSelector((state: RootState) => state.accountState);

    const accountInfo =
        selectedAccount &&
        accounts &&
        accounts.find &&
        accounts.find(account => String(account.Number) === String(selectedAccount));

    return (
        <S.StyledWrapper>
            <S.StyledDescription>
                Copie os dados abaixo para o seu banco e transfira dinheiro para sua conta na Terra
                Investimentos.
            </S.StyledDescription>

            <S.StyledItemWrapper id="TED-account-bank-mobile">
                <S.StyledItemText>Banco</S.StyledItemText>
                <S.StyledItemTextRight>{baseBankAccount.bank}</S.StyledItemTextRight>
                <S.StyledIcon icon={['far', 'copy']} />
                <CopyToClipboard
                    bindTo="TED-account-bank-mobile"
                    text={`${accountInfo.Number}-${accountInfo.Digit}`}
                    popoverText="Copiado"
                />
            </S.StyledItemWrapper>

            <S.StyledItemWrapper id="TED-agency-info-mobile">
                <S.StyledItemText>Agência</S.StyledItemText>
                <S.StyledItemTextRight>{baseBankAccount.agency}</S.StyledItemTextRight>
                <S.StyledIcon icon={['far', 'copy']} />
                <CopyToClipboard
                    bindTo="TED-agency-info-mobile"
                    text={`${accountInfo.Number}-${accountInfo.Digit}`}
                    popoverText="Copiado"
                />
            </S.StyledItemWrapper>

            <S.StyledItemWrapper id="TED-account-info-mobile">
                <S.StyledItemText>Conta</S.StyledItemText>
                {accountInfo ? (
                    <>
                        <S.StyledItemTextRight>
                            {`${accountInfo.Number}-${accountInfo.Digit}`}
                        </S.StyledItemTextRight>
                        <S.StyledIcon icon={['far', 'copy']} />
                        <CopyToClipboard
                            bindTo="TED-account-info-mobile"
                            text={`${accountInfo.Number}-${accountInfo.Digit}`}
                            popoverText="Copiado"
                        />
                    </>
                ) : (
                    <Skeleton height={15} width={75} />
                )}
            </S.StyledItemWrapper>
        </S.StyledWrapper>
    );
};

export default TEDAccount;
