export const Actions = {
    FETCH_STRUCTURED_PRODUCTS_INIT: 'FETCH_STRUCTURED_PRODUCTS_INIT',
    FETCH_STRUCTURED_PRODUCTS_SUCCESS: 'FETCH_STRUCTURED_PRODUCTS_SUCCESS',
    FETCH_STRUCTURED_PRODUCTS_ERROR: 'FETCH_STRUCTURED_PRODUCTS_ERROR',
    FETCH_STRUCTURED_PRODUCT_DETAILS: 'FETCH_STRUCTURED_PRODUCT_DETAILS',
    FETCH_STRUCTURED_PRODUCT_DETAILS_SUCCESS: 'FETCH_STRUCTURED_PRODUCT_DETAILS_SUCCESS',
    FETCH_STRUCTURED_PRODUCT_DETAILS_ERROR: 'FETCH_STRUCTURED_PRODUCT_DETAILS_ERROR',
    SELECT_STRUCTURED_PRODUCT: 'SELECT_STRUCTURED_PRODUCT',
    CLEAN_STRUCTURED_PRODUCT_SELECTED: 'CLEAN_STRUCTURED_PRODUCT_SELECTED',
    FETCH_STRUCTURED_PRODUCT_STATUS_INIT: 'FETCH_STRUCTURED_PRODUCT_STATUS_INIT',
    FETCH_STRUCTURED_PRODUCT_STATUS_SUCCESS: 'FETCH_STRUCTURED_PRODUCT_STATUS_SUCCESS',
    FETCH_STRUCTURED_PRODUCT_STATUS_ERROR: 'FETCH_STRUCTURED_PRODUCT_STATUS_ERROR',
    FETCH_STRUCTURED_PRODUCTS_REQUESTS_INIT: 'FETCH_STRUCTURED_PRODUCTS_REQUESTS_INIT',
    FETCH_STRUCTURED_PRODUCTS_REQUESTS_SUCCESS: 'FETCH_STRUCTURED_PRODUCTS_REQUESTS_SUCCESS',
    FETCH_STRUCTURED_PRODUCTS_REQUESTS_ERROR: 'FETCH_STRUCTURED_PRODUCTS_REQUESTS_ERROR',
    GET_STRUCTURED_PRODUCTS_TYPE_INIT: 'GET_STRUCTURED_PRODUCTS_TYPE_INIT',
    GET_STRUCTURED_PRODUCTS_TYPE_SUCCESS: 'GET_STRUCTURED_PRODUCTS_TYPE_SUCCESS',
    GET_STRUCTURED_PRODUCTS_TYPE_ERROR: 'GET_STRUCTURED_PRODUCTS_TYPE_ERROR',
};

export const STATUS_IN_PROGRESS = 1;
