import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import {
    fetchRecommendationsHistory,
    fetchRecommendationsHistorySuccess,
    fetchRecommendationsHistoryError,
} from './index.actions';

export const initialState = {
    table: null,
    loading: false,
    error: null,
};

export const recommendationsHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_RECOMMENDATIONS_HISTORY_INIT:
            return loop(
                { ...state, loading: true, error: null, table: null },
                Cmd.run(fetchRecommendationsHistory, {
                    successActionCreator: fetchRecommendationsHistorySuccess,
                    failActionCreator: fetchRecommendationsHistoryError,
                    args: [action.id],
                }),
            );

        case Actions.FETCH_RECOMMENDATIONS_HISTORY_SUCCESS:
            return { ...state, table: [...action.table], loading: false, error: null };

        case Actions.FETCH_RECOMMENDATIONS_HISTORY_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_RECOMMENDATIONS_HISTORY_RESET:
            return { ...state, error: null, table: null, loading: false };

        default:
            return state;
    }
};
