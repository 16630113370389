import * as React from 'react';

const useBodyMarginsCalculator = (mobile: boolean) => {
    React.useEffect(() => {
        if (mobile) {
            document.body.style.marginLeft = 'initial';
            document.body.style.marginBottom = '60px';
        } else {
            document.body.style.marginLeft = '220px';
            document.body.style.marginBottom = 'initial';
        }

        return () => {
            document.body.style.marginLeft = 'initial';
            document.body.style.marginBottom = 'initial';
            document.body.style.overflow = 'initial';
        };
    }, [mobile]);
};

export default useBodyMarginsCalculator;
