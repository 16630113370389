export enum PositionTypes {
    STOCKS = 'stocks',
    FIXED_INCOME = 'fixedIncome',
    TREASURY_DIRECT = 'treasuryDirect',
    FUNDS = 'funds',
    FUTURES = 'futures',
    EARNINGS_DIVIDENDS = 'earningsDividends',
    BTC = 'btc',
    COLLATERAL = 'collateral',
    INTRADAY = 'intraday',
    TERMS = 'terms',
    PRIVATE_PENSION = 'privatePension',
}

export const PositionActions = {
    POSITIONS_FETCH: 'POSITIONS_FETCH',
};
