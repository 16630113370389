import { formatCurrency } from '@terrainvest/react-components';

const parseDialyLiquidity = value => {
    return value ? 'Diária' : 'No vencimento';
};

const OFFER_FIELDS = [
    { label: 'DialyLiquidity', normalizer: parseDialyLiquidity },
    { label: 'MinOrderValue', normalizer: value => formatCurrency(value, { currency: '' }) },
];

const normalizeOffer = offer => {
    const normalizedOffer = { ...offer };

    OFFER_FIELDS.forEach(field => {
        const rawField = normalizedOffer[field.label];
        normalizedOffer[`${field.label}Normalized`] = field.normalizer(rawField);
    });

    return normalizedOffer;
};

export const normalizeOffers = offers => {
    return [...offers].map(offer => normalizeOffer(offer));
};
