import * as React from 'react';

import SideNavSubSection from './SideNavSubSection';
import * as S from './index.styles';
import { SideNavSubSectionsProps } from './index.types';

const SideNavSubSections = ({ subSections, open }: SideNavSubSectionsProps) => {
    return (
        <>
            {subSections.map(subSection => (
                <S.StyledWrapper key={subSection.title} open={open} {...subSection.otherProps}>
                    <SideNavSubSection subSection={subSection} open={open} />
                </S.StyledWrapper>
            ))}
        </>
    );
};

export default SideNavSubSections;
