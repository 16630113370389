import styled from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';

export const StyledPasswordTip = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
    color: ${({ theme }) => theme.global.colors.white};
`;

export const StyledTitle = styled(Text)`
    margin: 0;
`;

export const StyledRules = styled.div`
    width: 100%;
`;

const getRuleColor = (hasPassedRule: boolean | null, paint: boolean) => {
    if (hasPassedRule) return '#4ADE80';
    if (hasPassedRule !== null) return '#FB7185';

    if (!paint) return '#F97316';

    return '#999999';
};

export const StyledIcon = styled(Icon)`
    width: 16px;
`;

export const StyledText = styled(Text)`
    margin: 0;
    line-height: ${({ theme }) => theme.global.spaces.ultraviolet};
`;

export const StyledRule = styled.div`
    display: flex;
    align-items: start;
    gap: ${({ theme }) => theme.global.spaces.highFrequencyGammaRay};

    ${StyledIcon} {
        color: ${({ hasPassedRule, paint }) => getRuleColor(hasPassedRule, paint)};
    }
`;
