import * as React from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard, Skeleton } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import { baseBankAccount } from '../../../../Utils/General/CommonInfo';

import { StyledSubtitle } from '../../ProfileMenu/index.styles';
import * as S from './index.styles';

export const HeaderTEDAccountItem = () => {
    const { selectedAccount } = useSelector((state: RootState) => state.clientState);
    const { accounts } = useSelector((state: RootState) => state.accountState);

    const accountInfo =
        selectedAccount &&
        accounts &&
        accounts.find &&
        accounts.find(account => String(account.Number) === String(selectedAccount));

    return (
        <S.StyledWrapper>
            <StyledSubtitle>Minha Conta para TED</StyledSubtitle>
            <S.StyledItemWrapper id="TED-bank-info">
                <S.StyledItemText>Banco</S.StyledItemText>
                <S.StyledItemText>
                    <b>{baseBankAccount.bank}</b>
                </S.StyledItemText>
                <S.StyledIcon icon={['fal', 'copy']} />
                <CopyToClipboard
                    bindTo="TED-bank-info"
                    text={baseBankAccount.bank}
                    popoverText="Copiado"
                />
            </S.StyledItemWrapper>

            <S.StyledItemWrapper id="TED-agency-info">
                <S.StyledItemText>Agência</S.StyledItemText>
                <S.StyledItemText>
                    <b>{baseBankAccount.agency}</b>
                </S.StyledItemText>
                <S.StyledIcon icon={['fal', 'copy']} />
                <CopyToClipboard
                    bindTo="TED-agency-info"
                    text={baseBankAccount.agency}
                    popoverText="Copiado"
                />
            </S.StyledItemWrapper>

            <S.StyledItemWrapper id="TED-account-info">
                <S.StyledItemText>Conta</S.StyledItemText>
                {accountInfo ? (
                    <>
                        <S.StyledItemText>
                            <b>{`${accountInfo.Number}-${accountInfo.Digit}`}</b>
                        </S.StyledItemText>
                        <S.StyledIcon icon={['fal', 'copy']} />
                        <CopyToClipboard
                            bindTo="TED-account-info"
                            text={`${accountInfo.Number}-${accountInfo.Digit}`}
                            popoverText="Copiado"
                        />
                    </>
                ) : (
                    <Skeleton height={15} width={75} />
                )}
            </S.StyledItemWrapper>
        </S.StyledWrapper>
    );
};

export default HeaderTEDAccountItem;
