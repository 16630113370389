import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchBroker, fetchBrokerSuccess, fetchBrokerError } from './index.actions';

export const initialState = {
    brokers: null,
    loading: false,
    error: null,
    fileUrl: null,
};

export const brokerReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_BROKER_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchBroker, {
                    successActionCreator: fetchBrokerSuccess,
                    failActionCreator: fetchBrokerError,
                }),
            );

        case Actions.FETCH_BROKER_SUCCESS:
            return { ...state, brokers: [...action.brokers], loading: false, error: false };

        case Actions.FETCH_BROKER_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_BROKER_RESET:
            return { ...state, brokers: null, loading: false, error: false };

        default:
            return state;
    }
};
