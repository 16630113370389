import { Checkbox, Radio } from '@terrainvest/react-components';
import styled from 'styled-components';

const InputStyle = 'width: calc(100% - 32px);';

export const StyledRadio = styled(Radio)`
    ${InputStyle}
`;

export const StyledCheckbox = styled(Checkbox)`
    ${InputStyle}
`;

export const StyledSuitabilityQuestion = styled.div`
    position: relative;
`;
