import * as React from 'react';
import { useSelector } from 'react-redux';

import { Link, DynamicLogo } from '@terrainvest/react-components';

import { RootState } from '../../../../store';
import Separator from '../../../../Utils/General/index.separator';
import { ENDPOINTS } from '../../../../Utils/Api';

import {
    StyledWrapper,
    StyledFeedbackWrapper,
    StyledTitleText,
    StyledBodyText,
    StyledButtonWrapper,
    StyledButton,
} from '../../Common/index.styles';

export const RedirectUnfinishedSignup = ({ clientData }) => {
    const { partner } = useSelector((state: RootState) => state.partnerState);

    return (
        <StyledWrapper>
            <DynamicLogo
                src={partner.logos.login}
                alt={`Logo ${partner.name}`}
                width="200px"
                height="100px"
            />
            <Separator />
            <StyledFeedbackWrapper>
                <StyledTitleText large>
                    Olá, <span>{clientData.Name.toLowerCase()}</span>
                </StyledTitleText>
                <StyledBodyText large>
                    Estão faltando alguns dados para finalizarmos seu cadastro na Terra
                    Investimentos. Para dar continuidade, clique no botão abaixo, preencha seu CPF
                    ou CNPJ e você já será direcionado para o ponto em que parou.
                </StyledBodyText>
                <Separator />
                <StyledButtonWrapper>
                    <Link href={ENDPOINTS.SIGNUP_CONTINUE} border={false} target="_self">
                        <StyledButton type="button" primary>
                            Continuar Cadastro
                        </StyledButton>
                    </Link>
                </StyledButtonWrapper>
            </StyledFeedbackWrapper>
        </StyledWrapper>
    );
};

export default RedirectUnfinishedSignup;
