import React from 'react';

interface WindowSize {
    width: number;
    height: number;
}

const useWindowSize = () => {
    const isBrowser = typeof window === 'object';

    const getSize = () => ({
        width: isBrowser ? window.innerWidth : undefined,
        height: isBrowser ? window.innerHeight : undefined,
    });

    const [windowSize, setWindowSize] = React.useState<WindowSize>(getSize);

    React.useEffect(() => {
        const handleResize = () => setWindowSize(getSize());
        if (isBrowser) {
            window.addEventListener('resize', handleResize);
        }
        return () => isBrowser && window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
