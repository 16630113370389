export const normalizeString = value => {
    // https://metring.com.br/javascript-substituir-caracteres-especiais

    return value
        ? value
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
              .toLowerCase()
        : value;
};

export const normalizeUpperWithSpace = value => {
    // https://metring.com.br/javascript-substituir-caracteres-especiais

    return value
        ? value
              .normalize('NFD')
              .replace(/([\u0300-\u036f])/g, '')
              .toUpperCase()
        : value;
};

export const normalizeStringLite = value => {
    return value ? value.trim().toLowerCase() : value;
};

export const truncateText = (value, limit = 10, suffix = '(...)') => {
    return value && value.length > limit ? `${(value as string).slice(0, limit)}${suffix}` : value;
};

/**
 * Remove caracteres especiais, mantendo os espaços.
 * Substitui espaços multiplos por espaços simples.
 * @param value string pura
 */
export const normalizeSpecialCharacters = value => {
    return value
        ? value
              .trim()
              .normalize('NFD')
              .replace(/([^0-9a-zA-Z\s])/g, '')
              .replace(/(\s{2,})/g, ' ')
              .toLowerCase()
        : value;
};

export const capitalizeAllWords = (value: string) => {
    return value ? value.toLowerCase().replace(/\b(\w)/g, x => x.toUpperCase()) : value;
};
