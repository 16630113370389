import { parseDateForDisplay } from '../../Utils/Parsers';
import { STATUS_IN_PROGRESS } from './index.constants';

export const normalizeOffers = data =>
    data && data.map
        ? data.map(offer => ({
              id: offer.Id,
              name: offer.Name,
              type: offer.Type,
              minimalReserve: offer.MinimalReserve,
              maximumReserve: offer.MaximumReserve,
              minimalIndicative: offer.MinimalIndicative,
              maximumIndicative: offer.MaximumIndicative,
              estimatedPrice: offer.EstimatedPrice,
              liquidationDate: offer.LiquidationDate,
              liquidationDateNormalized: parseDateForDisplay(offer.LiquidationDate),
              reserveDateStart: offer.ReserveDateStart,
              reserveDateStartNormalized: parseDateForDisplay(offer.ReserveDateStart),
              reserveDateLimit: offer.ReserveDateLimit,
              reserveDateLimitNormalized: parseDateForDisplay(offer.ReserveDateLimit),
              modalityId: offer.ModalityId,
              asset: offer.Asset,
              isByQuantity: offer.ByQuantity,
              minQuantityReserve: offer.MinimalQuantityReserve,
              maxQuantityReserve: offer.MaximumQuantityReserve,
              risk: {
                  color: offer.Risk.Color,
                  description: offer.Risk.Description,
              },
              suitability: offer.SuitabilityOffer,
              suitabilityDescription: offer.SuitabilityDescription,
              status: offer.Status,
              statusDescription: offer.StatusDescription,
          }))
        : [];

export const normalizeOffer = offer => {
    return {
        id: offer.Id,
        name: offer.Name,
        type: offer.Type,
        files: normalizeFiles(offer.Files),
        modalities: normalizeModalities(offer.Modalities),
        schedules: normalizeSchedules(offer.Schedules),
        minimalReserve: offer.MinimalReserve,
        maximumReserve: offer.MaximumReserve,
        minimalIndicative: offer.MinimalIndicative,
        maximumIndicative: offer.MaximumIndicative,
        estimatedPrice: offer.EstimatedPrice,
        liquidationDate: offer.LiquidationDate,
        liquidationDateNormalized: parseDateForDisplay(offer.LiquidationDate),
        linkedReserveDateLimitNormalized: parseDateForDisplay(offer.LinkedReserveDateLimit),
        reserveDateStart: offer.ReserveDateStart,
        reserveDateStartNormalized: parseDateForDisplay(offer.ReserveDateStart),
        reserveDateLimit: offer.ReserveDateLimit,
        reserveDateLimitNormalized: parseDateForDisplay(offer.ReserveDateLimit),
        modalityId: offer.ModalityId,
        asset: offer.Asset,
        isByQuantity: offer.ByQuantity,
        minQuantityReserve: offer.MinimalQuantityReserve,
        maxQuantityReserve: offer.MaximumQuantityReserve,
        risk: {
            color: offer.Risk.Color,
            description: offer.Risk.Description,
        },
        suitability: offer.Suitability,
        suitabilityDescription: offer.SuitabilityDescription,
        suitabilityOffer: offer.SuitabilityOffer,
        status: offer.Status,
        statusDescription: offer.StatusDescription,
    };
};

export const normalizeModalities = modalities =>
    modalities && modalities.map
        ? modalities.map(modality => ({
              id: modality.Id,
              value: modality.Name,
              description: modality.Description,
              offeringId: modality.OfferingId,
          }))
        : [];

export const normalizeHistory = data =>
    data && data.map
        ? data.map(offer => ({
              id: offer.Id,
              offerId: offer.OfferingId,
              name: offer.OfferingName,
              liquidationDate: offer.LiquidationDate,
              liquidationDateNormalized: parseDateForDisplay(offer.LiquidationDate),
              bookDate: offer.ReserveDate,
              bookDateNormalized: parseDateForDisplay(offer.ReserveDate),
              reserveDate: offer.ReserveDate,
              reserveDateNormalized: parseDateForDisplay(offer.ReserveDate),
              reserveLimit: offer.ReserveDateLimit,
              amount: offer.Amount,
              quantity: offer.Quantity,
              isByQuantity: offer.Quantity !== 0,
              statusName: offer.StatusDescription,
              isInProgress: offer.Status === STATUS_IN_PROGRESS,
          }))
        : [];

export const normalizeSchedules = data =>
    data &&
    data.map(schedule => ({
        date: parseDateForDisplay(schedule.Date),
        description: schedule.Description,
        id: schedule.Id,
        offeringId: schedule.OfferingId,
    }));

export const normalizeFiles = data =>
    data &&
    data.map(file => ({
        displayName: file.DisplayName,
        extension: file.Extension,
        file: file.File,
        fileTitleId: file.FileTitleId,
        fileUrl: file.FileUrl,
        id: file.Id,
        name: file.Name,
        offeringId: file.OfferingId,
    }));
