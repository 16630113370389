export interface BankAccountFields {
    AccountDigit: string;
    AccountNumber: string;
    AccountType: string;
    AgencyNumber: string;
    BankNumber: string;
    ClientId: string;
    CpfCoOwner?: string;
    NameCoOwner?: string;
}

export const Actions = {
    NEW_BANK_ACCOUNT_START: 'NEW_BANK_ACCOUNT_START',
    NEW_BANK_ACCOUNT_SET_FIELD: 'NEW_BANK_ACCOUNT_SET_FIELD',
    NEW_BANK_ACCOUNT_VALIDADE_FIELD: 'NEW_BANK_ACCOUNT_VALIDADE_FIELD',
    NEW_BANK_ACCOUNT_RESET_FIELDS: 'NEW_BANK_ACCOUNT_RESET_FIELDS',
};

export const createFields = (
    clientId: number | string,
    accountTypeInitial = '0',
): BankAccountFields => {
    const bankAccountFields: BankAccountFields = {
        AccountDigit: '',
        AccountNumber: '',
        AccountType: accountTypeInitial,
        AgencyNumber: '',
        BankNumber: '',
        ClientId: `${clientId}`,
        CpfCoOwner: '',
        NameCoOwner: '',
    };
    return bankAccountFields;
};

export const createErrors = (): BankAccountFields => {
    const bankAccountErrors: BankAccountFields = createFields('', '');
    return bankAccountErrors;
};
