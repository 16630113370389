import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { BankAccount } from '../Banks';
import { normalizeAbleToWithdraw } from './index.normalize';

/* Withdraw Start */

export const postWithdraw = params => {
    const MARKET_ID = 'XBSP';

    const allParams = { ...params, MarketID: MARKET_ID, TaxValue: 0 };

    return fetch(ENDPOINTS.POST_WITHDRAW, {
        method: 'POST',
        body: JSON.stringify(allParams),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
};

/* Withdraw End */
/* AbleToWithdraw Start  */

export const fetchAbleToWithdrawInit = () => ({
    type: Actions.ABLE_TO_WITHDRAW_FETCH_INIT,
});

export const fetchAbleToWithdraw = async () => {
    const response = await fetch(ENDPOINTS.GET_IS_VALID_WITHDRAW_TIME, {
        method: 'GET',
    });
    const isAble = await response.json();
    return normalizeAbleToWithdraw(isAble);
};

export const fetchAbleToWithdrawSuccess = (isValidTime: boolean) => ({
    type: Actions.ABLE_TO_WITHDRAW_FETCH_SUCCESS,
    isValidTime,
});

export const fetchAbleToWithdrawError = error => ({
    type: Actions.ABLE_TO_WITHDRAW_FETCH_ERROR,
    error: error.message,
});

export const ableToWithdrawReset = () => ({
    type: Actions.ABLE_TO_WITHDRAW_RESET,
});

/* AbleToWithdraw End  */

export const setWithdrawValue = (value: number) => ({
    type: Actions.SET_WITHDRAW_VALUE,
    value,
});

export const setBankAccount = (bankAccount: BankAccount) => ({
    type: Actions.SET_BANK_ACCOUNT,
    bankAccount,
});
