import { parseQuantityInput } from '../../../Utils/Parsers';

const normalizeBalance = balance => balance && parseFloat(parseQuantityInput(balance));

const normalizeDate = date => date && date.split(' ').shift();

const normalizeExtract = extract => ({
    balance: normalizeBalance(extract.Balance),
    balanceCredit: normalizeBalance(extract.BalanceCredit),
    balanceDebt: normalizeBalance(extract.BalanceDebt),
    balanceTotal: normalizeBalance(extract.BalanceTotal),
    date: normalizeDate(extract.Date),
    description: extract.Description,
});

export const normalizeExtracts = extracts => {
    const extractsData =
        extracts && extracts.map ? extracts.map(extract => normalizeExtract(extract)) : [];

    return extractsData;
};

export const normalizePatrimonyInfo = info => ({
    availableBalance: info.Available,
    patrimony: info.Patrimony,
    consolidatedPatrimony: info.GrossEquityIncome,
    balanceFuture: info.BalanceFuture,
    updatedIn: Date.now(),
});
