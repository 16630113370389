import styled from 'styled-components';
import { Text } from '@terrainvest/react-components';

export const StyledHeaderLogOutItem = styled.div`
    display: flex;
    margin-bottom: 53px;
    text-transform: uppercase;
    ${Text.Styles.StyledText} {
        margin: 0;
        color: ${({ theme }) => theme.global.colors.primary} !important;
        font-size: ${({ theme }) => theme.global.fontSizes.xRay};
        cursor: pointer;
        :hover {
            font-weight: ${({ theme }) => theme.global.fontWeights.medium};
        }
    }
`;
