import { fetch, ENDPOINTS } from '../../Utils/Api';
import { PositionActions } from './index.constants';

export const fetchPositions = accountNumber => ({
    type: PositionActions.POSITIONS_FETCH,
    accountNumber,
});

export const createActions = ({ assetName, endpoint, normalize }) => ({
    fetchInit: accountNumber => ({
        type: `${assetName}_FETCH_INIT`,
        accountNumber,
    }),
    fetch: accountNumber =>
        fetch(endpoint.replace(':accountNumber', accountNumber), {
            method: 'GET',
        })
            .then(response => (response.ok ? response.json() : Promise.reject(response.status)))
            .then(normalize),
    fetchSuccess: position => ({
        type: `${assetName}_FETCH_SUCCESS`,
        position,
    }),
    fetchError: error => ({
        type: `${assetName}_FETCH_ERROR`,
        error,
    }),
});

export const fetchFundPosition = (idPosition, accountNumber) => {
    const url = ENDPOINTS.GET_FUND_POSITION.replace(':idPosition', idPosition).replace(
        ':accountNumber',
        accountNumber,
    );

    return fetch(url, { method: 'GET' }).then(response => response.json());
};
