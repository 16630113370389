import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../../../Utils/Api';
import { responseToFile } from '../../../../Utils/Parsers';

export const fetchExtractsPeriodViewInit = data => ({
    type: Actions.FETCH_EXTRACTS_PERIOD_INIT,
    data,
});

export const mountExtractsPeriodViewUrl = data =>
    ENDPOINTS.GET_EXTRACTS_PERIOD_PDF.replace(':accountNumber', data.account)
        .replace(':initial', data.initDate)
        .replace(':final', data.finalDate);

export async function fetchExtractsPeriodView(data) {
    const url = mountExtractsPeriodViewUrl(data);

    const response = await fetch(url, { method: 'GET' });

    if (response.status && response.status === 200) {
        const defaultFilename = `extrato_periodo.pdf`;
        return responseToFile(response, defaultFilename);
    }

    throw new Error(response.status.toString());
}

export const fetchExtractsPeriodViewSuccess = fileURL => ({
    type: Actions.FETCH_EXTRACTS_PERIOD_SUCCESS,
    fileURL,
});

export const fetchExtractsPeriodViewError = error => ({
    type: Actions.FETCH_EXTRACTS_PERIOD_ERROR,
    error: error.message,
});

export const fetchExtractsPeriodViewReset = () => ({
    type: Actions.FETCH_EXTRACTS_RESET,
});

export const fetchExtractSendToEmail = (payload, setVisibleSendToEmail) => {
    fetch(ENDPOINTS.POST_EXTRACTS_EMAIL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
        .then(() => {
            setVisibleSendToEmail(true);
        })
        .catch(() => {
            setVisibleSendToEmail(false);
        });
};
