import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #27272a;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30%;
    ${media.max.md`
        flex-direction: column;
        justify-content: space-between;
        gap: ${({ theme }) => theme.global.spaces.infrared};
        overflow-y: scroll;
    `}
`;
