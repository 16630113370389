import moment from 'moment';
import { fetchUploads } from '../../Utils/Api/fetchUploads';
import { fetch, ENDPOINTS } from '../../Utils/Api';

export const createActions = ({ prefix, endpoint, normalize = data => data }) => ({
    fetchInit: (param?) => ({
        type: `COMMON_${prefix}_FETCH_INIT`,
        param,
    }),
    fetch: param =>
        fetch(endpoint.replace(':param', param), {
            method: 'GET',
        })
            .then(response => response.json())
            .then(normalize),
    fetchSuccess: data => ({
        type: `COMMON_${prefix}_FETCH_SUCCESS`,
        data,
    }),
    fetchError: error => ({
        type: `COMMON_${prefix}_FETCH_ERROR`,
        error,
    }),
});

export const postFiles = fileData => {
    const formData = new FormData();

    formData.append('receipt_customerid', fileData.clientId);
    formData.append('receipt_type', fileData.type);
    formData.append('image0', fileData.file, fileData.file.filename);
    formData.append('receipt_validity_date', moment(new Date()).format('DD/MM/YYYY'));

    return fetchUploads(ENDPOINTS.POST_DOCUMENT, {
        method: 'POST',
        body: formData,
    });
};

export const getAddress = postalCode =>
    fetch(ENDPOINTS.GET_ADDRESS, {
        method: 'POST',
        body: JSON.stringify(postalCode),
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    });
