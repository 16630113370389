import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SideNavContext } from '../../SideNavContext';

import * as S from './index.styles';
import { SideNavSubSectionProps } from './index.types';

const SideNavSubSection = ({ subSection, open }: SideNavSubSectionProps) => {
    const history = useHistory();

    const { setOpen } = React.useContext(SideNavContext);

    const hasItems = !!subSection.items && !!subSection.items.length;
    const isSoon = !!subSection.otherProps && subSection.otherProps.soon;

    const handleClick = (path: string) => () => {
        history.push(`/app/${path}`);
        setOpen(false);
    };

    const renderTitle = () => (
        <S.StyledSubSectionTitle
            onClick={subSection.path ? handleClick(subSection.path) : null}
            {...subSection.otherProps}
        >
            {subSection.title}
            {isSoon && <S.StyledSoon>Em breve</S.StyledSoon>}
        </S.StyledSubSectionTitle>
    );

    if (subSection.otherProps && subSection.otherProps.customComponent)
        return <subSection.otherProps.customComponent />;

    if (!hasItems) return renderTitle();

    return (
        <S.StyledSubSectionWrapper open={open}>
            {renderTitle()}
            {subSection.items.map(item => (
                <S.StyledSubSectionitem
                    key={item.title}
                    onClick={item.path ? handleClick(item.path) : null}
                    {...item.otherProps}
                >
                    {item.title}
                </S.StyledSubSectionitem>
            ))}
        </S.StyledSubSectionWrapper>
    );
};

export default SideNavSubSection;
