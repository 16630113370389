import {
    FormGroup,
    H2,
    InputCpf,
    InputDate,
    InputText,
    Radio,
    Text,
    Select,
    InputTel,
} from '@terrainvest/react-components';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { parseDateForDisplay } from '../../../../../Utils/Parsers';
import { CommonPrefixes } from '../../../../../Redux/Common/index.constants';
import { REGISTRATION_STATUS } from '../../../../../Utils/General/index.registration-status';
import { disclaimerTexts, StyledDisclaimerText } from '../../../../Common/TextualInfo';

import AddressInput from '../../../AdressInput';
import { StyledCol, StyledRow } from '../../../Common/GridStyles';
import { Field, SelectField } from '../../../Form/Field';
import { fieldLengthLimits } from '../../../index.settings';
import {
    dateLessThanToday,
    validateDate,
    validateEmail,
    validateFile,
    validateLength,
    validatePhone,
    validateRequiredCpfValue,
    validateRequiredField,
} from '../../../index.validators';

import * as S from '../index.styles';

export const ProcuratorInputForPerson = ({
    preffix,
    index,
    showValidityDate,
    validityEnabled,
    validityDisabled,
}) => {
    const { values, setFieldValue } = useFormikContext<any>();

    const onChange = (name, type, form) => file => {
        form.setFieldValue('RegistrationStatusId', REGISTRATION_STATUS.IN_ANALYSIS);

        form.setFieldValue(name, {
            file,
            clientId: values.ClientId,
            type,
        });
    };

    const onRemoveFile = name => {
        setFieldValue(name, {});
    };

    const getNameAndRemoveAttribute = name => {
        if (name) {
            const element = document.getElementsByName(name);
            return element[0]?.removeAttribute('hidden');
        }

        return name;
    };

    return (
        <StyledRow>
            <StyledCol md={12} sm={12}>
                <Field name={`${preffix}.ProcuratorName`} validate={validateRequiredField}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Nome"
                            fullWidth
                            maxLength={fieldLengthLimits.name}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.ProcuratorCpfCnpj`} validate={validateRequiredCpfValue}>
                    {({ inputProps }) => <InputCpf {...inputProps} label="CPF" fullWidth />}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}.DocumentTypeId`}
                    commonPrefix={CommonPrefixes.DocumentTypes}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange: onChangeSelect, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Tipo de Documento"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChangeSelect}
                        />
                    )}
                </SelectField>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.DocumentNumber`} validate={validateRequiredField}>
                    {({ inputProps }) => (
                        <InputText {...inputProps} label="Número do Documento" fullWidth />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.DocumentIssuerDate`} validate={validateDate}>
                    {({ inputProps }) => (
                        <InputDate
                            {...inputProps}
                            id={`procurators[${preffix}]-validate-date`}
                            label="Data de emissão"
                            fullWidth
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}.DocumentIssuerName`}
                    commonPrefix={CommonPrefixes.DocumentIssuerAgencies}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange: onChangeSelect, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="Órgão emissor"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChangeSelect}
                        />
                    )}
                </SelectField>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <SelectField
                    name={`${preffix}.DocumentIssuerState`}
                    commonPrefix={CommonPrefixes.States}
                    validate={validateRequiredField}
                >
                    {({ field, meta, onChange: onChangeSelect, options, defaultValue }) => (
                        <Select
                            name={field.name}
                            label="UF do emissor"
                            fullWidth
                            suggest
                            suggestLimit={8}
                            error={!!meta.error}
                            message={meta.error || ''}
                            defaultValue={defaultValue}
                            options={options}
                            onChange={onChangeSelect}
                        />
                    )}
                </SelectField>
            </StyledCol>

            <AddressInput preffix={preffix} />

            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.Skype`} validate={value => validateLength('skype', value)}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="Skype"
                            fullWidth
                            maxLength={fieldLengthLimits.skype}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.MobilePhone`} validate={validatePhone}>
                    {({ inputProps }) => <InputTel {...inputProps} label="Celular" fullWidth />}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.Email`} validate={validateEmail}>
                    {({ inputProps }) => (
                        <InputText
                            {...inputProps}
                            label="E-mail"
                            fullWidth
                            maxLength={fieldLengthLimits.email}
                        />
                    )}
                </Field>
            </StyledCol>
            <StyledCol md={6} sm={12}>
                <Field name={`${preffix}.PoliticallyExposedPerson`}>
                    {({ field, form }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>É pessoa políticamente exposta?</Text>
                            <Radio
                                label="Sim"
                                defaultChecked={field.value === true}
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                onChange={() => {
                                    form.setFieldValue(
                                        `${preffix}.PoliticallyExposedPerson` as never,
                                        true,
                                    );
                                }}
                            />
                            <Radio
                                label="Não"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={() => {
                                    form.setFieldValue(
                                        `${preffix}.PoliticallyExposedPerson` as never,
                                        false,
                                    );
                                }}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>
            <StyledCol col={12}>
                <StyledDisclaimerText small>
                    {disclaimerTexts.procurator.authorization}
                </StyledDisclaimerText>
            </StyledCol>
            <StyledCol md={12} sm={12}>
                <Field name={`${preffix}.ValidityIndeterminate`}>
                    {({ field }) => (
                        <FormGroup
                            name={field.name}
                            border
                            vertical={false}
                            fullWidth
                            onChange={evt => {
                                field.onChange(evt);
                            }}
                        >
                            <Text>Validade</Text>
                            <Radio
                                label="Indeterminada"
                                // eslint-disable-next-line react/jsx-boolean-value
                                defaultValue={true}
                                defaultChecked={!field.value || field.value === true}
                                onChange={validityEnabled}
                                onClick={validityEnabled}
                            />
                            <Radio
                                label="Até"
                                defaultChecked={field.value === false}
                                defaultValue={false}
                                onChange={validityDisabled}
                                onClick={validityDisabled}
                            />
                        </FormGroup>
                    )}
                </Field>
            </StyledCol>

            {showValidityDate && (
                <StyledCol md={6} sm={12}>
                    <Field name={`${preffix}.ValidityDate`} validate={dateLessThanToday}>
                        {({ inputProps, field }) => {
                            return (
                                <InputDate
                                    {...inputProps}
                                    defaultValue={parseDateForDisplay(field.value)}
                                    label="Validade"
                                    fullWidth
                                    laterDate
                                    calendarProps={{
                                        maxYear: new Date().getFullYear() + 30,
                                        minYear: new Date().getFullYear(),
                                    }}
                                />
                            );
                        }}
                    </Field>
                </StyledCol>
            )}

            <Field name={`files.authenticatedPowerofAttorney${index}`} validate={validateFile}>
                {({ field, meta, form }) => {
                    return (
                        <>
                            <S.StyledFileSection
                                name={getNameAndRemoveAttribute(field.name)}
                                error={meta.error}
                            >
                                <H2 weight="medium">Procuração do Procurador {index + 1}</H2>
                                <S.CustomInputFileUpload
                                    name={field.name}
                                    width="100%"
                                    error={!!meta.error}
                                    message="Arquivos aceitos: PNG, JPEG e PDF"
                                    onChange={onChange(field.name, 17, form)}
                                    onRemove={() => onRemoveFile(field.name)}
                                />
                                <S.ErrorText>{meta.error ? meta.error : ''}</S.ErrorText>
                            </S.StyledFileSection>
                        </>
                    );
                }}
            </Field>

            <Field name={`files.procuratorDocument${index}`} validate={validateFile}>
                {({ field, meta, form }) => {
                    return (
                        <S.StyledFileSection
                            name={getNameAndRemoveAttribute(field.name)}
                            error={meta.error}
                        >
                            <H2 weight="medium">
                                Documento de Identificação do Procurador {index + 1}
                            </H2>
                            <S.CustomInputFileUpload
                                name={field.name}
                                width="100%"
                                error={!!meta.error}
                                message="Arquivos aceitos: PNG, JPEG e PDF"
                                onChange={onChange(field.name, 21, form)}
                                onRemove={() => onRemoveFile(field.name)}
                            />
                            <S.ErrorText>{meta.error ? meta.error : ''}</S.ErrorText>
                        </S.StyledFileSection>
                    );
                }}
            </Field>
        </StyledRow>
    );
};

export default ProcuratorInputForPerson;
