import { fetch, ENDPOINTS } from '../../Utils/Api';
import { Actions } from './index.constants';
import { BannerResponseNormalized } from './index.types';
import { normalizeBanners } from './index.normalize';

const getSeenBanners = () => JSON.parse(sessionStorage.getItem('seenBanners')) || [];
const setSeenBanners = (banner: BannerResponseNormalized) => {
    const seen = getSeenBanners();

    if (seen.indexOf(banner.id) === -1) {
        seen.push(banner.id);

        sessionStorage.setItem('seenBanners', JSON.stringify(seen));
    }
};

export const fetchBannersInit = () => {
    return {
        type: Actions.BANNERS_FETCH_INIT,
    };
};

export const fetchBanners = () => {
    return fetch(ENDPOINTS.GET_BANNERS, {
        method: 'GET',
    })
        .then(response => response.json())
        .then((response: any) => normalizeBanners(response))
        .then(normalizedBanners => {
            const today = new Date();
            // const seenBanners = getSeenBanners();

            // const banners = normalizedBanners.filter(
            //     banner => seenBanners.indexOf(banner.id) === -1 && today <= banner.endDateObject,
            // );

            const banners = normalizedBanners.filter(
                banner => today >= banner.startDateObject && today <= banner.endDateObject,
            );

            return banners;
        });
};

export const fetchBannersSuccess = (banners: BannerResponseNormalized[]) => {
    return {
        type: Actions.BANNERS_FETCH_SUCCESS,
        banners,
    };
};

export const fetchBannersError = (error: Error) => {
    return {
        type: Actions.BANNERS_FETCH_ERROR,
        error,
    };
};

export const markBannerAsSeen = (
    banners: BannerResponseNormalized[],
    currentBanner: BannerResponseNormalized,
) => {
    setSeenBanners(currentBanner);

    return {
        type: Actions.BANNERS_SET,
        banners: banners.filter(banner => banner.id !== currentBanner.id),
    };
};

export const markBannerAsRead = () => ({
    type: Actions.BANNERS_SET_READ_STATUS,
    readStatus: true,
});

export const markBannerAsUnread = () => ({
    type: Actions.BANNERS_SET_READ_STATUS,
    readStatus: false,
});
