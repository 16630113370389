import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';

import { fetchRLPStatus, fetchRLPStatusSuccess, fetchRLPStatusError } from './index.actions';

export const initialState = {
    isRlpActive: null,
    loading: false,
    error: null,
};

export const rlpReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RLP_GET_STATUS_INIT:
            return loop(
                { ...state, loading: true, error: null },
                Cmd.run(fetchRLPStatus, {
                    successActionCreator: fetchRLPStatusSuccess,
                    failActionCreator: fetchRLPStatusError,
                    args: [action.clientId],
                }),
            );
        case Actions.RLP_GET_STATUS_SUCCESS:
            return {
                ...state,
                isRlpActive: action.response,
                loading: false,
                error: false,
            };
        case Actions.RLP_GET_STATUS_ERROR:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};

export default rlpReducer;
