import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { BankAccount, normalizeBanks } from './index.normalize';

export const fetchBanksInit = clientId => ({
    type: Actions.BANKS_FETCH_INIT,
    clientId,
});

export const fetchBanks = clientId => {
    return fetch(ENDPOINTS.GET_BANKS.replace(':clientId', clientId), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(banks => normalizeBanks(banks));
};

export const fetchBanksSuccess = (banks: Array<BankAccount>) => ({
    type: Actions.BANKS_FETCH_SUCCESS,
    banks,
});

export const fetchBanksError = error => ({
    type: Actions.BANKS_FETCH_ERROR,
    error: error.message,
});

export const fetchBanksReset = () => ({
    type: Actions.BANKS_FETCH_RESET,
});

export const selectBankAccount = (bankAccountIndex: number) => ({
    type: Actions.BANKS_SELECT_ACCOUNT,
    bankAccountIndex,
});

export const setShowingNewAccount = (isShowing: boolean) => ({
    type: Actions.BANKS_SHOW_NEW_ACCOUNT_ACCOUNT,
    isShowing,
});
