import { findAuthorization } from './index.helpers';

const BaseAuthorizer = {
    get: (authorizationKey: string) => {
        const authorizerConfig = findAuthorization(authorizationKey);

        if (authorizerConfig) {
            return authorizerConfig.authorizer();
        }

        throw new Error(`Authorization not configured for key "${authorizationKey}"`);
    },
    has: (authorizationKey: string): boolean => {
        return !!findAuthorization(authorizationKey);
    },
};

export default BaseAuthorizer;
