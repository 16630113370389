import { loop, Cmd } from 'redux-loop';
import { Actions, INNER_TABS_NAMES } from './index.constants';
import {
    fetchExtractsDate,
    fetchExtractsDateSuccess,
    fetchExtractsDateError,
} from './index.actions';

export const initialState = {
    extracts: null,
    extractsTab: INNER_TABS_NAMES.tab1,
    loading: false,
    error: null,
    fileUrl: null,
};

export const extractsReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_EXTRACTS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchExtractsDate, {
                    successActionCreator: fetchExtractsDateSuccess,
                    failActionCreator: fetchExtractsDateError,
                    args: [action.account],
                }),
            );

        case Actions.FETCH_EXTRACTS_SUCCESS:
            return { ...state, extracts: [...action.extracts], loading: false, error: false };

        case Actions.FETCH_EXTRACTS_ERROR:
            return { ...state, error: action.error, loading: false };

        case Actions.FETCH_EXTRACTS_RESET:
            return { ...state, extracts: null, loading: false, error: false };

        case Actions.CHANGE_EXTRACTS_TAB_INIT:
            return { ...state, extractsTab: action.extractsTab, loading: false, error: false };

        case Actions.CHANGE_EXTRACTS_TAB_SUCCESS:
            return { ...state, extractsTab: action.extractsTab, loading: false, error: false };

        case Actions.CHANGE_EXTRACTS_TAB_ERROR:
            return { ...state, error: true, loading: false };

        case Actions.CHANGE_EXTRACTS_TAB_RESET:
            return { ...state, extractsTab: INNER_TABS_NAMES.tab1, loading: false, error: false };

        default:
            return state;
    }
};
