import * as React from 'react';

import { ErrorText } from '../index.types';
import * as S from './index.styles';

const StepError = ({ errorTexts }: { errorTexts: Array<ErrorText> }) => {
    return (
        <S.StyledError>
            <S.StyledIcon icon={['far', 'circle-exclamation']} />
            <S.StyledTexts>
                {errorTexts.map(({ text, bold }, index) => (
                    <S.StyledText bold={bold} key={index.toString()}>
                        {text}
                    </S.StyledText>
                ))}
            </S.StyledTexts>
        </S.StyledError>
    );
};

export default StepError;
