import { Actions } from './index.constants';
import { fetch, ENDPOINTS } from '../../Utils/Api';
import { normalizeBalance } from './index.normalize';

export const fetchBalanceInit = accountNumber => ({
    type: Actions.BALANCE_FETCH_INIT,
    accountNumber,
});

export const fetchBalance = accountNumber => {
    return fetch(ENDPOINTS.GET_BALANCE_REPORT.replace(':accountNumber', accountNumber), {
        method: 'GET',
    })
        .then(response => response.json())
        .then(normalizeBalance);
};

export const fetchBalanceSuccess = balance => ({
    type: Actions.BALANCE_FETCH_SUCCESS,
    balance,
});

export const fetchBalanceError = error => ({
    type: Actions.BALANCE_FETCH_ERROR,
    error: error.message,
});

export const toggleBalanceVisibility = () => ({
    type: Actions.BALANCE_TOGGLE_VISIBILITY,
});
