import styled, { css } from 'styled-components';
import { Icon, Text } from '@terrainvest/react-components';
import { NAV_MOBILE_MAX_WIDTH } from '../SideNav/index.constants';

export const StyledMobileSidebarWrapper = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    transition: ${({ theme }) => theme.components.header.transition} !important;
    background-color: ${({ theme }) => theme.global.colors.mainBackground};
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-sizing: border-box;
    z-index: 800;
`;

export const openStyles = css`
    ${Text.Styles.StyledText}, ${Icon.Styles.StyledIcon} {
        color: ${({ theme }) => theme.global.colors.primary} !important;
    }
    ::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${({ theme }) => theme.global.colors.primary};
        border-radius: 8px;
    }
`;

export const StyledMobileSidebarItem = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: ${({ theme }) =>
        `${theme.global.spaces.highFrequencyXRay} ${theme.global.spaces.lowFrequencyXRay}`};
    ${({ open }) => open && openStyles};
`;

export const StyledIcon = styled(Icon)`
    color: ${({ theme }) => theme.global.colors.backgroundTextColor};
`;

export const StyledText = styled(Text)`
    margin: 0;
    color: ${({ theme }) => theme.global.colors.white};
`;

export const StyledOverflow = styled.div`
    position: fixed;
    top: ${({ theme }) => theme.global.spaces.highFrequencyRadio};
    height: 100vh;
    right: 0;
    left: ${({ open }) => (open ? NAV_MOBILE_MAX_WIDTH : 'unset')};
    background-color: #27272a4d;
    backdrop-filter: blur(4px);
    z-index: 800;

    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: opacity 0.4s 0.2s;
`;

export const StyledFinancialOverview = styled.div`
    position: fixed;
    top: ${({ open, theme }) => (open ? theme.global.spaces.highFrequencyRadio : '100vh')};
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.global.spaces.lowFrequencyVisible};
    transition: ${({ theme }) => theme.components.header.transition} !important;
    background-color: #f4f4f4;
    box-sizing: border-box;
    z-index: 700;
`;
