import styled from 'styled-components';

import { OpenFlatCard, HighlightedText, Text, H3 } from '@terrainvest/react-components';
import { GlobalNotification } from '../Common/GlobalNotification';

export const StyledOpenFlatCard = styled(OpenFlatCard)`
    display: flex;
    padding: 24px;
    width: 100%;
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    & ${HighlightedText.Styles.StyledSubTitle}, ${H3} {
        margin-top: 0 !important;
    }
`;

export const StyledDisclaimer = styled(Text)`
    font: ${props => props.theme.global.typographyConfig.headings.h3.font};
    color: ${props => props.theme.components.notification.colors.warn.foreground} !important;
    text-align: justify;
`;

export const StyledGlobalNotification = styled(GlobalNotification)`
    & div {
        width: 100%;
        margin: 0;
    }
`;
