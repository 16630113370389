import {
    parseDateForDisplay,
    parseFinancialValue,
    parseTimeForDisplay,
} from '../../../Utils/Parsers';
import { TransferRequestProps } from './index.types';

export const normalizeHistory = (history): Array<TransferRequestProps> =>
    history.map(entry => ({
        ...entry,
        DateNormalized: parseDateForDisplay(entry.Date),
        TimeNormalized: parseTimeForDisplay(entry.Date),
        AmountNormalized: parseFinancialValue(entry.Amount),
    }));
