import * as React from 'react';

import PartnerLogo from './PartnerLogo';
import AppDownload from './AppDownload';
import * as S from './index.styles';

const PartnerSide = () => {
    return (
        <S.StyledPartnerWrapper>
            <PartnerLogo />
            <AppDownload />
        </S.StyledPartnerWrapper>
    );
};

export default PartnerSide;
