import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import { StyledSubtitle } from '../../ProfileMenu/index.styles';
import * as S from './index.styles';

export const HeaderAdvisorItem = () => {
    const { advisor } = useSelector((state: RootState) => state.advisorsState);

    const mountPhoneToLink = () => {
        const { phone } = advisor.normalized;
        return `tel:${phone.replace(new RegExp(/\D+/g), '')}`;
    };

    const mountEmailToLink = () => {
        const email = advisor.Email.toLowerCase();
        return `mailto:${email}`;
    };

    if (!advisor) return <></>;

    return (
        <S.StyledWrapper>
            <StyledSubtitle>Assessor</StyledSubtitle>
            <S.StyledItemWrapper>
                <S.StyledItemText>
                    <b>{advisor.Name}</b>
                </S.StyledItemText>
            </S.StyledItemWrapper>

            <S.StyledItemWrapper>
                <S.StyledItemText>{advisor.normalized.phone}</S.StyledItemText>
                <S.StyledIcon
                    icon={['fal', 'phone']}
                    onClick={() => window.open(mountPhoneToLink(), '_self')}
                />
            </S.StyledItemWrapper>

            <S.StyledItemWrapper>
                <S.StyledItemText>{advisor.Email.toLowerCase()}</S.StyledItemText>
                <S.StyledIcon
                    icon={['fal', 'envelope']}
                    onClick={() => window.open(mountEmailToLink(), '_self')}
                />
            </S.StyledItemWrapper>
        </S.StyledWrapper>
    );
};

export default HeaderAdvisorItem;
