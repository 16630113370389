import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { channelIds } from '../../Redux/Client';

const ChannelAuthorizer = ({ children, allowRedirect, blockInstitutional }) => {
    const { client } = useSelector(state => state.clientState);

    const authorized =
        (client && client.ChannelId !== channelIds.INSTITUTIONAL && blockInstitutional) ||
        !blockInstitutional;

    if (authorized) {
        return <>{children}</>;
    }

    if (allowRedirect && !authorized) {
        return <Redirect to="/app" />;
    }

    return null;
};

ChannelAuthorizer.propTypes = {
    children: PropTypes.node.isRequired,
    allowRedirect: PropTypes.bool,
    blockInstitutional: PropTypes.bool,
};

ChannelAuthorizer.defaultProps = {
    allowRedirect: false,
    blockInstitutional: false,
};

export default ChannelAuthorizer;
