import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@terrainvest/react-components';

import { fetchResetPassword } from '../../../../../Redux/Auth';
import { postNotificationFromError } from '../../../../../Redux/Notification';
import { usePasswordTips } from '../../../../../Utils/Hooks/usePasswordTips';

import { BottomWrapper, InputWrapper, StyledText } from '../../LoginForm/index.styles';

import { StyledPrimaryButton } from '../index.styles';
import { StyledInsideTitle, StyledSpacingText } from '../../index.styles';

import PasswordTips from './PasswordTips';
import * as S from './index.styles';
import { NewPasswordProps } from './index.types';
import { isKnownError } from './index.constants';

const NewPassword = ({ username, token, onPasswordChange, onPasswordError }: NewPasswordProps) => {
    const dispatch = useDispatch();

    const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const {
        password,
        confirmPassword,
        passwordTipsComponent,
        setPassword,
        setConfirmPassword,
    } = usePasswordTips({
        title: 'Requisitos',
        onSuccess: () => setCanSubmit(true),
        onError: () => setCanSubmit(false),
        PasswordTipComponent: PasswordTips,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setter: Function) => {
        setter(event.target.value);
    };

    const disablePaste = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const resetPassword = async () => {
        if (loading) return;
        setLoading(true);

        const resetPayload = {
            username,
            token,
            newPassword: password,
        };

        fetchResetPassword(resetPayload)
            .then(() => onPasswordChange())
            .catch(e => {
                if (isKnownError(e)) {
                    dispatch(postNotificationFromError(e));
                    return;
                }
                onPasswordError();
            })
            .finally(() => setLoading(false));
    };

    return (
        <S.StyledWrapper>
            <S.StyledTop>
                <StyledInsideTitle style={{ margin: 0 }}>Alteração de senha</StyledInsideTitle>

                <StyledSpacingText>Cadastre abaixo uma nova senha de acesso</StyledSpacingText>
            </S.StyledTop>

            <InputWrapper>
                <StyledText>Digite sua nova senha</StyledText>
                <S.StyledInputPassword
                    id="new-password"
                    name="password"
                    autcomplete="new-password"
                    fullWidth
                    value={password}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(evt, setPassword)
                    }
                />
            </InputWrapper>
            <InputWrapper>
                <StyledText>Confirme sua nova senha</StyledText>
                <S.StyledInputPassword
                    id="new-password-confirm"
                    name="confirmPassword"
                    autcomplete="new-password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(evt, setConfirmPassword)
                    }
                    onPaste={disablePaste}
                    onDrop={disablePaste}
                />
            </InputWrapper>

            <BottomWrapper>
                <StyledPrimaryButton onClick={resetPassword} disabled={!canSubmit}>
                    Continuar
                    {loading && <Icon icon="spinner-third" spin size="xs" padRight />}
                </StyledPrimaryButton>
            </BottomWrapper>

            <S.TipsSpace />

            {passwordTipsComponent}
        </S.StyledWrapper>
    );
};

export default NewPassword;
