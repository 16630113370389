import styled from 'styled-components';
import { InputFileUpload, Text } from '@terrainvest/react-components';

interface StyledInputUploadFileProps {
    show: boolean;
}

interface StyledFileSectionProps {
    error: boolean;
}

export const StyledFileSection = styled.div<StyledFileSectionProps>`
    width: 100%;

    margin-left: -17px;

    &:not(:first-child) {
        margin-top: 40px;
    }

    ${InputFileUpload.Styles.StyledDropeZone} {
        margin-top: 28px;
    }

    ${({ error }) => (error ? `border-radius: 2px; padding: 16px; border:1px solid red;` : 'none')};
`;

export const CustomInputFileUpload = styled(InputFileUpload)<StyledInputUploadFileProps>`
    input {
        visibility: hidden;
    }
`;

export const ErrorText = styled(Text)`
    text-align: center;
    color: ${props => props.theme.global.colors.error} !important;
`;
