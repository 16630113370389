import styled, { css } from 'styled-components';
import { Text } from '@terrainvest/react-components';
import { StyledButton } from '../index.styles';

export const FeedbackWrapper = styled.div`
    width: 100%;
    & div {
        background-color: unset;
        box-shadow: none;
        min-height: 0;
        padding: 0;
    }

    && h3 {
        color: ${({ color }) => color};
        max-width: 300px;
    }
    && path,
    && i {
        stroke: ${({ color }) => color};
    }
    ${({ spacingText }) =>
        spacingText &&
        css`
            p {
                letter-spacing: 0.9px;
            }
            h3 {
                margin-top: ${({ theme }) => theme.global.spaces.lowFrequencyXRay};
            }
        `};
`;

export const StyledPrimaryButton = styled(StyledButton)`
    margin-top: ${({ theme }) => theme.global.spaces.lowFrequencyVisible};
`;

export const StyledBoldText = styled(Text)`
    font-weight: ${({ theme }) => theme.global.fontWeights.bold};
`;
