import { loop, Cmd } from 'redux-loop';
import { Actions } from './index.constants';
import { fetchInvestmentOffers, fetchOffersSuccess, fetchOffersError } from './index.actions';

export const initialState = {
    offers: null,
    loading: false,
    error: null,
};

export const investmentOffersReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_OFFERS_INIT:
            return loop(
                { ...state, loading: true },
                Cmd.run(fetchInvestmentOffers, {
                    successActionCreator: fetchOffersSuccess,
                    failActionCreator: fetchOffersError,
                }),
            );

        case Actions.FETCH_OFFERS_SUCCESS:
            return { ...state, offers: action.offers, loading: false, error: false };

        case Actions.FETCH_OFFERS_ERROR:
            return { ...state, error: action.error, loading: false };

        default:
            return state;
    }
};
