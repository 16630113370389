import { StatusOfSubscriptionProps } from './index.types';

/* eslint-disable max-len */
const basicUrl = '/app/subscricao';
const subscriptionsUrl = '/:id/:type';
const historyUrl = '/historico';

export const urls = {
    BASIC: basicUrl,
    SUBSCRIPTION: subscriptionsUrl,
    HISTORY: historyUrl,
    SUBSCRIPTION_FULL: `${basicUrl}${subscriptionsUrl}`,
    HISTORY_FULL: `${basicUrl}${historyUrl}`,
};

export const urlInfo = {
    root: '/app/subscricao',
    frag: '/:id/:type',
    full: '/app/subscricao/:id/detalhes',
    fullReserves: '/app/subscricao/reservas/',
    reserves: '/reservas/',
    history: '/historico',
    fragReserves: '/app/subscricao/:id/reserva/',
};

export const bgSuitabilityColors = {
    conservative: '#BDECEF',
    moderate: '#F7EBB5',
    risky: '#F7D0CE',
    none: '#A1A1AA',
    open: '#D8F3FB',
    processing: '#FBE6D3',
    finalized: '#16A34A',
    registered: '#4ADE80',
};

export const textSuitabilityColors = {
    conservative: '#155E62',
    moderate: '#715E0A',
    risky: '#A92621',
    none: '#A1A1AA',
    open: '#00A0CB',
    processing: '#DA7F2B',
    finalized: '#FFFFFF',
    registered: '#004E32',
};

export const format = (value: number) => {
    return String(value).replace('.', ',');
};

export const label = {
    confirm: 'Para confirmar a subscrição, por favor, insira sua assinatura eletrônica abaixo.',
    cancel: 'Para cancelar a reserva, por favor, insira sua assinatura eletrônica abaixo.',
    description:
        'Se desejar cancelar, selecione a opção ao lado e depois insira sua assinatura eletrônica para continuar.',
    operations: `
    Subscrição de ações é uma operação que permite a conversão de novas ações ordinárias da empresa a um preço fixo proporcional, em algum momento do futuro.

    O direito de subscrição é concedido aos acionistas como uma espécie de preferência para a compra de novas ações que serão eventualmente emitidas pela empresa.
    `,
    textEmpty: 'Você ainda não tem nenhum ativo com direito a subscrição para ser exibido.',
};

export const StatusOfSubscription: StatusOfSubscriptionProps[] = [
    { oldValue: 'canFail', parsedValue: 'Pendentes' },
    { oldValue: 'discarded', parsedValue: 'Descartado' },
    { oldValue: 'failed', parsedValue: 'Falhou' },
    { oldValue: 'notSent', parsedValue: 'Não enviado' },
    { oldValue: 'sent', parsedValue: 'Processando' },
    { oldValue: 'waitingExternal', parsedValue: 'Aguard. aprovações' },
    { oldValue: 'sync_error', parsedValue: 'Falhou' },
    { oldValue: 'done', parsedValue: 'Finalizado' },
    { oldValue: 'registered', parsedValue: 'Registrado' },
    { oldValue: 'liquidation', parsedValue: 'Em liquidação' },
    { oldValue: 'Em aberto', parsedValue: 'Em aberto' },
];

export enum TAB_NAME {
    TAB1 = 0,
    TAB2 = 1,
    TAB3 = 2,
}
