import * as React from 'react';
import { Icon } from '@terrainvest/react-components';
import * as S from './index.styles';

const ErrorMessage = ({ message }) => (
    <S.StyledMessageWrapper>
        <Icon icon={['far', 'circle-exclamation']} /> {message}
    </S.StyledMessageWrapper>
);

export default ErrorMessage;
