export enum SORTING_ORDER {
    ASCENDING = 'ascending',
    DESCENDING = 'descending',
}

export const sortByProp = (obj1, obj2, prop) => {
    const prop1 = obj1[prop].toUpperCase();
    const prop2 = obj2[prop].toUpperCase();

    if (prop1 < prop2) {
        return -1;
    }
    if (prop1 > prop2) {
        return 1;
    }
    return 0;
};

export const sortStringProps = (obj1, obj2, prop: string, direction: SORTING_ORDER) => {
    const prop1 = obj1[prop].toUpperCase();
    const prop2 = obj2[prop].toUpperCase();

    return direction === SORTING_ORDER.ASCENDING
        ? prop1.localeCompare(prop2, 'pt-BR')
        : prop2.localeCompare(prop1, 'pt-BR');
};
