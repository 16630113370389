import { fetch, ENDPOINTS } from '../../../Utils/Api';
import { Actions } from './index.constants';
import { BankAccount } from '../index.normalize';

export const removeBankAccount = (bankAccountId: string | number, electronicCheck: string) => {
    return fetch(ENDPOINTS.DELETE_REMOVE_BANK_ACCOUNT, {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ bankAccountId, electronicCheck }),
    });
};

export const selectBankAccountToRemove = (bankAccount: BankAccount) => ({
    type: Actions.REMOVE_BANK_ACCOUNT_SELECT,
    bankAccount,
});

export const resetBankAccountToRemove = () => ({
    type: Actions.REMOVE_BANK_ACCOUNT_RESET,
});
