/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HighlightedText, Link, H3, Checkbox, Icon } from '@terrainvest/react-components';

import { RootState } from '../../../store';
import { postClientAgreement, fetchClientAgreementByTypeInit } from '../../../Redux/Agreements';
import {
    postNotificationFromError,
    notifySuccessMsg,
    postNotification,
} from '../../../Redux/Notification';

import { Separator } from '../../../Utils/General/index.separator';
import { StyledContainerNoPadd, StyledRowNoPadd, StyledColNoPadd } from '../../Common/Grid';

import { disclaimerTexts } from '../../Common/TextualInfo';
import { ElectronicPasswordInput } from '../../Common/ElectronicPasswordInput';
import { ENDPOINTS } from '../../../Utils/Api';
import { useNotifyAppLoad } from '../../../Utils/Hooks';
import { needsAgreementUpdate } from '../../../Utils/General';
import HybridLink from '../../Common/HybridLink';

import {
    StyledOpenFlatCard,
    StyledContentWrapper,
    StyledDisclaimer,
    StyledGlobalNotification,
} from '../index.styles';
import * as S from './index.styles';

export const ExpiredIntermediationContract = () => {
    const RPA_CONTRACT_ID = '28';

    const [termAcceptance, setTermAcceptance] = React.useState(false);

    const { client, loading: loadingClient } = useSelector((state: RootState) => state.clientState);
    const { agreementByType: agreement, loading: loadingAgreement } = useSelector(
        (state: RootState) => state.agreementsState,
    );

    const [electronicCheck, setElectronicCheck] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    useNotifyAppLoad();

    const renderContractCheckbox = () => {
        const urlContractFile = `${
            process.env.API_URL
        }${ENDPOINTS.GET_AGREEMENTS_PDF_BY_CLIENT_ID.replace(':clientId', client.ClientId).replace(
            ':signatureOption',
            'true',
        )}`;

        const urlRpaFile = `${process.env.API_URL}${ENDPOINTS.GET_AGREEMENT_FILE.replace(
            ':agreementId',
            RPA_CONTRACT_ID,
        )}`;

        const agreementText = (
            <S.StyledAgreementText>
                Declaro que li e concordo com o{' '}
                <HybridLink overrideGetUrl href={urlContractFile}>
                    <Link border>Contrato de Intermediação</Link>
                </HybridLink>
                {` e `}
                <HybridLink overrideGetUrl href={urlRpaFile}>
                    <Link border>Regras e Parâmetros</Link>
                </HybridLink>
            </S.StyledAgreementText>
        );

        return (
            <S.StyledTermsButtonsWrapper>
                <Checkbox
                    label={agreementText}
                    value={termAcceptance}
                    onChange={e => setTermAcceptance(e.target.checked)}
                />
            </S.StyledTermsButtonsWrapper>
        );
    };

    const handleDataNotExpired = () => {
        redirectToHome();
    };

    const handleDataExpired = () => {
        notifyExpiration();
    };

    const handleChangedElectronicPassword = event => {
        setElectronicCheck(event.target.value);
    };

    const handleSubmit = async () => {
        const agreementRPA = {
            AgreementType: Number(RPA_CONTRACT_ID),
        };
        setSubmitting(true);
        try {
            await postClientAgreement(agreement, client, electronicCheck);
            await postClientAgreement(agreementRPA, client, electronicCheck);

            dispatch(fetchClientAgreementByTypeInit(client))
                .then(redirectToHome)
                .then(() =>
                    dispatch(notifySuccessMsg('Sucesso! Registro atualizado.', { timeout: 8000 })),
                );
        } catch (e) {
            dispatch(postNotificationFromError(e));
            setSubmitting(false);
        }
    };

    const redirectToHome = () => {
        return history.push('/app');
    };

    const notifyExpiration = () => {
        dispatch(
            postNotification('A validade de seu contrato de intermediação expirou.', {
                timeout: 8000,
                type: 'error',
            }),
        );
    };

    React.useEffect(() => {
        const hasClient = client && !loadingClient;
        const hasAgreement = agreement && !loadingAgreement;

        if (!hasClient) {
            return;
        }

        if (!hasAgreement) {
            dispatch(fetchClientAgreementByTypeInit(client));
            return;
        }

        if (hasClient && hasAgreement) {
            setSubmitting(false);
            const userDataExpired = needsAgreementUpdate(agreement);

            if (!userDataExpired) {
                handleDataNotExpired();
            }

            if (userDataExpired) {
                handleDataExpired();
            }
        }
    }, []);

    return (
        <StyledContainerNoPadd>
            <StyledRowNoPadd>
                <StyledColNoPadd>
                    <Separator />
                    <StyledOpenFlatCard>
                        <StyledContentWrapper>
                            <HighlightedText
                                asideColor="error"
                                title="TERMOS ATUALIZADOS"
                                subTitle="Contrato de Intermediação"
                                large
                            />

                            <StyledDisclaimer>
                                {disclaimerTexts.dataExpiration.reviewAndAcceptToContinue}
                            </StyledDisclaimer>
                        </StyledContentWrapper>
                    </StyledOpenFlatCard>
                    <Separator />
                    <StyledOpenFlatCard>
                        <StyledContentWrapper>
                            <H3>Contrato de Intermediação</H3>
                            {renderContractCheckbox()}
                            <Separator />
                            <ElectronicPasswordInput
                                fullWidth
                                name="password"
                                id="password"
                                label="Assinatura eletrônica"
                                autoComplete="new-password"
                                onChange={handleChangedElectronicPassword}
                                setLoading={loadingClient}
                            />
                            <Separator />
                            <S.StyledSubmitButton
                                fullWidth
                                type="submit"
                                onClick={handleSubmit}
                                disabled={submitting || !electronicCheck.length || !termAcceptance}
                            >
                                {submitting && <Icon icon="spinner" spin padRight />}
                                ACEITAR E CONTINUAR
                            </S.StyledSubmitButton>
                        </StyledContentWrapper>
                    </StyledOpenFlatCard>
                </StyledColNoPadd>
            </StyledRowNoPadd>
            <StyledGlobalNotification />
        </StyledContainerNoPadd>
    );
};

export default ExpiredIntermediationContract;
