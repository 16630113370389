export const normalizeBanks = banks =>
    banks.map(bank => ({ id: bank.Value, value: `${bank.Value} - ${bank.Description}` }));

export const normalizeAdvisors = advisors =>
    advisors.map(advisor => ({ id: advisor.Id, value: advisor.Name, ...advisor }));

export const normalizeRepresentativeCategories = options =>
    options.map(option => ({ id: option.Id, value: option.Name, ...option }));

export const normalizeOptions = options =>
    options.map(option => ({ id: option.Value, value: option.Description, ...option }));

export const normalizePJActivities = options =>
    options.map(option => ({ id: option.Id, value: option.Description, ...option }));

export const normalizeConstitution = options =>
    options.map(option => ({ id: option.Id, value: option.Description, ...option }));

export const normalizeFatca = options =>
    options
        .map(option => ({
            id: option.Id,
            value: option.Description,
            hasGiin: option.Giin,
        }))
        .sort((a, b) => (a.value < b.value ? -1 : 1));
