import styled from 'styled-components';
import { Button } from '@terrainvest/react-components';

export const StyledFinalData = styled.div`
    ${Button.Styles.StyledButton} {
        height: 48px;
        margin-bottom: 32px;
    }
`;

export const Wrapper = styled.div`
    div[type='error'] {
        margin-bottom: 2rem;
    }
`;
